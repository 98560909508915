<template>
    <div v-if="MultipleChoice" class="MultipleChoice" :style="fullscreenCon?'width: 1510px':'width: 640px;'">
        <div style="color:#000">已选择<span style="color:#1492ff">{{Num}}</span>条</div>
        <img src="@/assets/img/MyWork/close1.png" style="float:right;margin-top:-10px;cursor: pointer;" @click="Close">
        <div class="list" style="text-align:center">
            <div v-for="(item,index) in list" :key="index" @click="ChoseItem(index)" style="width:160px">
                <div><img :src="item.img"></div>
                <div style="color:#000">{{item.title}}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
const emit = defineEmits(['Close','ClickForWard','OpenDownloadPop','workTopicDeleteChatRecord'])
const list = ref([
    { img: require('@/assets/img/MyWork/ForwardMultiple.png'), title: '转发' },
    { img: require('@/assets/img/MyWork/DownLoadMultiple.png'), title: '下载' },
    { img: require('@/assets/img/MyWork/DelMultiple.png'), title: '删除' }
])
const props = defineProps({
    MultipleChoice: {
        type: Boolean,
        default: false
    },
    Num: {
        type: Number,
        default: 0
    },
    fullscreenCon: {
        type: Boolean,
        default: false
    },
})
function ChoseItem(index) {
    if(props.Num.value <= 0) {
        ElMessage({
            message:'请至少选择一条数据！',
            type: 'error',
            duration: 3000
        })
        return
    }
    if (index == 0) {
        emit('ClickForWard')
    } else if (index == 1) {
        emit('OpenDownloadPop')
    } else {
        emit('workTopicDeleteChatRecord')
    }
}
function Close() {
    emit('Close')
}
// watch(() => conversationStore.isFullscreen, (val) => {
//     fullscreenCon.value = val
// }, { immediate: true })
</script>

<style scoped lang="scss">
.MultipleChoice {
    border-top: #d6dbe2 solid 1px;
    padding: 10px;
    position: absolute;
    // width: 100%;
    // bottom: -137px;
    bottom: 0;
    background:#fff;
    height:146px;
}
.list {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    text-align: center;
}
</style>
