import { defineStore } from 'pinia'
import { getScrollbarWidth } from '@/utils/index'

const useOnlineStore = defineStore('online', {
    state: () => ({
        // 在线客服弹出框的显示和隐藏
        onlineShow: false,
        scrollbarWidth: 0,
        // wsid
        wsId: null,
        // 获取消息
        wsMsg: null,
        // 在线客服发送的心跳信息
        params: {},
        // 在线客服连接状态 0：断开 1：连接
        onlineConnection: 0,
        // 是否已经打开过在线客服
        isOpen:false,
        // 消息提醒数据
        newNumObj:{},
    }),
    actions: {
        // 是否已经打开过在线客服
        SET_ISOPEN(data) {
          this.isOpen=data  
        },
        // 设置消息提醒数据
        SET_NEWNUM(data) {
            this.newNumObj = data
        },
        // 设置在线客服连接状态
        SET_ONLINE_CONNECTION(data) {
            this.onlineConnection = data
        },
        // 设置在线客服发送的心跳信息
        SET_PARAMS(data) {
            this.params = data
        },
        // 关闭开启在线客服
        setOnlineShow() {
            this.onlineShow = !this.onlineShow
            let width = getScrollbarWidth()
            this.scrollbarWidth = width
            if (this.onlineShow) {
                document.body.style.overflow = 'hidden'
                document.body.style.width = `calc(100% - ${width}px)`
            } else {
                document.body.style.overflow = 'auto'
                document.body.style.width = '100%'
                // closeWebSocket()
                // this.wsId.onclose()
            }
        },
        //设置wsId
        SET_WSID(data) {
            this.wsId = data
        },
        //设置ws消息
        SET_WSMSG(data) {
            this.wsMsg = data
        }
    }
})

export default useOnlineStore