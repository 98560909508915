<template>
    <div class="online-top-container">
        <div class="content">
            <div class="left">
                <el-icon>
                    <Service />
                </el-icon>
                <div class="title">在线客服</div>
            </div>
            <div class="right">
                <div class="minimize" @click="close">
                    <el-icon>
                        <SemiSelect />
                    </el-icon>
                </div>
                <div class="close" @click="close">
                    <el-icon>
                        <CloseBold />
                    </el-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { onlineStore } from '@/store'
// -------------------------------------------数据---------------------------------------------------


// -------------------------------------------方法---------------------------------------------------
const close = () => {
    onlineStore.setOnlineShow()
    localStorage.removeItem('uuid')
}
</script>

<style lang="scss" scoped>
.online-top-container {
    .content {
        padding: 10px 20px;
        box-sizing: border-box;
        background: #1664ff;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: #fff;

        .left {
            display: flex;
            align-items: center;

            .title {
                margin-left: 10px;
            }
        }

        .right {
            display: flex;
            align-items: center;

            .minimize {
                margin-right: 10px;
                cursor: pointer;
            }

            .close {
                cursor: pointer;
            }
        }
    }
}
</style>