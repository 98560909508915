<template>
    <div class="businessCard">
    <!-- 名片 -->
    <div class="card_box">
        <div style="margin:20px 20px 10px">
            <div class="overhide">
                <div class="fl">
                    <img class="cura" style="width:54px;height:54px;border-radius: 3px;margin-right:10px" :src="`${imgUrl}${cardDetail.headUrl}`" @click="preview()" />
                </div>
                <div class="fl" style="width: 200px;">
                    <div class="tit_c1">
                        <tooltipOver :content="cardDetail.realName" className="width:100%;"></tooltipOver>
                    </div>
                    <div class="tit_c2">
                        <tooltipOver :content="cardDetail.orgName" className="width:100%;"></tooltipOver>
                    </div>
                </div>
            </div>
            <div class="bot_card_box">
                <div class="fl" style="width: 49%;">
                    <div class="fl"><i class="iconfont icon-shoujidenglu maincolor"></i></div>
                    <div class="fl" v-if="cardDetail.telPhone">
                        <tooltipOver :content="cardDetail.telPhone" className="width:110px;"></tooltipOver>
                    </div>
                    <div v-else style="color: #ccc;" class="ml10 font12 fl"> 暂无信息 </div>
                </div>
                <div class="fl" style="width: 49%;">
                    <div class="fl" style="margin-top: 1px;"><i class="iconfont icon-youjian maincolor"></i></div>
                    <div class="fl" style="margin-left: 5px;" v-if="cardDetail.email">
                        <tooltipOver :content="cardDetail.email" className="width:110px;"></tooltipOver>
                    </div>
                    <div v-else style="color: #ccc;" class="ml10 font12 fl"> 暂无信息 </div>
                </div>
            </div>
        </div>
        <div class="ChoseMailList" @click="ChoseMailList()">
            <div class="ChoseMailListBtn">发消息</div>
        </div>
    </div>
  </div>
</template>

<script setup>
// import { findBusinessCard } from '@/request/MyWork'
import { onMounted, ref } from 'vue';
import http from '@/api/http';
import { showView } from '@/api'
const emit = defineEmits(['ChoseMailList1'])
const imgUrl = ref(http.FileUrl)
const props = defineProps({
    cardDetail: {
        type: Object,
        default: () => {}
    },
})

function ChoseMailList() {
    emit('ChoseMailList1', props.cardDetail)
}
function preview() {
    showView({
        fileUrl: props.cardDetail.headUrl,
    }).then((res) => {
        if (res.code == 0) {
            window.open(res.data)
        } else {
            ElMessage({ message: "该附件格式不支持在线预览！", type: "warning" })
        }
    })
}
</script>

<style lang="scss" scoped>

.businessCard{
    .card_box{
        border-radius: 2px;
        background: #FFFFFF;
        border: 1px solid #D5D7E0;
        box-shadow: 0px 2px 8px 0px #00000026;
    }
    .tit_c1{
        color: #333333;
        font-size: 16px;
        line-height: 16px;
    }
    .tit_c2{
        color: #666666;
        font-size: 12px;
        line-height: 12px;
        margin-top: 10px;
    }
    .bot_card_box{
        overflow: hidden;
        border-top: 1px solid #D5D7E0;
        margin-bottom: 14px;
        color: #333333;
        font-size: 12px;
        line-height: 12px;
        margin-top: 10px;
        padding-top: 10px;
    }
.ChoseMailList {
    line-height: 46px;
    text-align: center;
    color: #1492ff;
    cursor: pointer;
    padding-bottom: 15px;
    .ChoseMailListBtn{
        width: 86px;
        height: 28px;
        line-height: 28px;
        background: #1492ff;
        color: #fff;
        text-align: center;
        margin: auto;
        border-radius: 3px;
    }
}
}
</style>