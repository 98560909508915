<template>
  <div class="powerCheck">
    <!-- 个人用户弹窗 -->
    <el-dialog
      v-model="dialogVisible1"
      title="提示"
      width="540"
      :before-close="handleClose1"
    >
      <div class="flexh mb20">
        <el-icon :size="20" class="orangecolor"><WarningFilled /></el-icon>
        <div style="padding: 0 10px">
          <div v-if="userIdentityData.isRight == '1'">
            尊敬的用户您好，科技管家暂时只面向签约客户开放，由于所选的企业目前还未签约，所以无法切换至该企业。如有疑问也可联系
            <span @click="openLink" class="blueText">在线客服</span>
            获取帮助。感谢您的理解与支持！
          </div>
          <div v-else>
            尊敬的用户您好，科技管家暂时只面向签约客户（企业用户）开放，如果您目前还不是企业用户，可通过
            <span @click="goLink('bindEnterprise')" class="blueText"
              >绑定企业</span
            >
            进行升级；如果您已是企业用户，则可点击
            <span @click="goLink('ShoppingMall')" class="blueText"
              >前往商城</span
            >
            选择服务完成签约即可解锁科技管家，如有疑问也可联系
            <span @click="openLink" class="blueText">在线客服</span>
            获取帮助。感谢您的理解与支持！
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 签约客户（认证，待认证）弹窗 -->
    <el-dialog
      v-model="dialogVisible2"
      title="提示"
      width="1000"
      :before-close="handleClose1"
    >
      <div class="flexh mb20" v-if="userIdentityData.isRight == '1'">
        <el-icon :size="20" class="orangecolor"><WarningFilled /></el-icon>
        <div style="padding: 0 10px">
          尊敬的用户您好，由于所选企业目前还未完成认证，所以无法切换至该企业，您可点击下方‘去认证’按钮返回科技管家首页点击‘进入管家’完成认证，如有疑问也可联系
          <span @click="openLink" class="blueText">在线客服</span>
          获取帮助。感谢您的理解与支持！
        </div>
      </div>
      <div v-else class="kjgj_box">
        <!-- 企业认证页面组件 -->
        <enterpriseCertification
          ref="enterprise1"
          :isKjgj="'1'"
          :codeType="'fr_ct'"
          :userIdentityData="userIdentityData"
          tipWidth="900px"
          @submitKJGJ="submitKJGJ"
        ></enterpriseCertification>
      </div>
      <template #footer v-if="userIdentityData.isRight == '1'">
        <div class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取消</el-button>
          <el-button type="primary" @click="goKJGJHome">去认证</el-button>
        </div>
      </template>
    </el-dialog>
    <!-- 签约客户（员工）弹窗、科技管家切换公司弹窗3 -->
    <el-dialog
      v-model="dialogVisible3"
      title="提示"
      width="540"
      :before-close="handleClose1"
    >
      <div class="flexh mb20">
        <el-icon :size="20" class="orangecolor"><WarningFilled /></el-icon>
        <div style="padding: 0 10px">
          <div v-if="userIdentityData.isRight == '1'">
            尊敬的用户您好，您目前暂无所选企业的科技管家使用权限，请联系所选企业的负责人进入管家的‘安全管理’模块为您配置权限，如有疑问也可联系
            <span @click="openLink" class="blueText">在线客服</span>
            获取帮助。感谢您的理解与支持！
          </div>
          <div v-else>
            尊敬的用户您好，您目前暂无科技管家的使用权限，请联系企业负责人进入管家的‘安全管理’模块为您配置权限，如有疑问也可联系
            <span @click="openLink" class="blueText">在线客服</span>
            获取帮助。感谢您的理解与支持！
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 签约客户（员工）弹窗、科技管家切换公司弹窗4 -->
    <el-dialog v-model="dialogVisible4" title="提示" width="540" :before-close="handleClose1">
      <div class="flexh mb20">
        <el-icon :size="20" class="orangecolor"><WarningFilled /></el-icon>
        <div style="padding: 0 10px">
          <div>
            当前默认企业尚未完成法人认证，请点击进入<span @click="goCompany" class="blueText">管理企业</span>模块完成认证操作，
            如有疑问也可联系<span @click="openLink" class="blueText">在线客服</span>获取帮助。感谢您的理解与支持！
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script setup>
import { ref, watch } from "vue";
import { headStore, userStore, onlineStore } from "@/store";
import { useRouter } from "vue-router";
import enterpriseCertification from "@/views/informationCloud/bindEnterprise/enterpriseCertification.vue";
import useUserStore from "@/store/user";
import { saveCertInfo, findCertInfo } from "@/api/technologyManager.js";
import { ElMessage } from "element-plus";

const emit = defineEmits(["closeDialog"]);
const useUser = useUserStore();
const router = useRouter();
const enterprise1 = ref(null);
const dialogVisible1 = ref(false);
const dialogVisible2 = ref(false);
const dialogVisible3 = ref(false);
const dialogVisible4 = ref(false);
const userIdentityData = ref({});
const companyInfoId = JSON.parse(localStorage.getItem("companyData")) ? JSON.parse(localStorage.getItem("companyData")).companyInfoId : ""; //企业ID

// 判断登陆者身份，弹出弹窗
watch(() => useUser.userIdentity,(val) => {
    if (val) {
      // 0个人用户或未签约 1已认证 2员工未授权 3需法人验证码(只能输入验证码) 4法人初次认证（只能输入手机号，验证码）proxyCert=1：身份证号，材料能输入
      userIdentityData.value = useUser.userIdentity;
      if (userIdentityData.value.certStatus && userIdentityData.value.certStatus != "") {
        if (userIdentityData.value.certStatus == "0") {
          dialogVisible1.value = true;
        } else if (userIdentityData.value.certStatus == "2") {
          dialogVisible3.value = true;
        } else if (userIdentityData.value.certStatus == "3") {
          dialogVisible2.value = true;
        } else if(userIdentityData.value.certStatus == "4"){
          dialogVisible4.value = true
        } else {
          router.push({path: userIdentityData.value.pathJump ? userIdentityData.value.pathJump : "/projectProcess",query: userIdentityData.value.params ? userIdentityData.value.params : null,});
          if (userIdentityData.value.isRight == "1") {
            // 科技管家切换公司时，刷新浏览器，重置companyInfo
            window.location.reload();
          }
        }
      } else {
        dialogVisible1.value = false;
        dialogVisible2.value = false;
        dialogVisible3.value = false;
        dialogVisible4.value = false;
      }
    }
  },
  { immediate: true }
);
function goLink(val) {
  let path = router.resolve({ path: val });
  window.open(path.href);
}
// 打开在线客服
function openLink() {
  onlineStore.setOnlineShow();
  dialogVisible4.value = false;
}
//   关闭弹窗
function handleClose1() {
  userIdentityData.value.certStatus = "";
  userStore.setUserIdentity(userIdentityData.value);
  dialogVisible1.value = false;
  dialogVisible2.value = false;
  dialogVisible3.value = false;
  dialogVisible4.value = false;
}
// 科技管家回调
function submitKJGJ(val) {
  saveCertInfo(val).then((res) => {
    if (res.code == 0) {
      handleClose1();
      goLogin1();
      ElMessage.success({ message: res.message });
    } else {
      ElMessage.error({ message: res.message });
    }
  });
  enterprise1.value.resetCall();
}
// 当前认证状态 0个人用户或未签约 1已认证（正常跳转页面） 2员工未授权 3需法人验证码(只能输入验证码) 4法人初次认证（只能输入手机号，验证码）proxyCert=1：身份证号，材料能输入
function goLogin1() {
  findCertInfo({ companyInfoId: companyInfoId }).then((res) => {
    if (res.code == 0) {
      const cerData = res.data;
      cerData.pathJump = "/projectProcess";
      userStore.setUserIdentity(cerData);
      if (cerData && cerData.certStatus == "1") {
        // 缓存公司信息
        localStorage.setItem("companyData", JSON.stringify(cerData));
        setTimeout(() => {
          userStore.setUserJur() 
        });
      }
    } else {
      ElMessage.error(res.message);
    }
  });
}
// 跳转管理企业页面
function goCompany(){
  router.push("/PersonalCenter?id=8");
  dialogVisible4.value = false;
}
// 返回科技管家首页
function goKJGJHome() {
  handleClose1();
  router.push({ path: "/technologyManager" });
}
</script>
  
  <style lang="scss">
.powerCheck {
  .el-dialog {
    margin-top: 100px;
  }
}
.powerCheck {
  .kjgj_box {
    height: 700px;
    overflow-y: auto;
  }
}
</style>
  