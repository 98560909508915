<template>
  <div class="helper-container">
    <div class="conter">
      <div class="lineBox" @click="openOnline">
        <div class="lineTem">
          <!-- <img src="@/assets/img/icon/serviceIcon.png" alt=""> -->
          <img src="https://chenvafile.obs.cn-north-1.myhuaweicloud.com/project/1741855600713/3.425629122400655.png" alt="">
        </div>
        <div class="btnBox">在线咨询</div>
      </div>
      <div class="cardBox">
        <!-- <div class="herTem">
          <img src="@/assets/img/icon/xcxIcon.png" alt="">
          <div class="text">小程序</div>
        </div> -->
        <div class="herTem" v-jurisdiction:login="openCart">
          <img src="@/assets/img/icon/chatIcon.png" alt="">
          <div class="text">购物车</div>
        </div>
        <!-- <div class="herTem">
          <img src="@/assets/img/icon/AIIocn.png" alt="">
          <div class="text">AI助手</div>
        </div> -->
        <div class="herTem" @click="backTop">
          <img src="@/assets/img/icon/backIcon.png" alt="">
          <div class="text">移至顶部</div>
        </div>
      </div>

    </div>

  </div>
</template>

<script setup>
import { onlineStore } from "@/store";
import { ref } from "vue";
import { useRouter } from "vue-router";
// -----------------------------------------------数据----------------------------------------
const router = useRouter();

const timerId = ref(null);

// -----------------------------------------------方法----------------------------------------
// 回到顶部
function backTop() {
  if (timerId.value) {
    clearInterval(timerId.value);
    return;
  }
  timerId.value = setInterval(() => {
    document.documentElement.scrollTop -= 50;
    if (document.documentElement.scrollTop <= 0) {
      document.documentElement.scrollTop = 0;
      clearInterval(timerId.value);
      timerId.value = null;
    }
  }, 16);
}

// 打开购物车
const openCart = () => {
  router.push("/personalCenter?id=2");
};
// 打开在线咨询
function openOnline() {
  onlineStore.setOnlineShow();
}
</script>

<style lang="scss" scoped>
.helper-container {
  .conter {
    .lineBox {
      width: 84px;

      .lineTem {
        width: 83px;
        height: 83px;

        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          position: relative;
          z-index: 1;
        }
      }

      .btnBox {
        border-radius: 4px;
        background: #1492ff;
        width: 100%;
        height: 30px;
        line-height: 30px;
        color: #ffffff;
        font-size: 14px;
        text-align: center;
        margin-top: -15px;
        cursor: pointer;
        position: relative;
        z-index: 99;
      }
    }

    .cardBox {
      margin-top: 11px;
      width: 83px;
      border-radius: 4px;
      background: #ffffff;
      box-shadow: 0px 10px 20px 0px #0f3a5f19;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px 0;

      .herTem {
        margin-bottom: 32px;
        cursor: pointer;

        display: flex;
        flex-direction: column;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }

        .iconBox {
          font-size: 24px;
          color: #1492ff;
        }

        .text {
          color: #1492ff;
          font-size: 14px;
          margin-top: 8px;
        }

        .iconTio {
          transform: rotate(180deg);
        }
      }
    }
  }
}
</style>