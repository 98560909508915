<template>
    <div class="Historical">
        <img src="@/assets/img/MyWork/closeBox.png" @click="$emit('close')" class="closeBox">
        <div style="width:100%;text-align:center;line-height:52px;color:#000;font-size:14px;font-weight: bold;">操作日志</div>
        <el-table size="mini" :data="table" max-height="750px" class="journal" style="width: 100%;">
            <el-table-column width="70" align="left">
                <template #default="scope">
                    <img style="width:32px;height:32px;border-radius: 3px;margin-top:8px" :src="urlImg+scope.row.headUrl" />
                </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="userName" width="100" align="left" label="操作人"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="content" align="left" label="操作内容"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="createTime" width="150" align="left" label="操作时间"></el-table-column>
        </el-table>
    </div>
</template>

<script setup>
import {  onMounted, ref } from 'vue'
import http from '@/api/http'
// import { workTopicFindOperaLogList } from '@/request/MyWork'
const table = ref([])
const urlImg = ref(http.FileUrl)
const props = defineProps({
    groupInfoId: {
        type: String,
        default: ''
    }
})

function workTopicFindOperaLogList() {
    workTopicFindOperaLogList({
        groupInfoId: groupInfoId.value
    }).then((res) => {
        table.value = res.data
    })
}

onMounted(()=>[
    workTopicFindOperaLogList()
])
</script>

<style scoped lang="scss">
@keyframes examples {
    0% {
        right: -800px;
    }
    100% {
        right: 0px;
    }
}
.Historical {
    animation-name: examples;
    animation-duration: 1s;
    position: fixed;
    right: 0px;
    top: 50px;
    z-index: 5000;
    width: 800px;
    height: 845px;
    padding: 10px;
    // border: solid 1px red;
    box-shadow: 0px 0px 8px 8px rgba(189, 203, 224, 0.3);
    background: #fff;
}
.tabList {
    margin-top: 20px;
    display: inline-block;
    width: 80px;
    cursor: pointer;
    .listItem {
        height: 100px;
    }
}
.ChoseTab {
    color: red;
}
.list {
    height: 200px;
    overflow: auto;
}
.closeBox {
    position: absolute;
    left: -30px;
    top: 0;
}
</style>
<style>
.journal .el-table__body tr:hover > td {
    background: #f3f6ff !important;
}
</style>