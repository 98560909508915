
import { ElMessage } from 'element-plus'
/**
 * 一键复制指令
 */
export default {
    mounted(el, binding) {
        el.$copy = () => {
            const input = document.createElement('input');
            input.value = binding.value; // 获取绑定的值
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
            ElMessage({ type: 'success', message: '复制成功' })
        };
        el.addEventListener('click', el.$copy);
    },
    unmounted(el) {
        el.removeEventListener('click', el.$copy);
    }
}