// 科技管家
export const technologyManager = [
    {
        path: '/technologyManager',
        name: 'technologyManager',
        component: () => import('@/views/technologyManager/index.vue'),
        meta: {
            title: '科技管家',
        },
    },
    {
        path: '/securityManage',
        name: 'securityManage',
        component: () => import('@/views/technologyManager/securityManage/index.vue'),
        meta: {
            title: '安全管理',
            isTech: true,
        },
    },
    {
        path: '/technology',
        name: 'technology',
        component: () => import('@/views/technologyManager/technology/index.vue'),
        meta: {
            title: '科技活动',
            isTech: true,
        },
    },
    {
        path: '/projectProcess',
        name: 'projectProcess',
        component: () => import('@/views/technologyManager/projectProcess/index.vue'),
        meta: {
            title: '项目进度',
            isTech: true,
        },
    },
    {
        path: '/projectDetail',
        name: 'projectDetail',
        component: () => import('@/views/technologyManager/projectProcess/components/prodetail.vue'),
        meta: {
            title: '项目进度详情',
            isTech: true,
        },
    },
    {
        path: '/dataResources',
        name: 'dataResources',
        component: () => import('@/views/technologyManager/dataResources/index.vue'),
        meta: {
            title: '科创图谱',
            isTech: true,
        },
    },
    {
        path: '/proPlanDetail',
        name: 'proPlanDetail',
        component: () => import('@/views/technologyManager/dataResources/components/proPlanDetail.vue'),
        meta: {
            title: '项目规划',
            isTech: true,
        },
    },
]
