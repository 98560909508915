// 我的会话 状态管理
import { defineStore } from 'pinia'
const conversationStore = defineStore('conversation', {
    state: () => ({
        isSetShow: false, //设置
        isFullscreen: false, //全屏
        backgroundMyWork: '4', //myWork的头部根据不同的模块显示不同的背景图 1代表蓝灰白，2代表蓝灰白白，3代表蓝白白，4蓝灰深蓝===已弃用
        myWork: {},
        draft: [], //Mywork聊天框草稿
        scoketObj: {},
        myworkOpen: false,
    }),
    actions: {
        //是否打开设置
        setSetShow(data) {
            this.isSetShow = data
        },
        // 是否全屏
        setFullscreen(data) {
            this.isFullscreen = data
        },
        setBackgroundMyWork(state, payload) {
            this.backgroundMyWork = payload
        },
        SET_Draft(state, str) {
            this.draft = str
        },
        //判断myWork是否关闭
        SET_myworkOpen(state) {
            this.myworkOpen = state
        },
        //通信
        SET_Scoket(state, str) {
            this.scoketObj = state
            // console.log('推送', this.scoketObj)
        }
    }
})

export default conversationStore