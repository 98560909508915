<template>
    <div class="problem-container">
        <div class="conter">
            <div class="topBox">
                <div class="title">您可能想问</div>
                <div class="refresh" @click="refresh" v-if="!prop.isHistory">
                    <el-icon>
                        <Refresh />
                    </el-icon>
                    <div class="text">换一批</div>
                </div>
            </div>
            <div class="rowBox">
                <div class="colBox" v-for="item in problemList " :key="item.id" @click="openMsgChange(item)">
                    {{ item.questionDesc }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue';
import { getProblemList } from '@/api/online.js'
const prop = defineProps({
    option: {
        type: Array,
        default: () => []
    },
    isHistory:{
        type: Boolean,
        default: false
    },
    sessionId: {
        type: [String, Number],
        default: ''
    }
})

const emit = defineEmits(['change'])
// -------------------------------------------数据---------------------------------------------------

const reqParams = reactive({
    // 来源 1平台 2app 3小程序
    msgSource: '1',
    // 起始页码
    startIndex: 1,
    // 每页显示数
    pageSize: 5,
    // 通道号，建立通道后的唯一ID
    sessionId: prop.sessionId
})

const problemList = ref([])

onMounted(() => {
    problemList.value = prop.option
})
// -------------------------------------------方法---------------------------------------------------
// 点击问题触发
const openMsgChange = (val) => {
    emit('change', val)
}

const refresh = async () => {
    reqParams.startIndex += 1
    const res = await getProblemList(reqParams)
    if (res.code == 0) {
        if (res.data.length == 0) {
            reqParams.startIndex = 0
        } else {
            problemList.value = res.data
        }
    }
}

</script>

<style lang="scss" scoped>
.problem-container {
    flex: 1;

    .conter {
        padding: 5px 10px;
        box-sizing: border-box;
        background: #fff;
        max-width: 80%;

        .topBox {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            color: #999;

            .refresh {
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    color: #1492ff;
                }

                .text {
                    margin-left: 5px;
                }
            }
        }

        .rowBox {
            .colBox {
                padding: 5px 0;
                cursor: pointer;
                color: #1492ff;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>