import { defineStore } from 'pinia'
const useHeadStore = defineStore('head', {
    state: () => ({
        // 头部是否显示
        headShow:true,
        // 是否显示底部
        footerShow: true,
        // 是否显示帮助
        helperShow:true,
        // 是否是科技管家页面
        kjgjHeader: false,
        // 内容高度
        contentHeight: '',
        // 头部高度
        height: 78,
        defHeight: 78,
        footerHeight: 280,
        // 默认背景颜色
        defaultBgColor: 'transparent',
        // 激活后的背景颜色
        actrveBgColor: '#fff',
        // 还原背景颜色
        reductionBgColor: '#fff',
        // 头部位置
        position: 'absolute',
        // 头部白名单
        headWhiteList: ['/index', '/technologyManager', '/ShoppingMall', '/information'],
        // 头部--我的消息数量
        msgNum: 0,
        // 头部--我的会话数量
        conNum:0,
        // 头部下拉列表
        dropList: [
            {
                name: '我的积分',
                id: 1,
                path: '/PersonalCenter',
                icon: 'icon-wodejifen'
            },
            {
                name: '购物车',
                id: 2,
                path: '/PersonalCenter',
                icon: 'icon-gouwuche'
            },
            {
                name: '未生效订单',
                id: 3,
                path: '/PersonalCenter',
                icon:'icon-a-Group1142813570-copy'
            },
            {
                name: '我的收藏',
                id: 4,
                path: '/PersonalCenter',
                icon:'icon-wodeshoucang'
            },
            {
                name: '产学研需求',
                id: 5,
                path: '/PersonalCenter',
                icon:'icon-chanxueyanxuqiu'
            },
            {
                name: '专家评审需求',
                id: 6,
                path: '/PersonalCenter',
                icon:'icon-zhuanjiapingshenxuqiu'
            },
            {
                name: '专家认证',
                id: 7,
                path: '/expertCertification',
                icon:'icon-zhuanjiarenzheng'
            },
            {
                name: '管理企业',
                id: 8,
                path: '/PersonalCenter',
                icon:'icon-guanliqiye'
            },
            {
                name: '账号设置',
                id: 9,
                path: '/PersonalCenter/system',
                icon:'icon-zhanghaoshezhi'
            },
            {
                name: '退出登录',
                id: 10,
                path: '',
                icon:''
            }
        ]
    }),
    actions: {
        // 设置头部是否显示
        setHeadShow(val) {
            this.headShow = val
        },
        // 设置底部是否显示
        setFooterShow(val) {
            this.footerShow = val
        },
        // 设置帮助是否显示
        setHelperShow(val) {
            this.helperShow = val
        },
        // 设置默认背景颜色
        setDefaultBgColor(val) {
            this.defaultBgColor = val
        },
        // 设置激活背景颜色
        setActrveBgColor(val) {
            this.actrveBgColor = val
        },
        // 设置头部位置
        setPosition(val) {
            this.position = val
        },
        // 设置高度
        setHeight(val) {
            if (val) {
                this.height = val
            } else {
                this.height = this.defHeight
            }
        },
        // 改变头部我的消息数量
        setMsgNum(val) {
            this.msgNum = val
        },
        // 改变头部会话消息数量
        setConNum(val) {
            this.conNum = val
        },
        // 设置头部高度
        setFooterHeight(val) {
            this.footerHeight = val
        },
        // 设置内容高度
        setcontentHeight() {
            this.contentHeight = `calc(100vh - ${this.height}px - ${this.footerHeight}px)`
            if(!this.headShow&&!this.footerShow) {
                this.contentHeight = `calc(100vh)`
            }
        },
        // 科技管家菜单
        setKjgjHeader(val) {
            this.kjgjHeader = val
        }
    }
})
export default useHeadStore