let wsId = null
import http from '@/api/http'
let bsseUrl = http.ws
import { onlineStore, conversationStore, headStore, userStore } from '@/store'
import { getToken } from '@/utils/auth'
import { urcount } from '@/api/technologyManager'//接口

// 心跳定时器id
let heartbeatTimer = null
export const createWebSocket = (paramsJSon = null) => {
    wsId = new WebSocket(bsseUrl);
    onlineStore.SET_WSID(wsId)
    wsId.onopen = () => {
        console.log('ws连接成功');
        if (getToken()) {
            // myWork
            let dataParans = JSON.stringify(paramsJSon)
            sendHandle(dataParans)
            // 消息
            sendHandle(JSON.stringify({
                type: 1,
                userId: userStore.userId || sessionStorage.getItem('userId')
            }))
        }
        // 在线客服
        if (onlineStore.isOpen) {
            let usession = userStore.userId || localStorage.getItem("sessionLocalStorageId")
            let obj = {
                type: 1,
                userId: `${usession}@onlineService@1`
            }
            sendHandle(JSON.stringify(obj))
        }
        startHeartbeat(paramsJSon)
    };
    wsId.onmessage = (event) => {
        messageHandle(event, paramsJSon)
    };
    wsId.onerror = (error) => {
        console.error('WebSocket error:', error);
        clearInterval(heartbeatTimer)
        reconnect(paramsJSon)
    }
    wsId.onclose = () => {
        console.log('ws已关闭onclose');
        closeWebSocket(paramsJSon)
    }
    return wsId
}

// 接收消息处理
export const messageHandle = (e, paramsJSon) => {
    if (e.data == 'heard') {
        return
    }
    if (e.data == 'unHeard') {
        clearInterval(heartbeatTimer)
        reconnect(paramsJSon)
        return
    }
    let dataObj = JSON.parse(e.data) || {}
    /**
     * messageSubject
     *  1:消息
     *  2:在线客服消息
     *  3:myWork
     */
    if (dataObj.messageSubject == 2) {
        if (dataObj.msgType == 2) {
            dataObj.chatMsg = JSON.parse(dataObj.chatMsg)
        }
        // role 1:客服 2:游客
        let params = {
            ...dataObj,
            role: 1
        }
        // 设置到pinia中
        onlineStore.SET_WSMSG(params)
    } else if (dataObj.messageSubject == 1) {
        onlineStore.SET_NEWNUM(dataObj)
        headStore.setMsgNum(dataObj.totalMsgCount)
    } else if (dataObj.messageSubject == 3) {
        conversationStore.SET_Scoket(e.data)
        urcount().then(res => {
            if (res.code == 0) {
                headStore.setConNum(res.data)
            }
        })
    }
}
// 发送消息
export const sendHandle = (data) => {
    wsId.send(data)
}
// 关闭websocket
export const closeWebSocket = (paramsJSon) => {
    console.log('ws已关闭');
    clearInterval(heartbeatTimer)
    reconnect(paramsJSon)
}

// 重连机制
export const reconnect = (paramsJSon) => {
    let params = {}
    if (getToken()) {
        let urerId = userStore.userId || sessionStorage.getItem('userId')
        params = {
            type: 1,
            userId: urerId + '@MYWORK@1'
        }
        sendHandle(JSON.stringify(params))
    }
    createWebSocket(paramsJSon)
}
// 心跳机制--开启
export const startHeartbeat = (sendMsg) => {
    heartbeatTimer = setInterval(() => {
        console.log('发送心跳');
        if (wsId) {
            if (onlineStore.onlineConnection == 1) {
                let obj = onlineStore.params
                sendHandle(JSON.stringify(obj))
            }
            if (getToken()) {
                if (sendMsg) {
                    let params = sendMsg
                    params.type = 2
                    sendHandle(JSON.stringify(params))
                }
                sendHandle(JSON.stringify({
                    type: 2,
                    userId: userStore.userId || sessionStorage.getItem('userId')
                }))
            }
        } else {
            console.log('ws已关闭,停止心跳');
            clearInterval(heartbeatTimer)
        }
    }, 5000)
}