<template>
    <div>
        <div class="imgs">
            <img v-for="(item,index) in list" style="margin:0 1px" :key="index" :style="{width:list.length==2||list.length==4?'15px':list.length==1?'36px':list.length==3?'15px':'10px',
        height:list.length==2||list.length==4?'15px':list.length==1?'36px':list.length==3?'15px':'10px'}" :src="urlImg+item" />
        </div>
    </div>
</template>

<script setup>
import {  ref } from 'vue'
import http from '@/api/http'
const urlImg = ref(http.FileUrl)
const props = defineProps({
    list: {
        type: Array,
        default: []
    }
})
</script>

<style scoped lang="scss">
.imgs {
    display: flex;
    width: 36px;
    height: 36px;
    flex-wrap: wrap-reverse;
    justify-content: center;
    // align-content:center;
    // vertical-align:middle;
    align-items: center;
    background: #e7eaf4;
    border-radius: 3px;
    img{
        border-radius: 3px;
    }
}
</style>