import { get, postForm, postJson } from './http'
// 生成游客标识
export const getTouristId = (p) => postJson('uaa/sysOnlineChat/generateBs')
// 查询聊天记录列表
export const getPageList = (p) => postJson('/uaa/sysOnlineChat/encry/findCl', p, true)
// 查询推荐列表（换一批/换一批）
export const getProblemList = (p) => postJson('/uaa/sysOnlineChat/encry/changeBatch', p, true)
// 发送消息
export const sendMsg = (p) => postJson('/uaa/sysOnlineChat/encry/sendMsg', p, true)
// 分配客服
export const assignService = (p) => postJson('/uaa/sysOnlineChat/encry/fpkf', p, true)