import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout/index.vue'
import { informationCloud } from './informationCloud'
import { PersonalCenter } from './PersonalCenter'
import { ShoppingMall } from './ShoppingMall'
import { technologyManager } from './technologyManager'

const routes = [
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        redirect: '/index',
        children: [
            {
                path: '/index',
                name: 'index',
                component: () => import('@/views/index/index.vue'),
                meta: {
                    title: '辰华科技云平台',
                },
            },
            ...informationCloud,//消息、企业认证、科技资讯等
            ...PersonalCenter,//个人中心
            ...ShoppingMall,//商城
            ...technologyManager,//科技管家
        ],
    },
    //分享-h5页面
    {
        path: '/serviceDetailShare',
        name: 'serviceDetailShare',
        component: () => import('@/views/ShoppingMall/mallOrders/serviceDetailShare.vue'),
        meta: {
            title: '服务详情',
        },
    },
    // 科技管家-文件预览列表
    {
        path: '/technologypreview',
        name: 'technologypreview',
        component: () => import('@/views/technologyManager/technologypreview/index.vue'),
        meta: {
            title: '文件预览列表',
        },
    },
    // 科技管家-文件预览详情
    {
        path: '/previewdetail',
        name: 'previewdetail',
        component: () => import('@/views/technologyManager/technologypreview/previewdetail.vue'),
        meta: {
            title: '文件预览详情',
        },
    },
    {
        path: '/canvasCard',
        name: 'canvasCard',
        component: () => import('@/views/informationCloud/information/canvasCard/index.vue'),
        meta: {
            title: '政策卡片',
        },
    },
    // 协议
    {
        path: '/agreement',
        name: 'agreement',
        component: () => import('@/views/agreement/index.vue'),
        meta: {
            title: '协议',
        },
    },
    // 404页面放在最后
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('@/views/notFound.vue'),
        meta: {
            title: '辰华科技云平台',
        },
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})
// 导航守卫设置页签标题
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});
export default router
