import { get, postForm, postJson } from './http'

// 登录/注册
export const loginApi = (p) => postJson('/uaa/sysUser/encry/login/token', p, true)
// 重置密码 type=1 忘记密码 2 设置登录密码
export const resetPassword = (p, type) => {
    let url = '/uaa/sysUser/encry/login/rp'
    if (type == 2) {
        url = '/uaa/sysUser/encry/login/dl'
    }
    return postJson(url, p, true)
}
// 获取用户信息
export const getUserInfoApi = (p) => get('/uaa/current/user', p, true)
// 查询用户详情
export const getUserInfoDetails = (p) => postJson('/uaa/sysUser/findDetail?userId=' + p)
// 退出登录
export const logout = (p) => postForm('/uaa/sysUser/logout/token', p)
// 账号设置
export const setSytem = (p) => postJson('/uaa/sysUser/encry/login/as', p, true)
// 更换手机号
export const setPhone = (p) => postJson('/uaa/sysUser/encry/login/cp', p, true)
// 专家认证
export const setExpertAuthentication = (p) => postJson('/uaa/sysExpertCertInfo/encry/ec', p, true)

// 查询是否有专家认证正在审核
export const getExperIsAudit = (p) => postJson('/uaa/sysExpertCertInfo/findIsAudit', p)



// 
