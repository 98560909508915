<template>
    <div class="searchbox">
        <el-input ref="searchBoxRef" onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;" clearable :maxlength="maxlength" :placeholder="searchPh" v-model="keywordsValue" @clear="searchEmit()" @blur="searchEmit()" @keyup.enter="$event.target.blur()">
            <template #append>
                <div class="search_btn" @click="searchEmit">搜索</div>
            </template>
        </el-input>
    </div>
</template>

<script setup>
import {ref, watch} from 'vue'
import { ElMessage } from 'element-plus'
const keywordsValue = ref('')
const emit = defineEmits(['changeSearch'])
const props = defineProps({
    searchPh: {// 提示语
        type: String,
        default: ''
    },
    sKeywords: {// 当前输入的值
        type: String,
        default: ''
    },
    maxlength: {// 输入长度
        type: String,
        default: ''
    },
    // searchPh: String,// 提示语
    // sKeywords: String,// 当前输入的值
    // maxlength: String// 输入长度
})
watch(() => props.sKeywords, (newValue, oldValue) => {
    keywordsValue.value = newValue
},{ immediate: true });
function searchEmit() {
    // 若输入特殊字符“<”，“>”，“；”，“””，查询时，弹出警示提示“请勿输入特殊字符”
    const check = /^[<>《》；;“”''，,]$/
    if (check.test(keywordsValue.value) && keywordsValue.value) {
        ElMessage({
            message: '请勿输入特殊字符',
            type: 'warning',
        })
        keywordsValue.value = ''
    } else {
        emit('changeSearch', keywordsValue.value)
    }
}
</script>

<style scoped lang="scss">
.searchbox {
    text-align: left;
    margin-right: 12px;
    font-size: 12px;
    color: #d5d7e0;
    border-color: #d5d7e0;
    .search_btn{
        color: #fff;
        font-size: 12px;
    }
}
</style>
<style lang="scss">
.searchbox .el-input-group__append {
    background-color: #1492ff;
    border: 1px solid #1492ff;
    padding: 0 20px;
    overflow: hidden;
    box-shadow: 0 0 0 0;
    cursor: pointer;
}
.searchbox {
    width: 400px;
    .el-input__wrapper{
        box-shadow:none;
        border: 1px solid #1492ff
    }
    .el-input__wrapper, .el-input{
        height: 32px;
    }
}
</style>
