import axios from 'axios' // 引入axios
import { ElMessage } from 'element-plus'
import { aesEncode, rsaEncode } from '@/utils/encryption'//加密
import { getUuid } from '@/utils/index'//获取随机数
import { getToken ,removeToken} from '@/utils/auth'
//后台请求基础路径
let baseUrl = ''
let ws = ''
const FileUrl = 'http://chenvafile.obs.cn-north-1.myhuaweicloud.com'
// 分享外部链接地址
const prefixUrl = 'https://www.chenva.com'
//开发环境
if (process.env.NODE_ENV == 'development') {
    baseUrl = 'http://192.168.0.202:20000/'
    ws = 'ws://192.168.0.202:18886/'
} else if (process.env.NODE_ENV === 'production') {
    // 生产环境
    baseUrl = 'https://www.chenva.com/api/'
    ws = 'wss://www.chenva.com/tim/'
    // 隐藏打印
    var logDebug = false
    console.log = (function (oriLogFunc) {
        return function () {
            if (logDebug) {
                oriLogFunc.apply(this, arguments)
            }
        }
    })(console.log)
}else {
    //测试环境
    baseUrl = 'http://192.168.0.202:20000/'
    ws = 'ws://192.168.0.202:18886/'
}
axios.defaults.baseURL = baseUrl
//设置请求超时
axios.defaults.withCredentials = true
//请求头设置
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 请求拦截器
axios.interceptors.request.use(
    (config) => {
        // 每次发送请求之前判断vuex中是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        const token = getToken()
        const openId = localStorage.id
        token && (config.headers.Authorization = 'Bearer' + ' ' + token)
        openId && (config.headers.openId = openId)
        let uuid = getUuid()
        // isEncryption有值时加密
        if (config.isEncryption) {
            let params = {
                ...JSON.parse(config.data),
                TIMESTAMP: getTime(),
            }
            let sign = {
                requestData: aesEncode(JSON.stringify(params), uuid),
                encrypted: rsaEncode(uuid),
            }
            config.data = sign
        }
        return config
    },
    (response) => {
        if (response.data.code == 401) {
            localStorage.clear()
        }
        if (response.data.code == 500) {
            ElMessage({ showClose: true, message: response.data.message, type: 'error' })
        }
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        return
    },
    (error) => {
        return Promise.reject(error)
    }
)
// 响应拦截器
let flag = true
let kegjnum1 = 0
let kegjnum2 = 0
axios.interceptors.response.use(
    (response) => {
        if (response.data.code == 401) {
            localStorage.clear()
            return response
        } else if (response.data.code == 4094) {
            // 科技管家  弹出提示 4094 普通员工无权限
            if (kegjnum1 == 0) {
                ElMessage({ message: '尊敬的用户您好，您目前暂无科技管家的使用权限，请联系企业负责人进入管家的‘安全管理’模块为您配置权限', type: 'error' })
            }
            kegjnum1++
        } else if (response.data.code == 4095) {
            // 科技管家  弹出提示 4095 未进行法人认证
            if (kegjnum2 == 0) {
                ElMessage({ message: '请进行企业认证后再进入科技管家！', type: 'error' })
            }
            kegjnum2++
        } else {
            return response
        }
    },
    (error) => {
        let data = error?.response?.data || error
        if(error?.response?.status == 401&&getToken()){
            removeToken()
            if(window.location.pathname!='/index'){
                location.href = '/index'
            }
        }else if (data.code == 2000||data.code == 2012) {
            // 接口报错，清空token  测试关闭，正式时打开
           // if (data.code == 2000 || data.code === 'ERR_NETWORK') {
            removeToken()
            if(window.location.pathname!='/index'){
                location.href = '/index'
            }
        }else if(data.code==4038){
            // 接口限制
            ElMessage({ showClose: true, message: data.message||'', type: 'error' })
        }
        return Promise.reject(error)
    }
)
// * get方法，对应get请求
// * @param {String} url [请求的url地址]
// * @param {Object} params [请求时携带的参数]
// */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params: params }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err)
        })
    })
}
// * post方法，对应post请求
//  * @param {String} url [请求的url地址]
//  * @param {Object} params [请求时携带的参数]
//  */
export function postForm(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function postJson(url, params, isEncryption) {
    return new Promise((resolve, reject) => {
        axios.post(url, JSON.stringify(params), {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            isEncryption,
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function exportPost(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
        }).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}
export default {
    baseUrl: baseUrl,
    ws: ws,
    FileUrl: FileUrl,
    prefixUrl:prefixUrl
}


function getTime() {
    let date = new Date();
    var year = date.getFullYear().toString()
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    let t = year + month.toString() + day.toString() + hours.toString() + minutes.toString() + seconds.toString()
    return t
}
