<template>
  <!-- 登录 -->
  <div class="login-container" v-if="useUser.loginShow">
    <el-dialog v-model="useUser.loginShow" :show-close="true" :close-on-click-modal="false" :before-close="beforeClose" class="loginDialog" width="1041px">
      <div class="container">
        <div class="loginCard">
          <div class="title">欢迎登录</div>
          <div class="tab-box">
            <div class="item" v-for="item in tabList" :key="item.value" @click="tabChange(item.value)" :class="{ actrveTab: ruleForm.loginType === item.value }">
              {{ item.name }}
            </div>
          </div>
          <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon size="large" class="loginForm">
            <el-form-item prop="phoneNumber">
              <div class="phoneInput rowBox">
                <div class="prefix">+86</div>
                <el-input v-model="ruleForm.phoneNumber" maxlength="11" placeholder="请输入手机号" class="input" />
              </div>
            </el-form-item>
            <el-form-item prop="validCode" v-if="ruleForm.loginType == 1">
              <div class="rowBox codeBox">
                <el-input v-model="ruleForm.validCode" placeholder="请输入短信验证码" class="codeInput" />
                <CodeFrom :value="ruleForm.phoneNumber" codeType="lg_rg" height="46px" class="codeBtn" />
              </div>
            </el-form-item>
            <el-form-item prop="loginPassword" v-if="ruleForm.loginType == 2">
              <div class="rowBox">
                <el-input v-model="ruleForm.loginPassword" placeholder="请输入密码" maxlength="18" show-password class="inputBox" />
              </div>
            </el-form-item>
          </el-form>
          <div class="forgotPasswordBox" v-if="ruleForm.loginType == 2">
            <span @click="openPwd" class="blueText"> 忘记密码？</span>
          </div>
          <div class="checkBox">
            <el-checkbox v-model="checked" size="large" />
            我已阅读并同意
            <span @click="openProtocol(1)">《辰华科技云平台服务协议》</span>
            <span>、</span>
            <span @click="openProtocol(2)">《辰华科技云平台隐私政策》</span>
            <span>、</span>
            <span @click="openProtocol(3)">《辰华科技云平台企服商城使用须知》</span>
          </div>
          <div class="btnBox">
            <el-button type="primary" size="large" @click="loginFrom" :loading="loadding" color="#1492ff" class="btn">
              {{ ruleForm.loginType == 1 ? "立即登录/注册" : "立即登录" }}
            </el-button>
          </div>
          <div class="tip">使用短信验证码快捷登录。未注册用户将自动创建账号</div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import useUserStore from "@/store/user";
const useUser = useUserStore();
import CodeFrom from "@/components/code/index.vue";
import { loginApi } from "@/api/user";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import wsConnectionOrder from "@/utils/websocket.js";
import { createWebSocket, sendHandle } from "@/utils/ws.js";
import { onlineStore } from "@/store";
const emit = defineEmits(["change"]);
// -----------------数据-------------------
// 校验规则
const rules = reactive({
  phoneNumber: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      pattern: /^1[3-9]\d{9}$/,
      message: "手机号号码格式错误",
      trigger: "blur",
    },
  ],
  loginPassword: [
    { required: true, message: "请输入密码", trigger: "blur" },
    {
      pattern:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}\[\]\\\/|:;<>"',.?])[A-Za-z\d!@#$%^&*()_\-+={}\[\]\\\/|:;<>"',.?]{8,16}$/,
      message: "密码格式不正确",
      trigger: "blur",
    },
  ],
  validCode: [{ required: true, message: "请输入短信验证码", trigger: "blur" }],
});
// 提交表单数据
const ruleForm = reactive({
  // 手机号码
  phoneNumber: "",
  // 登录密码
  loginPassword: "",
  // 登录类型 1快捷登录/注册 2密码登录
  loginType: "1",
  // 验证码
  validCode: "",
});
// tab切换列表
const tabList = reactive([
  {
    name: "快捷登录/注册",
    value: "1",
  },
  {
    name: "密码登录",
    value: "2",
  },
]);
const checked = ref(false);
// 获取ref进行校验
const ruleFormRef = ref(null);

const router = useRouter();
const route = useRoute();
const loadding = ref(false);
// -----------------方法-------------------
// 登录
const loginFrom = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      if (!checked.value) {
        return ElMessage.error("请同意并勾选协议");
      }
      try {
        loadding.value = true;
        loginApi(ruleForm).then(async (res) => {
          if (res.code == 0) {
            ElMessage.success("登录成功");
            useUser.setLoginShow(false);
            useUser.setToken(res.data.access_token);
            localStorage.setItem("timerPor", "");
            useUser.GetUserInfo().then((vis) => {
              // 创建mywork的websocket链接
              // wsConnectionOrder.initWebSocket()
              let params = {
                type: 1,
                userId: vis.id + "@MYWORK@1",
              };
              createWebSocket(params);
              if (useUser.signinToHome.includes(route.path)) {
                router.push("/");
                location.reload();
              } else {
                location.reload();
              }
              emit("change");
            });
          } else {
            ElMessage.error(res.message);
          }
          loadding.value = false;
        });
      } catch (error) {
        loadding.value = false;
      }
    }
  });
};
// 切换登录方式
const tabChange = (val) => {
  ruleForm.loginType = val;
};
// 点击忘记密码按钮触发
const openPwd = () => {
  router.push("/forgotPassword?type=1");
  useUser.setLoginShow(false);
};

// 点击协议按钮触发
const openProtocol = (val) => {
  let href = router.resolve({
    path: "/agreement",
    query: {
      id: val,
    },
  });
  window.open(href.href, "_blank");
};

// 关闭弹窗
const beforeClose = (done) => {
  ruleForm.loginType = "1";
  done();
};
</script>

<style lang="scss" scoped>
.login-container {
  :deep(.loginDialog) {
    padding: 0 !important;
    border-radius: 4px !important;
    background: url("@/assets/img/loginBg.png") no-repeat;
    background-size: 100% 100%;
    width: 960px;
    height: 600px;
    box-shadow: 0px 0px 6.5px rgba(0, 0, 0, 0.59);
    overflow: hidden;

    .el-dialog__header {
      padding: 0 !important;
      height: 0px !important;
    }

    .el-dialog__title::before {
      width: 0px;
    }

    .el-dialog__body {
      box-shadow: none !important;
      --el-dialog-bg-color: transparent !important;
    }
  }

  .container {
    width: 960px;
    height: 600px;
    display: flex;
    justify-content: end;

    .loginCard {
      width: 488px;
      background: #fff;
      padding: 0 64px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0px 0 10px rgb(120 119 119 / 10%);

      .title {
        font-size: 22px;
        font-weight: bold;
      }

      .tab-box {
        display: flex;
        align-items: center;
        margin: 32px 0 53px;

        .item {
          font-size: 16px;
          cursor: pointer;
          margin-right: 20px;
          color: #a1a1a1;
          position: relative;

          &::after {
            content: "";
            display: block;
            position: absolute;
            right: -10px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 10px;
            background: #a1a1a1;
          }

          &:last-child::after {
            display: none;
          }
        }

        .actrveTab {
          color: #555555;
          font-weight: bold;
        }
      }

      .loginForm {
        .el-form-item--large {
          margin-bottom: 36px !important;

          &:last-child {
            margin-bottom: 18px !important;
          }
        }

        .rowBox {
          width: 100%;
          height: 46px !important;
          display: flex;
          align-items: center;
          border: 1px solid #e6eaef;
          border-radius: 5px;
          line-height: 46px !important;

          :deep(.inputBox) {
            flex: 1;

            .el-input__wrapper {
              box-shadow: none;
            }
          }
        }

        .codeBox {
          border: none;

          :deep(.codeInput) {
            flex: 1;
            height: 46px !important;

            .el-input__wrapper {
              height: 46px !important;
            }
          }

          .codeBtn {
            margin-left: 10px;
          }
        }
      }

      .checkBox {
        font-size: 12px;

        span {
          color: #3da7f9 !important;
          cursor: pointer;
        }
      }

      .forgotPasswordBox {
        text-align: right;
      }

      .btnBox {
        margin-top: 82px;
        width: 100%;
        text-align: center;

        .btn {
          width: 161px;
          height: 32px;
        }
      }

      .tip {
        margin-top: 20px;
        font-size: 12px;
        color: #d0d0d0;
        text-align: center;
      }
    }
  }
}
</style>
