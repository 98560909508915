
import { getToken } from '@/utils/auth'

import useUserStore from '@/store/user'

/**
 * 权限指令
 * 
 * v-jurisdiction:login--判断是否登录
 *  --如果 v-jurisdiction:login="ctm"  则判断是否登录，如果登录则执行ctm函数
 *  --如果 v-jurisdiction:login="ctm(11)" 
 *              const ctm= computed(() => {
                    return (val)=>()=>toPath(val)
                })
 */
export default {
    mounted(el, binding, vnode) {
        const userStioe = useUserStore()
        el.addEventListener('click', () => {
            if (binding.arg === 'login') {
                if (!getToken()) {
                    userStioe.setLoginShow(true)
                } else {
                    if (binding.value && typeof binding.value === 'function') {
                        binding.value()
                    }
                }
            }
        })
    }
}

