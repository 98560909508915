<template>
    <div class="congroup" :style="fullscreenCon?'height:calc(100vh - 31px);':'height:632px;'">
        <div class="qunzu_title">
            <div style="width:320px;height:28px;padding-top:5px;margin-left: 6px">
                <el-input size="small" clearable class="search_center" style="width:320px;height: 28px;" ref="input" v-model="keywords1" placeholder="搜索群组" @keyup.enter="search1" @clear="search1()" @input="search1" onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;">
                </el-input>
            </div>
        </div>
        <!-- 群组 -->
        <div class="mailList">
            <div style="width:341px; overflow: auto;border-right: solid 1px #d5d7e0;background: #f5f5f5;">
                <div class="topicList">
                    <div class="topictree" v-if="groupList && groupList.length">
                        <div class="list1" v-for="(item, index) in groupList" :key="index" @click="goDetailGroup(item)">
                            <div style="padding: 10px" class="flexh">
                                <TopImg :list="item.picList"></TopImg>
                                <tooltipOver :content="item.chatName" className="max-width:240px;margin-left: 10px;"></tooltipOver>
                                <span>({{ item.memberNum }}人)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="no_data">
                <img src="@/assets/img/tableEmpty.png" class="tableEmpty">
                <div class="mt10">暂无内容，试试点击群组名称开启会话</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import TopImg from './components/topimg.vue'
import { conversationStore } from '@/store'
import { cctgs, ict } from '@/api/technologyManager'//接口
const emit = defineEmits(['callBack'])
const keywords1 = ref('')
const groupList = ref([])
const fullscreenCon = computed(() => {
    return conversationStore.isFullscreen
})
// 查询用户服务群
function getGroupList(){
    cctgs({keyword: keywords1.value}).then((res) => {
        if(res.code == '0') {
            groupList.value = res.data
        }
    })
}
function goDetailGroup(val){
    ict({chatId: val.id}).then((res) => {
        if (res.code == 0) {
            emit('callBack', res.data)
        } else {
            this.$message({
                message: res.message,
                type:  'error',
                duration: 3000
            })
        }
    })
}
function search1(){
    getGroupList()
}
function clear1(){
    keywords1.value = ''
    getGroupList()
}
onMounted(()=>{
    getGroupList()
})
watch(() => fullscreenCon, (val) => {
}, { immediate: true })
</script>

<style lang="scss" scoped>
.congroup{
    width: 100%;
    padding-left: 6px;
    .mailList {
        display: flex;
        height: 100%;
    }
    .topicList {
        background: #f5f5f5;
        width: 100%;
    }
    .topictree {
        // max-height: 500px;
        overflow-y: auto;
    }
    .list1{
        cursor: pointer;
        // border-bottom: 1px solid #d5d7e0;
    }
    .no_data{
        margin: 200px auto 0;
        .tableEmpty{
            width: 200px;
            height: 200px;
            margin: auto;
        }
    }
    .qunzu_title{
        margin-top: -30px;
        // margin-left: 10px;
        padding: 5px;
        font-size: 18px;
        background-color: #f5f5f5;
        width:341px;
        border-right: 1px solid #d5d7e0;
    }
}
</style>
<style lang="scss">
.congroup .el-input__wrapper{
    height: 28px;
}
</style>