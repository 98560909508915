<template>
    <div class="online-bottom-container">
        <div class="content">
            <el-input v-model="keyValue" size="large" placeholder="请输入你想咨询的问题~" @keyup.enter="submit">
                <template #suffix>
                    <div @click="submit" class="btnBox">
                        <el-icon class="el-input__icon">
                            <Promotion />
                        </el-icon>
                    </div>
                </template>
            </el-input>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
const emit = defineEmits(['submit'])
// -------------------------------------------数据---------------------------------------------------
const keyValue = ref('')

// -------------------------------------------方法---------------------------------------------------
const submit = () => {
    emit('submit', keyValue.value)
    keyValue.value = ''
}
</script>

<style lang="scss" scoped>
.online-bottom-container {
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: #f5f5f5;

    .content {
        display: flex;

        .btnBox {
            cursor: pointer;
        }
    }
}
</style>