<template>
    <div class="empower-container">
        <el-dialog v-model="dialogVisible" title="企业认证授权书" width="800px" center :before-close="handleClose">
            <div class="conter">
                <div>山东辰华科技信息有限公司</div>
                <div>
                    <span>本企业</span>
                    <span class="blueText">{{ reqParams.companyName }}</span>
                </div>
                <div>
                    <span>确认授权</span>
                    <span class="blueText">{{ reqParams.headUserName }}</span>
                    <span>（身份证号：</span>
                    <span class="blueText">{{ reqParams.headCard }}</span>
                    <span>）</span>
                </div>
                <div>
                    作为企业负责人代表本企业向贵公司完成企业认证，提供认证所需材料，执行企业信息维护管理、解除认证、负责人变更功能，有权以本企业名义同意《辰华科技企业认证服务协议》并完成认证所需的相关操作，在本企业撤销该被授权人的授权前，其操作行为对本企业具有约束力。
                </div>
                <div class="right">
                    <div class="cell">
                        <div class="label">企业公章：</div>
                        <div class="value"></div>
                    </div>
                    <div class="cell">
                        <div class="label">申请日期：</div>
                        <div class="value">{{ parseTime() }}</div>
                    </div>
                </div>
            </div>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="handleClose">取消</el-button>
                    <el-button type="primary" @click="submit">
                        下载授权书
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue';
import { getPoaPre } from '@/api/enterprise.js'
import { getToken } from '@/utils/auth';
import { elMessage } from '@/store';
import { parseTime } from '@/utils/index.js'
import http from '@/api/http'
// -----------------------------------数据-----------------------------------------------
const prop = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    proData: {
        type: Object,
        default: () => { }
    }
})
const emit = defineEmits(['close'])
// 弹出框的显示和隐藏
const dialogVisible = ref(false)

const reqParams = reactive({
    // 企业名称
    companyName: '',
    // 负责人身份证号
    headCard: '',
    // 负责人姓名
    headUserName: ''
})

// -----------------------------------监听-----------------------------------------------
watch(() => prop.proData, (val) => {
    for (let key in reqParams) {
        reqParams[key] = val[key]
        reqParams['headUserName'] = val['headName']
        reqParams['headCard'] = val['headCreditCode']
    }
}, { immediate: true, deep: true })
// --------------------------------页面加载触发-------------------------------------------
onMounted(() => {
    dialogVisible.value = prop.show
    limitation()
})
// -----------------------------------方法-----------------------------------------------
// 提交
const submit = () => {
    getPoaPre(reqParams).then(res => {
        if (res.code == 0) {
            let url = http.baseUrl + 'uaa/sysCompanyCertInfo/getPoa?exportKey=' + res.data + '&access_token=' + getToken()
            window.open(url, '_blank')
        }
    })

}
// 关闭
const handleClose = () => {
    emit('close')
}

// 限制
const limitation = () => {
    let flag = false
    for (const key in reqParams) {
        if (!reqParams[key]) {
            flag = true
        }
    }
    if (flag) {
        elMessage.error('企业名称、负责人姓名、负责人身份证号不可为空！')
        handleClose()
    }
}

</script>

<style lang="scss" scoped>
// 
.empower-container {
    .conter {
        background: #edf1ff;
        padding: 20px;
        box-sizing: border-box;
        line-height: 40px;
        font-size: 18px;

        .right {
            .cell {
                display: flex;
                justify-content: flex-end;

                .value {
                    width: 120px;
                }
            }
        }
    }

    .dialog-footer {
        display: flex;
        justify-content: flex-end;
    }
}
</style>