<template>
    <div class="Historical" @click="hideBox" :style="isHeightNewsH">
        <div v-if="props.hisSource == '2'" class="overhidehis">
            <div class="fl ml10">{{listAll.length}}条与"{{props.keywords2}}"相关的历史记录</div>
            <div class="fr mr10 cura" @click="goDetail">进入聊天</div>
        </div>
        <div v-else>
            <div class="typeTitle overhide mt10">
                <div @click="closehis" class="bakf fl ml10">
                    <i class="iconfont icon-xitongfanhui"></i>
                </div>
                <div class="bakr fl ml10">"{{from.chatName}}"历史记录</div>
            </div>
            <div style="padding: 10px;">
                <div v-if="isHistory" @click="backParentHis" class="bakf fl" style="margin-right: 3px;margin-top: 5px;">
                    <i class="iconfont icon-xitongfanhui"></i>
                </div>
                <div class="searchboxH">
                    <div class="sou fl">
                        <el-input v-model="from.keywords" clearable onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;" placeholder="搜索" @clear="workTopicFindChatRecordList('first')" @blur="workTopicFindChatRecordList('first')" @keyup.enter="$event.target.blur()" size="small">
                            <template #append>
                                <el-button :icon="Search" size="small" @click="workTopicFindChatRecordList('first')" class="cura" style="width:40px;min-width:40px;margin: 0;padding:0"/>
                            </template>
                        </el-input>
                    </div>
                </div>
                <el-date-picker v-model="from.timeList" type="daterange" format="YYYY-MM-DD" value-format="YYYY-MM-DD" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="getFTime" style="width:230px;"/>
                <el-select filterable clearable v-model="from.memberId" @change="workTopicFindChatRecordList('first')" size="default" style="width:123px;margin-left: 10px;" placeholder="请选择">
                    <el-option v-for="item in personnelList" :key="item.id" :label="item.mname" :value="item.id"></el-option>
                </el-select>
                <div v-if="!isHistory" style="width:100%;border-bottom: solid 1px #f5f5f5;height:40px">
                    <div v-for="(item,index) in tabList" @click="ChoseTabList(index)" :class="['tabList',ChoseTab==index?'ChoseTab':'']" :key="index">{{item}}</div>
                    <div v-if="ChoseTab==1" style="float:right;margin-top:10px">
                        <!-- <sortboxtitle ref="sortboxtitle" :sortList="sortList" :isSort="false" :sortId="sortId" @changeSort="changeSort" /> -->
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isHistory">
            <div class="HistoricalList" :style="isHeightNewsH1" id="ChatBoxHis" ref="listHis1" @scroll="scrollEventHis">
                <div v-for="(item,index) in listHis" :key="index" :class="['HistoricalItem',item.checked?'choseBg':'',locationIdHIs==item.id?'choseBg':'']" :id="item.id" @click="HistoricalItemChecked(item)">
                    <el-checkbox style="margin-top:5px;margin-left:5px" v-if="MultipleChoice" @change="HistoricalItemChecked(item)" v-model="item.checked"></el-checkbox>
                    <div style="text-align:center;" v-if="item.msgType=='0' || item.msgType=='-1'">
                        <el-popover placement="right" trigger="click">
                            <div class="popoverIsReference ">{{item.formatTime}}&nbsp;&nbsp;{{item.chatMsg}}</div>
                            <template #reference>
                                <div class="remove " style="display: inline-block;overflow:hidden;text-overflow:ellipsis; white-space:nowrap;max-width:550px;padding:0 10px;margin-left: 6px;">{{item.formatTime}}&nbsp;&nbsp;{{item.chatMsg}}</div>
                            </template>
                        </el-popover>
                    </div>
                    <div v-else class="mySendOut" @contextmenu.prevent="openMenu($event,item)" @mouseover="mouseoverItem(item)">
                        <img  @click="workGroupFindFriendInfo(item,$event)" :src="urlImg + item.sendUserHeadUrl" class="headUrl" />
                        <div>
                            <div style="margin:3px 0 8px 0;">
                                <span style="font-size:12px;color:#000;">{{item.sendUserName}}</span>
                                <span style="margin: 0 5px"></span>
                                <span style="font-size:12px;color:#899ea4">{{item.formatTime}}</span>
                            </div>
                            <div v-if="item.msgType=='1'" style="max-width: 470px;display: flex;">
                                <div style="cursor: pointer;word-break: break-all;" class="messages" v-html="item.chatMsg"> </div>
                            </div>
                            <div v-else-if="item.msgType=='4'" style="max-width: 500px;display: flex;" :class="[item.sendUserId==userId?'reverse':'' ]" @click="handleClickMyWork($event,item)">
                                <div v-html="item.chatMsg" @contextmenu.prevent="openMenu($event,item)"> </div>
                            </div>
                            <div v-else style="display:flex;">
                                <div @click="preview(item.fileUrl)" @contextmenu.prevent="openMenu($event,item)" v-if="item.fileSuffix=='png' || item.fileSuffix=='jpg'||item.fileSuffix=='jpeg'" class="imgf" style="cursor: pointer;width: 220px;height: 125px;">
                                    <div>
                                        <img :src="urlImg + item.fileUrl" style="cursor: pointer;max-width: 218px;max-height: 123px;margin: 0 auto;vertical-align:middle;display: block;"/>
                                    </div>
                                </div>
                                <div v-else @click="preview(item.fileUrl)" style="display:flex;align-items: center;cursor: pointer;position: relative;" class="fileItem">
                                    <div style="margin: 5px 10px 5px 5px;cursor: pointer;float: left;">
                                        <img v-if="item.fileSuffix=='bmp'||item.fileSuffix=='webp'" src='@/assets/img/MyWork/32jpg.png' />
                                        <img v-else-if="item.fileSuffix=='docx'" src='@/assets/img/MyWork/32word.png' />
                                        <img v-else-if="item.fileSuffix=='zip'||item.fileSuffix=='rar'||item.fileSuffix=='7z'||item.fileSuffix=='ar'" src='@/assets/img/MyWork/32zip.png' />
                                        <img v-else-if="item.fileSuffix=='xls'" src='@/assets/img/MyWork/32exl.png' />
                                        <img v-else-if="item.fileSuffix=='xlsx'||item.fileSuffix=='xltx'||item.fileSuffix=='xlsm'||item.fileSuffix=='xlsb'||item.fileSuffix=='csv'||item.fileSuffix=='xml'" src='@/assets/img/MyWork/32exl.png' />
                                        <img v-else-if="item.fileSuffix=='ppt'||item.fileSuffix=='pptx'||item.fileSuffix=='xps'||item.fileSuffix=='pps'||item.fileSuffix=='ppsx'||item.fileSuffix=='ppa'||item.fileSuffix=='ppam'||item.fileSuffix=='pot'||item.fileSuffix=='potx'||item.fileSuffix=='thmx'" src='@/assets/img/MyWork/32p.png' />
                                        <img v-else-if="item.fileSuffix=='pdf'" src='@/assets/img/MyWork/32pdf.png' />
                                        <img v-else-if="item.fileSuffix=='doc'||item.fileSuffix=='dot'||item.fileSuffix=='docm'||item.fileSuffix=='dotm'||item.fileSuffix=='dotx'" src='@/assets/img/MyWork/32word.png' />
                                        <img v-else-if="item.fileSuffix=='txt'||item.fileSuffix=='rtf'" src='@/assets/img/MyWork/32text.png' />
                                        <img v-else-if="item.fileSuffix=='mov'||item.fileSuffix=='mp4'||item.fileSuffix=='avi'||item.fileSuffix=='wmv'||item.fileSuffix=='asf'||item.fileSuffix=='rm'||item.fileSuffix=='rmvb'" src='@/assets/img/MyWork/32movie.png' />
                                        <img v-else-if="item.fileSuffix=='mp3'||item.fileSuffix=='wav'||item.fileSuffix=='wv'||item.fileSuffix=='wma'" src='@/assets/img/MyWork/32audio.png' />
                                        <img v-else-if="item.fileSuffix=='PHTML'||item.fileSuffix=='htm'||item.fileSuffix=='html'||item.fileSuffix=='JSP'||item.fileSuffix=='HTML'||item.fileSuffix=='php'||item.fileSuffix=='ASP'||item.fileSuffix=='PHP'||item.fileSuffix=='PHP3'" src='@/assets/img/MyWork/32html.png' />
                                        <img v-else-if="item.fileSuffix=='psd'||item.fileSuffix=='eps'" src='@/assets/img/MyWork/32ps.png' />
                                        <img v-else-if="item.fileSuffix=='svg'" src='@/assets/img/MyWork/32svg.png' />
                                        <img v-else-if="item.fileSuffix=='xmind'" src='@/assets/img/MyWork/32Xmind.png' />
                                        <img v-else-if="item.fileSuffix=='rp'" src='@/assets/img/MyWork/32rp.png' />
                                        <img v-else src='@/assets/img/MyWork/32other.png' />
                                    </div>
                                    <div class="fl">
                                        <!-- <el-tooltip placement="top" v-if="item.fileName.length>19" :content="item.fileName">
                                            <div class="overone" style="width:200px">{{item.fileName?item.fileName:''}}</div>
                                        </el-tooltip>
                                        <div class="overone" v-else style="width:200px">{{item.fileName?item.fileName:''}}</div> -->
                                        <tooltipOver :content="item.fileName ? item.fileName : ''" className="width:200px;"></tooltipOver>
                                        <div>{{item.fileSize}}</div>
                                    </div>
                                    <div v-if="item.readSign =='3'" class="downtag">已下载</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div v-show="ChoseTab==0" :style="isHeightNewsH1" class="HistoricalList" id="ChatBox" ref="listHis2" @scroll="scrollEventHis">
                <div v-if="props.hisSource != '2' && from.keywords && from.keywords != ''" class="fl ml10">{{listAll.length}}条与“{{from.keywords}}”相关的历史记录</div>
                <div v-for="(item,index) in listAll" :key="index" :class="['HistoricalItem',item.checked?'choseBg':'',locationIdHIs==item.id?'choseBg':'']" :id="item.id" @click="HistoricalItemChecked(item)">
                    <el-checkbox style="margin-top:5px;margin-left:5px" v-if="MultipleChoice" @change="HistoricalItemChecked(item)" v-model="item.checked"></el-checkbox>
                    <div style="text-align:center;" v-if="item.msgType=='0' || item.msgType=='-1'">
                        <el-popover placement="right" trigger="click">
                            <div class="popoverIsReference">{{item.formatTime}}&nbsp;&nbsp;{{item.chatMsg}}</div>
                            <template #reference>
                                <div class="remove" style="display: inline-block;overflow:hidden;text-overflow:ellipsis; white-space:nowrap;max-width:550px;padding:0 10px;margin-left: 6px;">{{item.formatTime}}&nbsp;&nbsp;{{item.chatMsg}}</div>
                            </template>
                        </el-popover>
                    </div>
                    <div v-else class="mySendOut" @contextmenu.prevent="openMenu($event,item)" @mouseover="mouseoverItem(item)">
                        <img  @click="workGroupFindFriendInfo(item,$event)" :src="urlImg + item.sendUserHeadUrl" class="headUrl" />
                        <div>
                            <div style="margin:3px 0 8px 0;">
                                <span style="font-size:12px;color:#000;">{{item.sendUserName}}</span>
                                <span style="margin: 0 5px"></span>
                                <span style="font-size:12px;color:#899ea4">{{item.formatTime}}</span>
                            </div>
                            <div v-if="item.msgType=='1'" style="max-width: 470px;display: flex;">
                                <div style="cursor: pointer;word-break: break-all;" class="messages" v-html="item.chatMsg"> </div>
                            </div>
                            <div v-else-if="item.msgType=='4'" style="max-width: 500px;display: flex;" :class="[item.sendUserId==userId?'reverse':'' ]" @click="handleClickMyWork($event,item)">
                                <div v-html="item.chatMsg" @contextmenu.prevent="openMenu($event,item)"> </div>
                            </div>
                            <div v-else style="display:flex;">
                                <div @click="preview(item.fileUrl)" @contextmenu.prevent="openMenu($event,item)" v-if="item.fileSuffix=='png' || item.fileSuffix=='jpg'||item.fileSuffix=='jpeg'" class="imgf" style="cursor: pointer;width: 220px;height: 125px;">
                                    <div>
                                        <img :src="urlImg + item.fileUrl" style="cursor: pointer;max-width: 218px;max-height: 123px;margin: 0 auto;vertical-align:middle;display: block;"/>
                                    </div>
                                </div>
                                <div v-else @click="preview(item.fileUrl)" style="display:flex;align-items: center;cursor: pointer;position: relative;" class="fileItem">
                                    <div style="margin: 5px 10px 5px 5px;cursor: pointer;float: left;">
                                        <img v-if="item.fileSuffix=='bmp'||item.fileSuffix=='webp'" src='@/assets/img/MyWork/32jpg.png' />
                                        <img v-else-if="item.fileSuffix=='docx'" src='@/assets/img/MyWork/32word.png' />
                                        <img v-else-if="item.fileSuffix=='zip'||item.fileSuffix=='rar'||item.fileSuffix=='7z'||item.fileSuffix=='ar'" src='@/assets/img/MyWork/32zip.png' />
                                        <img v-else-if="item.fileSuffix=='xls'" src='@/assets/img/MyWork/32exl.png' />
                                        <img v-else-if="item.fileSuffix=='xlsx'||item.fileSuffix=='xltx'||item.fileSuffix=='xlsm'||item.fileSuffix=='xlsb'||item.fileSuffix=='csv'||item.fileSuffix=='xml'" src='@/assets/img/MyWork/32exl.png' />
                                        <img v-else-if="item.fileSuffix=='ppt'||item.fileSuffix=='pptx'||item.fileSuffix=='xps'||item.fileSuffix=='pps'||item.fileSuffix=='ppsx'||item.fileSuffix=='ppa'||item.fileSuffix=='ppam'||item.fileSuffix=='pot'||item.fileSuffix=='potx'||item.fileSuffix=='thmx'" src='@/assets/img/MyWork/32p.png' />
                                        <img v-else-if="item.fileSuffix=='pdf'" src='@/assets/img/MyWork/32pdf.png' />
                                        <img v-else-if="item.fileSuffix=='doc'||item.fileSuffix=='dot'||item.fileSuffix=='docm'||item.fileSuffix=='dotm'||item.fileSuffix=='dotx'" src='@/assets/img/MyWork/32word.png' />
                                        <img v-else-if="item.fileSuffix=='txt'||item.fileSuffix=='rtf'" src='@/assets/img/MyWork/32text.png' />
                                        <img v-else-if="item.fileSuffix=='mov'||item.fileSuffix=='mp4'||item.fileSuffix=='avi'||item.fileSuffix=='wmv'||item.fileSuffix=='asf'||item.fileSuffix=='rm'||item.fileSuffix=='rmvb'" src='@/assets/img/MyWork/32movie.png' />
                                        <img v-else-if="item.fileSuffix=='mp3'||item.fileSuffix=='wav'||item.fileSuffix=='wv'||item.fileSuffix=='wma'" src='@/assets/img/MyWork/32audio.png' />
                                        <img v-else-if="item.fileSuffix=='PHTML'||item.fileSuffix=='htm'||item.fileSuffix=='html'||item.fileSuffix=='JSP'||item.fileSuffix=='HTML'||item.fileSuffix=='php'||item.fileSuffix=='ASP'||item.fileSuffix=='PHP'||item.fileSuffix=='PHP3'" src='@/assets/img/MyWork/32html.png' />
                                        <img v-else-if="item.fileSuffix=='psd'||item.fileSuffix=='eps'" src='@/assets/img/MyWork/32ps.png' />
                                        <img v-else-if="item.fileSuffix=='svg'" src='@/assets/img/MyWork/32svg.png' />
                                        <img v-else-if="item.fileSuffix=='xmind'" src='@/assets/img/MyWork/32Xmind.png' />
                                        <img v-else-if="item.fileSuffix=='rp'" src='@/assets/img/MyWork/32rp.png' />
                                        <img v-else src='@/assets/img/MyWork/32other.png' />
                                    </div>
                                    <div class="fl">
                                        <!-- <el-tooltip placement="top" v-if="item.fileName.length>19" :content="item.fileName">
                                            <div class="overone" style="width:200px">{{item.fileName?item.fileName:''}}</div>
                                        </el-tooltip>
                                        <div class="overone" v-else style="width:200px">{{item.fileName?item.fileName:''}}</div> -->
                                        <tooltipOver :content="item.fileName ? item.fileName : ''" className="width:200px;"></tooltipOver>
                                        <div>{{item.fileSize}}</div>
                                    </div>
                                    <div v-if="item.readSign =='3'" class="downtag">已下载</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="item.isHover==true && (from.keywords || props.keywords2 || from.memberId || (from.timeList && from.timeList.length > 0))" class="look_box fl" @click="workGroupForwardLocateChat(item)">查看上下文</div>
                </div>
            </div>
            <div v-show="ChoseTab==1">
                <div>
                    <el-table ref="listHis3" id="listHis3ID" class="Historicals" :max-height="maxHeight" :row-key="id" element-loading-text="数据加载中" :data="FileData" style="width: 100%" tooltip-effect="dark" @selection-change="handleSelectionChange" @scroll="handleScroll">
                        <el-table-column type="selection" width="55" v-if="FileMultiple">
                        </el-table-column>
                        <el-table-column width="300">
                            <template #header>
                                <span style="font-weight: 900;">名称</span>
                            </template>
                            <template #default="scope">
                                <div style="display:flex;align-items: center;margin:10px 0" @contextmenu.prevent="openMenu($event,scope.row)">
                                    <div class="fileimg">
                                        <img v-if="scope.row.fileSuffix=='png' || scope.row.fileSuffix=='jpg'||scope.row.fileSuffix=='jpeg'" :src="urlImg+scope.row.fileUrl" />
                                        <img v-else-if="scope.row.fileSuffix=='bmp'||scope.row.fileSuffix=='webp'" src='@/assets/img/MyWork/32jpg.png' />
                                        <img v-else-if="scope.row.fileSuffix=='docx'" src='@/assets/img/MyWork/32word.png' />
                                        <img v-else-if="scope.row.fileSuffix=='zip'||scope.row.fileSuffix=='rar'||scope.row.fileSuffix=='7z'||scope.row.fileSuffix=='ar'" src='@/assets/img/MyWork/32zip.png' />
                                        <img v-else-if="scope.row.fileSuffix=='xls'" src='@/assets/img/MyWork/32exl.png' />
                                        <img v-else-if="scope.row.fileSuffix=='xlsx'||scope.row.fileSuffix=='xltx'||scope.row.fileSuffix=='xlsm'||scope.row.fileSuffix=='xlsb'||scope.row.fileSuffix=='csv'||scope.row.fileSuffix=='xml'" src='@/assets/img/MyWork/32exl.png' />
                                        <img v-else-if="scope.row.fileSuffix=='ppt'||scope.row.fileSuffix=='pptx'||scope.row.fileSuffix=='xps'||scope.row.fileSuffix=='pps'||scope.row.fileSuffix=='ppsx'||scope.row.fileSuffix=='ppa'||scope.row.fileSuffix=='ppam'||scope.row.fileSuffix=='pot'||scope.row.fileSuffix=='potx'||scope.row.fileSuffix=='thmx'" src='@/assets/img/MyWork/32p.png' />
                                        <img v-else-if="scope.row.fileSuffix=='pdf'" src='@/assets/img/MyWork/32pdf.png' />
                                        <img v-else-if="scope.row.fileSuffix=='doc'||scope.row.fileSuffix=='dot'||scope.row.fileSuffix=='docm'||scope.row.fileSuffix=='dotm'||scope.row.fileSuffix=='dotx'" src='@/assets/img/MyWork/32word.png' />
                                        <img v-else-if="scope.row.fileSuffix=='txt'||scope.row.fileSuffix=='rtf'" src='@/assets/img/MyWork/32text.png' />
                                        <img v-else-if="scope.row.fileSuffix=='mov'||scope.row.fileSuffix=='mp4'||scope.row.fileSuffix=='avi'||scope.row.fileSuffix=='wmv'||scope.row.fileSuffix=='asf'||scope.row.fileSuffix=='rm'||scope.row.fileSuffix=='rmvb'" src='@/assets/img/MyWork/32movie.png' />
                                        <img v-else-if="scope.row.fileSuffix=='mp3'||scope.row.fileSuffix=='wav'||scope.row.fileSuffix=='wv'||scope.row.fileSuffix=='wma'" src='@/assets/img/MyWork/32audio.png' />
                                        <img v-else-if="scope.row.fileSuffix=='PHTML'||scope.row.fileSuffix=='htm'||scope.row.fileSuffix=='html'||scope.row.fileSuffix=='JSP'||scope.row.fileSuffix=='HTML'||scope.row.fileSuffix=='php'||scope.row.fileSuffix=='ASP'||scope.row.fileSuffix=='PHP'||scope.row.fileSuffix=='PHP3'" src='@/assets/img/MyWork/32html.png' />
                                        <img v-else-if="scope.row.fileSuffix=='psd'||scope.row.fileSuffix=='eps'" src='@/assets/img/MyWork/32ps.png' />
                                        <img v-else-if="scope.row.fileSuffix=='svg'" src='@/assets/img/MyWork/32svg.png' />
                                        <img v-else-if="scope.row.fileSuffix=='xmind'" src='@/assets/img/MyWork/32Xmind.png' />
                                        <img v-else-if="scope.row.fileSuffix=='rp'" src='@/assets/img/MyWork/32rp.png' />
                                        <img v-else src='@/assets/img/MyWork/32other.png' />
                                    </div>
                                    <div class="fl">
                                        <tooltipOver :content="scope.row.fileName" className="width:230px;"></tooltipOver>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="sendUserName" show-overflow-tooltip>
                            <template #header>
                                <span style="font-weight: 900;">创建人</span>
                            </template>
                        </el-table-column>
                        <el-table-column width="100" prop="formatTime" show-overflow-tooltip>
                            <template #header>
                                <span style="font-weight: 900;">创建时间</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fileSize" show-overflow-tooltip>
                            <template #header>
                                <span style="font-weight: 900;">大小</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="his_multip">
            <MultipleChoice1 :fullscreenCon="fullscreenCon" :Num="ChoseListMsgId.length ? ChoseListMsgId.length : SelectionChangeList.length" @Close="CloseMultipleChoice" :MultipleChoice="MultipleChoice"></MultipleChoice1>
        </div>
        <div id="Mask" ref="Mask" v-show="rightPopup||RightDetailPop||RightQuotePop||RightAitePop" style="width:100vw;height:100vh; position: fixed;top:0px;left:0px;z-index:9999" @click="ClickMark"></div>
        <div id="groupInfoId" class="rightPopup">
            <div class="rightPopupItem" @click="download(1)">下载</div>
            <div class="rightPopupItem" @click="ForWardS">转发</div>
            <div class="rightPopupItem" @click="ClickFileMultiple">多选</div>
            <div class="rightPopupItem" @click="workGroupForwardLocateChat('1')">查看上下文</div>
        </div>
        <div id="groupInfoIds" class="rightPopup">
            <div class="rightPopupItem" @click="download(1)">下载</div>
            <div class="rightPopupItem" @click="ForWardS">转发</div>
            <div class="rightPopupItem" @click="Multiple">多选</div>
            <div class="rightPopupItem" @click="workTopicDeleteChatRecord('all')">删除</div>
            <div v-if="from.keywords || props.keywords2 || from.memberId || (from.timeList && from.timeList.length > 0)" class="rightPopupItem" @click="workGroupForwardLocateChat">查看上下文</div>
        </div>
        <!-- 名片 -->
        <div class="detailsPop" ref="detailsPop">
            <businessCard ref="businessCard1" :cardDetail="FriendInfo" @ChoseMailList1="ChoseMailList"></businessCard>
        </div>
        <div style="width:642px;height:640px; position: fixed;top:0px;left:0px;z-index:999" @click="CloseRecordShow" v-if="recordShow"></div>
        <!-- 多选下载弹窗 -->
        <DownloadPop :list="DownLoadList" :dialogVisible="DownloadPopDialog" @close="DownloadPopDialog=false"></DownloadPop>
    </div>
</template>

<script setup>
import { onMounted, ref, nextTick, watch, computed } from 'vue'
import http from '@/api/http'
import { Search } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import MultipleChoice1 from './MultipleChoice.vue'
import DownloadPop from './downloadPop.vue'
import { conversationStore } from '@/store'
import businessCard from '@/views/technologyManager/conversation/components/businessCard.vue'
import { bCard, cthts, ict, ctms, dctr, pstmsg, download1 } from '@/api/technologyManager'//接口
import { ExportDownloadPost } from '@/js/exportParams' //导出
const emit = defineEmits(['newDetail', 'hisBack','callBackHis'])
import { showView } from '@/api'
const DownloadPopDialog = ref(false)
const MultipleChoice = ref(false)
const FileMultiple = ref(false)
const locationIdHIs = ref('')
const maxHeight = ref(510)
const from = ref({
    sortType: '1', //排序类型 1时间排序 2名称排序 3大小排序 默认值1
    messageType: '1', //消息类型 1全部 2文件 默认1
    memberId: '',
    keywords: '',
    offset: '0', //偏移量 默认0
    timeList: [],
    newMessageCount: '0', //新消息条数 默认0
    groupInfoId: '',
    endTime: '',
    startTime: ''
})
const recordShow = ref(false)
const sortList = ref([ { value: '时间排序', id: '1' }, { value: '名称排序', id: '2' }, { value: '大小排序', id: '3' } ])
const sortId = ref('1')
const ChoseTab = ref(0)
const personnelList = ref([])
const FileData = ref([])
const urlImg = http.FileUrl
const listAll = ref([])
const tabList = ref(['全部', '文件'])
const SelectionChangeList = ref(false)
const top = ref(0)
const RightMessage = ref({})
const ChoseListMsgId = ref([])
const selectMore = ref(false)
const RightQuotePop = ref(false)
const rightPopup = ref(false)
const RightDetailPop = ref(false)
const FriendInfo = ref({})
const RightAitePop = ref(false)
const listHis = ref([])
const listHis1 = ref(null)
const listHis2 = ref(null)
const listHis3 = ref(null)
const isHistory = ref(false)
// const table = ref(null)
// const lsit = ref(null)
const Mask = ref(null)
const Aite = ref(null)
const detailsPop = ref(null)
const isHeightNewsH = ref('height: 560px;')
const isHeightNewsH1 = ref()
const businessCard1 = ref(null)
const DownLoadList = ref([])
const fullscreenCon = ref(null)
const props = defineProps({
    groupInfoId: {
        type: String,
        default: ''
    },
    hisSource: {
        type: String,
        default: ''
    },
    keywords2: {
        type: String,
        default: ''
    },
})
watch(() => conversationStore.isFullscreen, (val) => {
    fullscreenCon.value = val
    getNewsHeightHis()
}, { immediate: true })
// 点击会议消息的查看详情按钮时，跳转页面
function handleClickMyWork(event, item) {
    const clickedInsideVHtml = event.target.closest('#goDetailButton') != null
    if (clickedInsideVHtml) {
        goMeetingDetail(item)
    }
}
// 跳转至会议详情 ==== 添加会议时，如果勾选发送MyWork消息，在这里需要跳转
function goMeetingDetail(val){
    window.open(window.location.protocol + "//" + window.location.host + val.linkUrl, '_blank')
}
// 隐藏小弹窗
function hideBox(){CloseRecordShow()}
// 关闭历史页面
function closehis(){
   emit('newDetail')
}
// 查看上下文
function mouseoverItem(val){
    listAll.value.forEach(el => {
        el['isHover'] = false
        if(el.id==val.id) {
            el['isHover'] = true
        }
    });
}
// 进入聊天
function goDetail(){
    emit('newDetail', props.groupInfoId)
}
function ClickFileMultiple() {
    FileMultiple.value = true
    MultipleChoice.value = true
    // maxHeight.value = 370
    recordShow.value = false
    // isHeightNewsH1.value = 'height: 370px;'//聊天框的高度
    document.getElementById('groupInfoId').style.display = 'none'
    getNewsHeightHis()
}
// 关闭多选下载底部
function CloseMultipleChoice() {
    MultipleChoice.value = false
    FileMultiple.value = false
    SelectionChangeList.value = []
    ChoseListMsgId.value = []
    listAll.value.map((item) => (item.checked = false))
    if (ChoseTab.value == 1) {
        listHis3.value.clearSelection()
        SelectionChangeList.value = []
    }
    // maxHeight.value = 510
    // listHis2.value.style.height = '510px'
    // isHeightNewsH1.value = 'height: 510px;'//聊天框的高度
    getNewsHeightHis()
}
// 多选转发
function ClickForWard(){
    let checkList = []
    if(ChoseTab.value == 0) {
        checkList = ChoseListMsgId.value
    } else {
        SelectionChangeList.value.forEach(el => {
            checkList.push(el.id)
        });
    }
    emit('hisBack', checkList)
}
// 下载
function OpenDownloadPop() {
    DownLoadList.value = []
    ChoseListMsgId.value.map((item) => {
        listAll.value.map((items) => {
            if (item == items.id && items.fileUrl) {
                DownLoadList.value.push(items)
            }
        })
    })
    if (SelectionChangeList.value.length) {
        DownLoadList.value = SelectionChangeList.value
    }
    DownloadPopDialog.value = true
}
function HistoricalItemChecked(e) {
    if (MultipleChoice.value) {
        ChoseListMsgId.value.indexOf(e.id) != -1 ? ChoseListMsgId.value.splice(ChoseListMsgId.value.indexOf(e.id), 1) : ChoseListMsgId.value.push(e.id)
        e.checked = !e.checked
    }
}
// 多选
function Multiple() {
    MultipleChoice.value = true
    recordShow.value = false
    document.getElementById('groupInfoIds').style.display = 'none'
    // maxHeight.value = 360
    // isHeightNewsH1.value = 'height: 360px;'//聊天框的高度
    getNewsHeightHis()
}
function CloseRecordShow() {
    recordShow.value = false
    document.getElementById('groupInfoId').style.display = 'none'
    document.getElementById('groupInfoIds').style.display = 'none'
}
// 转发
function ForWardS() {
    recordShow.value = false
    let listIds = []
    if(RightMessage.value && RightMessage.value != {}) {
        listIds.push(RightMessage.value.id)
    }
    document.getElementById('groupInfoId').style.display = 'none'
    document.getElementById('groupInfoIds').style.display = 'none'
    emit('hisBack', listIds)
}
// 查看上下文
function workGroupForwardLocateChat(val, type) {
    listHis.value = []
    let recordId = ''
    locationIdHIs.value = ''
    recordShow.value = false
    isHistory.value = true
    if(val && val.id) {
        recordId = val.id
    } else {
        recordId = RightMessage.value.id
    }
    document.getElementById('groupInfoId').style.display = 'none'
    pstmsg({
        chatId: props.groupInfoId,
        msgTime: '',
        recordId: recordId
    }).then((res) => {
        listHis.value = res.data
        nextTick(function () {
            // 先滚动到底部
            const chatBox1 = document.getElementById('ChatBoxHis')
            chatBox1.scrollTop = chatBox1.scrollHeight
            locationIdHIs.value = recordId
            // 再滚动到具体位置
            // setTimeout(() => {
                FileOffset({ messageId: recordId, offset: 0 }, type)
            // }, 300);
        })
    }).catch((err) => {})
}
function FileOffset(e, type) {
    setTimeout(function () {
        if(type=='1') {
            document.getElementById(e.messageId).scrollIntoView()
        } else {
            const el = document.getElementById(e.messageId)  // 需要出现在顶部的元素
            const top = el.offsetTop
            const content = document.getElementById('ChatBoxHis')  // 父级容器
            content.scrollTop = top-100
        }
    }, 300)
    setTimeout(function () {
        locationIdHIs.value = ''
    }, 3000)
}
// 返回查看上下文之前
function backParentHis(){
    recordShow.value = false
    isHistory.value = false
    listHis.value = []
}
//文件预览
function preview(row) {
    showview({
        fileurl: row
    }).then((res) => {
        if (res.code == 0) {
            window.open(res.data)
        } else {
            ElMessage({ message: '该附件格式不支持在线预览！', type: 'warning' })
        }
    })
}
// 查询用户信息
function workGroupFindFriendInfo(item, e, type) {
    if (MultipleChoice.value) return
    bCard({userId: item.sendUserId}).then((res) => {
        FriendInfo.value = res.data
        Mask.value.style.display = 'inline'
        let div = detailsPop.value.value
        div.style.display = 'inline'
        if (type == 'TopicSetting') {
            div.style.left = e.clientX - 314 + 'px'
        } else if (item.id == userId.value) {
            div.style.left = e.clientX - 314 + 'px'
        } else {
            div.style.left = e.clientX + 'px'
        }
        div.style.top = e.clientY - 20 + 'px'
    })
}
function ClickMark() {
    rightPopup.value = false
    RightDetailPop.value = false
    RightQuotePop.value = false
    RightAitePop.value = false
    Mask.style.display = 'none'
    document.getElementById('RightQuotePop').style.display = 'none'
    detailsPop.value.style.display = 'none'
    if (Aite.value) Aite.value.style.display = 'none'
}
// 点击名片聊天
function ChoseMailList(item) {
    detailsPop.value.style.display = 'none'
    Mask.value.style.display = 'none'
    if (disable.value) return
    disable.value = true
    ict({towardUserId: item.id}).then((res) => {
        ClickMark()
        if (res.code == 0) {
            TopicSettingShow.value = false
            details.value = item
            recordShow.value = true
            HistoricalList.value = []
            disable.value = false
            CloseRecordShow()
            setTimeout(() => {
                emit('callBackHis', res.data)
            });
        } else {
            ElMessage({
                message: res.message,
                type: 'error',
                duration: 3000
            })
            disable.value = false
        }
    })
}
// 删除消息
function workTopicDeleteChatRecord(e) {
    if (e != 'all' && !SelectionChangeList.value.length && !ChoseListMsgId.value.length) {
        ElMessage({
            message: '请选择文件',
            type: 'error',
            duration: 3000
        })
        return
    }
    ElMessageBox.confirm('是否确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        let list1 = []
        if (ChoseListMsgId.value && ChoseListMsgId.value.length.length > 0) {
            list1 = ChoseListMsgId.value
        } else if(SelectionChangeList.value && SelectionChangeList.value.length > 0){
            SelectionChangeList.value.map((item) => {
                list1.push(item.id)
            })
        } else {
            list1[0] = RightMessage.value.id
        }
        dctr({
            ids: list1
        }).then((res) => {
            if (res.code == 0) {
                for (let i = 0; i < list1.length; i++) {
                    for (let y = 0; y < FileData.value.length; y++) {
                        if (list1[i] == FileData.value[y].id) {
                            FileData.value.splice(y, 1)
                        }
                    }
                }
                if (list1.value.indexOf(RightMessage.value) !== -1) {
                    list1.value.splice(list1.value.indexOf(RightMessage.value), 1)
                }
                parent.removalMessage(e == 'all' ? RightMessage.value.id : list1)
                MultipleChoice.value = false
                ChoseListMsgId.value = []
                list1.map((item) => (item.checked = false))
                if (ChoseTab.value == 1) {
                    listHis3.value.clearSelection()
                    SelectionChangeList.value = []
                }
            }
            recordShow.value = false
            document.getElementById('groupInfoIds').style.display = 'none'
            // maxHeight.value = 510
            // listHis2.value.style.height = '510px'
            // isHeightNewsH1.value = 'height: 510px;'//聊天框的高度
            getNewsHeightHis()
            ElMessage({
                message: res.code == 0 ? '操作成功' : res.message,
                type: res.code == 0 ? 'success' : 'error',
                duration: 3000
            })
        })
    })
}
function openMenu(e, item) {
    RightMessage.value = item
    recordShow.value = true
    if (ChoseTab.value) {
        document.getElementById('groupInfoId').style.display = 'inline'
        document.getElementById('groupInfoId').style.left = e.clientX + 'px'
        document.getElementById('groupInfoId').style.top = e.clientY - 20 + 'px'
    } else {
        document.getElementById('groupInfoIds').style.display = 'inline'
        document.getElementById('groupInfoIds').style.left = e.clientX + 'px'
        document.getElementById('groupInfoIds').style.top = e.clientY - 20 + 'px'
    }
}
// 下载附件
function download(item) {
    document.getElementById('groupInfoId').style.display = 'none'
    let data = []
    if (item) {
        data = [
            {
                id: RightMessage.value.id,
                fileSize: '',
                fileName: RightMessage.value.fileName,
                fileSize: '',
                fileUrl: RightMessage.value.fileUrl
            }
        ]
        if(RightMessage.value.msgType !='2' && RightMessage.value.msgType !='3') {
            ElMessage({ message: '只能保存文件到电脑！', type: 'warning',duration: 3000 })
            return
        }
        RightMessage.value.readSign = '3'
    } else {
        if (!SelectionChangeList.value.length && !DownLoadList.value.length) {
            ElMessage({
                message: '请选择文件',
                type: 'error',
                duration: 3000
            })
            return
        }
        data = SelectionChangeList.value.length ? SelectionChangeList.value : DownLoadList.value
        if(SelectionChangeList.value.length) {
            SelectionChangeList.value.forEach(el => {
                el.readSign = '3'
            })
        } else if(DownLoadList.value) {
            DownLoadList.value.forEach(el => {
                el.readSign = '3'
            })
        }
        for(let i=0;i<data.length;i++) {
            if(data[i].msgType !='2' && data[i].msgType !='3') {
                ElMessage({ message: '只能保存文件到电脑！', type: 'warning',duration: 3000 })
                return
            }
        }
    }
    download1(data).then((res) => {
        ExportDownloadPost(res)
        MultipleChoice.value = false
        recordShow.value = false
        // window.open(http.baseUrl + 'uaa/workMyFiles/downFile?downToken=' + res.data.downToken, '_self')
        document.getElementById('groupInfoId').style.display = 'none'
        document.getElementById('groupInfoIds').style.display = 'none'
        // maxHeight.value = 510
        // listHis2.value.style.height = '510px'
        // isHeightNewsH1.value = 'height: 510px;'//聊天框的高度
        getNewsHeightHis()
        if (ChoseTab.value == 1) {
            listHis3.value.clearSelection()
            SelectionChangeList.value = []
        }
    })
    listAll.value.map((item) => {
        item.checked = false
    })
}
// 人员下拉
function workTopicFindChatMemberList() {
    personnelList.value = []
    ctms({ chatId: props.groupInfoId }).then((res) => {
        if (res.data && res.data.length) {
            personnelList.value = res.data
        }
    })
}
function getFTime(e){
    from.timeList = e
    workTopicFindChatRecordList('first')
}
// 查询详情
function workTopicFindChatRecordList(type) {
    isHistory.value = false
    if (type) {
        from.value.offset = 0
        FileData.value = []
        listAll.value = []
    }
    if(from.value.timeList==null) {
        from.value.timeList=[]
    }
    let keywordsT = from.value.keywords
    if(props.hisSource.value== '2') {
        keywordsT = props.keywords2 
    } else {
        props.keywords2 = ''
    }
    let params = {
        beginTime: from.value.timeList.length > 0?from.value.timeList[0]:'',
        endTime: from.value.timeList.length > 0?from.value.timeList[1]:'',
        chatId: props.groupInfoId,
        keyword: keywordsT,
        nodeTime: from.value.nodeTime,
        offset:from.value.offset ? from.value.offset : 0,
        querySize: 25,
        sentUserId:from.value.memberId,
        sortFiled: sortId.value,
        sortType: from.value.sortType,
        tag: ChoseTab.value + 1
    }
    cthts(params).then((res) => {
        if(res.code == 0) {
            // res.data.dataList.map((item) => (item.checked = false))
            let dataList = res.data.dataList
            for (let i = 0; i < dataList.length; i++) {
                dataList[i].checked = false
                const chatMsg = JSON.parse(JSON.stringify(dataList[i].chatMsg))
                if(chatMsg && chatMsg.indexOf('@') != -1) {
                    // 使用正则表达式进行匹配并提取需要的部分
                    let regex = /@\S+/g;
                    let matches = chatMsg.match(regex);
                    if(matches&&matches.length >0) {
                        matches.forEach(el => {
                            const aIndex = personnelList.value.findIndex(itemA => '@'+itemA.staffName == el)
                            if(aIndex != -1 || el=='@所有人') {
                                dataList[i].chatMsg = replaceAllH(dataList[i].chatMsg, el,'<span style="color:#1492ff">'+el+'</span>')
                            }
                        });
                    }
                }
                if(chatMsg && chatMsg.indexOf('.com') != -1 || chatMsg.indexOf('.cn') != -1) {
                    var urlReg= /[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/g;
                    var array = chatMsg.match(urlReg);
                    dataList[i].chatMsg = replaceAllH(dataList[i].chatMsg, array,'<a href=\"http:\/\/'+array+'\">'+array+'</a>')
                }
            }
            if (from.value.messageType == 1) {
                listAll.value = [...dataList, ...listAll.value]
            } else {
                FileData.value = [...dataList, ...FileData.value]
                if (type) {
                    nextTick(() => {
                        let tableBodyWrapper = document.getElementById('listHis3ID')
                        tableBodyWrapper.scrollTop = tableBodyWrapper.scrollHeight
                    })
                }
            }
            from.value.offset = res.data.offset
            from.value.nodeTime = res.data.nodeTime
            from.value.chatName = res.data.chatName
            if (type) {
                nextTick(() => {
                    let targetbox = listHis2.value
                    targetbox.scrollTop = targetbox.scrollHeight
                    top.value = targetbox.scrollHeight
                })
            } else {
                if (dataList.length)
                nextTick(function () {
                    let top1 = listHis2.value.scrollHeight
                    listHis2.value.scrollTop = top1 - top.value
                    top.value = top1
                })
            }
        }
    })
}
// 替换@
function replaceAllH(item, sourceStr,newStr){
    let reg = new RegExp(sourceStr,'g')
    return item.replace(reg, newStr)
}
function ChoseTabList(index) {
    if (ChoseTab.value == index) return
    FileData.value = []
    MultipleChoice.value = false
    ChoseListMsgId.value = []
    listAll.value = []
    ChoseTab.value = index
    SelectionChangeList.value = []
    from.value.timeList = []
    from.value.offset = 0
    from.value.messageType = index + 1
    // maxHeight.value = 510
    // listHis2.value.style.height = '510px'
    // listHis3.value.style.height = '510px'
    // isHeightNewsH1.value = 'height: 510px;'//聊天框的高度
    getNewsHeightHis()
    workTopicFindChatRecordList('first')
}
// 排序
function changeSort(sortId) {
    sortId.value = sortId
    workTopicFindChatRecordList('first')
}
function handleSelectionChange(e) {
    SelectionChangeList.value = e
    if (SelectionChangeList.value.length) {
        MultipleChoice.value = true
        // maxHeight.value = 380
        // isHeightNewsH1.value = 'height: 380px;'//聊天框的高度
        getNewsHeightHis()
    } else {
        MultipleChoice.value = false
        // maxHeight.value = 510
        // isHeightNewsH1.value = 'height: 510px;'//聊天框的高度
        getNewsHeightHis()
        FileMultiple.value = false
    }
}
function handleScroll(event){
  if (event.scrollTop == 0) { // 检查是否触顶
    workTopicFindChatRecordList()// 调用加载更多数据的函数
  }
}
function newInit(){
    ChoseTab.value = 0
    from.value.messageType = 1
    listAll.value = []
    listHis.value = []
    isHistory.value = false
    //查询详情
    workTopicFindChatRecordList('first')
    // 查询人员下拉
    workTopicFindChatMemberList()
}
// 判断高度
function getNewsHeightHis(){
    if(fullscreenCon.value && fullscreenCon.value == true) {
        if(props.hisSource == '2') {
            isHeightNewsH.value = 'width: 1500px;height: 100vh;'//整个聊天框的高度
            isHeightNewsH1.value = 'height: calc(100vh - 150px)'//聊天框的高度
            maxHeight.value = 'calc(100vh - 150px)'
        } else {
            isHeightNewsH.value = 'width: 1500px;height: calc(100vh - 12px)'//整个聊天框的高度
            isHeightNewsH1.value = 'height: calc(100vh - 170px)'//聊天框的高度
            maxHeight.value = 'calc(100vh - 170px)'
        }
        if(MultipleChoice.value == true) {
            isHeightNewsH1.value = 'height: calc(100vh - 300px)'//聊天框的高度
            maxHeight.value = 'calc(100vh - 304px)'
        }
    } else {
        if(props.hisSource == '2') {
            isHeightNewsH.value = 'width: 642px;height: 669px;'//整个聊天框的高度
            isHeightNewsH1.value = 'height: 580px;'//聊天框的高度
            maxHeight.value = 580
        } else {
            isHeightNewsH.value = 'width: 642px;height: 650px;'//整个聊天框的高度
            isHeightNewsH1.value = 'height: 500px;'//聊天框的高度
            maxHeight.value = 500
        }
        if(MultipleChoice.value == true) {
            isHeightNewsH1.value = 'height: 360px;'//聊天框的高度
            maxHeight.value = 360
        }
    }
}
function scrollEventHis(e){
    var scrollTop = e.target.scrollTop
    if (scrollTop == 0 && listAll.value.length) {
        // 触顶
        workTopicFindChatRecordList()
    }
}
onMounted(()=>{
    // 查询人员下拉
    workTopicFindChatMemberList()
    //查询详情
    workTopicFindChatRecordList('first')
    listHis2.value.addEventListener('scroll', (e) => {
        var scrollTop = e.target.scrollTop
        if (scrollTop == 0 && listAll.value.length) {
            // 触顶
            workTopicFindChatRecordList()
        }
    })
    // listHis2.value.bodyWrapper.addEventListener(
    //     'scroll',
    //     (res) => {
    //         const height = res.target
    //         const scrollTop = height.scrollTop
    //         if (scrollTop == 0 && FileData.value.length) {
    //             workTopicFindChatRecordList()
    //         }
    //     },
    //     true
    // )
    // if(document.getElementById('listHis3ID')) {
    //     document.getElementById('listHis3ID').oncontextmenu = function (e) {
    //         return false
    //     }
    // }
})
</script>

<style scoped lang="scss">
.Historical {
    position: absolute;
    right: 0px;
    top: 0;
    // width: 642px;
    // height: 640px;
    background: #fff;
}
.tabList {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #000;
    display: inline-block;
    font-size: 14px;
    margin-right: 60px;
    height: 30px;
    // width: 80px;
    cursor: pointer;
    .listItem {
        height: 100px;
    }
}
.ChoseTab {
    color: #1492ff;
    border-bottom: solid 1px #1492ff;
}

.rightPopup {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    width: 85px;
    border: 1px solid #ebeef5;
    background: #fff;
    font-size: 12px;
    display: none;
    // margin: 10px;
    color: #000;
    // line-height: 20px;
    box-shadow: 2px 0px 7px #d8def0;
    .rightPopupItem {
        line-height: 25px;
        padding-left: 10px;
        cursor: pointer;
    }
    .rightPopupItem:hover {
        background: #f2f5f9;
    }
}
.closeBox {
    position: absolute;
    left: -30px;
    top: 0;
}
.HistoricalList {
    padding: 10px 10px 0;
    // height: 510px;
    overflow-y: auto;
    .choseBg {
        background: #e9eefa;
    }
    .HistoricalItem {
        width: 100%;
        padding-bottom: 5px;
        display: flex;
        margin-bottom: 5px;
        // align-items: center;
        // justify-content: space-between;
        .headUrl {
            height: 36px;
            width: 36px;
            border-radius: 3px;
            margin: 5px;
            cursor: pointer;
        }
        .mySendOut {
            display: flex;

            // flex-wrap: wrap;
        }
        .reverse {
            flex-direction: row-reverse;
        }
        .messages {
            background: #f0f2f5;
            color: #000;
            font-size: 14px;
            padding: 5px 10px;
            line-height: 20px;
            border-radius: 3px;
        }
        .fileItem {
            background: #fff;
            color: #000;
            border: solid 1px #d6dbe2;
            font-size: 12px;
            padding: 5px 10px;
            width: 300px;
            border-radius: 3px;
        }
        .message {
            background: #1492ff;
            color: #fff;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 3px;
        }
    }
}
.btn {
    margin-left: 20px;
    cursor: pointer;
    display: flex;
    background: #f3f6ff;
    border-radius: 14px;
    color: #1492ff;
    align-items: center;
}
.remove {
    background: #f0f2f5;
    color: #666;
    font-size: 12px;

    text-align: center;
    cursor: pointer;
    line-height: 28px;
}
.ml10 {
    margin-left: 10px;
}
.mr10 {
    margin-right: 10px;
}
.overhidehis {
    height: 32px;
    line-height: 32px;
    overflow: hidden;
    border-top: 1px solid #d5d7e0;
    border-bottom: 1px solid #f2f5f9;
    margin-top: 35px;
}
.cura {
    color: #1492ff;
    cursor: pointer;
}
.typeTitle {
    color: #000;
    font-size: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d5d7e0;
    margin-bottom: 10px;
}
.bakf {
    font-size: 14px;
    color: #000;
}
.bakr {
    font-size: 16px;
    color: #000;
}
.downf {
    font-size: 12px;
    background: #e6a23c;
    color: #fff;
}
.Historical .searchboxH {
    text-align: left;
    float: left;
    height: 28px;
    line-height: 28px;
    // height: 32px;
    // line-height: 32px;
    margin-right: 10px;
    width: 200px;
    font-size: 12px;
    color: #d5d7e0;
    border-color: #d5d7e0;
}
.downtag{
    width: 40px;
    height: 16px;
    background: #e6a23c;
    color: #fff;
    position: absolute;
    right: 0px;
    bottom: 0px;
    text-align: center;
    border-radius: 3px 3px 0 0;
}
.look_box{
    color: #1492ff;
    float: right;
    margin: 35px 10px 0;
    cursor: pointer;
}
.fileimg{
    width:32px;
    height:32px;
    float: left;
    margin-right:5px;
    font-size: 12px;
    color: #000;
    img{
        width:32px;
        height:32px; 
        border-radius: 3px;
    }
}
.detailsPop {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    display: none;
    width: 324px;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(189, 203, 224, 0.3);
    border-radius: 3px;
    // border: solid 1px rgb(240, 242, 245);
}

.ChoseMailList {
    line-height: 46px;
    text-align: center;
    color: #1492ff;
    cursor: pointer;
    padding-bottom: 20px;
    .ChoseMailListBtn{
        width: 99px;
        height: 30px;
        line-height: 30px;
        background: #1492ff;
        color: #fff;
        text-align: center;
        margin: auto;
        border-radius: 3px;
    }
}
.imgf{
    width: 230px;
    height: 127px;
    border: 1px solid #d5d7e0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}
.ovh{
    overflow: auto;
}
</style>
<style lang="scss">
.Historical a:hover {
    text-decoration: underline;
}
.Historicals .el-table__body tr:hover > td {
    background: #f3f6ff !important;
}
.Historical .searchboxH .el-input-group__append {
    padding: 0 !important;
    color: #fff !important;
    font-size: 16px;
    background-color: #1492ff;
    border: 1px solid #1492ff;
    overflow: hidden;
    box-shadow: 0 0 0 0;
    cursor: pointer;
}
.Historical .searchboxH {
    .sou {
        .el-input__inner {
            width: 130px;
            height: 28px;
        }
    }
}
.Historical .el-input--mini .el-input__inner,
.Historical .el-input__inner {
    height: 28px;
    line-height: 28px;
}
.his_multip .MultipleChoice{
    bottom: 3px;
}
.HistoricalList a{
    color: #1492ff !important;
    font-size: 14px;
}
.HistoricalList a:visited{
    color: #551a8b !important;
}
.Historical .el-table .el-table__cell{
    border-right: 1px solid transparent !important;
}
.Historical .el-table .el-table__body tr:hover>td {
    background: #f2f5f9 !important;
}
.Historical .el-table th.el-table__cell {
    background: #fff !important;
}
.Historical .el-table td, .Historical .el-table th.is-leaf{
    border-bottom: 1px solid #f2f5f9 !important;
}
.Historical .el-table::before{
    background-color: #f2f5f9 !important;
}
.Historical .el-date-editor .el-range-separator{
    padding-right: 12px !important;
}
.Historical .el-select__wrapper{
    min-height: 28px !important;
    line-height: 28px;
}
.Historical .el-input__wrapper, .Historical .el-input{
    height: 32px !important;
}

</style>