// 我的会话 socket
import http from '@/api/http'
import { ElMessage, ElMessageBox } from 'element-plus'
import { conversationStore } from '@/store'
// let orgId =JSON.parse(localStorage.getItem("user")).orgId
let wsConnectionOrder = {
  $wsOrder: null,
  lockReturn: false,
  //   timeout: 6000,
  timeout: 3000,
  timeoutObj: null,
  timeoutNum: null,
  //初始化webSocket长连接
  initWebSocket: function (e) {
    let userid = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId')+'@MYWORK@1' : ''
    if (userid) {
      console.log(userid,'userid');
      // const wsuri = http.ws+"imserver/"+orgId +':'+userid;
      const wsuri = http.ws
      this.$wsOrder = new WebSocket(wsuri)
      this.$wsOrder.onopen = this.wsOpen
      this.$wsOrder.onclose = this.wsClose
      this.$wsOrder.onmessage = this.wsMsg
      this.$wsOrder.onerror = this.wsError
    }
  },
  //打开websocket
  wsOpen: function (e) {
    //开始websocket心跳
    wsConnectionOrder.startWsHeartbeat()
    wsConnectionOrder.sendMsg(1)
    console.log('orderws success', e)
  },
  wsClose: function (e) {
    // wsConnectionOrder.reconnect()
    console.log('关闭websocket')
  },
  sendMsg: function (e) {
    console.log(e, 'send')
    var obj = {}
    obj.type = e
    obj.userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId')+'@MYWORK@1' : ''
    if (this.$wsOrder && this.$wsOrder.readyState == 1) {
      // websocket 启动之后在发送数据
      this.$wsOrder.send(JSON.stringify(obj))
    }
  },
  wsMsg: function (e) {
    console.log(e, 'wsMsg')
    const _this = this
    if (e && e.data != 'heard') {
      conversationStore.SET_Scoket(e.data)
      const aData = JSON.parse(e.data)
      if (aData && aData.messageType == '6') {
        ElMessage.error(aData.message)
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/index'
          location.reload()
        }, 1000)
      }
    }
    //每次接收到服务端消息后 重置websocket心跳
    wsConnectionOrder.resetHeartbeat()
  },
  wsError: function (err) {
    // //console.log("wsError",err);
    wsConnectionOrder.reconnect()
  },
  //重启websocket
  reconnect: function () {
    // console.log('重启函数')
    let _this = this
    if (_this.lockReturn) {
      return
    }
    _this.lockReturn = true
    _this.timeoutNum && clearTimeout(_this.timeoutNum)
    _this.timeoutNum = setTimeout(function () {
      //_this.wsClose();
      _this.initWebSocket()
      _this.lockReturn = false
    }, 1000)
  },
  //开启websocket
  startWsHeartbeat: function () {
    let _this = this
    _this.timeoutObj && clearInterval(_this.timeoutObj)
    _this.timeoutObj = setInterval(function () {
      //发送消息给服务端
      var obj = {}
      obj.type = 2
      obj.userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId')+'@MYWORK@1' : ''
      _this.$wsOrder.send(JSON.stringify(obj))
      //判断websocket当前状态
      if (_this.$wsOrder.readyState != 1) {
        _this.reconnect()
      }
    }, _this.timeout)
  },
  //重置websocket心跳
  resetHeartbeat: function () {
    let _this = this
    clearInterval(_this.timeoutObj)
    _this.startWsHeartbeat()
  }
}

//抛出websocket对象
export default wsConnectionOrder
