import { get, postForm, postJson } from './http'
// ----------------------------------公共api
// 预览文件
export const showView = (p) => get('mall/commonUtil/showView', p)
// 下载文件
export const downFile = (p) => get('mall/commonUtil/downFile', p)
// 地区
export const getAreaApi = (url, p) => get(url, p)
// 通过字典编码查询字典项列表
export const getDictList = (p, key = 'dictCode') => postJson(`/uaa/sysDict/encry/findList`, { [key]: p }, true)
// 获取短信验证码
export const getCodeSms = (p) => postJson(`/uaa/sms/encry/sendValidSms`, p, true)
// 查询云平台-banner列表
export const getBannerList = (p) => postJson('/uaa/banner?position=' + p)