<template>
    <div :class="[padding?'establishInformations':'establishInformation']">
        <div class="fl mlfile">
            <div class="item" :title="'附件'">
                <div style="width:20px;display: inline-block;">
                    <FileUpload v-if="!Dialogue" @loadingNumber="(e)=>{$emit('loadingNumber',e)}" :DragType="type" :icon="true" @send-file="(e)=>{$emit('FileList',e)}"></FileUpload>
                    <DialogueFileUp v-else @loadingNumber="(e)=>{$emit('loadingNumber',e)}" :DragType="type" :icon="true" @send-file="(e)=>{$emit('FileList',e)}"></DialogueFileUp>
                </div>
            </div>
        </div>
        <!-- <div class="fl">
            <div class="item" :title="'我的文件'">
                <div style="cursor: pointer;margin:0 10px" @click="$emit('type',2)">
                    <i class="iconfont icon-wenjian"></i>
                </div>
            </div>
        </div>
        <div class="fl">
            <div class="item" :title="'记事本'">
                <div style="cursor: pointer;margin:0 10px" @click="$emit('type',1)">
                    <i class="iconfont icon-jishiben2"></i>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script setup>
import { ref } from 'vue'
import FileUpload from './FileUpload.vue'
import DialogueFileUp from './DialogueFileUp.vue'
const textarea2 = ref('')
const props = defineProps({
    padding: {
        type: Boolean,
        default: false
    },
    Dialogue: {
        type: Boolean,
        default: true
    },
    type: {
        type: Number,
        default: 1
    },
    height: {
        type: Number,
        default: 100
    }
})

function claer() {
    textarea2.value = ''
}
</script>
<style scoped lang="scss">
.establishInformation {
    width: 100%;
    padding: 10px 10px 10px 20px;
    border-top: #d6dbe2 solid 1px;
    // background: #e5ebff;
}
.establishInformations {
    width: 100%;
    padding: 10px 0px 0px 0px;
    border-top: #d6dbe2 solid 1px;
    // background: #e5ebff;
}
.ml20 {
    margin-left: 20px;
}
</style>
<style>
.establishInformations .el-textarea__inner {
    padding-left: 10px;
}
.establishInformation .el-textarea__inner {
    padding-left: 10px;
}
</style>