// //个人中心
export const PersonalCenter = [
    {
        path: '/PersonalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/PersonalCenter/index.vue'),
        meta: {
            title: '个人中心',
        },
        children:[
            {
                path: '/PersonalCenter/system',
                name: 'system',
                component: () => import('@/views/PersonalCenter/system/index.vue'),
                meta: {
                    title: '账号设置',
                }
            }
        ]
    },
]