//消息、企业认证、科技资讯等
export const informationCloud = [
    {
        path: '/messageManagement',
        name: 'messageManagement',
        component: () => import('@/views/informationCloud/messageManagement/index.vue'),
        meta: {
            title: '消息中心',
        },
    },
    {
        path: '/feedBackManagement',
        name: 'feedBackManagement',
        component: () => import('@/views/informationCloud/feedBackManagement/index.vue'),
        meta: {
            title: '意见反馈',
        },
    },
    {
        path: '/partnersManagement',
        name: 'partnersManagement',
        component: () => import('@/views/informationCloud/partnersManagement/index.vue'),
        meta: {
            title: '招募伙伴',
        },
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: () => import('@/views/informationCloud/forgotPassword/index.vue'),
        meta: {
            title: '忘记密码',
        },
    },
    {
        path: '/replacePhone',
        name: 'replacePhone',
        component: () => import('@/views/informationCloud/replacePhone/index.vue'),
        meta: {
            title: '更换手机号',
        }
    },
    {
        path: '/expertCertification',
        name: 'expertCertification',
        component: () => import('@/views/informationCloud/expertCertification/index.vue'),
        meta: {
            title: '专家认证',
        }
    },
    {
        path: '/bindEnterprise',
        name: 'bindEnterprise',
        component: () => import('@/views/informationCloud/bindEnterprise/index.vue'),
        meta: {
            title: '企业绑定',
        }
    },
    {
        path: '/enterpriseCertification',
        name: 'enterpriseCertification',
        component: () => import('@/views/informationCloud/bindEnterprise/enterpriseCertification.vue'),
        meta: {
            title: '企业认证',
        }
    },
    {
        path: '/information',
        name: 'information',
        component: () => import('@/views/informationCloud/information/index.vue'),
        meta: {
            title: '科创资讯',
        }
    },
    {
        path: '/informationDetail',
        name: 'informationDetail',
        component: () => import('@/views/informationCloud/information/informationDetail/index.vue'),
        meta: {
            title: '科创资讯详情',
        }
    },
    {
        path: '/enterprisUser',
        name: 'enterprisUser',
        component: () => import('@/views/PersonalCenter/enterprise/enterprisUser.vue'),
        meta: {
            title: '管理用户',
        }
    },
    {
        path: '/integral',
        name: 'integral',
        component: () => import('@/views/informationCloud/integral/index.vue'),
        meta: {
            title: '我的积分',
        }
    },
    {
        path: '/bill',
        name: 'bill',
        component: () => import('@/views/informationCloud/integral/bill.vue'),
        meta: {
            title: '积分明细',
        }
    },
    {
        path: '/policySubscription',
        name: 'policySubscription',
        component: () => import('@/views/informationCloud/policySubscription/index.vue'),
        meta: {
            title: '政策订阅',
        }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/informationCloud/about/index.vue'),
        meta: {
            title: '关于我们',
        }
    },
    {
        path: '/policyMatching',
        name: 'policyMatching',
        component: () => import('@/views/informationCloud/policyMatching/index.vue'),
        meta: {
            title: '政策匹配详情',
        }
    },
    
]
