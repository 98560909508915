<template>
    <div class="work_more" :style="fullscreenCon?'width:97%;height: 100%':'width:983px;height: 100%'">
        <div class="backtop">
            <div @click="backPbtn" class="fl ml10 cura">
                <i class="iconfont icon-xitongfanhui"></i>
            </div>
            <div class="fl ml10">{{selectTitle}}</div>
        </div>
        <div class="transfer__body" v-loading="allLoading" style="margin-top: 20px;"  :style="fullscreenCon?'height: 80vh':'height: 530px'">
            <div class="transfer-pane fl">
                <div class="transfer-pane__tools">
                    <searchbox searchPh="姓名" :sKeywords="pagination.keyword" @changeSearch="search" />
                </div>
                <div v-if="source == '2'" class="transfer-pane__body left-pane">
                    <el-tabs v-model="activeName" class="transfer-pane__body-tab" @tab-click="changeActive">
                        <el-tab-pane label="最近" name="1">
                            <div class="single-list" v-loading="loading">
                                <template v-if="treeData3 && treeData3.length > 0">
                                    <div v-for="(item,index) in treeData3" :key="index" class="selected-item-user" @click="handleNodeClick(item)">
                                        <div class="selected-item-main overhide">
                                            <el-checkbox v-model="item.checked1" @change="handleCheckedCitiesChange(item)"></el-checkbox>
                                            <topimg :list="item.picList" class="ml10"></topimg>
                                            <div class="selected-item-text">
                                                <p class="name">{{item.mname}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-else>
                                    <div v-if="pagination.keyword == '' || !pagination.keyword" class="no_tree_data">
                                        <img src="@/assets/img/tableEmpty.png" alt="" srcset="">
                                        <div style="margin-top:10px">暂无数据</div>
                                    </div>
                                    <div v-if="pagination.keyword != ''" class="no_tree_data">
                                        <img src="@/assets/img/nosearch.png" alt="" srcset="">
                                        <div style="margin-top:10px">暂无搜索条件</div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="通讯录" name="2">
                            <div class="single-list" v-loading="loading">
                                <template v-if="treeData3.length">
                                    <div v-for="(item,index) in treeData3" :key="index" class="selected-item-user" @click="handleNodeClick(item)">
                                        <div class="selected-item-main">
                                            <el-checkbox v-model="item.checked1" @change="handleCheckedCitiesChange(item)"></el-checkbox>
                                            <topimg v-if="item.headUrl && item.headUrl.length > 0" :list="[item.headUrl]" class="ml10"></topimg>
                                            <div class="selected-item-text">
                                                <div class="name">
                                                    <div class="fl"> {{item.mname}} </div>
                                                    <div v-for="(itemu, indexu) in userTagList" :key="indexu" class="fl">
                                                        <span v-if="item.mtag != '3'">
                                                            <span v-if="itemu.dictCode == item.mtag">（{{itemu.dictName}}）</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                <p class="organize" :title="item.orgName">{{item.orgName}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-else>
                                    <div v-if="pagination.keyword == '' || !pagination.keyword" class="no_tree_data">
                                        <img src="@/assets/img/tableEmpty.png" alt="" srcset="">
                                        <div style="margin-top:10px">暂无数据</div>
                                    </div>
                                    <div v-if="pagination.keyword != ''" class="no_tree_data">
                                        <img src="@/assets/img/nosearch.png" alt="" srcset="">
                                        <div style="margin-top:10px">暂无搜索条件</div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="群组" name="3">
                            <div class="single-list" v-loading="loading">
                                <template v-if="treeData3.length">
                                    <div v-for="(item,index) in treeData3" :key="index" class="selected-item-user" @click="handleNodeClick(item)">
                                        <div class="selected-item-main">
                                            <el-checkbox v-model="item.checked1" @change="handleCheckedCitiesChange(item)"></el-checkbox>
                                            <topimg v-if="item.picList && item.picList.length > 0" :list="item.picList" class="ml10"></topimg>
                                            <div class="selected-item-text">
                                                <p class="name">{{item.mname}}</p>
                                                <p class="organize" :title="item.orgName">{{item.orgName}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-else>
                                    <div v-if="pagination.keyword == '' || !pagination.keyword" class="no_tree_data">
                                        <img src="@/assets/img/tableEmpty.png" alt="" srcset="">
                                        <div style="margin-top:10px">暂无数据</div>
                                    </div>
                                    <div v-if="pagination.keyword != ''" class="no_tree_data">
                                        <img src="@/assets/img/nosearch.png" alt="" srcset="">
                                        <div style="margin-top:10px">暂无搜索条件</div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div v-else>
                    <div class="transfer-pane__body left-pane">
                        <div class="transfer-pane__body-tab">
                            <div class="single-list" v-loading="loading">
                                <template v-if="treeData3.length">
                                    <div v-for="(item,index) in treeData3" :key="index" class="selected-item-user" @click="handleNodeClick(item)">
                                        <div class="selected-item-main">
                                            <el-checkbox v-model="item.checked1" @change="handleCheckedCitiesChange(item)"></el-checkbox>
                                            <topimg v-if="item.headUrl && item.headUrl.length > 0" :list="[item.headUrl]" class="ml10"></topimg>
                                            <div class="selected-item-text">
                                                <p class="name">{{item.mname}}</p>
                                                <p class="organize" :title="item.orgName">{{item.orgName}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-else>
                                    <div v-if="pagination.keyword == '' || !pagination.keyword" class="no_tree_data">
                                        <img src="@/assets/img/tableEmpty.png" alt="" srcset="">
                                        <div style="margin-top:10px">暂无数据</div>
                                    </div>
                                    <div v-if="pagination.keyword != ''" class="no_tree_data">
                                        <img src="@/assets/img/nosearch.png" alt="" srcset="">
                                        <div style="margin-top:10px">暂无搜索条件</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="transfer-pane fr">
                <div class="transfer-pane__tools">
                    <span>已选 {{selectedData.length || 0}} 人</span>
                    <el-button @click="removeAll" type="text" class="removeAllBtn">清空列表</el-button>
                </div>
                <div class="transfer-pane__body shadow right-pane">
                    <template v-if="selectedData.length">
                        <div v-for="(item,index) in selectedData" :key="index" class="selected-item-user">
                            <div class="selected-item-main">
                                <topimg v-if="item.picList && item.picList.length > 0" :list="item.picList"></topimg>
                                <el-avatar v-else-if="item.headUrl" :size="36" :src="urlImg+item.headUrl" class="selected-item-headIcon" style="border-radius: 3px;"></el-avatar>
                                <el-avatar v-else :size="36" src="@/assets/img/headerImg.png" class="selected-item-headIcon" style="border-radius: 3px;"></el-avatar>
                                <div class="selected-item-text">
                                    <div class="name">
                                        <div class="fl"> {{item.mname}} </div>
                                        <div v-for="(itemu, indexu) in userTagList" :key="indexu" class="fl">
                                            <span v-if="item.mtag != '3'">
                                                <span v-if="itemu.dictCode == item.mtag">（{{itemu.dictName}}）</span>
                                            </span>
                                        </div>
                                    </div>
                                    <p class="organize" v-if="item.orgName" :title="item.orgName">{{item.orgName}}</p>
                                </div>
                                <i class="el-icon-delete" @click="removeData(index, item)"></i>
                            </div>
                        </div>
                    </template>
                    <div v-else class="no_tree_data">
                        <img src="@/assets/img/tableEmpty.png" alt="" srcset="">
                        <div style="margin-top:10px">暂无数据</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fr" style="margin: 15px">
            <el-button size="small" type="primary" @click="saveUser" :loading="loading1">保存</el-button>
            <el-button size="small" @click="cancelUser" :loading="loading1">取消</el-button>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import http from '@/api/http'
const emit = defineEmits(['getValue','getValueCancel'])
// import { findOrgUsersTree, findOutsourcingUsers, findUsersByName } from '@/request/systemManagement'
// import { findChatListByTab, findTopicMembers } from '@/request/MyWork'
// import { throttle } from 'throttle-debounce'
import searchbox from '@/components/searchbox/index.vue' //排序
import topimg from '../news/components/topimg.vue'
import { cctgs, fwdadbk, rtcts, coUser } from '@/api/technologyManager'//接口

const allLoading = ref(false)
const loading = ref(false)
const loading1 = ref(false)
const activeName = ref('1')
const treeData = ref([])
const treeData3 = ref([])
const selectedData = ref([])
const urlImg = http.FileUrl
const props1 = ref({children: 'children',label: 'lable'})
const nodeId = ref('0')
const ids = ref([])
const ids1 = ref([])
const ids2 = ref([])
const total = ref(0)
const isAsync = ref(false)
const finish = ref(false)
const listLoading = ref(false)
const pagination = ref({keyword: '',currentPage: 1,pageSize: 20})
// const orgId = JSON.parse(localStorage.getItem('userMassage')).orgId
const orgId = ''
const selLength = ref(0)
const newArr = ref([])
const selectedData2 = ref([])
const userList = ref([])
const userKey = ref('')
const infiniteBody = ref(null)
const tree1 = ref(null)
// 1：法人 2：负责人 3：普通成员 4：客户经理 5：业务人员 
const userTagList = ref([{dictCode:'1', dictName:'法人'},{dictCode:'2', dictName:'负责人'}, {dictCode:'3', dictName:'普通成员'},{dictCode:'4', dictName:'客户经理'},{dictCode:'5', dictName:'业务人员'}])
const props = defineProps({
    value: {
        type: Array,
        default: () => []
    },
    // 来源 2转发
    source: {
        type: String,
        default: '2'
    },
    selectTitle: {
        type: String,
        default: '1'
    },
    buildId: {
        type: String,
        default: ''
    },
    fullscreenCon: {
        type: Boolean,
        default: false
    },
})

watch(() => props.value, (newValue, oldValue) => {
    ids.value = []
    ids.value = newValue.map((o) => o.id)
    selLength.value = newValue.length
},{ immediate: true });



function init() {
    ids.value = []
    ids1.value = []
    ids2.value = []
    treeData.value = []
    treeData3.value = []
    // selectedData1.value = []
    selectedData2.value = []
    pagination.value.keyword = ''
    nodeId.value = '0'
    isAsync.value = false
    finish.value = false    
    if (props.source == '2') {
        activeName.value = '1'
        setTimeout(() => {
            getSelectList()
            getData()
        });
    } else {
        getCusData()
        getSelectList()
    }
}
function cancelUser() {
    backPbtn()
}
function saveUser() {
    let aType = '1'
    if (props.selectTitle == '选择服务群成员') {
        aType = '2'
    }
    emit('getValue', aType, selectedData.value)
}
// 选人弹窗关闭
function backPbtn() {
    let aType = '1'
    if (props.selectTitle == '选择服务群成员') {
        aType = '2'
    }
    emit('getValueCancel', aType)
}
// 切换组织
function changeActive() {
    pagination.value.keyword = ''
    userKey.value = ''
    isAsync.value = false
    treeData.value = []
    treeData3.value = []
    setTimeout(() => {
        getData()
    });
}
function bindScroll() {
    vBody = infiniteBody.value
    vBody.addEventListener('scroll')
}
// 客户
function getCusData(){
    // { coId: props.buildId, keyword: pagination.value.keyword }
    coUser({ coId: props.buildId, keyword: pagination.value.keyword }).then((res) => {
        searchData(res.data)
    })
}
// 1 最近 2通讯录 3群组
function getData() {
    treeData3.value = []
    loading.value = true
    if (activeName.value == '1') {
        rtcts({ keyword: pagination.value.keyword }).then((res) => {
            res.data.forEach(el => {
                el.mname = el.chatName
            });
            searchData(res.data)
        })
    } else if (activeName.value == '2') {
        fwdadbk({ keyword:pagination.value.keyword }).then((res) => {
            searchData(res.data)
        })
    } else {
        cctgs({ keyword:pagination.value.keyword }).then((res) => {
            res.data.forEach(el => {
                el.mname = el.chatName
            });
            searchData(res.data)
        })
    }
}
function getSearch() {
    loading.value = true
    treeData3.value = []
    if (props.source == '2') {
        getData()
    } else {
        getCusData()
    }
}
// 已选的数据
function getSelectList() {
    allLoading.value = false
    selectedData.value = props.value
    if (searchData.value && selectedData.value.length > 0) {
        ids1.value = selectedData.value.map((o) => o.id)
    }
}
// 搜索
function search1(){
    nodeId.value = '0'
    treeData3.value = []
    pagination.value.currentPage = 1
    finish.value = false
    isAsync.value = true
    setTimeout(() => {
        getSearch()
    }, 300)
}
function search(val) {
    pagination.value.keyword = val
    nodeId.value = '0'
    treeData3.value = []
    pagination.value.currentPage = 1
    finish.value = false
    if (val) {
        isAsync.value= true
    } else {
        isAsync.value = false
    }
    setTimeout(() => {
        getSearch()
    }, 300)
}
// 组装搜索数据
function searchData(data) {
    treeData3.value = data
    if (treeData3.value.length > 0) {
        treeData3.value.forEach((el) => {
            el.checked1 = false
            const aIndex = selectedData.value.some((item) => item.id == el.id)
            if (aIndex) {
                el.checked1 = true
                selectedData.value.push(el)
            }
        })
        const map = new Map()
        selectedData.value = selectedData.value.filter((v) => !map.has(v.id) && map.set(v.id, 1))
    }
    loading.value = false
}
// 节点点击事件
// function handleCheckChange() {
//     if (activeName.value == '1') {
//         selectedData1.value = []
//         let aData1 = []
//         selectedData1.value = []
//         ids1.value = []
//         aData1 = tree1.getCheckedNodes()
//         if(aData1 && aData1.length > 0) {
//             aData1.forEach((el) => {
//                 if (el.nodeType == 'user') {
//                     selectedData1.value.push(el)
//                     ids1.value.push(el.id)
//                 }
//             })
//         } else {
//             selectedData.value = selectedData2.value
//         }
//     }
//     selectedData.value = selectedData1.value.concat(selectedData2.value)
//     const map = new Map()
//     selectedData.value = selectedData.value.filter((v) => !map.has(v.id) && map.set(v.id, 1))
// }
// 单条点击事件
function handleNodeClick(data) {
    data.checked1 = !data.checked1
    if (data.checked1) {
        selectedData2.value.push(data)
        selectedData.value.push(data)
        const map = new Map()
        selectedData2.value = selectedData2.value.filter((v) => !map.has(v.id) && map.set(v.id, 1))
    } else {
        const aIndex2 = selectedData2.value.findIndex((item) => item.id == data.id)
        if (aIndex2 != -1) {
            selectedData2.value.splice(aIndex2, 1)
        }
        const aIndex = selectedData.value.findIndex((item) => item.id == data.id)
        if (aIndex != -1) {
            selectedData.value.splice(aIndex, 1)
        }
    }
    setTimeout(() => {
        const map = new Map()
        selectedData.value = selectedData.value.filter((v) => !map.has(v.id) && map.set(v.id, 1))
    })
}
// 搜索列表点击事件
function handleCheckedCitiesChange(data) {
    handleNodeClick(data)
    // 强制刷新列表，改变checkbox的选中状态
    treeData3.value.splice()
}
// 清空列表
function removeAll() {
    selectedData.value = []
    ids1.value = []
    selectedData2.value = []
    ids.value = []
    // 清空当前页
    if (treeData3.value.length > 0) {
        treeData3.value.forEach((el) => {
            el.checked1 = false
        })
    }
    // 清空树的勾选状态
    if (activeName.value == '1') {
        tree1.value.setCheckedKeys([])
    }
}
// 删除单个
function removeData(index, item) {
    selectedData.value.splice(index, 1)
    ids1.value.splice(index, 1)
    const aIndex2 = selectedData2.value.findIndex(d => d.id === item.id)
    const aIndex3 = treeData3.value.findIndex(d => d.id === item.id)
    if (activeName.value == '1') {
        tree1.setChecked(item, false, true)
    } else {
        if(aIndex2 != -1) {
            selectedData2.value.splice(aIndex2, 1)
        }
        treeData3.value[aIndex3].checked1 = false
    }
}
defineExpose({
    init
})
</script>

<style scoped lang="scss">
.ml10 {
    margin-left: 10px;
}
.no_tree_data {
    width: 100%;
    text-align: center;
    color: #000;
    img{
        width: 200px;
        margin: 80px auto 0;
    }
}
.work_more {
    // width: 983px;
    padding-left: 10px;
    background: #fff;
    position: absolute;
    top: 0;
    // height: 660px;
    height: 100%;
    z-index: 20;
    .transfer__body .transfer-pane {
        width: 46%;
    }
}
.backtop {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #d5d7e0;
    font-size: 16px;
    display: block;
    color: #000;
    margin-top: 20px;
}
.single-list{
    // max-height: 428px;
    overflow-y: auto;
}
.cus_img2{
        width: 36px;
        height: 36px;
        border-radius: 4px;
        background-color: #0052cc;
        color: #fff;
        text-align: center;
        line-height: 36px;
        font-size: 18px;
    }
.cus_tip2{
    color: #FA8C16;
}
</style>
<style lang="scss">
.tabs1 .el-tabs__nav {
    margin-left: 20px;
}
.tabs2 .el-tabs__nav {
    margin-left: 160px;
}
.work_more .el-avatar--circle{
    border-radius: 0%;
}

.work_more .el-tabs__header{
    margin: 0 !important;
}
.work_more .el-input--mini .el-input__inner, .work_more .el-input__inner{
    height: 28px;
    line-height: 28px;
}
.work_more .el-input--mini .el-input__icon {
    line-height: 32px;
}
.work_more .el-tabs__item{
    padding: 0 10px;
}

.work_more .transfer__body {
  line-height: 32px;
  display: flex;
  justify-content: space-around;
  height: 400px;

  .transfer-pane {
    width: 360px;
  }

  .transfer-pane__tools {
    margin-bottom: 8px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    .removeAllBtn {
      color: #fa2d2d;
    }
  }

  .transfer-pane__body {
    position: relative;
    width: 100%;
    height: calc(100% - 40px);
    overflow: auto;
    overflow-x: hidden;
    font-size: 14px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;

    .el-tab-pane {
      padding: 10px 0 !important;
    }

    &.left-pane {
      .custom-title {
        height: 39px;
        padding: 0 12px;
        line-height: 39px;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-bottom: 1px solid #dcdfe6;

        &+.single-list {
          height: calc(100% - 40px);
        }
      }

      .single-list {
        height: 100%;
        overflow: auto;
        overflow-x: hidden;

        .selected-item-user {
          cursor: pointer;
        }

        .selected-item {
          padding: 0px 12px;
          cursor: pointer;

          &:hover {
            background-color: #f5f7fa;
          }
        }
      }
    }
  }

  .selected-item-user {
    padding: 0 12px;

    &:hover {
      background-color: #f5f7fa;
    }

    .selected-item-main {
      border-bottom: 1px solid #F4F6F9;
      display: flex;
      align-items: center;
      height: 50px;
    }

    .selected-item-headIcon {
      flex-shrink: 0;

      &.icon {
        width: 36px;
        height: 36px;
        text-align: center;

        i {
          font-size: 22px;
          line-height: 36px;
        }
      }
    }

    .selected-item-text {
      min-width: 0;
      flex: 1;
      margin-left: 10px;

      .name {
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #000;
      }

      .organize {
        height: 17px;
        line-height: 17px;
        color: #555;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .el-icon-delete:hover {
      color: #0052cc;
      cursor: pointer;
    }
  }

  .selected-item-user-multiple {
    padding-left: 12px;
    position: relative;

    .selected-item-title {
      font-size: 14px;
      color: #303133;
      display: flex;
      align-items: center;

      span {
        padding-left: 6px;
      }
    }

    .selected-item-main {
      box-sizing: content-box;
      display: flex;
      align-items: center;
      height: 64px;
      padding-left: 21px;
      position: relative;

      &:hover {
        background-color: #f5f7fa;
      }

      &:last-child {
        border-bottom: 1px solid #F2F5F8;
      }

      &:not(:last-child) {
        &:before {
          position: absolute;
          content: "";
          bottom: 0;
          right: 0;
          width: calc(100% - 21px);
          height: 1px;
          background-color: #F2F5F8;
        }
      }
    }

    .selected-item-headIcon {
      flex-shrink: 0;

      &.icon {
        width: 36px;
        height: 36px;
        text-align: center;

        i {
          font-size: 22px;
          line-height: 36px;
        }
      }
    }

    .selected-item-icon {
      width: 36px;
      height: 36px;
      background: linear-gradient(193deg, #A7D6FF 0%, #1990FA 100%);
      border-radius: 50%;
      line-height: 36px;
      color: #FFFFFF;
      font-size: 14px;
      text-align: center;
    }

    .selected-item-text {
      flex: 1;
      margin-left: 10px;
      min-width: 0;

      .name {
        color: #333333;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        margin-bottom: 2px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .organize {
        color: #999999;
        height: 17px;
        line-height: 17px;
        color: #999999;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .delete {
      margin-right: 15px;
      font-size: 15px;
      align-items: center;

      &:hover {
        color: #f56c6c;
        cursor: pointer;
      }
    }
  }

  .right-pane {
    box-sizing: border-box;
    overflow: auto;
    border: 1px solid #dcdfe6;

    .selected-item {
      padding: 0px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        background-color: #f5f7fa;
      }

      span {
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .el-icon-delete:hover {
        color: #0052cc;
        cursor: pointer;
      }
    }
  }
}

.work_more .table-checkbox.el-checkbox.is-disabled {

  .el-checkbox__inner,
  .el-checkbox__inner::after,
  input,
  .el-checkbox__label {
    cursor: pointer !important;
  }

  .el-checkbox__inner {
    background-color: #fff !important;

    &:hover {
      border-color: #1890ff !important;
    }
  }

  &.is-checked {
    .el-checkbox__inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;

      &::after {
        border-color: #fff !important;
      }
    }

    input {
      color: #fff !important;
      border-color: #fff !important;
    }
  }

}
.work_more .el-tabs__item {
    padding-left: 20px !important;
}
</style>