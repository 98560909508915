<template>
  <div class="code-container">
    <el-button @click.stop="getCode" :color="props.bgColor" :style="codeStyle" :disabled="codeShow" class="btnCode">
      {{codeLabel }}
    </el-button>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

import { checkPhone } from "@/utils/test.js";
import { elMessage } from "@/store";
import { getCodeSms } from "@/api/index";
const props = defineProps({
  // 手机号校验
  value: {
    type: String,
    default: "",
  },
  // 科技管家订单下载验证
  smsKey: {
    type: String,
    default: "",
  },
  // 倒计时秒数
  seconds: {
    type: [Number, String],
    default: 60,
  },
  color: {
    type: String,
    default: "#fff",
  },
  actrveColor: {
    type: String,
    default: "#fff",
  },
  text: {
    type: String,
    default: "获取验证码",
  },
  // 验证码类型
  codeType: {
    type: String,
    default: "",
  },
  bgColor: {
    type: String,
    default: "#1492ff",
  },
  height: {
    type: String,
    default: "35px",
  },
});

const emit = defineEmits(["change"]);

// ----------------数据-------------------

// 倒计时
const timerId = ref(null);
// 倒计时秒数
const timeDown = ref(props.seconds * 1);
// 验证码文字
const codeLabel = ref(props.text);
const codeShow = ref(false);

// ---------------计算属性-----------------------
const codeStyle = computed(() => {
  return {
    color: codeShow.value ? props.actrveColor : props.color,
    height: props.height,
  };
});
// ----------------方法-------------------

// 获取验证码
const getCode = () => {
  if (!props.value) {
    elMessage.warning("请输入手机号");
    return;
  }
  if (!checkPhone(props.value)) {
    elMessage.warning("请输入正确的手机号");
    return;
  }
  if (!timerId.value) {
    let params = {
      codeType: props.codeType,
      phoneNumber: props.value,
      key: props.smsKey ? props.smsKey : undefined,
    };
    codeShow.value = true;
    getCodeSms(params)
      .then((res) => {
        if (res.code == 0) {
          elMessage.success("验证码已发送，10分钟内有效。");
          startTimer();
        } else {
          elMessage.error(res.message);
          codeShow.value = false
        }
      })
      .catch((err) => {
        elMessage.error(err.message);
         codeShow.value = false;
      });
    emit("change");
  }
};

// 倒计时
const startTimer = () => {
  timerId.value = setInterval(() => {
    timeDown.value--;
    if (timeDown.value < 1) {
      clearInterval(timerId.value);
      timerId.value = null;
      timeDown.value = props.seconds * 1;
      codeLabel.value = props.text;
      codeShow.value = false;
    } else {
      codeLabel.value = `${timeDown.value}秒后重新获取`;
      codeShow.value = true;
    }
  }, 1000);
};

defineExpose({
  getCode,
});
</script>

<style lang="scss" scoped>
</style>
