import { get, postForm, postJson } from './http'
// 模糊查询企业列表
export const getEnterpriseNameList = (p) => postJson('/uaa/sysCompanyCertInfo/encry/likeCompanyInfo', p, true)
// 查询是否已经提交过认证
export const getCurrentEnterIsAuthApi = (p) => postJson('/uaa/sysCompanyCertInfo/encry/sc', p, true)
// 绑定企业
export const sumbitEnterpriseBind = (p) => postJson('/uaa/sysCompanyCertInfo/encry/bindEs', p, true)
// 点击下载授权书（前调用）
export const getPoaPre = (p) => postJson('/uaa/sysCompanyCertInfo/encry/getPoaPre', p, true)
//  点击下载授权书
export const getPoa = (p) => get('/uaa/sysCompanyCertInfo/getPoa?exportKey=' + p)
// 提交企业认证
export const sumbitEnterpriseAuth = (p) => postJson('/uaa/sysCompanyCertInfo/encry/ec', p, true)
// 查询管理企业列表
export const getEnterpriseList = (p) => postJson('/uaa/sysCompanyCertInfo/findMel', p)
// 查询企业信息
export const getEnterpriseInfoApi = (p) => postJson('/uaa/sysCompanyCertInfo/findCci?certId=' + p)
// 查询管理用户列表
export const getEnterprisefindMul = (p) => postJson('/uaa/sysCompanyCertInfo/findMul?certId=' + p)
// 设为负责人
export const settingLeader = (p) => postJson('/uaa/sysCompanyCertInfo/encry/settingLeader', p, true)
// 删除绑定用户
export const delBindUser = (p) => postForm('/uaa/sysCompanyCertInfo/delBu', p)
// 解除用户绑定
export const delUserBind = (p) => postForm('/uaa/sysCompanyCertInfo/revokeBd' , p)
// 取消企业认证
export const revokeCert = (p) => postForm('/uaa/sysCompanyCertInfo/revokeCert?certId=' + p)
// 设置默认企业
export const deftCo = (p) => postForm('/uaa/sysCompanyCertInfo/deftCo?bindId=' + p)
// 查询个人中心法人认证信息
export const findLpcInfo = (p, p1) => get('/techmgr/techmgrCert/findLpcInfo?certId=' + p + '&bindId=' + p1)
// 保存个人中心法人认证信息
export const lpCertSave = (p) => postJson('/techmgr/techmgrCert/lpCert', p)

// 查询企业基本信息
export const findCompanyBasicInfo = (p) => postForm('/uaa/information/findCompanyBasicInfo', p)
// 查询科创图谱左侧数据
export const findLeftData = (p) => postForm('/uaa/information/findLeftData', p)
// 查询是否符合立即解锁条件
export const findIsFhLjjsCondition = (p) => postForm('/uaa/information/findIsFhLjjsCondition', p)
// 查询科创图谱右侧数据（建议）
export const findRightData = (p) => postForm('/uaa/information/findRightData', p)
// 查询政策列表
export const findPolicyList = (p) => postForm('/uaa/information/findPolicyList', p)
// 查询政策匹配历史记录列表
export const findPolicyMatchHisRecordList = (p) => postForm('/uaa/information/findPolicyMatchHisRecordList', p)
// 清空政策匹配历史记录列表
export const clearPolicyMatchHisRecordList = (p) => postForm('/uaa/information/clearPolicyMatchHisRecordList', p)
// 查询政策匹配列表 （上一篇/下一篇）
export const findPolicyMatchTopAndUnder = (p) => postForm('/uaa/information/findPolicyMatchTopAndUnder', p)
// 根据手机号码查询姓名
export const findNameByPhone = (p) => postForm('/uaa/sysCompanyCertInfo/findNameByPhone', p)
















