<template>
    <div class="head-container">
        <!-- <div :style="[headStyle]" class="head-box " :class="{ headPlace: isScroll }"> -->
        <div class="head-box " :class="{ headPlace: isScroll }">
            <div :class="[!headStore.kjgjHeader?'wmain':'wmainkjgj']">
                <el-header :height="headStore.height + 'px'" class="flex2h rowBox">
                    <div class="left flex">
                        <Logo />
                        <div class="navBox">
                            <NavList />
                        </div>
                    </div>
                    <div class="right">
                        <div class="contactBox">
                            <img class="imgBox" src="@/assets/img/innovation/phone.png" alt="">
                        </div>
                        <!-- 用户信息 -->
                        <div class="userBox">
                            <div class="btnBox" v-if="!isToken" @click="goLogin">登录/注册</div>
                            <div class="infoBox" v-else>
                                <!-- 科技管家 展示在线咨询 -->
                                <div @click="OnlineService" class="cura title3 red_cura" style="margin: 0 10px;"
                                    v-if="headStore.kjgjHeader">
                                    <i class="iconfont icon-kechuang-kefu maincolor" style="font-size: 22px;" title="在线咨询"></i>
                                </div>
                                <div @click="openConver" class="cura title3 red_cura">
                                    <el-badge :value="headStore.conNum ? headStore.conNum : ''">
                                        <i class="iconfont icon-xiaoxi2 maincolor" style="font-size:22px;" title="我的会话"></i>
                                    </el-badge>
                                </div>
                                <div @click="goMessage" class="cura title3 red_cura" style="margin: 0 10px;">
                                    <el-badge :value="headStore.msgNum ? headStore.msgNum : ''">
                                        <i class="iconfont icon-tixing maincolor" style="font-size: 22px;" title="消息"></i>
                                    </el-badge>
                                </div>
                                <el-dropdown class="dropClass" v-if="!headStore.kjgjHeader">
                                    <div class="userName flexh">
                                        {{ userStore.user.nickName }}
                                        <el-icon class="el-icon--right">
                                            <arrow-down />
                                        </el-icon>
                                    </div>
                                    <template #dropdown>
                                        <el-dropdown-menu>
                                            <el-dropdown-item :command="item.id" v-for="item in headStore.dropList"
                                                :key="item.id" @click="handleCommand(item, item.id)">
                                                {{ item.name }}
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </template>
                                </el-dropdown>
                                <div v-else>
                                    <div class="userName flex2" style="align-items:end;">
                                        <div class="avatar ml10">
                                            <img :src="userStore.avatar" alt="" v-if="userStore.avatar">
                                            <i class="iconfont icon-touxiang notIcon" v-else style="font-size: 46px;"></i>
                                        </div>
                                        <div class="ml10">
                                            <div>{{ userStore.user.nickName }}</div>
                                            <div class="font12" style="margin-top: 5px;">
                                                <el-dropdown style="width: 138px;" trigger="click">
                                                    <div class="el-dropdown-link flex2">
                                                        <tooltipOver :content="companyData.companyName" className="width:146px"></tooltipOver>
                                                        <el-icon v-if="companyList && companyList.length" class="el-icon--right"><arrow-down /></el-icon>
                                                    </div>
                                                    <template #dropdown>
                                                        <el-dropdown-menu>
                                                            <el-dropdown-item v-for="item in companyList" :key="item.companyInfoId" :command="item.companyInfoId"
                                                                :class="item.companyInfoId == companyData.companyInfoId ? 'head-container_selected_item' : ''"
                                                                @click.native="handleCommand1(item)">
                                                                <tooltipOver :content="item.companyName"></tooltipOver>
                                                            </el-dropdown-item>
                                                        </el-dropdown-menu>
                                                    </template>
                                                </el-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-header>
            </div>
        </div>
        <!-- 占位符 -->
        <div :style="{ height: headStore.height + 'px', background: headStore.actrveBgColor }"></div>
        <!-- 登录 -->
        <login @change="loginChange"></login>
        <!-- 文件下载校验 -->
        <!-- <downfile @changeDown="changeDown"></downfile> -->
        <!-- 我的会话 -->
        <conversation :isShowdialog="isShowdialog" @closeDialog="closeDialog"></conversation>
        <!-- 科技管家--权限弹窗 -->
        <powerCheck></powerCheck>
    </div>
</template>

<script setup>
import login from '@/components/login/index.vue'
// import downfile from '@/components/downfile/index.vue'
import Logo from './logo.vue'
import NavList from './navList.vue'
import powerCheck from '@/views/technologyManager/components/powerCheck.vue'
import { onMounted, ref, watch, computed } from 'vue'
import { headStore, userStore, onlineStore } from '@/store'
import { useRoute, useRouter } from "vue-router"
import { getToken } from '@/utils/auth'
import { changeCompany, findCompanyList, findCertCoList } from '@/api/technologyManager.js'
import {  findNoReadNum } from '@/api/home'
import { ElMessage } from 'element-plus'
import {createChannel,sendChannel} from '@/utils/broadChannel'
const router = useRouter()
const route = useRoute()
const companyList = ref([])
const companyData = ref({})
// watch(() => headStore.conNum, (val) => {
//     // 我的消息数量改变，更新消息页面
// }, { immediate: true })
// watch(() => headStore.conNum, (val) => {
//     // 会话消息数量改变，更新会话弹窗
// }, { immediate: true })
//---------------------------------数据-----------------------
// 头部样式
const headStyle = ref({
    background: headStore.defaultBgColor,
    position: headStore.position,
})
const isScroll = ref(false)

// 判断是否登录
const isToken = ref(getToken())

// -----------------------------页面加载触发-------------
onMounted(() => {
    window.addEventListener('scroll', (e) => {
        scrollStyle(e)
    })
    // 科技管家查询当前的公司
    if (headStore.kjgjHeader) {
        getCompanySelect()
    }
    if(getToken()){
        findNoReadNumApi()
    }
    createChannel('globalControlChannel')
})
// ------------------------------方法-------------------
// 查询未读消息数量
const findNoReadNumApi=()=>{
    findNoReadNum().then(res=>{
        if(res.code==0){
            let num=0
            res.data.forEach(item=>{
                num+=item.unReadCount*1
            })
          headStore.setMsgNum(num)
        }
    })
}
// 登录
function goLogin() {
    userStore.setLoginShow(true)
}
// 跳转至消息
function goMessage() {
    let path = router.resolve({ path: 'messageManagement' })
    window.open(path.href)
    // router.push("/messageManagement")
}
// 在线咨询
function OnlineService() {
    onlineStore.setOnlineShow()
}

// 滚动触发
const scrollStyle = (e) => {
    if (e.target.scrollingElement.scrollTop > 30) {
        // headStyle.value.position = 'fixed'
        // headStyle.value.background = headStore.actrveBgColor
        isScroll.value = true
    } else {
        // headStyle.value.position = 'absolute'
        // headStyle.value.background = headStore.defaultBgColor
        isScroll.value = false
    }
}
// 下拉菜单
const handleCommand = (item, id) => {
    if (id == 10) {
        sendChannel('1')
        userStore.Logout(route)
        return
    }
    let path = '/PersonalCenter?id=' + id
    router.push(path)
}
const loginChange = () => {
    isToken.value = true
}
// // 下载权限页面
// const changeDown = () => {
//     userStore.setDownShow(false)
// }
// 我的会话======start
import conversation from '@/views/technologyManager/conversation/index.vue'
const isShowdialog = ref(false)
function openConver() {
    findCertCoList().then((res) => {
        if (res.code == 0) {
            if(res.data && res.data.length > 0) {
                isShowdialog.value = true
            } else {
                ElMessage.error({ message: '在进入科技管家后方可使用‘会话’功能，请前往解锁科技管家。' })
            }
        } else {
            ElMessage.error({ message: res.message })
        }
    })
}
function closeDialog() {
    isShowdialog.value = false
}
// 我的会话====end
// 科技管家查询右上角企业下拉列表==start
const getCompanySelect = () => {
    companyList.value = []
    findCompanyList().then((res) => {
        if (res.code == 0) {
            companyList.value = res.data
            companyData.value = JSON.parse(localStorage.getItem('companyData')) ? JSON.parse(localStorage.getItem('companyData')) : []
        } else {
            ElMessage.error({ message: res.message })
        }
    })
}
// 切换企业下拉 切换的企业未签约0 切换的企业未签约3,4 切换的企业未签约2
const handleCommand1 = (item) => {
    changeCompany({ companyInfoId: item.companyInfoId }).then((res) => {
        if (res.code == 0) {
            const aData = res.data
            aData.isRight = '1'//右侧下拉标识
            aData.pathJump = '/projectProcess'//成功后跳转路径
            userStore.setUserIdentity(aData)
            companyData.value = aData//用于刷新列表
            if (aData && aData.certStatus == '1') {
                // 缓存公司信息
                localStorage.setItem('companyData', JSON.stringify(aData))
                getCompanySelect()
                // 查询按钮和菜单权限
                userStore.setUserJur()
            }
        } else {
            ElMessage.error({ message: res.message })
        }
    })
}
watch(() => headStore.kjgjHeader, (val) => {
    // 查询按钮和菜单权限
    companyData.value = JSON.parse(localStorage.getItem('companyData')) ? JSON.parse(localStorage.getItem('companyData')) : []
    if(isToken.value && val == true) {
        userStore.setUserJur()
        getCompanySelect()
    }
    return companyData.value
}, { immediate: true })
// 科技管家查询右上角企业下拉列表==end

</script>

<style lang="scss" scoped>
.head-container {
    .wmainkjgj{
        padding: 0 60px;
    }
    .head-box {
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: 999;
        position: fixed;
        background: #fff;

        .el-header {
            padding: 0 0px;
        }

        .rowBox {
            .left {
                align-items: center;
                height: 100%;
            }

            .navBox {
                height: 100%;
                margin-left: 20px;
            }
            .right {
                display: flex;
                align-items: center;

                .contactBox {
                    .imgBox {
                        // width: 240px;
                        // height: 32px;
                        object-fit: cover;
                    }
                }

                .userBox {
                    margin-left: 20px;
                    color: #000;

                    .btnBox {
                        width: 108px;
                        height: 32px;
                        line-height: 32px;
                        text-align: center;
                        // background: linear-gradient(#94cbff, #1492ff 66%);
                        background: url('@/assets/img/loginBtnBg.png') no-repeat;
                        background-size: 100% 100%;
                        border-radius: 4px;
                        color: #fff;
                        cursor: pointer;
                    }


                    .infoBox {
                        display: flex;
                        align-items: center;

                        .userName {
                            cursor: pointer;
                            color: #000;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }

    .headPlace {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .avatar {
        width: 46px;
        height: 46px;
        border-radius: 50%;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
}
</style>
<style lang="scss">
.head-container .infoBox .red_cura {
    .el-badge__content--danger {
        background-color: #F8544B;
    }
}

/* 右侧菜单下拉选中的样式 */
.head-container_selected_item {
    color: #1492ff !important;
    /* 已选中项文字颜色 */
}
</style>
