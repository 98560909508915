import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'
const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCfXfMzgg4m5RRLg2vcrYBFN4sBhE1VtW1sBkXxC5wtCRaOZv0kudk9CIQfU6c+eEaaZKUnygxHWdSqdwURCE0IKgLcolXF+RHmu/rl977FfjRg9pAkBg5z05PfHDqWqkIsqX0iRaSP31BUZOgtwafbiBv2dBvRBMdq03ty4q8OQQIDAQAB'

// aes加密 --使用公钥加密
export const aesEncode = (data, key) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key)
    const encrypted = CryptoJS.AES.encrypt(data, keyHex, {
        // // 切记   需要和后端算法模式一致
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return encrypted.toString()
}

// //rsa加密 --给随机字符串加密
export function rsaEncode(key) {
    const encryptor = new JSEncrypt()
    encryptor.setPublicKey(publicKey) // 设置公钥
    return encryptor.encrypt(key) // 对数据进行加密
}

// aes解密 --使用公钥解密
export const decryptECB = (data, key) => {
    const keyHex = CryptoJS.enc.Utf8.parse(key)
    const decrypted = CryptoJS.AES.decrypt(data, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
}

// rsa解密 --使用私钥解密
export function rsaDecode(key) {
    const decryptor = new JSEncrypt()
    decryptor.setPrivateKey(publicKey) // 设置私钥
    return decryptor.decrypt(key) // 对数据进行解密
    
}