import { defineStore } from 'pinia'
import { getToken, setToken,removeToken } from '@/utils/auth'
import { getUserInfoApi, logout, getUserInfoDetails } from '@/api/user'
import { ElMessage } from 'element-plus'
import http from '@/api/http'
import { alreadyAuthority } from '@/api/technologyManager.js'

import useIntegralStore from './integral'
// import wsConnectionOrder from '@/utils/websocket.js'
import {sendHandle } from '@/utils/ws.js'


const useUserStore = defineStore('user', {
    state: () => ({
        userId:null,
        // 定位 370000 山东省  340000安徽省
        selectPosition: localStorage.getItem('selectPosition') ||'370000',
        // selectPosition: '340000',
        // 登录框显示状态
        loginShow: false,
        // token
        token: getToken() || '',
        // 用户信息
        user: {},
        // 用户头像
        avatar: '',
        // 科技管家 ==== 下载时是否开启短信验证
        downShow: false,
        downShowParams: {},
        // 科技管家 ==== 用户按钮权限
        userJurisdiction: [],
        // 科技管家--根据用户身份的弹窗
        userIdentity: {},
        // 退出登录后所在的页面跳转到首页
        logoutToHome: ['/PersonalCenter', '/forgotPassword','/securityManage','/technology','/projectProcess','/projectDetail','/dataResources'],
        // 登录后所在的页面跳转到首页
        signinToHome: ['/login', '/register', '/forgotPassword', 'personalCenter'],
    }),
    actions: {
        // 设置登录框显示状态
        setLoginShow(val) {
            this.loginShow = val
        },
        // 设置token
        setToken(val) {
            this.token = val
            setToken(val)
        },
        // 设置定位
        setSelectPosition(val) {
            this.selectPosition = val
            localStorage.setItem('selectPosition', val)
        },
        // 获取用户信息
        GetUserInfo() {
            return new Promise((resolve, reject) => {
                getUserInfoApi().then(res => {
                    if (res.code == 0) {
                        this.user = res.data
                        if (res.data.avatar) {
                            this.avatar = http.FileUrl + res.data.avatar
                        }
                        this.userId=res.data.id
                        sessionStorage.setItem('userId', res.data.id)
                        const integralStore = useIntegralStore()
                        try {
                            integralStore.setPreviewTime()
                        } catch (error) { }
                    }
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 退出登录
        Logout(route) {
            return new Promise((resolve, reject) => {
                let params = {
                    token: this.token
                }
                logout(params).then(res => {
                    if (res.code == 0) {
                        // 退出清除公司缓存
                        localStorage.setItem('companyData','{}')
                        // ElMessage.success('退出成功')
                        // 断开mywork的websocket链接
                        // wsConnectionOrder.sendMsg(4)
                        removeToken()
                        sessionStorage.removeItem('userId')
                        sendHandle(4)
                        this.clearCache()
                        ElMessage.success({
                            message: '退出成功',
                            type: 'success',
                            duration: 2000,
                            onClose: () => {
                                window.location.href = '/index'
                            }
                        })
                        // if (route) {
                        //     if (this.logoutToHome.includes(route.path)) {
                        //         setTimeout(() => {
                        //             window.location.href = '/index'
                        //         }, 1000)
                        //     } else {
                        //         window.location.reload();
                        //     }
                        // } else {
                        //     window.location.reload();
                        // }
                    } else {
                        ElMessage.error('退出失败')
                    }
                    removeToken()
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 退出登录清除缓存
        clearCache() {
            // 退出清除公司缓存
            localStorage.setItem('companyData','{}')
            removeToken()
            sessionStorage.removeItem('userId')
        },
        // 查询用户详情
        GetUserInfoDetails(id) {
            return new Promise((resolve, reject) => {
                getUserInfoDetails(id).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })

        },
        // 设置下载 短信验证弹窗的状态
        setDownShow(val, val2) {
            this.downShow = val//是否打开弹窗
            if (val2) {
                this.downShowParams = val2//下载参数
            }
        },
        // 查询按钮和菜单权限
        setUserJur() {
            const companyInfoId2 = JSON.parse(localStorage.getItem('companyData'))?JSON.parse(localStorage.getItem('companyData')).companyInfoId:''
            if(companyInfoId2) {
                alreadyAuthority({ companyInfoId: companyInfoId2 }).then(res => {
                    if (res.code === 0) {
                        this.userJurisdiction = res.data
                    } else {
                        ElMessage({
                            message: res.message,
                            type: 'error'
                        })
                    }
                })
            }
        },
        // 科技管家====根据用户身份弹窗
        setUserIdentity(val) {
            this.userIdentity = val
        },
    },
})
export default useUserStore
