const tokenKey = 'chenvaCloud2_token'
export const getToken = () => {
    return localStorage.getItem(tokenKey)
}

export const setToken = (token) => {
    localStorage.setItem(tokenKey, token)
}

export const removeToken = () => {
    localStorage.removeItem(tokenKey)
}
