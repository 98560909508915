import router from '@/router'
import useUserStore from '@/store/user'
import useHeadStore from '@/store/head'

import { getToken } from '@/utils/auth'
// setKjgjHeader
// 路由拦截
router.beforeEach((to, from, next) => {
    const userStore = useUserStore()
    const headStore = useHeadStore()
    if (to.meta.isTech) {
        // 科技导航使用新的菜单
        headStore.setKjgjHeader(true)
        // 科技管家--不要右侧悬浮的按钮
        headStore.setHelperShow(false)
    } else {
        headStore.setKjgjHeader(false)
    }

    if (getToken()) {
        // 获取用户信息
        if (Object.keys(userStore.user).length === 0) {
            userStore.GetUserInfo().then(() => {
                next()
            }).catch(() => {
                next()
            })
        } else {
            next()
        }
    } else {
        next()
    }
    // 滚动条置顶
    window.scrollTo(0, 0)

})