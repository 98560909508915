<template>
  <div class="logo-container">
    <div class="conter">
      <div class="logo" @click="$router.push('/index')">
        <img src="@/assets/img/headLogo.png" alt="" />
      </div>
      <div>
        <el-dropdown class="custom-dropdown">
          <div class="areaBox">
            <i class="iconfont icon-weizhibiaoji redcolor" style="font-size: 18px"></i>
            <span>{{ positionLabel }}</span>
            <i class="iconfont icon-xiajiantou2 blueText" style="font-size: 14px"></i>

          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="item in areaList" :key="item.value" @click="changeBatch(item)">
                {{item.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>

  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from "vue";
import { userStore } from "@/store";
// -------------------------------------数据-------------------------------------------------------------------
const positionLabel = ref("山东");
const areaList = ref([
  {
    name: "山东",
    value: "370000",
  },
  {
    name: "安徽",
    value: "340000",
  },
]);

onMounted(() => {
  getInit();
});

// -------------------------------------监听-------------------------------------------------------------------

watch(
  () => userStore.selectPosition,
  (val) => {
    nextTick(() => {
      getInit();
    });
  },
  { immediate: true, deep: true }
);

// ------------------------------------------------方法-------------------------------------------------------
const changeBatch = (item) => {
  positionLabel.value = item.name;
  userStore.setSelectPosition(item.value);
};
const getInit = () => {
  let item = areaList.value.find(
    (item) => item.value == userStore.selectPosition
  );
  if (item) {
    positionLabel.value = item.name;
  }
};
</script>

<style lang="scss" scoped>
.logo-container {
  .conter {
    display: flex;
    align-items: center;

    .logo {
      margin-right: 20px;
      cursor: pointer;

      img {
        width: 140px;
        height: 36px;
      }
    }

    :deep(.custom-dropdown) {
      /* 全局样式文件或组件内<style scoped>标签 */
      .el-dropdown-menu {
        outline-color: #1492ff;
      }
    }

    .areaBox {
      width: 80px;
      height: 36px;
      background: url("@/assets/img/home/dtbg.png") no-repeat;
      background-size: 100% 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        padding: 0 3px 0 5px;
        font-size: 14px;
        color: #0052CC;
      }
    }
  }
}
</style>