import { get, postForm, postJson, exportPost } from './http'

// 云平台-意见反馈
export const feedback = (p) => postJson('uaa/feedback', p)
// 云平台-招募伙伴
export const cooperation = (p) => postJson('uaa/cooperation', p)
// ===================安全管理===============
// 查询安全管理-全部权限 
export const allPurview = (p) => get('techmgr/techmgrRole/allPurview', p)
// 删除安全管理-角色
export const deletePurview = (p) => postJson('techmgr/techmgrRole/delete', p)
// 查询安全管理-角色列表
export const findRolesPurview = (p) => get('techmgr/techmgrRole/findRoles', p)
// 查询安全管理-认证企业全部员工
export const findStaffPurview = (p) => get('techmgr/techmgrRole/findStaff', p)
// 查询安全管理-角色授权详情
export const permissionDetail = (p) => get('techmgr/techmgrRole/permissionDetail', p)
// 添加安全管理-角色
export const saveRole = (p) => postJson('techmgr/techmgrRole/save', p)
// 保存安全管理-角色（授权）信息
export const saveRolePermission = (p) => postJson('techmgr/techmgrRole/saveRolePermission', p)
// 保存安全管理-角色员工数据
export const saveRoleStaff = (p) => postJson('techmgr/techmgrRole/saveRoleStaff', p)
// 查询安全管理-角色员工详情
export const staffDetail = (p) => get('techmgr/techmgrRole/staffDetail', p)
// 敏感操作设置保存
export const saveWarn = (p) => postJson('techmgr/techmgrWarn/save', p)
// 查询敏感操作设置详情
export const detailWarn = (p) => get('techmgr/techmgrWarn/detail', p)
// 分页查询科技管家-日志记录列表
export const findPageLog = (p) => postJson('techmgr/techmgrLog/findPage', p)
// 分页查询科技管家-日志记录列表筛选参数
export const findPageFilterLog = (p) => get('techmgr/techmgrLog/findPageFilter', p)
// 查询当前认证企业已授权功能权限
export const alreadyAuthority = (p) => get('techmgr/authority/already', p)
// 查询企业认证信息
export const findCertInfo = (p) => get('techmgr/techmgrCert/findCertInfo', p)
// 保存企业法人认证信息
export const saveCertInfo = (p) => postJson('techmgr/techmgrCert/saveCertInfo', p)
// 切换绑定企业
export const changeCompany = (p) => get('techmgr/techmgrCert/changeCompany', p)
// 查询绑定企业下拉列表
export const findCompanyList = (p) => get('techmgr/techmgrCert/findCompanyList', p)
// ===============项目进度================
// 查询企业项目分页列表
export const findProjects = (p) => postJson('techmgr/prj/lists', p)
// 查询企业项目分页列表筛选参数
export const findFilter = (p) => postJson('techmgr/prj/params', p)
// ==================项目详情和科创图谱=================
// 查询企业项目详情
export const detailprj = (p) => postJson('/techmgr/prj/detail', p)
// 查询当前项目资料分类明细
export const crntPrjFileCls = (p) => postJson('/techmgr/prj/crntPrjFileCls', p)
// 查询当前项目资料分页列表
export const crntPrjFiles = (p) => postJson('techmgr/prj/crntPrjFiles', p)
// 查询企业源资料分类明细
export const coFileCls = (p) => postJson('/techmgr/prj/coFileCls', p)
// 查询企业源资料分页列表
export const coFiles = (p) => postJson('techmgr/prj/coFiles', p)
// 云平台-源资料删除
export const coFileDel = (p) => postJson('techmgr/prj/coFileDel', p)
// 云平台-科创图谱源资料删除
export const coFileDel1 = (p) => postJson('techmgr/tic/coFileDel', p)
// 查询软著资料分类明细
export const coprFileCls = (p) => postJson('/techmgr/prj/coprFileCls', p)
// 查询软著资料分页列表
export const coprFiles = (p) => postJson('techmgr/prj/coprFiles', p)
// 查询专利资料分类明细
export const patFileCls = (p) => postJson('techmgr/prj/patFileCls', p)
// 查询专利资料分页列表
export const patFiles = (p) => postJson('techmgr/prj/patFiles', p)
// 查询项目资料分类明细
export const prjFileCls = (p) => postJson('techmgr/prj/prjFileCls', p)
// 查询项目资料分页列表
export const prjFiles = (p) => postJson('techmgr/prj/prjFiles', p)
// 查询财税资料分类明细
export const taxFileCls = (p) => postJson('techmgr/prj/taxFileCls', p)
// 查询财税资料分页列表
export const taxFiles = (p) => postJson('techmgr/prj/taxFiles', p)
// 查询科创咨询列表
export const tcsil = (p) => postForm('techmgr/tic/tcsil', p)
// 查询企业项目规划分享列表
export const ppsil = (p) => postForm('techmgr/tic/ppsil', p)
// 查询项目信息列表
export const copil = (p) => postForm('techmgr/tic/copil', p)
// 查询不可变信息-企业====暂时用不到
// export const conei = (p) => postForm('techmgr/tic/conei', p)
// 查询项目进展
export const findPrjProg = (p) => postJson('techmgr/prj/findPrjProg', p)
// 查询企业源资料分页列表筛选参数
export const coFileParams = (p) => postJson('techmgr/prj/coFileParams', p)
// 查询当前项目资料分页列表筛选参数
export const crntPrjFileParams = (p) => postJson('techmgr/prj/crntPrjFileParams', p)
// 查询软著资料分页列表筛选参数
export const coprFileParams = (p) => postJson('techmgr/prj/coprFileParams', p)
// 查询专利资料分页列表筛选参数
export const patFileParams = (p) => postJson('techmgr/prj/patFileParams', p)
// 查询项目资料分页列表筛选参数
export const prjFileParams = (p) => postJson('techmgr/prj/prjFileParams', p)
// 查询财税资料分页列表筛选参数
export const taxFileParams = (p) => postJson('techmgr/prj/taxFileParams', p)
// 云平台-提交需求
export const demand = (p) => postJson('techmgr/tic/demand', p)
// 源资料类型弹窗-查询企业源资料文件类型列表
export const cofty = (p) => postJson('techmgr/prj/cofty', p)
// 源资料新增==项目详情
export const coFileAdd = (p) => postJson('techmgr/prj/coFileAdd', p)
// 源资料新增==科创图谱
export const coFileAdd1 = (p) => postJson('techmgr/tic/coFileAdd', p)

// =========================我的会话---mywork================
// 查询会话列表
export const ctls = (p) => postJson('techmgr/mwk/ctls', p)
// 发送消息
export const sendMsg = (p) => postJson('techmgr/mwk/sendMsg', p)
// 查询会话消息记录列表
export const ctmes = (p) => postJson('techmgr/mwk/ctmes', p)
// 查询会话成员
export const ctms = (p) => postForm('techmgr/mwk/ctms', p)
// 查询@未读列表
export const amcts = (p) => postForm('techmgr/mwk/amcts', p)
// 列表进入会话
export const ict = (p) => postForm('techmgr/mwk/ict', p)
// 查询会话动态列表
export const ctdyn = (p) => get('techmgr/mwk/ctdyn', p)
// 查询会话历史记录
export const cthts = (p) => postJson('techmgr/mwk/cthts', p)
// 删除会话消息记录
export const dctr = (p) => postJson('techmgr/mwk/dctr', p)
// 撤回会话消息记录
export const rbctr = (p) => get('techmgr/mwk/rbctr', p)
// 查询消息已未读列表
export const recRd = (p) => postForm('techmgr/mwk/recRd', p)
// 修改会话名称
export const modifyChatName = (p) => postForm('techmgr/mwk/modifyChatName', p)
// 退出指定会话
export const exct = (p) => get('techmgr/mwk/exct', p)
// 查询服务群设置信息
export const cgi = (p) => get('techmgr/mwk/cgi', p)
// 话题置顶
export const tpct = (p) => postForm('techmgr/mwk/tpct', p)
// 查询用户名片
export const bCard = (p) => postForm('techmgr/mwk/bCard', p)
// 文件下载
export const download1= (p) => exportPost('techmgr/mwk/download', p)
// 查询用户服务群
export const cctgs = (p) => get('techmgr/mwk/cctgs', p)
// 查询通讯录
export const adbk = (p) => get('techmgr/mwk/adbk', p)
// 发起单人会话
export const sgct = (p) => get('techmgr/mwk/sgct', p)
// 转发
export const fwdMsg = (p) => postJson('techmgr/mwk/fwdMsg', p)
// 最近会话列表
export const rtcts = (p) => get('techmgr/mwk/rtcts', p)
// 查询客户人员
export const coUser = (p) => get('techmgr/mwk/coUser', p)
// 转发-通讯录
export const fwdadbk = (p) => get('techmgr/mwk/fwdadbk', p)
// 清空历史记录
export const clmsg = (p) => get('techmgr/mwk/clmsg', p)
// 更新会话成员
export const upctm = (p) => postJson('techmgr/mwk/upctm', p)
// 删除会话成员
export const delctm = (p) => postJson('techmgr/mwk/delctm', p)
// 未读消息数量
export const urcount = (p) => postJson('techmgr/mwk/urcount', p)
// 查找历史记录
export const sct = (p) => get('techmgr/mwk/sct', p)
// 定位消息记录
export const pstmsg = (p) => postForm('techmgr/mwk/pstmsg', p)
// 查询服务通知
export const svrnf = (p) => postJson('techmgr/mwk/svrnf', p)
// 删除会话
export const dpct = (p) => get('techmgr/mwk/dpct', p)
// 查询用户快捷发送方式
export const umsk = (p) => postJson('techmgr/mwk/umsk', p)
// 修改用户快捷发送方式
export const sumsk = (p) => postForm('techmgr/mwk/sumsk', p)
// 查询全部已完成法人认证企业列表===mywork使用
export const findCertCoList = (p) => get('techmgr/techmgrCert/findCertCoList', p)
// 标记已读
export const readSign = (p) => postForm('techmgr/mwk/readSign', p)
// ===================公共接口===================
// 下载前调用
export const preDownLoad = (p) => postJson('techmgr/authority/preDownLoad', p)
//  文件下载
export const download = (p) => exportPost('techmgr/authority/download', p)
// 文件预览
export const previewFile = (p) => postJson('techmgr/authority/preview', p)
// 文件分享
export const shareFile = (p) => postJson('techmgr/authority/share', p)
// 分享文件列表
export const sfl = (p) => postForm('techmgr/pub/sfl', p)
// 分享文件预览
export const sfp = (p) => postForm('techmgr/pub/sfp', p)