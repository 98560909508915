<template>
    <router-view />
</template>
<script setup>
import { nextTick, onBeforeMount,onMounted, reactive, ref } from 'vue';
import { getToken } from '@/utils/auth';
// import wsConnectionOrder from '@/utils/websocket.js'
import { createWebSocket } from '@/utils/ws.js'
import { headStore,userStore } from '@/store'
import { urcount } from '@/api/technologyManager'//接口
import {createChannel} from '@/utils/broadChannel'

// 建立websocket链接
function checkToken(){
     let params={}
    if(getToken()) {
        // 创建websocket
        // wsConnectionOrder.initWebSocket()
        let urerId=userStore.userId||sessionStorage.getItem('userId')
       params={
            type:1,
            userId:urerId+'@MYWORK@1' 
        }
    }
    nextTick(() => {
        createWebSocket(params)        
    })
    
}
// 查询会话未读消息
function getMyWorkNums() {
    if(getToken()) {
        urcount().then(res => {
            if (res.code == 0) {
                headStore.setConNum(res.data)
            }
        })
    }
}
onMounted(() => {
    checkToken()
    getMyWorkNums()
    createChannel('globalControlChannel',(e)=>{
       location.reload()
       userStore.clearCache()
    })
})
</script>
<style lang="scss"></style>
