<template>
    <div class="dynamics_box" :style="fullscreenCon?'width: 1500px':'width: 640px;'">
        <!-- 群动态 -->
        <div class="mt10">
            <div @click="backPbtn" class="fl ml20 cura">
                <i class="iconfont icon-xitongfanhui"></i>
            </div>
            <div class="fl ml10">{{details.title}}群动态</div>
        </div>
        <div v-if="dynamicsList && dynamicsList.length" class="dynamics_ul" style="margin-top:10px">
            <div class="dynamics_li" id="dsBox">
                <div v-for="(itemd, indexd) in dynamicsList" :key="indexd">
                    <div class="dy_time">{{itemd.formatTime}}</div>
                    <div class="dy_box">{{itemd.chatMsg}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { conversationStore } from '@/store'
import { ctdyn } from '@/api/technologyManager'//接口

const emit = defineEmits(['backDynamics'])
const dynamicsList = ref([])
const fullscreenCon = ref(null)
const props = defineProps({
    details: {
        type: Object,
        default: ()=>{}
    },
})
// 群动态
function dynamFun(){
    ctdyn({chatId: props.details.id}).then((res) => {
        if(res.code==0){
            dynamicsList.value = res.data
            setTimeout(() => {
                scrollToBottomDs()   
            });
        }
    })
}
// 滚动到底部
function scrollToBottomDs() {
    setTimeout(() => {
        let container = document.getElementById("dsBox")
        if(container) {
            container.scrollTop = container.scrollHeight
        }
    }, 300)
}
watch(() => conversationStore.isFullscreen, (val) => {
    fullscreenCon.value = val
}, { immediate: true })
function backPbtn(){
    emit('backDynamics')
}
onMounted(() => {
    dynamFun()
})
</script>

<style scoped lang="scss">
.dynamics_box{
    // width: 640px;
    margin-left: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    height: 96%;
    z-index: 10;
    .dynamics_ul {
        width: 100%;
        margin:10px auto;
        overflow-y: auto;
    }
    .dynamics_li{
        width: 98%;
        height: 600px;
        overflow-y: auto;
        margin: auto;
    }
    .dy_time{
        color: #8590A6;
        font-size: 12px;
        text-align: center;
    }
    .dy_box{
        width: 80%;
        margin: 10px auto;
        border-radius: 2px;
        background: #E8F2FF7F;
        color: #666666;
        font-size: 12px;
        line-height: 12px;
        padding: 10px;
        line-height: 1.5;
        text-align: center;
    }
    .ml20{
        margin-left: 20px;
    }
}
</style>