import { createPinia } from 'pinia'
const pinia = createPinia()

export default pinia

import { ElMessage, ElMessageBox } from 'element-plus'

import useUserStore from './user'
import useHeadStore from './head'
import useOnlineStore from './online'
import useCommonStore from './common'
import useIntegralStore from './integral'
import useConversationStore from './conversation'


export const userStore = useUserStore(pinia)
export const headStore = useHeadStore(pinia)
export const onlineStore = useOnlineStore(pinia)
export const commonStore = useCommonStore(pinia)
export const integralStore = useIntegralStore(pinia)
export const conversationStore = useConversationStore(pinia)




export const elMessage = ElMessage
export const elMessageBox = ElMessageBox


// 表单校验回显方法
export const formValidate = (data = [], key = 'field', value = 'message') => {
    let mapList = {}
    if (data.length == 0 || !Array.isArray(data)) {
        return mapList
    }
    for (let i = 0; i < data.length; i++) {
        mapList[data[i][key]] = data[i][value]
    }
    return mapList
}
