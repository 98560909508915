<template>
    <div class="conversation">
        <!--我的会话 overflow:拖拽超出可视区-->
        <div>
            <el-dialog v-model="dialogVisible" :width="fullscreenCon?'100%': isSetShow ? '1389px' : '1049px'" :fullscreen="fullscreenCon" draggable :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :before-close="handleClose">
                <!-- 自定义头部 -->
                <template #header >
                    <div style="position: absolute;z-index: 2;">
                        <div class="overhide">
                            <div v-if="fullscreenCon" title="还原我的会话" @click="bigAllCon" class="bigm_img" :style="isBtnBig">
                                <i class="iconfont icon-suoxiao"></i>
                            </div>
                            <div v-else title="放大我的会话" @click="bigAllCon" class="bigm_img" :style="isBtnBig">
                                <i class="iconfont icon-fangda"></i>
                            </div>
                            <div title="关闭我的会话" @click="handleClose" class="close_img" :style="isBtnClose">
                                <i class="iconfont icon-chahao"></i>
                            </div>
                        </div>
                    </div>
                </template>
                <div style="height: 30px;"></div>
                <div class="myworkbox" :style="fullscreenCon?'height:calc(100vh - 40px);':'height:640px;'">
                    <div style="display:flex;height:100%;">
                        <div class="left_title">
                            <img class="cura radio4" style="width:44px;height:44px;margin: 20px 10px 30px" :src="userStore.avatar" @click="workGroupFindFriendInfo($event)" />
                            <div class="tabList" v-for="(item,index) in tabList" :class="[ChoseIndex==index?'ChoseIndex':'']" :key="index" @click="ChoseTab(index,item.path)">
                                <i :style="{fontSize:item.size}" :class="`iconfont  ${item.icons}`"></i>
                                <div>{{item.name}}</div>
                                <div v-if="index==0&&MyWorkNum&& MyWorkNum>0" :style="{left:(MyWorkNum+'').length>2?'25px':'35px',padding:(MyWorkNum+'').length>2?'0 3px':''}" class="AllNums"> {{MyWorkNum>99?'99+':MyWorkNum}}</div>
                            </div>
                            <div style="margin-top: 100px" title="创建">
                                <el-popover ref="popover" placement="right-start" width="100px" trigger="click" popper-class="MyEstablish">
                                    <div class="MyEstablishItem" @click="ChoseEstablish('Dialogue')">发起对话</div>
                                    <div v-jurisdiction="'ChenvaM0041:sys_menu_function_buttons_136'" class="MyEstablishItem" @click="ChoseEstablish('Conversation')">创建话题</div>
                                    <template #reference>
                                        <i class="iconfont icon-biaoge-xinzeng" style="font-size:22px;"></i>
                                    </template>
                                </el-popover>
                            </div>
                        </div>
                        <!-- 占位盒子 -->
                        <div style="width: 64px;"></div>
                        <conmessage ref="conmessage1" v-if="showbox == 'conmessage'" @showRight="showRight"></conmessage>
                        <congroup v-if="showbox == 'congroup'" @showRight="showRight" @callBack="callBack"></congroup>
                        <conmaillist v-if="showbox == 'conmaillist'" @showRight="showRight" @callBack="callBack"></conmaillist>
                    </div>
                    <div ref="Mask" style="display: none;width:100%;height:600px; position: fixed;top:0px;left:0px;z-index:999" @click="ClickMark"></div>
                    <!-- 名片 -->
                    <div class="detailsPop" ref="detailsPop">
                        <businessCard ref="businessCard1" :cardDetail="FriendInfo" @ChoseMailList1="ChoseMailList"></businessCard>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { ElMessage } from 'element-plus'
import conmessage from "./news/conmessage.vue";
import congroup from "./news/congroup.vue";
import conmaillist from "./news/conmaillist.vue";
import http from '@/api/http'
import businessCard from '@/views/technologyManager/conversation/components/businessCard.vue'
import { userStore } from '@/store'
import { Search } from '@element-plus/icons-vue'
import { conversationStore, headStore } from '@/store'
import { bCard, urcount, sgct } from '@/api/technologyManager'//接口
import useHeadStore from '@/store/head'
import { getToken } from '@/utils/auth';

const useHeadStoreCount = useHeadStore()
const emit = defineEmits(['closeDialog','showRightP','changeTab','callBack'])
const dialogVisible = ref(false)
const activeIndex = ref(1)
const ChoseIndex = ref(0)
const DialogueShow = ref(false)
const MyWorkNum = ref(null)
const businessCard1 = ref(null)
const disable = ref(false)
const FriendInfo = ref({})
const setTime = ref('')
const showbox = ref('conmessage')
const ConversationShow = ref(false)
const Mask = ref(null)
const detailsPop = ref(null)
const conmessage1 = ref(null)
const popover = ref(null)
const Dialogue = ref(null)
const keyWords = ref('')
// const userId = JSON.parse(localStorage.getItem('userMassage')).id
// const imgHead = http.FileUrl+JSON.parse(localStorage.getItem('userMassage')).avatar
const userId = ''
const imgHead = ''
const fullscreenCon = ref(false)
const isBtnClose = ref('')
const isBtnBig = ref('')
const tabList = [
    { icons: 'icon-xiaoxi1', name: '消息', size: '24px', path: 'conmessage' },
    { icons: 'icon-zuzhiqunzu', name: '群组', size: '24px', path: 'congroup' },
    { icons: 'icon-tongxunlu', size: '22px', name: '通讯录', path: 'conmaillist' },
    // { icons: 'icon-huiyishi', size: '22px', name: '会议', path: '' },
]
const isSetShow = ref(false)
const props = defineProps({
    // 控制弹窗
    isShowdialog: {
        type: Boolean,
        default: false
    }
})
// ---------------计算属性-----------------------
watch(() => props.isShowdialog, (val) => {
    dialogVisible.value = val
    myWorkOpen()
    // 判断mywork是否打开
    conversationStore.SET_myworkOpen(val)
}, { immediate: true })
watch(() => headStore.conNum, (val) => {
    MyWorkNum.value = val
}, { immediate: true })
watch(() => conversationStore.isSetShow, (val) => {
    isSetShow.value = val
    getBtnPosition()
}, { immediate: true })


function changeMenu(index,val){
    activeIndex.value = index+1
}
// 查询未读消息
function getMyWorkNums() {
    if(getToken()) {
        urcount().then(res => {
            if (res.code == 0) {
                MyWorkNum.value = res.data
                headStore.setConNum(res.data)
            }
        })
    }
}
function ClickMark() {
    Mask.value.style.display = 'none'
    detailsPop.value.style.display = 'none'
}
// 点击名片聊天
function ChoseMailList(val) {
    detailsPop.value.style.display = 'none'
    sgct({userId: FriendInfo.value.id}).then((res) => {
        if (res.code == 0) {
            FriendInfo.value.groupInfoId = res.data.itemId
            FriendInfo.value.chatType = 4
            ChoseIndex.value = '0'
            showbox.value ='conmessage'
            setTimeout(() => {
                conmessage1.value.details = res.data
                conmessage1.value.getDetailTItle(res.data.id)   
            })
            disable.value = false
        } else {
            ElMessage({
                message: res.message,
                type: 'error',
                duration: 3000
            })
            disable.value = false
        }
    })
}
// 查询用户信息
function workGroupFindFriendInfo(e) {
    bCard({userId: sessionStorage.getItem('userId')}).then((res) => {
        FriendInfo.value = res.data
        Mask.value.style.display = 'inline'
        let div = detailsPop.value
        div.style.display = 'inline'
        div.style.left = e.clientX + 30 + 'px'
        div.style.top = e.clientY - 20 + 'px'
    })
}
function ChoseEstablish(path) {
    ChoseIndex.value = '4'
    popover.value.doClose()
    showbox.value = path
    showRight(false)
    $forceUpdate()
}
function openDrawer() {
    drawer.value = true
}
function ChoseTab(index, path) {
    if(index == 3) {
        ElMessage({
            message: '敬请期待',
            type: 'info',
            duration: 3000
        })
        return
    }
    ChoseIndex.value = index
    showbox.value = path
    showRight(false)
    setTimeout(() => {
        // 控制弹窗头展示的背景图
        if (index == 0) {
            conmessage1.value.workGroupFindPersonalMsgList()
            // conmessage1.value.closeDiaNew()
        }
    })
    emit('changeTab', index)
    conversationStore.setSetShow(false)
}
function showRight(val) {
    isSetShow.value = conversationStore.isSetShow
    emit('showRightP', val)
}
function tabBack(val) {
    ChoseIndex.value = 0
    if (val) {
        // 创建话题返回的id
        showbox = 'conmessage'
        conmessage1.value.workGroupFindPersonalMsgList()
    }
}
// 通讯录回调
function callBack(val){
    ChoseIndex.value = '0'
    showbox.value ='conmessage'
    setTimeout(() => {
        conmessage1.value.getDetailTItle(val.id)
    });
}
// header下拉回调
function callBackHeader(val){
    ChoseIndex.value = '0'
    showbox.value ='conmessage'
    setTimeout(() => {
        conmessage1.value.getDetailTItle(val.id)   
    });
}
function scrollToBottom(){
    if(conmessage1.value) {
        conmessage1.value.scrollToBottom()
    }
}
// 打开myWork
function myWorkOpen(){
    getBtnPosition()
    // if(showbox.value =='conmessage') {
    //     // 刷新
    //     // conmessage1.value.myWorkOpen()
    // }
}
function handleClose(){
    emit('closeDialog', false)
    dialogVisible.value = false
}
// 全屏 mywork
function bigAllCon(){
    fullscreenCon.value = !fullscreenCon.value
    conversationStore.setFullscreen(fullscreenCon.value)
    getBtnPosition()
}
// 判断按钮位置
function getBtnPosition(){
    const setShowCon = conversationStore.isSetShow
    if(fullscreenCon.value && fullscreenCon.value == true) {
        // 全屏
        if(setShowCon && setShowCon == true) {
            // 有设置 1173px
            isBtnClose.value = 'left:calc(100vw - 370px)'
            isBtnBig.value = 'left:calc(100vw - 390px)'
        } else {
            // 无设置 1513px
            isBtnClose.value = 'left:calc(100vw - 30px)'
            isBtnBig.value = 'left:calc(100vw - 50px)'
        }
    } else {
        // 小屏
        isBtnClose.value = 'left:1020px'
        isBtnBig.value = 'left:1000px'
    }
}
onMounted(()=>{
    // 初始化时清空数据
    conversationStore.setSetShow(false)
    conversationStore.setFullscreen(false)
    getMyWorkNums()
    // let val = $route.value
    // tabList.value.map((item, index) => {
    //     if (item.path == val.name) ChoseIndex.value = index
    // })
    // $bus.on('MyWorkNum', (e) => {
    //     MyWorkNum.value = e
    // })
})

</script>

<style scoped lang="scss">
.myWork_dialog {
    position: relative;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    overflow-y: auto;

    .el-dialog__body {
        padding: 0;
    }

    .el-dialog__header {
        padding: 0;
        height: 20px;
        background: #fff;
        border-radius: 3px;
    }

    .el-dialog__headerbtn {
        display: none;
    }

    .dialog_title {
        width: 100%;
        height: 20px;
        background: transparent;

        .close_img {
            position: absolute;
            left: 1022px;
            top: 0;
            cursor: pointer;
            width: 26px;
            height: 20px;
            background: transparent;
            text-align: center;
            border-radius: 3px;
        }

        .close_img:hover {
            background: #f2f5f9;
        }
    }
}

.myWorkDropdown,
.navItem {
    .el-badge {
        .el-badge__content {
            height: 20px;
        }
    }
}

.myWorkDropdown .el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background: #edecf0 !important;
    color: #1492ff !important;
}

.headersbox .el-badge__content--danger,
.myWorkDropdown .el-badge__content--danger {
    background-color: #fa2d2d;
}
.tabList {
    margin-left: 4px;
    position: relative;
    cursor: pointer;
    width: 58px;
    text-align: center;
    height: 58px;
    color: #c3c7d1;
    padding-top: 10px;
    border-radius: 3px;
    margin-bottom: 10px;
    span {
        position: absolute;
        right: 25px;
        top: 10px;
    }
}
.left_title {
    width: 66px;
    background: #fff;
    text-align: center;
    height: 100%;
    background: #324466;
    color: #c3c7d1;
    font-size: 13px;
    position: absolute;
    top: 0;
    z-index: 10;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}
.ChoseIndex {
    background: #27324a;
    color: #fff;
    // margin: 0 auto 10px;
}
.detailsPop {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    display: none;
    width: 324px;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(189, 203, 224, 0.3);
    border-radius: 3px;
    // border: solid 1px rgb(240, 242, 245);
}
.ChoseMailList {
    line-height: 46px;
    text-align: center;
    color: #1492ff;
    cursor: pointer;
    padding-bottom: 20px;
    .ChoseMailListBtn{
        width: 99px;
        height: 30px;
        line-height: 30px;
        background: #1492ff;
        color: #fff;
        text-align: center;
        margin: auto;
        border-radius: 3px;
    }
}
.AllNums {
    font-size: 12px;
    position: absolute;
    z-index: 999;
    top: 0px;
    background: red;
    color: #fff;
    min-width: 16px;
    text-align: center;
    border-radius: 20px;
}
.myworkbox{
//    height:640px;
   border-bottom-left-radius: 3px;
   border-bottom-right-radius: 3px;
//    border-top: 1px solid #caced9;
}
.close_img {
    position: absolute;
    // left: 1022px;
    top: 0;
    cursor: pointer;
    width: 26px;
    height: 20px;
    background: transparent;
    text-align: center;
    border-radius: 3px;
}

.close_img:hover {
    background: #f2f5f9;
}
.bigm_img{
    position: absolute;
    // left: 1000px;
    top: 0;
    cursor: pointer;
    width: 26px;
    height: 20px;
    background: transparent;
    text-align: center;
    border-radius: 3px;
}
.radio4{
    border-radius: 4px;
}
</style>
<style lang="scss">
.conversation{
    .el-dialog{
        padding: 0;
        .el-dialog__header{
            padding-bottom: 0 !important;
        }
    }
}
.MyEstablish {
    padding: 0;
    line-height: 30px;
    color: #000;
    cursor: pointer;
    min-width: 75px;
}
.MyEstablishItem {
    padding-left: 10px;
}
.MyEstablishItem:hover {
    background: #f2f5f9;
}
</style>
