<template>
    <div>
        <div :class="['FileUpload', props.DragType==0? 'Drag':props.DragType==1?'Drag1':props.DragType==2?'Drag2':'']">
        <!-- 编辑 -->
        <i style="cursor: pointer;font-size: 18px;" v-if="props.imgShow" @click="triggerClick" class="iconfont icon-fujian"></i>
        <el-button v-else size="mini" type="primary" @click="triggerClick">上传文件</el-button>
        <div class="areaDrag" v-show="showDrag">
            <div class="uploadbox">
                <el-upload drag class="file_upload" :action="addressOss" :http-request="uploadHttpRequest" :on-change="handleUploadChange" :before-remove="beforeRemove" :multiple="props.multiple" :on-exceed="handleExceed" :show-file-list='false' ref="fileup" :file-list="fileList">
                    <el-button class="el-upload__text" ref="upload">请把文件拖放到这个区域，就可作为文件添加</el-button>
                </el-upload>
                <i class="el-icon-close" @click.stop="close()"></i>
            </div>
        </div>
    </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import ObsClient from '@/js/esdk-obs-browserjs-3.19.9.min.js'
const addressOss = ref('')
const fileList = ref([])
const sendList = ref([])
const loadingNumber = ref(0)
const hook = ref(null)
const cp = ref(null)
const puase = ref(false)
const obsClient = ref(null)
const showPrise = ref(false)
const showDrag = ref(false)
const bigSigeLimit = ref(0)
const index = ref(0)
const open = ref(true)
const fileup = ref(null)
const upload = ref(null)
const emit = defineEmits(['reset-file','send-progress','send-file','loadingNumber'])
const props = defineProps({
    multiple: {
        type: Boolean,
        default: true
    },
    //  哪个地方的拖拽   0 我的文件拖拽  1 创建话题 发起对话里面的拖拽   2聊天框里面的拖拽
    DragType: {
        type: Number,
        default: 0
    },
    showPri: {
        type: Boolean,
        default: false
    },
    limit: {
        //限制文件个数
        type: Number
    },
    previewList: {
        //回显
        type: Array,
        default: () => {
            return []
        }
    },
    icon: {
        type: Boolean,
        default: false
    },
    limitNumber: {
        //限制大小
        type: Number,
        default: 0
    },
    formatLimit: {
        //是否限制文件格式 为空则不限制, 传入字符串为限制格式
        type: String,
        default: ''
    },
    imgShow: {
        type: Boolean,
        default: true
    }
})

watch(() => props.previewList, (val) => {
    if (val[0] && val[0].fileName == '' && val[0].fileUrl == '') return
    sendList.value = JSON.parse(JSON.stringify(val))
    fileList.value = JSON.parse(JSON.stringify(val))
},{ immediate: true });

function enter(data) {
    // 移入
    data.show = true
}
function leave(data) {
    // 移除
    data.show = false
}
// 显示拖拽
function showarea() {
    showDrag.value = showDrag.value
}
// 关闭拖拽
function close() {
    showDrag.value = false
}
//暂停上传
function puaseUpload() {
    puase.value = true
    hook.value.cancel()
}
//开始上传
function startUpload() {
    let file = {
        name: cp.value.sourceFile.name,
        raw: cp.value.sourceFile
    }
    puase.value = false
    handleUploadChange(file)
}
//删除文件
function deleteFile(index) {
    sendList.value.splice(index, 1)
    cleanFiles()
    let list = JSON.parse(JSON.stringify(sendList.value))
    emit('reset-file', list, indexs.value)
}
//清空已上传的文件列表
function cleanFiles() {
    fileup.value.clearFiles()
}
//触发点击函数
function triggerClick() {
    index.value = 0
    upload.value.$el.click()
}
//删除之前的回调
function beforeRemove(file) {}
//超出上限 回调函数
function handleExceed() {
    ElMessage({
        type: 'warning',
        message: `您已超出上限,上限个数为${props.limit}`,
        offset: 400
    })
}
//覆盖element默认上传事件
function uploadHttpRequest() {}
//上传之前的回调
function beforeUpload(file) {
    var FileExt = file.name.replace(/.+\./, '')
    console.log('FileExt', FileExt)
    let formatLimit1 = props.formatLimit.indexOf(FileExt.toLowerCase())
    //console.log('文件格式','FileExt',file)
    //限制文件总数最大支持2G
    let bigSige = Number(file.size) //文件总大小
    bigSigeLimit.value += bigSige
    if (bigSigeLimit.value / 1024 / 1024 > 2048) {
        ElMessage({
            message: '所有文件相加最大支持2G!',
            type: 'warning',
            duration: 3000
        })
        return -1
    }
    if (props.formatLimit != '' && formatLimit1 == -1) {
        ElMessage({
            message: '文件格式错误!',
            type: 'warning',
            duration: 3000
        })
        return -1
    }
}
//清除预览项
function cleanFile() {
    fileList.value = []
    sendList.value = []
}
//向父组件发送文件上传进度
function sendProgress(progress) {
    emit('send-progress', progress)
}
//向父组件传送文件
function sendFile(url, name, size, imglogo) {
    let data = {
        fileUrl: url,
        fileName: name,
        size: size,
        imglogo: imglogo
    }
    let fileList = []
    fileList.push(data)
    sendList.value.push(data)
    emit('send-file', fileList)
}
//文件上传
function handleUploadChange(file) {
    if (beforeUpload(file.raw ? file.raw : file) != -1) {
        let filename = file.name
        let config = {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: false
        }
        var key1 = 'project/' + new Date().getTime() + '/' + Math.random() * 10 + filename.substring(filename.lastIndexOf('.'))
        // } //没有断点续传版本
        var callback = function (transferredAmount, totalAmount, totalSeconds) {
            let progress = (transferredAmount * 100.0) / totalAmount
            emit('loadingNumber', { progress: parseInt(progress), name: file.name })
            loadingNumber.value = parseInt(progress)
            if ((transferredAmount * 100.0) / totalAmount == 100) {
                // 附件图标及大小
                // file.size = file.size
                var FilesExt = file.name.replace(/.+\./, '')
                // //console.log('444',FilesExt,file)
                // formatLimit:"'jpg','jpeg','png','pdf','ppt','doc','docx','xlsx','xls','rp'"
                if (FilesExt == 'jpg') {
                    file.imglogo = require('@/assets/img/MyWork/JPGB.png')
                } else if (FilesExt == 'png') {
                    file.imglogo = require('@/assets/img/MyWork/PNGB.png')
                } else if (FilesExt == 'pdf') {
                    file.imglogo = require('@/assets/img/MyWork/PDFB.png')
                } else if (FilesExt == 'ppt' || FilesExt == 'pptx') {
                    file.imglogo = require('@/assets/img/MyWork/ppt.png')
                } else if (FilesExt == 'doc' || FilesExt == 'docx') {
                    file.imglogo = require('@/assets/img/MyWork/doc.png')
                } else if (FilesExt == 'xls' || FilesExt == 'xlsx') {
                    file.imglogo = require('@/assets/img/MyWork/xls.png')
                } else if (FilesExt == 'rp') {
                    file.imglogo = require('@/assets/img/MyWork/RPB.png')
                } else if (FilesExt == 'zip' || FilesExt == 'rar' || FilesExt == 'exe') {
                    file.imglogo = require('@/assets/img/MyWork/yasuobao.png')
                } else if (FilesExt == 'html' || FilesExt == 'htm') {
                    file.imglogo = require('@/assets/img/MyWork/liulanqi.png')
                } else {
                    file.imglogo = require('@/assets/img/MyWork/defaultimg.png')
                }
                setTimeout(() => {
                    sendFile('/' + key1, filename, file.size, file.imglogo)
                    cp.value = null
                    showDrag.value = false
                }, 300);
            } else {
            }
        } //断点续传版本
        if (!obsClient.value) {
            obsClient.value = new ObsClient({
                access_key_id: 'OCJBUXXHFNP8V51WO4RD',
                secret_access_key: '2bhiXIaQQ9DUIr5Z0YV4Z6dLzETjjDHoMm60fp2N',
                server: 'https://obs.cn-north-1.myhuaweicloud.com'
            })
        }
        //断点续传
        let uploadData = {}
        let uploadFileData = {
            Bucket: 'chenvafile',
            Key: key1,
            SourceFile: file.raw ? file.raw : file,
            PartSize: 9 * 1024 * 1024,
            ProgressCallback: callback,
            EventCallback: function (eventType, eventParam, eventResult) {
                // 处理事件响应
            }
        }
        uploadData = uploadFileData
        obsClient.value.uploadFile(uploadData, function (err, result) {
            // 出现错误，再次调用断点续传接口，继续上传任务
            if (err) {
            } else {
                if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
                }
            }
        })
    }
}
</script>

<style>
.FileUpload {
    width: 100%;
}
.FileUpload .el-upload {
    width: 100%;
}
.Drag2 .el-upload-dragger {
    width: 100%;
    margin-top: -10px;
    height: 619px;
    background: #ffffd9;
    opacity: 0.8 !important;
    border-radius: 0;
    border: 1px solid #dfe2e8 !important;
    z-index: 1000;
}
.Drag1 .el-upload-dragger {
    width: 100%;
    margin-top: -35px;
    height: 160px;
    background: #ffffd9;
    opacity: 0.8 !important;
    border-radius: 0;
    border: 1px solid #dfe2e8 !important;
    z-index: 1000;
}
.Drag .el-upload-dragger {
    width: 100%;
    margin-top: -100px;
    height: 100%;
    background: #ffffd9;
    opacity: 0.8 !important;
    border-radius: 0;
    border: 1px solid #dfe2e8 !important;
    z-index: 1000;
}
.FileUpload .main_content:hover {
    background: #1492ff26 !important;
}
</style>
<style lang="scss" scoped>
.FileUpload {
    line-height: 20px;
    font-size: 12px;
    .top {
        overflow: hidden;
        margin-bottom: 10px;
        .filebutton {
            float: left;
            cursor: pointer;
            padding: 1px 6px;
            color: #1492ff;
        }
        .filebutton:hover {
            color: #fff;
            background: #1492ff;
        }
        .fujian {
            float: left;
            margin: 4px 3px 0 0;
        }
        .icon-xiaoshou {
            font-size: 12px;
        }
    }
}
.outbox {
    overflow: auto;
    max-height: 155px;
    /*横滚取高,纵滚取宽*/
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.1);
    }
    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
        // border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: rgba(0, 0, 0, 0.1);
    }
}
.el-upload__text {
    line-height: 145px;
    color: #000;
    font-size: 14px;
    background: #ffffd9;
    border: none;
}
.main_content {
    padding: 5px;
    background: #1492ff26;
    width: 300px;
    line-height: 30px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    cursor: default;
    float: left;
    margin: 0 10px 10px 0;
    .imgtype {
        display: block;
        float: left;
        margin-right: 10px;
    }
    .preview_file {
        max-width: 230px;
        float: left;
        p {
            overflow: hidden;
            font-size: 12px;
            color: #000;
            line-height: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .preview_operation {
        transition: all 0.3s;
        float: right;
        line-height: 16px;
        :hover {
            color: #1492ff;
            transition: all 0.3s;
            cursor: pointer;
        }
    }
    &:hover {
        background-color: #f0f2f5;
        transition: all 0.3s;
    }
}
.progress_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .progress_self {
        width: 80%;
    }
    .progress_icon {
        transition: all 0.3s;
        &:hover {
            color: #1492ff;
            transition: all 0.3s;
            cursor: pointer;
        }
    }
}
.areaDrag {
    width: 100%;
    position: absolute;
    // top: 30px;
    left: 0;
    .uploadbox {
        position: relative;
        .el-icon-close {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 99999;
            font-size: 16px;
            cursor: pointer;
        }
    }
}
</style>