import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '@/assets/icon/iconfont.css'
import '@/assets/style/index.scss'
import pinia from '@/store/index.js'
import plug from '@/components/index.js' //引入全局组件
// 自定义指令：登录：v-jurisdiction:login， 复制v-copy
import directive from './directive'
import './intercept.js'

import print from 'vue3-print-nb';


// 定义特性标志
window.__VUE_PROD_DEVTOOLS__ = false
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false

const app = createApp(App)


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(plug)
app.use(ElementPlus, { locale: zhCn, zIndex: 3000 })
app.use(directive)
app.use(pinia)
app.use(print)


app.use(router).mount('#app')
