import { get, postForm, postJson } from './http'

// 首页-最新资讯
export const getNewInformationPageList = (p) => postJson('/uaa/information/encry/findHomePageList', p, true)
// 最新资讯-更多
export const findHomePageMoreList = (p) => postJson('/uaa/information/encry/findHomePageMoreList', p, true)
// 我的订阅
export const getMyDyData = (p) => postJson('/uaa/information/getMyDyData', p)


// 资讯详情
export const getInformationDetail = (p) => postJson('/uaa/information/findDetail?declareId=' + p)
// 最新资讯-上一篇/下一篇
export const getNextListApi = (p) => postForm('/uaa/information/findAdjacentData', p)
// 查询我的收藏-上一篇/下一篇
export const findAdjacentData = (p) => postForm('/uaa/personalCenter/findAdjacentData', p)
// 最新资讯-是否收藏
export const getIsStarApi = (p) => postJson('/uaa/information/findIsStar?declareId=' + p)
// 收藏/取消收藏
export const cancelStarApi = (p) => postJson('/uaa/informationStarInfo/cancelStar?declareId=' + p)
// 换一批/换一批
export const changeBatch = (p) => postJson('/uaa/information/encry/changeBatch', p, true)
// 查询我的收藏
export const getMyStar = (p) => postForm('/uaa/personalCenter/findMyStar', p)
// 查询推荐HOT列表
export const getInfoHotList = (p) => postJson('/uaa/sysSubscribeNow/findHotTjList', p)
// 查询是否订阅-相关类型
export const findIsDy = (p) => postJson('/uaa/sysSubscribeNow/findIsDy', p)
// 保存/更新订阅设置
export const saveSetting = (p) => postJson('/uaa/sysSubscribeNow/encry/saveSetting', p, true)
// 订阅数据回显
export const findDyData = (p) => postJson('/uaa/sysSubscribeNow/findDyData', p)








// 产学研列表
export const getIURList = (p) => postJson('mall/platformIur/encry/findList', p, true)
// 产学研-详情
export const getIURDetail = (p) => postJson('mall/platformIur/findDetail?itemId=' + p)

// 专家评审需求列表
export const getReviewList = (p) => postJson('mall/mallExpertReview/encry/findList', p, true)
// 专家评审需求-详情
export const getReviewDetail = (p) => postJson('mall/mallExpertReview/findDetail?itemId=' + p)
// 项目类型去重
export const getServiceSelectList = (p) => postJson('mall/mallExpertReview/findServiceSelectList', p)
// 评价
export const evaluateData = (p, type) => {
    let url = 'mall/platformIur/encry/evaluateData'
    if (type == 2) {
        url = 'mall/mallExpertReview/encry/evaluateData'
    }
    return postJson(url, p, true)
}




// 积分
export const getIntegralData = (p) => postJson('/uaa/sysPointsManagement/findPointsData', p)
// 获取积分明细
export const findPointsDetail = (p) => postForm('/uaa/sysPointsManagement/findPointsDetail', p)
// 保存积分信息
export const savePointsInfo = (p) => postJson('/uaa/sysPointsManagement/savePointsInfoF', p)
// 每日签到
export const setDailyAttendance = (p) => postJson('/uaa/sysPointsManagement/setDailyAttendance', p)
// 保存特殊积分信息
export const saveSpPointsInfo = (p) => postJson('/uaa/sysPointsManagement/encry/saveSpPointsInfo', p, true)


