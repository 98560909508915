<template>
    <div class="uploadDrag-container">
        <!-- 单个详情 -->
        <div class="sbox" v-if="type == 'info' && (numLimit == '1' || sendList.length < 1)">
            <div :style="fileStyle" class="showbox">
                <div v-if="!imgUrl" style="background-color: #f2f5f9;height:100%">
                    <i class="iconfont icon-tupian"></i>
                    <div class="title" style="margin-top:-10px">暂无{{ title }}</div>
                </div>
                <div v-else style="height:100%">
                    <div v-if="!isPdf">
                        <el-image v-if="imgUrl != ''" fit="contain" :style="{ width: width, height: height}" :src="imgBaseUrl + imgUrl" ></el-image>
                    </div>
                    <div v-else  class="pdfBox" style="border: none;">
                        <img src="@/assets/img/MyWork/pdf.png" alt=""  style="border: none;" fit="scale-down" >
                    </div>
                    <div class="btnList">
                        <div style="margin:0 74px;cursor: pointer;" @click="preview">预览</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 单个上传 -->
        <div class="sbox" :style="fileStyle" v-if="type != 'info' && numLimit == '1'">
            <!-- 单选 -->
            <el-upload class="file_upload" :action="imgBaseUrl" :http-request="uploadHttpRequest"
                :on-change="handleUploadChange" :on-progress="triggerClick" :multiple="multiple"
                :on-exceed="handleExceed" ref="fileup" :accept="accept" :file-list="fileList"
                :show-file-list="false">
                <!-- 显示 -->
                <div v-if="!imgUrl">
                    <slot>
                        <div class="fileBox">
                            <slot name="icon">
                                <img src="@/assets/img/icon/fileIcon.png" alt="">
                            </slot>
                            <slot name="text">
                                <div class="title">
                                    <em v-if="must" style="color:red;">*</em>{{ title }}
                                    <i class="iconfont icon-wenhaofill tip" :title="tip" v-if="tip"></i>
                                </div>
                            </slot>
                        </div>
                    </slot>
                </div>
                <!-- 上传 -->
                <div v-if="imgUrl">
                    <el-image @error="error" :key="Math.random()" fit="scale-down"
                        :src="imgBaseUrl + imgUrl" :style="fileStyle" style="border: none;" v-if="!isPdf"></el-image>
                    <div v-else :style="fileStyle" class="pdfBox" style="border: none;">
                        <img src="@/assets/img/MyWork/pdf.png" alt=""  style="border: none;" fit="scale-down" >
                    </div>
                    <div class="btnList">
                        <div style="margin:0 45px;" @click.stop="preview">预览</div>
                        <div @click.stop="del">删除</div>
                    </div>
                </div>
            </el-upload>
        </div>
        <!-- 多个上传 -->
        <div v-if="numLimit != '1'" class="flex">
            <!-- 上传 -->
            <div class="showbox" :style="fileStyle" v-if="type != 'info'" style="padding-top: 15px;">
                <el-upload class="file_upload" :action="imgBaseUrl" :http-request="uploadHttpRequest"
                    :on-change="handleUploadChange" :on-progress="triggerClick" :multiple="multiple"
                    :on-exceed="handleExceed" ref="fileup" :accept="accept" :file-list="fileList"
                    :show-file-list="false" :limit="numLimit">
                    <slot>
                        <div class="fileBox">
                            <slot name="icon">
                                <img src="@/assets/img/icon/fileIcon.png" alt="">
                            </slot>
                            <slot name="text">
                                <div class="title">
                                    <em v-if="must" style="color:red;">*</em>
                                    请上传{{ title }}
                                    <i class="iconfont icon-wenhaofill tip" :title="tip" v-if="tip"></i>
                                </div>
                            </slot>
                        </div>
                    </slot>
                </el-upload>
            </div>
            <!-- 显示 -->
            <template v-if="sendList.length > 0">
                <div v-for="(item, index) in sendList" :key="index" class="showbox" :style="fileStyle">
                    <el-image @error="error" :key="Math.random()" fit="scale-down" :src="imgBaseUrl + item.fileUrl"></el-image>
                    <div class="btnList" v-if="type == 'info'">
                        <div style="margin:0 74px;cursor: pointer;" @click.stop="preview(imgUrl = item.fileUrl)">预览</div>
                    </div>
                    <div class="btnList" v-else>
                        <div style="margin:0 30px;text-align:center;cursor: pointer;" @click.stop="preview(imgUrl = item.fileUrl)">预览</div>
                        <div style="margin:0 30px;text-align:center;cursor: pointer;" @click.stop="delmore(index)">删除</div>
                    </div>
                </div>
            </template>
        </div>
        <!-- 预览 -->
        <div @click="mask = false" v-if="mask"
            style="background:rgba(0, 0, 0, 0.5);;width:100vw;height:100vh; position: fixed;top:0px;left:0px;z-index: 999999">
            <el-image class="maskImg" @click.stop="" fit="scale-down" :src="imgBaseUrl + imgUrl"></el-image>
        </div>
    </div>
</template>

<script>
import ObsClient from '@/js/esdk-obs-browserjs-3.19.9.min.js'
export default {
    name: 'FileUpload',
    data() {
        return {
            imgBaseUrl: 'http://chenvafile.obs.cn-north-1.myhuaweicloud.com', //上传地址
            mask: false,
            fileList: [], //预览列表
            sendList: [], //发送列表
            loadingNumber: 0, //上传进度值
            hook: null, //上传钩子
            cp: null, //记录断点
            puase: false, //暂停了
            obsClient: null, //华为云对象
            showPrise: this.showPri, //下载按钮显示隐藏
            imgUrl: '',
            fileName: '',
            imgUrls: [],
            maxnum:'',
            isPdf: false,
        }
    },
    props: {
        type: {//info时显示详情样式
            type: String,
            default: ''
        },
        tip: {
            //问号提示
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: true
        },
        showPri: {
            type: Boolean,
            default: false
        },
        // 限制个数
        numLimit: {
            type: Number,
            default: 1
        },
        // 是否必填
        must: {
            type: Boolean,
            default: false
        },
        fromList: {
            type: Object,
            default: () => {
                return {}
            }
        },
        previewList: {
            type: Array,
            default: () => {
                return []
            }
        },
        limitNumber: {
            //限制大小M为单位
            type: Number,
            default: 30
        },
        accept: {
            //是否限制文件格式 为空则不限制(xls,xlsx,doc,docx,pdf,jpg,png,jpeg)
            type: String,
            default: '.png,.jpg,.jpeg,.image/png,.bmp'
        },
        width: {
            type: String,
            default: '175px'
        },
        height: {
            type: String,
            default: '130px'
        },
        border: {
            type: [String, Boolean],
            default: '1px dashed #c9c9c9'
        },
    },
    watch: {
        fromList: {
            //单个上传
            deep: true,
            immediate:true,
            handler(val) {
                if (val) {
                    if(val){
                        let url= JSON.parse(JSON.stringify(val)).fileUrl
                         const regex = /\.pdf(\?|$)/i;
                         this.isPdf= regex.test(url)
                         this.imgUrl = url
                    }
                } else {
                    this.imgUrl = {}
                }
            }
        },
        previewList: {
            //多个
            deep: true,
            handler(val) {
                if (val) {
                    this.fileList = JSON.parse(JSON.stringify(val))
                    this.sendList = JSON.parse(JSON.stringify(val))
                    this.maxnum = this.fileList.length
                } else {
                    this.fileList = []
                }
            }
        }
    },
    computed: {
        fileStyle() {
            return {
                width: this.width,
                height: this.height,
                border: this.border
            }
        }
    },
    methods: {
        error() {
            this.imgUrl = this.imgUrl
            this.$forceUpdate()
        },
        del() {
            this.imgUrl = ''
            this.$emit('imgUrl', {})
        },
        // 多个上传删除
        delmore(index) {
            this.sendList.splice(index, 1)
            this.cleanFiles()
            this.$nextTick(() => {
                let list = JSON.parse(JSON.stringify(this.sendList))
                this.maxnum = list.length
                this.$emit('fileList', list)
            })
        },
        preview() {
            if(this.isPdf){
                window.open(this.imgBaseUrl+ this.imgUrl)
            }else{
                this.mask = true
            }
        },
        //暂停上传
        puaseUpload() {
            this.puase = true
            this.hook.cancel()
        },
        //开始上传
        startUpload() {
            let file = {
                name: this.cp.sourceFile.name,
                raw: this.cp.sourceFile
            }
            this.puase = false
            this.handleUploadChange(file)
        },
        //清空已上传的文件列表
        cleanFiles() {
            this.$refs.fileup.clearFiles()
        },
        //出发点击函数
        triggerClick() {
            this.fileList = []
            this.$refs.upload.$el.click()
        },
        //超出上限 回调函数
        handleExceed() {
            this.$message({ message: '最多上传' + this.numLimit + '个文件', type: 'warning' })
            return
        },
        //覆盖element默认上传事件
        uploadHttpRequest() { },
        //上传之前的回调
        beforeUpload(file) {
            let limitSize = file.size / 1024 / 1024
            var FileExt = file.name.replace(/.+\./, '')
            let accept = this.accept.split(',')
            accept.map((e, i) => {
                accept[i] = e.replace('.', '')
            })
            let formatLimit = accept.indexOf(FileExt.toLowerCase())
            if (this.accept != '' && formatLimit == -1) {
                this.$refs["fileup"].clearFiles()//清除错误的数据
                this.$message({ message: '文件格式错误!', type: 'warning' })
                this.cleanFiles()
                return -1
            }
            if (this.limitNumber && limitSize > this.limitNumber) {
                if (this.limitNumber > 1024) {
                    this.$message({ message: `最大上传${this.limitNumber / 1024}G文件`, type: 'warning' })
                } else {
                    this.$message({ message: `最大上传${this.limitNumber}M文件`, type: 'warning' })
                }
                this.cleanFiles()
                return -1
            }
            ++this.maxnum
            if (this.maxnum > this.numLimit && this.numLimit != 1) {
                --this.maxnum
                setTimeout(() => {
                    this.$message({ message: '最多上传' + this.numLimit + '个文件', type: 'warning' })
                }, 10)
                this.cleanFiles()
                return -1
            }
        },
        //清除预览项
        cleanFile() {
            this.fileList = []
            this.sendList = []
        },
        //向父组件发送文件上传进度
        sendProgress(progress) {
            this.$emit('send-progress', progress)
        },
        //向父组件传送文件
        sendFile(url, name) {
            let data = {
                fileUrl: url,
                fileName: name
            }
            if (this.numLimit != '1') {
                //多个
                let fileList = []
                fileList.push(data)
                this.sendList.push(data)
                this.$emit('fileList', this.sendList)
            } else {
                //单个
                const regex = /\.pdf(\?|$)/i;
                this.isPdf= regex.test(url)
                this.imgUrl = url
                this.$emit('imgUrl', data)
            }
        },
        //文件上传
        handleUploadChange(file) {
            if (this.beforeUpload(file.raw) != -1) {
                let that = this
                let filename = file.name
                let config = {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    withCredentials: false
                }

                var key1 = 'project/' + new Date().getTime() + '/' + Math.random() * 10 + filename.substring(filename.lastIndexOf('.'))
                var callback = function (transferredAmount, totalAmount, totalSeconds) {
                    let progress = (transferredAmount * 100.0) / totalAmount
                    that.sendProgress(parseInt(progress))
                    that.loadingNumber = parseInt(progress)
                    if ((transferredAmount * 100.0) / totalAmount == 100) {
                        setTimeout(function () {
                            that.sendFile('/' + key1, filename)
                            that.cp = null
                        }, 200)
                    } else {
                    }
                } //断点续传版本
                if (!this.obsClient) {
                    this.obsClient = new ObsClient({
                        access_key_id: 'OCJBUXXHFNP8V51WO4RD',
                        secret_access_key: '2bhiXIaQQ9DUIr5Z0YV4Z6dLzETjjDHoMm60fp2N',
                        server: 'https://obs.cn-north-1.myhuaweicloud.com'
                    })
                }
                //断点续传
                let uploadData = {}
                let uploadFileData = {
                    Bucket: 'chenvafile',
                    Key: key1,
                    SourceFile: file.raw,
                    PartSize: 9 * 1024 * 1024,
                    ProgressCallback: callback,
                    EventCallback: function (eventType, eventParam, eventResult) {
                        // 处理事件响应
                    }
                }
                uploadData = uploadFileData
                this.obsClient.uploadFile(uploadData, function (err, result) {
                    // 出现错误，再次调用断点续传接口，继续上传任务
                    if (err) {
                    } else {
                        if (result.CommonMsg.Status < 300 && result.InterfaceResult) {
                        }
                    }
                })
            }
        }
    },
    mounted() { 
    }
}
</script>
<style lang="scss">
.uploadDrag-container {
    .el-image {
        height: 100%;
    }

    .file_upload {
        height: 100%;

        .el-upload {
            height: 100%;
        }
    }

    .el-icon-upload,
    .icon-tupian {
        display: inline-block;
        font-size: 50px;
        color: #c0c6d0;
        margin-top: 25px;
    }
}
</style>
<style lang="scss" scoped>
.uploadDrag-container {
    line-height: normal !important;
}

.icon-wenhaofill {
    display: inline-block;
    margin-left: 5px;
}

.maskImg {
    position: absolute;
    z-index: 9800;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.btnList {
    position: absolute;
    z-index: 99;
    display: flex;
    color: #1492ff;
    width: 100%;
    height: 26px;
    line-height: 26px;
    background: rgba($color: #fff, $alpha: 0.8);
    bottom: 0px;
    font-size: 12px;
}

.showbox {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
    margin: 0px 10px 10px 0;
}

.sbox {
    border-radius: 3px;
    text-align: center;
    position: relative;

    .title {
        font-size: 12px;
        color: #1492ff;
        margin-top: 8px;
    }
}
.pdfBox{
    padding: 20px;
}
</style>