<template>
  <div class="footer-container">
    <div class="conter wmain">
      <div class="rowBox">
        <div class="colBox">
          <div class="Itemh2 title">快速链接</div>
          <div class="content">
            <div class="item cu" @click="toPath('/information')">科创资讯</div>
            <div class="item cu" @click="toPath('/ShoppingMall')">企服商城</div>
            <div class="item cu" @click="toPath('/technologyManager')">科技管家</div>
          </div>
        </div>
        <div class="colBox">
          <div class="Itemh2 title">关于我们</div>
          <div class="content">
            <div class="item cu" @click="toPath('/about')">公司介绍</div>
            <div class="item cu" @click="toAgree(2)">隐私政策</div>
            <div class="item cu" @click="goFeedBack">意见反馈</div>
          </div>
        </div>
        <div class="colBox contact">
          <div class="Itemh2 title">联系我们</div>
          <div class="content">
            <div class="item">山东公司：0531-55562558 济南市工业南路华润置地广场1号楼10层</div>
            <div class="item">安徽公司：0551-62383777 合肥市蜀山区聚云路138号白天鹅国际商务中心</div>
            <div class="item">服务热线：400-066-8888</div>
            <div class="item">工作时间：工作日 9:00-17:00</div>
            <div class="item cu" @click="toAgree(4)">免责声明</div>
          </div>
        </div>
        <div class="codeBox">
          <div class="colBox">
            <div class="Itemh2 title" style="margin-bottom: 15px;">辰华科技公众号</div>
            <div class="content">
              <div class="imgBox">
                <img src="@/assets/img/home/gzh.jpg" alt="">
                <div class="btm">微信公众号</div>
              </div>
            </div>
          </div>
          <div class="colBox">
            <div class="Itemh2 title" style="margin-bottom: 15px;">辰华科技小程序</div>
            <div class="content">
              <div class="imgBox">
                <img src="@/assets/img/home/xcx.jpg" alt="">
                <div class="btm">微信小程序</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icpBox">
      <div class=" icpRow">
        <div class="logo">
          <img src="@/assets/img/headLogo.png" alt="">
        </div>
        <div class="">
          Copyright © 2023 辰华科技云平台鲁ICP备16044082号-3 鲁公网安备 37011202000596号 丨 山东辰华科技信息有限公司 版权所有 侵权必究
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();
// 跳转意见反馈页面===新页面打开
function goFeedBack() {
  let path = router.resolve({ path: "feedBackManagement" });
  window.open(path.href);
}

function toPath(val) {
  router.push(val);
}
const toAgree = (val) => {
  let path = router.resolve({
    path: "/agreement",
    query: {
      id: val,
    },
  });
  window.open(path.href);
};
</script>

<style lang="scss" scoped>
.footer-container {
  .conter {
    padding: 20px 50px 30px;
    .rowBox {
      display: flex;
      justify-content: space-between;
      .contact {
        width: 500px !important;
      }
      .codeBox {
        display: flex;
        gap: 50px;
      }
      .colBox {
        color: #999;

        .Itemh2 {
          margin: 0;
        }

        .title {
          font-size: 18px;
          // font-weight: bold;
          color: #000;
          margin-bottom: 20px;
        }

        .content {
          padding-left: 8px;

          .item {
            margin-bottom: 10px;
            font-size: 14px;
          }

          .imgBox {
            text-align: center;
            img {
              width: 120px;
              height: 120px;
            }
            .btm {
              // margin-top: 20px;
            }
          }
        }
      }
    }
    .cu {
      cursor: pointer;
    }
  }

  .icpBox {
    background: #ececec;
    padding: 6px 0;

    .icpRow {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #424242;

      .logo {
        margin-right: 20px;
        width: 130px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
