import QRCode from 'qrcode';
//格式化时间
export const parseTime = (value, format = 'day') => {
    var date = ''
    if (value) {
        date = new Date(value * 1)
    } else {
        date = new Date()
    }
    var year = date.getFullYear()
    /* 在日期格式中，月份是从0开始的，因此要加0
     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
     * */
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

    // 拼接
    // return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds//精确到秒

    let time = ''
    if (format == 'day') {
        time = year + '-' + month + '-' + day
    }
    if (format == 's') {
        time = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
    }
    if (format == 't') {
        time = date.getTime()
    }
    return time
}

// 获取uuid
export const getUuid = (len = 16, radix = 16) => {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
    var uuid = []
    var i
    radix = radix || chars.length
    if (len) {
        for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)]
    }
    let U = uuid.join('')
    return U
}

// 获取浏览器滚动条宽度
export const getScrollbarWidth = () => {
    // 创建一个元素，设置为不可见
    const scrollDiv = document.createElement('div');
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.width = '100px';
    scrollDiv.style.height = '100px';
    scrollDiv.style.overflow = 'scroll';
    document.body.appendChild(scrollDiv);
    // 获取滚动条宽度
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    // 移除创建的元素
    document.body.removeChild(scrollDiv);
    return scrollbarWidth;
}
/**
 * 生成二维码
 * @param {*} el -- canvas的id
 * @param {*} url -- 二维码内容
 * @param {*} opt -- 二维码参数
 * @param {*} callback -- 错误回调函数
 */
export const createQRcode = (el, url, opt, callback) => {
    let qrOpt = {
        width: 120,
        errorCorrectionLevel: 'H',
        margin: 2,
        color: {
            dark: '#000000ff',
            light: '#ffffffff'
        }
    }

    Object.assign(qrOpt, opt)
    let canvas = document.getElementById(el)
    QRCode.toCanvas(canvas, url, qrOpt, err => {
        if (callback && typeof callback === 'function') {
            callback(err, url, opt)
        }
    })
}


// 获取url参数
export const getUrlParams = (url) => {
    let params = {}
    if (url) {
        let urlStr = url.split('?')[1]
        let urlArr = {}
        try {
            urlArr = urlStr.split('&')
            urlArr.forEach(item => {
                params[item.split('=')[0]] = item.split('=')[1]
            });
        } catch (error) {
        }

    } else {
        params = {}
    }
    return params
}

// 脱敏
export const desensitization = (str, start, end) => {
    if (!str) return ''
    let len = str.length
    let startStr = str.slice(0, start)
    let endStr = str.slice(-end)
    let desensitizationStr = ''
    for (let i = 0; i < len - start - end; i++) {
        desensitizationStr += '*'
    }
    return startStr + desensitizationStr + endStr
}