<template>
    <div class="search_mywork" style="position: absolute;">
        <!-- 更多后 -->
        <div class="searchbox" v-if="isMore">
            <div class="typeTitle">
                <div class="search_center">
                    <div @click="backPbtn" class="fl">
                        <i class="iconfont icon-xitongfanhui"></i>
                    </div>
                    <div class="ml20">搜索历史记录</div>
                </div>
            </div>
            <el-input size="mini" style="width:340px;height: 28px;margin-top: 10px;" class="search_center" ref="input" v-model="keywords2" placeholder="搜索联系人/群组/话题/历史记录" @blur="search2()" @keyup.enter="search2" @clear="search2" @input="search2" onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;">
                <template #suffix>
                    <div class="cura" style="margin: 4px 6px 0 0;">
                        <i @click.stop="clear2" class="iconfont icon-biaoge-guanbi" style="font-size: 12px"></i>
                        <!-- <i class="el-input__icon el-icon-search" @click.stop="search2"></i> -->
                    </div>
                </template>
            </el-input>
            <div class="list list1" ref="ChoseIndex" v-if="moreList && moreList.length > 0">
                <div class="mt10 search_center11">
                    <div v-for="(item, index) in moreList" :key="index" class="listItem linkleft" :style="{ background:searchId==item.id? '#e9e9e9':''}" @click.stop="ChoseMailList(item, '5')">
                        <div class="fl ml10" style="margin-top: 8px;">
                            <TopImg :list="item.picList"></TopImg>
                        </div>
                        <div style="line-height:20px;margin:6px 0 0 10px;">
                            <!-- <div>{{item.chatName}}</div>
                            <div v-if="item.lastChatRecord" class="smalltitle">{{item.lastChatRecord}}</div> -->
                            <div style="width:200px;color: #000;" class="overone" v-html="keyGoodsName(keywords2,item.chatName)"></div>
                            <div class="smalltitle" v-html="keyGoodsName(keywords2,item.lastChatRecord)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="noImg">
                <img src='@/assets/img/nosearch.png' style="margin-top:100px" />
                <div style="font-size:12px;color:#656c7a">暂无搜索结果</div>
            </div>
        </div>
        <!-- 更多前 -->
        <div class="searchbox" v-else>
            <!-- @blur="search()" -->
            <el-input size="mini" class="search_center" style="width:340px;height: 28px;" ref="input" v-model="keywords" placeholder="搜索联系人/群组/历史记录" @keyup.enter="search" @clear="clear" @input="search" onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;">
                <template #suffix>
                    <div class="cura">
                        <i @click.stop="clear" class="iconfont icon-biaoge-guanbi" style="font-size: 12px"></i>
                        <!-- <i class="el-input__icon el-icon-search" @click.stop="search"></i> -->
                    </div>
                </template>
            </el-input>
            <div class="list" ref="ChoseIndex" v-if="keywords">
                <div class="mt10 search_center11" v-if="LinkmanListTemp.length || GroupList.length || TopicList.length || historyList.length">
                    <div v-if="LinkmanListTemp && LinkmanListTemp.length>0">
                        <div class="title ml10">联系人</div>
                        <div v-for="(item, index) in LinkmanList" :key="index" @click.stop="ChoseMailList(item,'1')" class="linkleft">
                            <div class="listItem">
                                <div class="fl ml10">
                                    <img :src="urlImg + item.headUrl" style="width: 36px;height: 36px;border-radius: 3px;"/>
                                </div>
                                <div style="line-height:20px;margin:6px 0 0 10px;">
                                    <!-- <div>{{item.mname}}</div> -->
                                    <div class="overone" style="width:200px;color: #000;" v-html="keyGoodsName(keywords,item.mname)"></div>
                                    <div class="smalltitle">
                                        <tooltipOver :content="item.orgName" className="width:240px;"></tooltipOver>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="more" v-if="LinkmanListTemp.length>5" @click.stop="getMore(1)">{{isMore1?'收起':'查看更多'}}</div>
                    </div>
                    <div v-if="GroupList && GroupList.length>0">
                        <div class="title ml10">群组</div>
                        <div v-for="(item, index) in GroupList" :key="index" @click.stop="ChoseMailList(item,'2')" class="linkleft">
                            <div class="listItem">
                                <div class="fl ml10" style="margin-top: 8px;">
                                    <TopImg :list="item.picList"></TopImg>
                                </div>
                                <div style="line-height:20px;margin:6px 0 0 10px;">
                                    <!-- <div style="width:200px;color: #000;" class="overone">{{item.chatName}}</div>
                                    <div v-if="item.lastChatRecord" class="smalltitle">{{item.lastChatRecord}}</div> -->
                                    <div class="overone" style="width:200px;color: #000;" v-html="keyGoodsName(keywords,item.chatName)"></div>
                                    <div class="smalltitle" v-html="keyGoodsName(keywords,item.lastChatRecord)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="more" v-if="GroupListTemp.length>5" @click.stop="getMore(2)">{{isMore2?'收起':'查看更多'}}</div>
                    </div>
                    <div v-if="TopicList && TopicList.length>0">
                        <div class="title ml10">话题</div>
                        <div v-for="(item, index) in TopicList" :key="index" @click.stop="ChoseMailList(item,'3')" class="linkleft">
                            <div class="listItem">
                                <div class="fl ml10" style="margin-top: 8px;">
                                    <TopImg :list="item.picList"></TopImg>
                                </div>
                                <div style="line-height:20px;margin:6px 0 0 10px;" class="fl">
                                    <!-- 正常 -->
                                    <!-- <div style="width:200px;color: #000;" class="overone">{{item.chatName}}</div> 
                                    <div v-if="item.lastChatRecord" class="smalltitle">{{item.lastChatRecord}}</div> -->
                                    <!-- 高亮2 -->
                                    <div v-if="item.chatName" style="width:200px;color: #000;" class="overone" v-html="keyGoodsName(keywords,item.chatName)"></div>
                                    <div class="smalltitle" v-html="keyGoodsName(keywords,item.lastChatRecord)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="more" v-if="TopicListTemp.length>5" @click.stop="getMore(3)">{{isMore3?'收起':'查看更多'}}</div>
                    </div>
                    <div v-if="historyList && historyList.length > 0">
                        <div class="title ml10">历史记录</div>
                        <div v-for="(item, index) in historyList" :key="index" @click.stop="ChoseMailListHis(item,'5')" class="linkleft">
                            <div class="listItem">
                                <div class="fl ml10" style="margin-top: 8px;">
                                    <TopImg :list="item.picList"></TopImg>
                                </div>
                                <div style="line-height:20px;margin:6px 0 0 10px;">
                                    <!-- <div style="width:200px;color: #000;" class="overone">{{item.chatName}}</div>
                                    <div v-if="item.lastChatRecord" class="smalltitle">{{item.lastChatRecord}}</div> -->
                                    <div style="width:200px;color: #000;" class="overone" v-html="keyGoodsName(keywords,item.chatName)"></div>
                                    <div class="smalltitle" v-html="keyGoodsName(keywords,item.lastChatRecord)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="more" v-if="historyListTemp.length>5" @click.stop="getMore(4)">查看更多></div>
                    </div>
                </div>
                <div v-else class="noImg1">
                    <img src='@/assets/img/nosearch.png' style="margin-top:100px" />
                    <div style="font-size:12px;color:#656c7a;margin-top: 10px;">暂无搜索结果</div>
                </div>
            </div>
            <div v-else class="noImg">
                <img src='@/assets/img/tableEmpty.png' style="margin-top:100px" />
                <!-- <div style="font-size:12px;color:#656c7a;margin-top: 10px;">暂无数据</div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import http from '@/api/http'
import { ict, sct, sgct } from '@/api/technologyManager'//接口
import TopImg from '../news/components/topimg.vue'
const emit = defineEmits(['dialogue','switch'])

const urlImg = ref(http.FileUrl)
const ChoseTabIndex = ref(0)
const keywords = ref('')
const keywords2 = ref('')
const TopicList = ref([])
const TopicListTemp = ref([])
const LinkmanList = ref([])
const LinkmanListTemp = ref([])
const GroupList = ref([])
const GroupListTemp = ref([])
const historyList = ref([])
const historyListTemp = ref([])
const searchId = ref('')
const disable = ref(false)
const isMore = ref(false)
const isMore1 = ref(false)
const isMore2 = ref(false)
const isMore3 = ref(false)
const moreList = ref([])
// 关键字高亮
function keyGoodsName(keyword, title) {
    let replaceReg = new RegExp(keyword, 'ig')
    let replaceString = `<span style="color: #1492ff">${keyword}</span>`
    let aTitle = title.replace(replaceReg, replaceString)
    return aTitle
}
// 查看更多
function getMore(index) {
    moreList.value = []
    ChoseTabIndex.value = index
    if (ChoseTabIndex.value == 1) {
        isMore1.value = !isMore1.value
        setTimeout(() => {
            if (isMore1.value) {
                LinkmanList.value = LinkmanListTemp.value
            } else {
                LinkmanList.value = JSON.parse(JSON.stringify(LinkmanListTemp.value)).slice(0, 5)
            }
        });
    } else if (ChoseTabIndex.value == 2) {
        isMore2.value = !isMore2.value
        setTimeout(() => {
            if (isMore2.value) {
                GroupList.value = GroupListTemp.value
            } else {
                GroupList.value = JSON.parse(JSON.stringify(GroupListTemp.value)).slice(0, 5)
            }
        });
    } else if (ChoseTabIndex.value == 3) {
        isMore3.value = !isMore3.value
        setTimeout(() => {
            if (isMore3.value) {
                TopicList.value = TopicListTemp.value
            } else {
                TopicList.value = JSON.parse(JSON.stringify(TopicListTemp.value)).slice(0, 5)
            }
        });
    } else if (ChoseTabIndex.value == 4) {
        keywords2.value = JSON.parse(JSON.stringify(keywords.value))
        isMore.value = true
        moreList.value = historyListTemp.value
    }
}
// 查询详情
function ChoseMailList(item, type) {
    searchId.value = item.id
    // 改变表头的背景色
    if(type == '1') {//单人会话
        sgct({userId: item.id}).then((res) => {
            if (res.code == 0) {
                disable.value = false
                if(res.data){
                    emit('dialogue', res.data, keywords2.value,type)
                }
                // setTimeout(() => {
                // });
            } else {
                ElMessage({
                    message: res.message,
                    type: 'error',
                    duration: 3000
                })
                disable.value = false
            }
        })
    } else {
        ict({chatId: item.id}).then((res) => {
            if (res.code == 0) {
                disable.value = false
                setTimeout(() => {
                    emit('dialogue', res.data, keywords2.value,type)   
                });
            } else {
                ElMessage({
                    message: res.message,
                    type: 'error',
                    duration: 3000
                })
                disable.value = false
            }
        })
    }
}
// 历史记录 点击
function ChoseMailListHis(val, type) {
    searchId.value = val.id
    keywords2.value = JSON.parse(JSON.stringify(keywords.value))
    moreList.value = historyListTemp.value
    isMore.value = true
    ChoseMailList(val,type)
}
// 清空
function clear() {
    emit('switch')
}
// 搜索结果
function search() {
    historyList.value = []
    LinkmanList.value = []
    GroupList.value = []
    sct({keyword: keywords.value}).then((res) => {
        if (res.code == '0') {
            //   custGroupList服务群列表 historyList历史列表 custGroupList联系人
            LinkmanListTemp.value = res.data.personList
            LinkmanList.value = JSON.parse(JSON.stringify(res.data.personList)).slice(0, 5)
            GroupListTemp.value = res.data.custGroupList
            GroupList.value = JSON.parse(JSON.stringify(res.data.custGroupList)).slice(0, 5)
            historyListTemp.value = res.data.historyList
            historyList.value = JSON.parse(JSON.stringify(res.data.historyList)).slice(0, 5)
        }
    })
}
// 搜索结果
function search2() {
    moreList.value = []
    sct({
        keyword: keywords2.value
    }).then((res) => {
        if (res.code == 0) {
            moreList.value = res.data.historyList
        }
    })
}
// 清空
function clear2() {
    backPbtn()
}
// 弹窗关闭
function backPbtn() {
    keywords2.value = ''
    isMore.value = false
}
onMounted(()=>{
    searchId.value = ''
    // input.value.focus()
})
</script>

<style scoped lang="scss">
.search_mywork{
.searchbox {
    // height: 640px;
    height: 668px;
    border-right: solid 1px #d6dbe2;
    width: 100%;
    background: #f5f5f5;
    margin-top: -29px;
    padding-top: 10px;
}
.search_center{
    padding: 5px 10px;
}
.search_center11{
    margin-left: 3px;
}
.ChoseTabIndex {
    color: #1492ff;
    border-bottom: #1492ff solid 1px;
}
.list {
    // padding-left: 10px;
    overflow: auto;
    height: 600px;
    width: 100%;
    overflow-y: auto;
    .listItem {
        height: 50px;
        line-height: 50px;
        display: flex;
        align-content: center;
        cursor: pointer;
        // padding-left: 10px;
    }
}
.list1{
    height: 550px;
}
.linkleft:hover {
    background: #eeeeee;
    cursor: pointer;
}
.more {
    color: #1492ff;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 10px;
}
.title {
    margin-bottom: 5px;
    color: #8992a4;
    font-size: 12px;
    color: #000;
}
.mt10 {
    margin-top: 10px;
}
.ml10 {
    margin-left: 10px;
}
.typeTitle {
    color: #000;
    font-size: 14px;
    padding-bottom: 6px;
    border-bottom: 1px solid #d5d7e0;
    // margin-bottom: 10px;
}
.smalltitle{
    color: #8590a6;
    font-size: 12px;
    height: 20px;
    overflow: hidden;
}
.ml20{
    margin-left: 20px;
}
.noImg{
    width: 200px;
    text-align: center;
    margin: auto;
    img{
        width: 200px;
    }
}
.noImg1{
    width: 150px;
    text-align: center;
    margin: auto;
    img{
        width: 150px;
    }
}
}
</style>

<style lang="scss">
.search_mywork{
.searchbox .el-input--mini .el-input__inner, .searchbox .el-input__inner{
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    box-shadow: none;
}
.searchbox .el-input--mini .el-input__icon{
    line-height: 21px;
}
.searchbox .el-input__wrapper, .searchbox .el-input{
    height: 28px !important;
    margin-top: -3px;
}
.searchbox .el-input__wrapper{
    border:1px solid #d5d7e0;
}
}
</style>