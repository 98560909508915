<template>
  <div class="online-container">
    <!-- 头部区域 -->
    <div class="topBox">
      <Top />
    </div>
    <!-- 中间内容区域 -->
    <div class="conter" id="conterId">
      <div class="loaingBox" v-if="loading">
        <div class="loadingItem"></div>
      </div>
      <!-- 头部时间区域 -->
      <!-- <Tag :value="new Date().toDateString()" v-if="new Date().toDateString()" /> -->
      <!-- 消息 -->
      <div v-for="(item, index) in msgList" :key="index">
        <div class="userBox" :class="[item.role == 1 ? 'Pleft' : 'Pright']" v-if="item.msgType != 3">
          <!-- 头部区域 -->
          <div class="head" v-if="item.role == 1">
            <img src="@/assets/img/service/serviceIcon.png" alt="">
          </div>
          <!-- 内容区域 -->
          <div class="content">
            <div class="nameBox" v-if="item.msgType != 3">
              <span class="date" v-if="item.role == 2 && item.sendTime" style="margin-right: 10px;">
                {{ parseTime(item.sendTime, 's') }}
              </span>
              <span class="name">{{ item.nickName }}</span>
              <span class="date" v-if="item.role == 1 && item.sendTime" style="margin-left: 10px;">
                {{ parseTime(item.sendTime, 's') }}
              </span>
            </div>
            <!-- 消息 -->
            <div class="msgBox" v-if="item.msgType == 1">
              {{ item.chatMsg }}
            </div>
            <!-- 猜你想问 -->
            <Problem v-if="item.msgType == 2" :option="item.chatMsg" @change="proBleChange" :sessionId="commParams.sessionId" :isHistory="item.isHistory" />
          </div>
          <!-- 头部区域 -->
          <div class="head" v-if="item.role == 2">
            <img src="@/assets/img/service/userIcon.png" alt="">
          </div>
        </div>
        <!-- 人工客服 -->
        <div class="m10">
          <Artificial :item="item" :sessionId="commParams.sessionId" v-if="item.msgType == 3" />
        </div>
      </div>
    </div>
    <!-- 底部输入框区域 -->
    <div class="bottom">
      <Bottom @submit="msgAssemble" v-if="!isCutOff" />
      <div v-else class="cutoffBox" @click="openCutOpen">已断开</div>
    </div>
  </div>
</template>

<script setup>
import Top from "./top.vue";
import Tag from "./tag.vue";
import Artificial from "./artificial.vue";
import Bottom from "./bottom.vue";
import Problem from "./problem.vue";
import {
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  provide,
  reactive,
  ref,
  watch,
} from "vue";
import { getPageList, getTouristId, sendMsg } from "@/api/online.js";
import { userStore, onlineStore } from "@/store";
import { createWebSocket, sendHandle } from "@/utils/ws.js";
import { parseTime, getUuid } from "@/utils/index.js";

// -------------------------------------------数据---------------------------------------------------
// 获取列表参数
const reqParams = reactive({
  // 每页显示数
  pageSize: 10,
  // 最后发送时间
  lastSendTime: "",
});
// 公共参数
const commParams = reactive({
  // 通道号，建立通道后的唯一ID
  sessionId: "",
  // 来源 1平台 2app 3小程序
  msgSource: "1",
});

// 发送消息参数
const addParams = reactive({
  // 消息ID，问答式消息
  msgId: "",
  // 消息类型 1普通消息 2问答式消息 3提示消息
  msgType: "1",
  // 消息内容
  chatMsg: "",
});
//
const serviceItem = ref({
  // 头像
  headUrl: "",
  // 名称
  nickName: "小辰",
});
const userItem = ref({
  // 头像
  headUrl: "",
  // 名称
  nickName: "",
});

// 消息列表
const msgList = ref([]);
// 匹配库
const libraryData = [];
// 人工客服匹配库
let levList = ref([]);
// 是否显示人工客服
const levShow = ref(false);
// 触发一次
const isFalg = ref(true);
// 是否在发送中
const isBeingSend = ref(true);
// 是否断开
const isCutOff = ref(false);
// 判断是否是第一条消息
const isFrist = ref(false);

const loading = ref(false);
const loadHeight = ref(0);

// -------------------------------------------页面加载触发---------------------------------------------------

onMounted(() => {
  getInit();
  let conterId = document.getElementById("conterId");
  conterId.addEventListener("scroll", scrollTopFn);
  window.addEventListener("storage", (event) => {
    if (event.key == "uuid") {
      if (onlineStore.wsId) {
        onlineStore.SET_ONLINE_CONNECTION(0);
        isCutOff.value = true;
        let params = onlineStore.params;
        params.type = 4;
        sendHandle(JSON.stringify(params));
      }
    }
  });
});
// --------------------------------------------监听------------------------------------------------------------
watch(
  () => onlineStore.wsMsg,
  (val) => {
    setTimeout(() => {
      let Arr = { ...val, ...serviceItem.value };
      msgList.value.push(Arr);
      // 滚动到底部
      nextTick(() => {
        scrollFn();
      });
    }, 500);
  }
);
// -------------------------------------------方法---------------------------------------------------
// 点击
const openCutOpen = () => {
  getInit();
};

// 猜你想问点击触发
const proBleChange = (val) => {
  if (isCutOff.value) {
    return;
  }
  addParams.msgId = val.id;
  msgAssemble(val.questionDesc, 2, 2);
};

// 点击发送按钮触发 val-值 role 1:客服 2:游客  type-类型 1普通消息 2问答式消息 3提示消息
const msgAssemble = async (val, role = 2, type = 1) => {
  if (!val || !isBeingSend.value) {
    return;
  }
  addParams.chatMsg = val;
  addParams.msgType = type;
  let params = {
    ...addParams,
    ...commParams,
  };
  msgList.value.push({
    chatMsg: val,
    role: role,
    msgType: 1,
    ...userItem.value,
  });
  isBeingSend.value = false;
  // 滚动到底部
  nextTick(async () => {
    await scrollFn();
    try {
      const res = await sendMsg(params);
      if (res.code == 0) {
        addParams.msgId = "";
      }
      isBeingSend.value = true;
    } catch (error) {
      isBeingSend.value = true;
    }
  });
};

// 滚动到底部
const scrollFn = (val) => {
  let conterId = document.getElementById("conterId");
  let scrollTop = val || conterId.scrollHeight;
  nextTick(() => {
    conterId.scrollTop = scrollTop + 200;
  });
};
// 初始化
const getInit = async () => {
  // 获取游客id
  let sessionId =
    userStore.user?.id || localStorage.getItem("sessionLocalStorageId") || "";
  commParams.sessionId = sessionId;
  userItem.value.nickName = userStore.user?.nickName || sessionId;
  if (!sessionId) {
    await getTouristIdApi();
  }
  let params = {
    type: 1,
    userId: `${commParams.sessionId}@onlineService@1`,
  };

  let uuid = localStorage.getItem("uuid");
  uuid = getUuid();
  localStorage.setItem("uuid", uuid);

  if (onlineStore.wsId) {
    isCutOff.value = false;
    if (!onlineStore.isOpen) {
      sendHandle(JSON.stringify(params));
    }
    params.type = 2;
    onlineStore.SET_PARAMS(params);
    onlineStore.SET_ONLINE_CONNECTION(1);
  } else {
    isCutOff.value = false;
    await createWebSocket();
  }
  onlineStore.SET_ISOPEN(true);
  await getList();
};
// 获取游客id
const getTouristIdApi = async () => {
  const res = await getTouristId();
  if (res.code == 0) {
    commParams.sessionId = res.data;
    localStorage.setItem("sessionLocalStorageId", res.data);
    userItem.value.nickName = res.data;
  }
};
// 滚动到顶部触发 下一页
const scrollTopFn = (e) => {
  let scrollTop = e.target.scrollTop;
  if (scrollTop < 20) {
    if (isFalg.value) {
      isFalg.value = false;
      if (!isFrist.value) {
        reqParams.lastSendTime = msgList.value[0].sendTime;
        getList();
      }
    }
  }
};
// 查询聊天记录列表
const getList = async (val) => {
  let params = {
    ...reqParams,
    ...commParams,
  };
  loading.value = true;
  const res = await getPageList(params);

  if (res.code == 0) {
    res.data = res.data.map((item) => {
      if (item.msgType == 2) {
        item.chatMsg = JSON.parse(item.chatMsg);
      }
      if (params.sessionId == item.userId) {
        item.role = 2;
        for (const key in userItem.value) {
          userItem.value[key] = item[key];
        }
      } else {
        item.role = 1;
        for (const key in serviceItem.value) {
          serviceItem.value[key] = item[key];
        }
      }
      item["isHistory"] = true;
      return item;
    });
    if (!params.lastSendTime) {
    }
    res.data.reverse();
    if (res.data.length < params.pageSize) {
      isFrist.value = true;
    }
    nextTick(() => {
      msgList.value.unshift(...res.data);
      let conterId = document.getElementById("conterId");
      let h = conterId.scrollHeight - loadHeight.value;
      scrollFn(h);
      loadHeight.value = conterId.scrollHeight;
      if (res.data.length) {
        isFalg.value = true;
        loading.value = false;
      }
    });
  }else{
    loading.value = false;
  }
};

onBeforeUnmount(() => {
  localStorage.removeItem("uuid");
});

// 注入
provide("msgAssemble", msgAssemble);
provide("msgList", msgList);
</script>

<style lang="scss" scoped>
.online-container {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 200000;
  width: 450px;
  height: 500px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .conter {
    flex: 1;
    background-color: #f5f5f5;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;

    /* 自定义整个滚动条 */
    &::-webkit-scrollbar {
      width: 8px;
    }

    /* 自定义滚动条轨道 */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.1);
    }

    /* 自定义滚动条的滑块（thumb） */
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      /* 设置滑块的背景颜色 */
    }

    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      // border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgba(0, 0, 0, 0.1);
    }

    .userBox {
      display: flex;
      width: 100%;
      margin-top: 5px;
      margin-bottom: 10px;

      .head {
        width: 30px;
        height: 30px;
        background-color: #4e9aff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
      }

      .content {
        flex: 1;

        .nameBox {
          padding: 5px 0;
          font-size: 12px;
          color: #999;
        }

        .msgBox {
          padding: 5px 10px;
          box-sizing: border-box;
          background: #fff;
          max-width: 80%;
        }
      }
    }

    .Pleft {
      float: left;

      .head {
        margin-right: 10px;
      }

      .msgBox {
        float: left;
      }
    }

    .Pright {
      float: right;

      .head {
        margin-left: 10px;
      }

      .nameBox {
        text-align: right;
      }

      .msgBox {
        float: right;
      }
    }
  }
  .m10 {
    margin: 10px 0;
  }
  .cutoffBox {
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: red;
    cursor: pointer;
  }
  .loaingBox {
    height: 20px;
    background: transparent;
    display: flex;
    justify-content: center;
    .loadingItem {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid red;
      border-left: none;
      border-bottom: none;
      animation: loading 1s linear infinite;
    }
  }
  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>