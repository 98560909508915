// 通用附件预览下载
import http from '@/api/http'
import { showView } from '@/api'
import { ElMessage } from 'element-plus'
// 预览
export function previewFile(fileUrl) {
    showView({
        fileUrl: fileUrl,
    }).then((res) => {
        if (res.code == 0) {
            window.open(res.data)
        } else {
            ElMessage({ message: "该附件格式不支持在线预览！", type: "warning" })
        }
    })
}
// 下载
export function downloadFile(fileUrl, fileName) {
    let url = http.baseUrl + 'mall/commonUtil/downFile?fileUrl=' + fileUrl + '&fileName=' + fileName
    window.open(url, '_self')
}