<template>
    <div>
        <div class="move_back_big bigInput_box" :style="fullscreenCon?'width:1500px; height: 96vh;':'width: 640px; height: 660px;'">
            <div class="backtopMove">
                <div @click="backPbtn" class="fl ml20 cura">
                    <i class="iconfont icon-suoxiao"></i>
                </div>
                <div class="fl ml10">{{title}}</div>
            </div>
            <div class="list_big" style="margin-top:10px">
                <!-- 发送框 -->
                <div class="mywork_input_big_box">
                    <el-input maxlength="5000" type="textarea" autosize placeholder="请输入" v-model="detailMessage1" @change="changeBigDetail1">
                    </el-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { conversationStore } from '@/store'

const fullscreenCon = ref(false)
const detailMessage1 = ref('')
const emit = defineEmits(['changeBigDetail', 'changeBigDetailTemp'])
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    detailMessage:{
        type: String,
        default: ''
    }
})
watch(() => conversationStore.isFullscreen, (val) => {
    fullscreenCon.value = val
}, { immediate: true })
watch(() => props.detailMessage, (val) => {
    detailMessage1.value = val
}, { immediate: true })

// 弹窗关闭
function backPbtn() {
    emit('changeBigDetail',detailMessage1.value)
}
function changeBigDetail1() {
    emit('changeBigDetailTemp',detailMessage1.value)
}
</script>

<style scoped lang="scss">
.list_big {
    // height: 640px;
    // width: 96%;
    margin: auto;
    height: 610px;
    overflow-y: auto;
}
.move_back_big {
    // width: 642px;
    // height: 640px;
    background: #fff;
    position: absolute;
    top: 0;
    z-index: 20;
    margin-left: 2px;
    .transfer__body .transfer-pane {
        width: 46%;
    }
}
.backtopMove {
    cursor: pointer;
    overflow: hidden;
    font-size: 16px;
    border-bottom: 1px solid #d5d7e0;
    padding-bottom: 10px;
    margin-top: 10px;
    color: #000;
}
.bottom_btn {
    float: right;
    margin-right: 20px;
}
.ml10{
    margin-left: 10px;
}
.ml20{
    margin-left: 20px;
}
</style>
<style>
.bigInput_box .mywork_input_big_box .el-textarea__inner{
    border: 1px solid #fff !important;
    box-shadow: none;
}
</style>