import { defineStore } from 'pinia'
import { saveSpPointsInfo } from '@/api/information.js'
import { parseTime } from '@/utils/index'
const integralStore = defineStore('integral', {
    state: () => ({
        timeId: null
    }),
    actions: {
        // 设置浏览积分
        setIntegral(data) {
            return new Promise((resolve, reject) => {
                let params = {
                    secondsReq: getTime(),
                    ...data
                }
                saveSpPointsInfo(params).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 设置浏览时间
        setPreviewTime() {
            let por = localStorage.getItem('timerPor')
            let num = 0
            if (!por) {
                let params = {
                    [num]: {
                        stp: parseTime(new Date(), 't'),
                        etp: parseTime()
                    }
                }
                por = localStorage.setItem('timerPor', JSON.stringify(params))
            }
            let duration = 60 * 1000 * 10

            this.timeId = setInterval(() => {
                por = localStorage.getItem('timerPor')
                // 加载多少次
                num = Object.keys(JSON.parse(por))[0]
                // 获取第一次存储的数据
                let value = Object.values(JSON.parse(por))[0]
                // 获取第一次存储的时间--开始时间
                let vDate = parseTime(new Date(value.etp), 't')
                // 获取当前时间--
                let currentTime = parseTime(new Date(), 't')
                // 获取当前时间
                let cDate = parseTime(new Date(), 't')

                // 判断当前时间和第一次存储的时间差是否大于一天
                if (cDate - vDate > 60 * 1000 * 60 * 24) {
                    num = 0
                    let params = {
                        [num]: {
                            stp: parseTime(new Date(), 't'),
                            etp: parseTime()
                        }
                    }
                    localStorage.setItem('timerPor', JSON.stringify(params))
                    por = localStorage.getItem('timerPor')
                }
                value = Object.values(JSON.parse(por))[0]
                if (currentTime - value.stp >= duration) {
                    if (num == 0) {
                        this.setIntegral({ saTe: 2 })
                    }
                    num++
                    let params = {
                        [num]: {
                            stp: parseTime(new Date(), 't'),
                            etp: parseTime()
                        }
                    }
                    localStorage.setItem('timerPor', JSON.stringify(params))
                    clearInterval(this.timeId)
                }
            }, 1000);
        },
    }
})

export default integralStore

function getTime() {
    let date = new Date();
    var year = date.getFullYear().toString()
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    let t = year + month.toString() + day.toString() + hours.toString() + minutes.toString() + seconds.toString()
    return t
}