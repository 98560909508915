<template>
    <div class="nav-container">
        <div class="conter">
            <div class="rowBox" v-if="!headStore.kjgjHeader">
                <div class="colBox title" v-for="item in navList" :class="{ active: item.path === actrveValue }"
                    :key="item.dictCode" @click="goPage(item)">
                    {{ item.dictName }}
                </div>
                <!-- <div class="colBox">
                    <el-dropdown>
                        <span class="title">
                            <div class="flexh">
                                关于我们
                                <el-icon class="el-icon--right">
                                    <arrow-down />
                                </el-icon>
                            </div>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item>公司介绍</el-dropdown-item>
                                <el-dropdown-item>隐私政策</el-dropdown-item>
                                <el-dropdown-item @click="goFeedBack">意见反馈</el-dropdown-item>
                                <el-dropdown-item>联系我们</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div> -->
            </div>
            <div v-else class="rowBox">
                <div class="colBox title" v-for="(itemkj, indexkj) in kjgjList" :key="indexkj" @click="goKjMenu(itemkj)"
                    :class="{ active: itemkj.path === actrveValue }">
                    {{ itemkj.dictName }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { headStore } from '@/store'
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { userStore } from '@/store';
import { getToken } from '@/utils/auth';


//---------------------------------数据-------------------------------------------------------
const router = useRouter()
const route = useRoute()

const actrveValue = ref('')


const navList = [
    { dictName: '首页', path: '/index' },
    { dictName: '科创资讯', path: '/information' },
    { dictName: '企服商城', path: '/ShoppingMall' },
    { dictName: '科技管家', path: '/technologyManager' },
    { dictName: '关于我们', path: '/about' },
]
// 有安全管理的权限
const kjgjList = ref([])
const kjgjList1 = [
    { dictCode: '1', dictName: '项目进度', path: '/projectProcess' },
    { dictCode: '2', dictName: '订单管理', path: '/orderList' },
    { dictCode: '3', dictName: '安全管理', path: '/securityManage' },
    { dictCode: '5', dictName: '科创图谱', path: '/dataResources' }
]
// 没有安全管理的权限
const kjgjList2 = [
    { dictCode: '1', dictName: '项目进度', path: '/projectProcess' },
    { dictCode: '2', dictName: '订单管理', path: '/orderList' },
    // { dictCode: '3', dictName: '安全管理', path: '/securityManage' },
    { dictCode: '5', dictName: '科创图谱', path: '/dataResources' }
]

//---------------------------------监听-------------------------------------------------------
function getkjgjList() {
    if (userStore.userJurisdiction.includes('16e38ab691a611efa140c025a5a79333')) {
        kjgjList.value = kjgjList1
    } else {
        kjgjList.value = kjgjList2
    }
}
watch(() => route.path, (newVal) => {
    actrveValue.value = newVal
}, { immediate: true, deep: true })
watch(() => headStore.kjgjHeader, (val) => {
   if(getToken() && val) {
        userStore.setUserJur()
    }
}, { immediate: true })
watch(() => userStore.userJurisdiction, (val) => {
    getkjgjList()
}, { immediate: true })
//---------------------------------方法-------------------------------------------------------
// 企服商城
function goPage(val) {
    actrveValue.value = val.path
    if (val.path) {
        router.push(val.path)
    }
}

// 跳转科技管家其他页面
function goKjMenu(val) {
    actrveValue.value = val.path
    router.push(val.path)
}
// 跳转意见反馈页面===新页面打开
function goFeedBack() {
    let path = router.resolve({ path: 'feedBackManagement' })
    window.open(path.href)
}
</script>

<style lang="scss" scoped>
.nav-container {
    height: 100%;

    .conter {
        height: 100%;
        .rowBox {
            height: 100%;
            display: flex;
            align-items: center;

            .colBox {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 104px;
                height: 100%;
                position: relative;
                &:hover {
                    color: #1492ff;
                }
            }

            .title {
                font-size: 16px;
                color: #333;
                cursor: pointer;
            }
        }

        .active {
            // font-size: 20px !important;
            font-weight: bold !important;
            color: #1492FF !important;
            background: linear-gradient(180deg, #FFFFFF 21%, #A9D7FF7F 100%);
            &::after{
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                transform: translate(-50%,0);
                bottom: 0;
                width: 90%;
                height: 3px;
                background: #1492FF;
            }
        }
    }
}
</style>