<template>
  <div class="enterpriseCertification-container">
    <div class="conter" :class="{ wmain: !props.isKjgj }">
      <div class="h1">
        <div v-if="sourceType != 'info'">填写企业认证信息</div>
        <div v-else>企业认证信息</div>
      </div>
      <div class="tipBox tipCardContainer" style="padding:  4px 63px;">
        <template v-if="sourceType != 'info'">
          <p>1、营业执照照片或扫描件，企业名称必须与您填写的企业名称一致；图片文字需清晰可见，内容真实有效，不得做任何修改；</p>
          <p>2、<span class="blueText" @click="openPower"> 点击下载授权书</span>，填写完成后拍照上传，注意授权书填写完整，拍摄清晰，使用手写签名和实体公章；</p>
          <p>3、支持JPG\JPEG\PNG格式，大小不超过5M；</p>
          <p>4、如未通过审核，请仔细阅读退回通知，并依据退回通知内容进行修改。</p>
          <p>5、审核时间周一至周五9:00--17:00（晚17点后提交会在次日9点后审核认证），节假日提交认证则会延期至工作日。</p>
          <p>6、如果您需要加急尽快完成企业认证，可通过‘在线客服’或400-066-8888联系我们.</p>
          <p>7、负责人姓名与手机号一一对应，若修改负责人姓名后，则与该手机号对应的姓名均同步修改，请谨慎操作！</p>
        </template>
        <p v-else> 如企业信息发生变化或信息不准确，可通过‘在线客服’或400-066-8888联系我们。</p>
      </div>
      <div class="content">
        <el-form ref="formRef" :model="reqPrams" :rules="rules" :label-width="fromLabelWidth" size="large" status-icon :disabled="sourceType == 'info'">
          <el-form-item label="企业名称" prop="companyName" :error="errorTextMap['companyName']">
            <el-input v-if="props.isKjgj == '1'" v-model="reqPrams.companyName" placeholder="请输入企业名称" disabled />
            <el-select v-else v-model="reqPrams.companyName" filterable remote reserve-keyword placeholder="请输入企业名称" :remote-method="remoteMethod" :loading="nameLoading" clearable allow-create @change="selectChange" size="default" class="selectBox">
              <el-option v-for="item in nameOptions" :key="item.companyInfoId" :label="item.companyName" :value="item.companyName" @click="nameChange(item)" />
            </el-select>
          </el-form-item>
          <el-form-item label="信用代码" prop="organizationCode" :error="errorTextMap['organizationCode']">
            <el-input v-model="reqPrams.organizationCode" placeholder="请输入信用代码" @blur="xydmBlur" maxlength="18" :disabled="isSelectCode || props.isKjgj == '1'" />
          </el-form-item>
          <div class="tip" :style="{ marginLeft: fromLabelWidth }">
            <div v-if="iscurrentNameAuth == 2">您所在的企业已通过认证，请勿重复提交。</div>
            <div v-if="iscurrentNameAuth == 1">您所在的企业已提交认证，审核通过后可进行绑定，请耐心等待。</div>
          </div>
          <el-form-item label="负责人姓名" prop="headName" :error="errorTextMap['headName']">
            <el-input v-model="reqPrams.headName" placeholder="请输入负责人姓名" maxlength="10" :disabled="props.isKjgj == '1'" />
          </el-form-item>
          <el-form-item label="负责人身份证号" prop="headCreditCode" :error="errorTextMap['headCreditCode']">
            <!--  :disabled="props.isKjgj == '1' && reqPrams.proxyCert != '1'"  -->
            <el-input v-model="reqPrams.headCreditCode" placeholder="请输入负责人身份证号" maxlength="18" />
          </el-form-item>
          <el-form-item label="认证材料" prop="businessLicenseNames" :error="errorTextMap['businessLicenseNames'] + errorTextMap['enAuthLetterName']" required>
            <div class="rowBox">
              <div class="colBox">
                <!--  :type="(props.isKjgj == '1' && reqPrams.proxyCert != '1')||sourceType=='info'?'info':''" -->
                <uploadDrag @imgUrl="businessChange" :fromList="businessUpArr" title="上传营业执照（正面照片）" :limitNumber="50" :type="sourceType=='info'?'info':''" accept='.png,.jpg,.jpeg,.image/png,.bmp' />
              </div>
              <div class="colBox">
                <!-- :type="(props.isKjgj == '1' && reqPrams.proxyCert != '1')||sourceType=='info'?'info':''" -->
                <uploadDrag @imgUrl="prwpChange" :fromList="powerUpArr" title="上传企业授权书（盖公章）" :limitNumber="50" :type="sourceType=='info'?'info':''" accept='.png,.jpg,.jpeg,.image/png,.bmp,.pdf' />
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="props.isKjgj == '1'" label="法人姓名" prop="enterpriseLegalPerson" :error="errorTextMap['enterpriseLegalPerson']">
            <el-input disabled v-model="reqPrams.enterpriseLegalPerson" placeholder="请输入负责人手机号" maxlength="11" />
          </el-form-item>
          <el-form-item v-if="props.isKjgj == '1'" label="法人手机号" prop="legalPersonPhone" :error="errorTextMap['legalPersonPhone']">
            <el-input v-model="reqPrams.legalPersonPhone" placeholder="请输入负责人手机号" maxlength="11" :disabled="props.isKjgj == '1' && reqPrams.certStatus != '4'" />
          </el-form-item>
          <el-form-item v-if="props.isKjgj != '1'" label="负责人手机号" prop="headPhoneNumber" :error="errorTextMap['headPhoneNumber']">
            <el-input v-model="reqPrams.headPhoneNumber" placeholder="请输入负责人手机号" maxlength="11" @blur="phoneBlur" />
          </el-form-item>
          <template v-if="sourceType != 'info'">
            <el-form-item label="短信验证码" prop="validCode" :error="errorTextMap['validCode']">
              <div class="rowBox">
                <el-input v-model="reqPrams.validCode" placeholder="请输入短信验证码" class="input" />
                <CodeFrom :value="props.isKjgj == '1'?reqPrams.legalPersonPhone:reqPrams.headPhoneNumber" :codeType="codeType" />
              </div>
            </el-form-item>
          </template>
        </el-form>
        <div class="bottomBox">
          <div class="checkBox">
            <el-checkbox v-model="checked" size="large" />
            我已阅读并同意 <span class="blueText" @click="openAgreen">《辰华科技云平台企业认证与绑定服务使用须知》</span>
          </div>
          <el-button type="primary" class="btnBox" size="large" @click="loginFrom" :loading="loadding">提交</el-button>
        </div>
      </div>
    </div>
    <!-- 授权书弹出框 -->
    <Empower :show="showEmpower" @close="showEmpower = false" :proData="reqPrams" v-if="showEmpower" />
  </div>
</template>

<script setup>
import CodeFrom from "@/components/code/index.vue";
import uploadDrag from "@/components/uploads/uploadDrag.vue";
import Empower from "./empower.vue";
import { headStore, elMessage, formValidate } from "@/store";
import { onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { checkCreditCode, checkPhone } from "@/utils/test";
import {
  getEnterpriseNameList,
  getCurrentEnterIsAuthApi,
  sumbitEnterpriseAuth,
  getEnterpriseInfoApi,
  findNameByPhone,
} from "@/api/enterprise.js";
// ----------------------------数据----------------------------------------------------------
const props = defineProps({
  isKjgj: {
    // 科技管家弹窗引用是1 默认2
    type: String,
    default: "2",
  },
  userIdentityData: {
    // 科技管家弹窗引用===传过来表单参数
    type: Object,
    default: {},
  },
  tipWidth: {
    type: String,
    default: "820px",
  },
  // 是否跳转
  isRouter: {
    type: Boolean,
    default: true,
  },
  codeType: {
    type: String,
    default: "cm_ct", //默认企业认证：cm_ct 科技管家是法人认证：fr_ct
  },
});
const emit = defineEmits("submit");

const route = useRoute();
const router = useRouter();

// 上传自定义校验
const checkUpLoad = (rule, value, callback) => {
  if (!reqPrams.businessLicenseUrl || !reqPrams.enAuthLetterUrl) {
    callback(new Error("请上传营业执照和授权书"));
  } else {
    callback();
  }
};

const rules = reactive({
  companyName: [
    {
      required: true,
      message: "请输入企业信息名称",
      trigger: ["blur", "change"],
    },
  ],
  organizationCode: [
    { required: true, message: "请输入信用代码", trigger: ["blur", "change"] },
    {
      required: true,
      message: "请输入正确的统一社会信用代码",
      pattern: /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/,
      trigger: ["blur", "change"],
    },
  ],
  headName: [
    {
      required: true,
      message: "请输入负责人姓名",
      trigger: ["blur", "change"],
    },
  ],
  headCreditCode: [
    {
      required: true,
      message: "请输入负责人身份证号",
      trigger: ["blur", "change"],
    },
    {
      required: true,
      message: "请输入正确的身份证号",
      pattern:
        /^[1-9]\d{5}(18|19|20|21|22)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|[Xx])$/,
      trigger: ["blur", "change"],
    },
  ],
  headPhoneNumber: [
    {
      required: true,
      message: "请输入负责人手机号码",
      trigger: ["blur", "change"],
    },
    {
      required: true,
      message: "请输入正确的手机号码",
      pattern: /^1[3-9]\d{9}$/,
      trigger: ["blur", "change"],
    },
  ],
  legalPersonPhone: [
    { required: true, message: "请输入法人手机号码", trigger: "blur" },
    {
      required: true,
      message: "请输入正确的手机号码",
      pattern: /^1[3-9]\d{9}$/,
      trigger: "blur",
    },
  ],
  validCode: [
    {
      required: true,
      message: "请输入短信验证码",
      trigger: ["blur", "change"],
    },
  ],
  businessLicenseNames: [
    { validator: checkUpLoad, trigger: ["blur", "change"] },
  ],
});
// 提交数据
const reqPrams = reactive({
  // 企业信息名称
  companyName: "",
  // 信用代码
  organizationCode: "",
  // 负责人姓名
  headName: "",
  // 负责人身份证号
  headCreditCode: "",
  // 营业执照名称
  businessLicenseNames: "",
  // 营业执照url
  businessLicenseUrl: "",
  // 企业授权书名称
  enAuthLetterName: "",
  // 企业授权书url
  enAuthLetterUrl: "",
  // 负责人手机号码
  headPhoneNumber: "",
  //  验证码
  validCode: "",
});
// 企业名称列表
const nameOptions = ref([]);
// 获取地址栏信息
const dataObj = ref({});
// 来源
const sourceType = ref("");
// 表单label宽度
const fromLabelWidth = ref("125px");
// 是否是选择企业后显示的信用代码
const isSelectCode = ref(false);
// 是否同意协议
const checked = ref(false);
// 企业名称加载中效果
const nameLoading = ref(false);
// 当前企业是否已经提交过认证 1待审核 2已认证 其他的不用进行提醒
const iscurrentNameAuth = ref(0);
// 按钮加载中效果
const loadding = ref(false);
// ref
const formRef = ref(null);
// 授权书弹出框
const showEmpower = ref(false);
// 上传图片回显
const businessUpArr = ref([]);
// 上传图片回显
const powerUpArr = ref([]);

// 错误提示信息
const errorTextMap = ref({});
// 科技管家给表单传值
watch(
  () => props.userIdentityData,
  (val) => {
    if (val) {
      for (const key in val) {
        reqPrams[key] = val[key];
        businessUpArr.value == [];
        powerUpArr.value == [];
        businessUpArr.value = {
          fileName: "",
          fileUrl: val.businessLicenseUrl,
        };
        powerUpArr.value = {
          fileName: "",
          fileUrl: val.enAuthLetterUrl,
        };
      }
    }
  },
  { immediate: true }
);
// --------------------------- 页面加载触发---------------------------------------------------
onMounted(() => {
  getInit();
});
// ----------------------------方法----------------------------------------------------------
// 根据手机号码查询姓名
const phoneBlur = () => {
  let flag = checkPhone(reqPrams.headPhoneNumber);
  if (!flag) {
    return;
  }
  findNameByPhone({
    phoneNumber: reqPrams.headPhoneNumber,
    quType: 1,
  }).then((res) => {
    if (res.code == 0) {
      if (res.data) {
        if (res.data && typeof res.data == "string") {
          reqPrams.headName = res.data;
        }
      }
    }
  });
  //
};
// 点击提交按钮触发
const loginFrom = () => {
  emit("submitKJGJ", reqPrams);
  formRef.value.validate((valid) => {
    if (valid) {
      if (!checked.value) {
        elMessage.error("请同意并勾选协议");
        return;
      }
      loadding.value = true;
      errorTextMap.value = {};
      // 科技管家提交回调
      if (props.isKjgj == "1") {
        emit("submitKJGJ", reqPrams);
        return;
      }
      // 科技管家之外的提交
      sumbitEnterpriseAuth(reqPrams)
        .then((res) => {
          if (res.code == 0) {
            elMessage.success("提交成功，请耐心等待审核结果");
            emit("submit", reqPrams);
            if (props.isRouter) {
              router.push("/PersonalCenter?id=8");
            }
          } else {
            elMessage.error(res.message);
          }
          loadding.value = false;
        })
        .catch((err) => {
          loadding.value = false;
          if (err.response && err.response.data && err.response.data.data) {
            let arr = err.response.data.data;
            errorTextMap.value = formValidate(arr);
          }
        });
    }
  });
};
// 点击下载授权书按钮触发
const openPower = () => {
  showEmpower.value = true;
};
// 营业执照上传成功触发
const businessChange = (e) => {
  reqPrams.businessLicenseNames = e.fileName;
  reqPrams.businessLicenseUrl = e.fileUrl;
};
// 企业授权书上传成功触发
const prwpChange = (e) => {
  reqPrams.enAuthLetterName = e.fileName;
  reqPrams.enAuthLetterUrl = e.fileUrl;
};
// 企业名称下拉框改变触发
const selectChange = (val) => {
  if (!val) {
    isSelectCode.value = false;
  }
  reqPrams.organizationCode = "";
};
// 企业名称下拉框选中
const nameChange = (val) => {
  if (val) {
    isSelectCode.value = true;
    reqPrams.organizationCode = val.organizationCode;
    getCurrentEnterIsAuth(val.organizationCode);
  } else {
    reqPrams.organizationCode = "";
  }
};

// 查询当前企业是否已经提交过认证
const getCurrentEnterIsAuth = (val) => {
  if (!val) {
    return;
  }
  getCurrentEnterIsAuthApi({ organizationCode: val }).then((res) => {
    if (res.code == 0) {
      iscurrentNameAuth.value = res.data.auditStatus;
    }
  });
};
// 信用代码失去焦点触发
const xydmBlur = () => {
  if (sourceType.value == "info") {
    return;
  }
  if (!checkCreditCode(reqPrams.organizationCode)) {
    return elMessage.error("请输入正确的统一社会信用代码");
  }
  getCurrentEnterIsAuth(reqPrams.organizationCode);
};
// 企业名称下拉框搜索
const remoteMethod = (key) => {
  if (!key) {
    return;
  }
  nameLoading.value = true;
  getEnterpriseNameList({ keywords: key })
    .then((res) => {
      if (res.code == 0) {
        nameOptions.value = res.data;
      }
      nameLoading.value = false;
    })
    .catch(() => {
      nameLoading.value = false;
    });
};
// 获取数据
const getInit = () => {
  if (Object.keys(route.query).length) {
    reqPrams.companyName = route.query.companyName;
    reqPrams.organizationCode = route.query.organizationCode;
    dataObj.value = route.query;
    sourceType.value = route.query.type || "";
    if (route.query.type == "info") {
      getEnterpriseInfo(dataObj.value.certId);
    }
  }
};

// 查询企业信息
const getEnterpriseInfo = (id) => {
  getEnterpriseInfoApi(id).then((res) => {
    if (res.code == 0) {
      for (const key in reqPrams) {
        reqPrams[key] = res.data[key];
      }
      businessUpArr.value = {
        fileName: res.data.businessLicenseNames,
        fileUrl: res.data.businessLicenseUrl,
      };
      powerUpArr.value = {
        fileName: res.data.enAuthLetterName,
        fileUrl: res.data.enAuthLetterUrl,
      };
    }
  });
};
// 点击协议
const openAgreen = () => {
  let path = router.resolve({
    path: "agreement",
    query: {
      id: "5",
    },
  });
  window.open(path.href);
};
// 父页面调用此页面的回调，用于改变loading等
const resetCall = () => {
  loadding.value = false;
};
defineExpose({ resetCall });
</script>

<style lang="scss" scoped>
.enterpriseCertification-container {
  padding: 10px 0;
  .conter {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 20px 0;
    box-sizing: border-box;

    .tipBox {
      margin: 10px 0 20px;
      width: v-bind("props.tipWidth");
    }

    .content {
      width: 498px;

      .tip {
        color: red;
      }

      .rowBox {
        width: 100%;
        display: flex;
        align-items: center;

        .colBox {
          &:first-child {
            margin-right: 20px;
          }
        }

        .input {
          flex: 1;
          margin-right: 20px;
        }
      }

      .checkBox {
        :deep(.el-checkbox) {
          margin-right: 10px;
        }
      }

      .btnBox {
        width: 60%;
        margin-left: 61px;
        margin-top: 10px;
      }
    }
    :deep(.selectBox) {
      .is-disabled .el-select__placeholder {
        color: #333 !important;
      }
    }
  }

  :deep(.fileUploadClass) {
    width: 200px;
    height: 100%;
    background: #fff;
    border: 1px dashed #ccc;

    .el-button {
    }
  }
  .bottomBox{
    margin-left: 59px;
  }
}
</style>