<template>
    <div class="artificial-container">
        <div class="conter">
            <Tag value="''">
                <span v-html="prop.item.chatMsg"></span>
            </Tag>
        </div>
        <Tag value="正在匹配中..." v-if="prop.item.status == 1"></Tag>
        <Tag value="连接成功" v-if="prop.item.status == 2"></Tag>
        <Tag value="连接失败" v-if="prop.item.status == 3"></Tag>
    </div>
</template>

<script setup>
import { inject, nextTick, onMounted, reactive, ref } from 'vue';
import { assignService } from '@/api/online'
import {elMessage} from '@/store'
import Tag from './tag.vue'

// -------------------------------------------数据---------------------------------------------------
const prop = defineProps({
    item: {
        type: Object,
        default: () => ({})
    },
    sessionId: {
        type: [String, Number],
        default: ''
    }
})
// 注入
const injectData = inject('msgAssemble')
const levChangeData = inject('levChange')
const msgList = inject('msgList')

const suitedText = ref('')


const reqParams = reactive({
    sessionId: prop.sessionId,
    msgSource: '1'
})
// ----------------------------------------页面加载触发--------------------------------------------------

onMounted(() => {
    window.openLev = openLev
})
// -------------------------------------------方法---------------------------------------------------
// 点击人工客服按钮
const openLev = async () => {
    try {
        const res = await assignService(reqParams)
    } catch (error) {
        elMessage.error(error)
    }
}

</script>

<style lang="scss" scoped></style>