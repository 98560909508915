<template>
    <div class="conmessage" ref="container">
        <!-- 消息 -->
        <div>
        <div v-if="details.id && details.chatType == '4' && details.chatStatus != '2' && details.deleteFlag == '0'" class="dialogset" :style="isSetbtn" title="设置" @click="openSet">
            <!-- <i class="iconfont icon-sangedian1"></i> -->
            <div class="blueText">群组管理</div>
        </div>
        <div class="news" :style="fullscreenCon?'height:calc(100vh - 31px);':'height:636px;'">
            <div class="news_left" v-if="recordShow">
                <div class="search_box">
                    <div class="search" @click="recordShow=false">
                        <span>搜索联系人/群组/历史记录</span>
                        <i class="el-icon-search"></i>
                    </div>
                </div>
                <div id="LeftList" style="height:94%; overflow-y: auto;" @mouseleave="hoverId=''">
                    <div v-for="item in LeftList" @contextmenu.prevent="LeftListOpenMenu($event,item)" :key="item.id" class="LeftList" :style="{ background:details.id==item.id? '#e9e9e9':''}" @click="ChoseDetails(item)" @mouseover="hoverDeail(item)">
                        <div v-if="item.topSign==1" class="AngleMark"></div>
                        <div v-if="item.chatStatus==='1'" class="Disable">
                            <span>已失效</span>
                        </div>
                        <div v-if="item.chatStatus==='2'" class="Disable">
                            <span>已解散</span>
                        </div>
                        <div style="width:260px;margin-left:10px;margin-top:5px;">
                            <div style="display:flex;">
                                <div class="head_img_f">
                                    <div v-if="item.chatType=='5'" style="width: 36px;height: 36px;"><img src="@/assets/img/MyWork/service1.png" /></div>
                                    <TopImg v-else :list="item.picList"></TopImg>
                                    <div v-if="item.chatType=='3'" class="head_img_s">
                                        <i v-if="item.online=='1'" class="iconfont icon-diannaodenglu maincolor" style="font-size: 8px;margin-left:1px;"></i>
                                        <i v-else class="iconfont icon-diannaodenglu" style="font-size: 8px;margin-left:1px;"></i>
                                    </div>
                                </div>
                                <div v-if="item.unRead" :style="{left:(item.unRead+'').length>2?'40px':'38px',padding:(item.unRead+'').length>2?'0 3px':''}" class="readCount"> {{item.unRead>99?'99+':item.unRead}}</div>
                                <div style="margin-left:10px;display:flex;flex-wrap: wrap; align-items: center;">
                                    <div class="LeftListCenter">
                                        <tooltipOver :content="item.chatName" className="width:170px;font-size: 14px;color:#000;"></tooltipOver>
                                        <div style="display:flex;justify-content:flex-end;width:100px;">
                                            <div class="tip" style="margin-right:3px" v-if="item.chatType=='0'">集团</div>
                                            <div class="tip" style="margin-right:3px" v-else-if="item.chatType=='1'">公司</div>
                                            <div class="tip" style="margin-right:3px" v-else-if="item.chatType=='2'">话题</div>
                                            <div v-else style="width:40px;height:2px"></div>
                                            <div class="ShowTime">
                                                <tooltipOver :content="item.lastChatTime" className="width:50px;" class="fl"></tooltipOver>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="item.chatStatus==2">
                                        <div class="overone" style="width:180px;color:#8590a6;font-size: 12px;">{{item.lastChatRecord}}</div>
                                        <!-- <div class="overone" style="width:180px;color:#8590a6;font-size: 12px;"> 当前服务群已解散 </div> -->
                                    </div>
                                    <div v-else class="overone" style="width:200px;font-size: 12px;">
                                        <span v-if="item.draft">
                                            <span style="color:red">[草稿]</span>
                                            <span style="color:rgb(137, 146, 164);">{{item.draftMessage?item.draftMessage:'文件'}}</span>
                                        </span>
                                        <!-- <span style="color:red" v-else-if="item.atMe>0">[有人@我]</span> -->
                                        <span v-else-if="item.atMe>0">
                                            <span style="color:red">[有人@我]</span>
                                            <span style="margin-left: 10px;">{{item.lastChatRecord}}</span>
                                        </span>
                                        <span v-else-if="item.lastChatRecord" style="color:#8590a6;font-size: 12px;">
                                            <div>
                                                <div v-html="item.lastChatRecord" style="width:200px;height: 20px;color:#8590a6;font-size: 12px;" class="fl overone"></div>
                                            </div>
                                        </span>
                                        <div style="width:10px;height:10px" v-else></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="hoverId==item.id" :title="'删除会话'" @click.stop="workGroupDeleteChatS(item)" class="hover_delete"><i class="iconfont icon-chahao"></i></div>
                    </div>
                    <div v-if="LeftShow" style="text-align:center;margin-top:100px">
                        <i class="iconfont icon-jiazai maincolor" style="font-size: 18px;"></i>
                        <div style="font-size:12px;color:#656c7a">拼命加载中</div>
                    </div>
                </div>
            </div>
            <div v-else style="width:342px;height:100%;">
                <Search @dialogue="dialogueHis" @switch="switchClose"></Search>
            </div>
            <!-- <div class="news_temp"></div> -->
            <div v-if="details.id" :style="isWidthNews" style="margin-left:340px;">
                <!-- 聊天标题 -->
                <div class="title" v-if="details.id" :style="isWidthNews">
                    <div style="display:flex;align-items: center;">
                        <!-- <TopImg :list="details.picList"></TopImg> -->
                        <div class="fl" :style="fullscreenCon?'max-width:600px;':'max-width:400px;'">
                            <div class="cura" v-if="details.chatType == '3'"  @click="workGroupFindFriendInfo(details,$event,'chatTop')">
                                <tooltipOver :content="details.chatName" className="width:100%;"></tooltipOver>
                            </div>
                            <div v-else>
                                <tooltipOver :content="details.chatName" className="width:100%;"></tooltipOver>
                            </div>
                            <!-- <span style="color:#000;font-size:14px">{{details.chatName}}</span> -->
                            <!-- <span v-if="details.chatType!=4&&details.chatType" style="color:#000;font-size:14px">（{{details.imgList.length}}人）</span> -->
                        </div>
                    </div>
                </div>
                <div style="margin: 100px auto;width:120px" v-if="details.deleteFlag =='1'">
                    <div class="remove" style="width: 120px;">{{details.chatType==2?'您被移出该话题':details.chatType==4?'您已被移除该服务群':'您被移出该部门群'}}</div>
                </div>
                <div style="margin: 100px auto;width:120px" v-else-if="details.chatStatus =='2'">
                    <div class="remove" style="width: 120px;">当前服务群已解散</div>
                </div>
                <div v-else-if="details.chatType =='5'">
                    <!-- 服务通知 -->
                    <serviceslist ref="serviceslist1" :details="details"></serviceslist>
                </div>
                <div style="position: relative;" :style="isHeightNews" v-else>
                    <!-- 聊天记录 -->
                    <div class="HistoricalList mt10" ref="HistoricalList1" id="ChatBox" @scroll="scrollEvent">
                        <div v-for="(item,index) in HistoricalList" :key="index" :class="['HistoricalItem',item.checked?'choseBg':'',locationId==item.id?'choseBg':'']" :id="item.id">
                            <el-checkbox style="margin-top:5px;margin-left:5px;float:left" v-if="MultipleChoice" @change="HistoricalItemChecked(item)" v-model="item.checked"></el-checkbox>
                            <!-- 系统提醒 -->
                            <div style="text-align:center" v-if="item.msgType=='0'">
                                <el-popover placement="right" trigger="click">
                                    <div class="popoverIsReference">{{item.formatTime}}&nbsp;&nbsp;{{item.chatMsg}}</div>
                                    <template #reference>
                                        <div class="remove" style="display: inline-block;overflow:hidden;text-overflow:ellipsis; white-space:nowrap;max-width:550px;padding:0 10px">{{item.formatTime}}&nbsp;&nbsp;{{item.chatMsg}}</div>
                                    </template>
                                </el-popover>
                            </div>
                            <div v-else>
                                <!-- 时间展示方案2 暂时不用 -->
                                <!--<div class="sendtimebox">
                                    <span class="sendtimeS">{{item.formatTime}}</span>
                                </div> -->
                                <div class="mySendOut" :class="[ 'mySendOut', item.sendUserId==userId?'reverse':'' ]">
                                    <img @contextmenu.prevent="RightAite($event,item)" @click="workGroupFindFriendInfo(item,$event)" :src="urlImg +item.sendUserHeadUrl" class="headUrl" />
                                    <div>
                                        <div v-if="item.sendUserId==userId" style="margin:3px 0 8px 0;text-align:right;cursor: pointer;">
                                            <span style="font-size:12px;color:#899ea4">{{item.formatTime}}</span>
                                            <span style="margin: 0 5px"></span>
                                            <span @click="workGroupFindFriendInfo(item,$event)" style="font-size:12px;color:#000;">{{item.sendUserName}}</span>
                                        </div>
                                        <div v-else style="margin:3px 0 8px 0;cursor: pointer;">
                                            <span @click="workGroupFindFriendInfo(item,$event)" style="font-size:12px;color:#000;">{{item.sendUserName}}</span>
                                            <span style="margin: 0 5px"></span>
                                            <span style="font-size:12px;color:#899ea4">{{item.formatTime}}</span>
                                        </div>
                                        <div v-if="item.msgType=='1'" style="max-width: 500px;display: flex;align-items: flex-end;" :class="[item.sendUserId==userId?'reverse':'' ]">
                                            <div @mouseup="getText" v-html="item.chatMsg" @contextmenu.prevent="openMenu($event,item)" style="cursor: pointer;word-wrap:break-word;line-height:20px;white-space:pre-line;" :class="[ item.sendUserId==userId?'message':'messages' ]"> </div>
                                            <div v-if="item.sendUserId==userId">
                                                <readComponents :itemlist="item" :chatType="details.chatType"></readComponents>
                                            </div>
                                        </div>
                                        <div v-else-if="item.msgType=='4'" style="max-width: 500px;display: flex;align-items: flex-end;" :class="[item.sendUserId==userId?'reverse':'' ]" @click="handleClickMyWork($event,item)">
                                            <div @mouseup="getText" v-html="item.chatMsg" @contextmenu.prevent="openMenu($event,item)"> </div>
                                            <div v-if="item.sendUserId==userId">
                                                <readComponents :itemlist="item" :chatType="details.chatType"></readComponents>
                                            </div>
                                        </div>
                                        <div v-else style="max-width: 500px;display: flex;align-items: flex-end;" :class="[item.sendUserId==userId?'reverse':'' ]">
                                            <div @click="preview(item.fileUrl)" @contextmenu.prevent="openMenu($event,item)" v-if="item.fileSuffix=='png' || item.fileSuffix=='jpg'||item.fileSuffix=='jpeg'" class="imgf" style="cursor: pointer;width: 220px;height: 125px;">
                                                <div>
                                                    <img :src="urlImg + item.fileUrl" style="cursor: pointer;max-width: 218px;max-height: 125px;margin: 0 auto; display: block;"/>
                                                </div>
                                            </div>
                                            <div v-else @click="preview(item.fileUrl)" @contextmenu.prevent="openMenu($event,item)" :class="[item.sendUserId==userId?' fileItem':'fileItem' ]" style="display:flex;align-items: center;cursor: pointer;position:relative;"> 
                                                <div style="margin: 5px 10px 5px 5px;cursor: pointer;float: left;">
                                                    <img v-if="item.fileSuffix=='png' || item.fileSuffix=='jpg'||item.fileSuffix=='jpeg'" :src="urlImg+item.fileUrl" />
                                                    <img v-else-if="item.fileSuffix=='bmp'||item.fileSuffix=='webp'" src='@/assets/img/MyWork/32jpg.png' />
                                                    <img v-else-if="item.fileSuffix=='docx'" src='@/assets/img/MyWork/32word.png' />
                                                    <img v-else-if="item.fileSuffix=='zip'||item.fileSuffix=='rar'||item.fileSuffix=='7z'||item.fileSuffix=='ar'" src='@/assets/img/MyWork/32zip.png' />
                                                    <img v-else-if="item.fileSuffix=='xls'" src='@/assets/img/MyWork/32exl.png' />
                                                    <img v-else-if="item.fileSuffix=='xlsx'||item.fileSuffix=='xltx'||item.fileSuffix=='xlsm'||item.fileSuffix=='xlsb'||item.fileSuffix=='csv'||item.fileSuffix=='xml'" src='@/assets/img/MyWork/32exl.png' />
                                                    <img v-else-if="item.fileSuffix=='ppt'||item.fileSuffix=='pptx'||item.fileSuffix=='xps'||item.fileSuffix=='pps'||item.fileSuffix=='ppsx'||item.fileSuffix=='ppa'||item.fileSuffix=='ppam'||item.fileSuffix=='pot'||item.fileSuffix=='potx'||item.fileSuffix=='thmx'" src='@/assets/img/MyWork/32p.png' />
                                                    <img v-else-if="item.fileSuffix=='pdf'" src='@/assets/img/MyWork/32pdf.png' />
                                                    <img v-else-if="item.fileSuffix=='doc'||item.fileSuffix=='dot'||item.fileSuffix=='docm'||item.fileSuffix=='dotm'||item.fileSuffix=='dotx'" src='@/assets/img/MyWork/32word.png' />
                                                    <img v-else-if="item.fileSuffix=='txt'||item.fileSuffix=='rtf'" src='@/assets/img/MyWork/32text.png' />
                                                    <img v-else-if="item.fileSuffix=='mov'||item.fileSuffix=='mp4'||item.fileSuffix=='avi'||item.fileSuffix=='wmv'||item.fileSuffix=='asf'||item.fileSuffix=='rm'||item.fileSuffix=='rmvb'" src='@/assets/img/MyWork/32movie.png' />
                                                    <img v-else-if="item.fileSuffix=='mp3'||item.fileSuffix=='wav'||item.fileSuffix=='wv'||item.fileSuffix=='wma'" src='@/assets/img/MyWork/32audio.png' />
                                                    <img v-else-if="item.fileSuffix=='PHTML'||item.fileSuffix=='htm'||item.fileSuffix=='html'||item.fileSuffix=='JSP'||item.fileSuffix=='HTML'||item.fileSuffix=='php'||item.fileSuffix=='ASP'||item.fileSuffix=='PHP'||item.fileSuffix=='PHP3'" src='@/assets/img/MyWork/32html.png' />
                                                    <img v-else-if="item.fileSuffix=='psd'||item.fileSuffix=='eps'" src='@/assets/img/MyWork/32ps.png' />
                                                    <img v-else-if="item.fileSuffix=='svg'" src='@/assets/img/MyWork/32svg.png' />
                                                    <img v-else-if="item.fileSuffix=='xmind'" src='@/assets/img/MyWork/32Xmind.png' />
                                                    <img v-else-if="item.fileSuffix=='rp'" src='@/assets/img/MyWork/32rp.png' />
                                                    <img v-else src='@/assets/img/MyWork/32other.png' />
                                                </div>
                                                <div class="fl">
                                                    <tooltipOver :content="item.fileName ? item.fileName : ''" className="width:200px;"></tooltipOver>
                                                    <div style="color:#8992a4">{{item.fileSize}}</div>
                                                </div>
                                                <div v-if="item.readSign =='3'" class="downtag">已下载</div>
                                            </div>
                                            <div v-if="item.sendUserId==userId">
                                                <readComponents :itemlist="item" :chatType="details.chatType"></readComponents>
                                            </div>
                                        </div>
                                        <!-- 引用 -->
                                        <div v-if="item.refMsg && item.refMsg.sendUserName" :class="item.sendUserId==userId?'fr':'fl'" style="margin-top:10px;max-width: 300px;background: #f2f5f9;border-radius: 3px;" @contextmenu.prevent="openQuote($event,item)">
                                            <!-- 聊天 -->
                                            <div v-if="item.refMsg.chatMsg" style="width: 100%;display: flex;" :class="[item.refMsg.sendUserId==userId?'reverse':'' ]">
                                                <el-popover placement="right" trigger="click">
                                                    <div class="popoverIsReference"><span style="float:left">{{item.refMsg.sendUserName}}</span>:<span style="word-wrap:break-word;white-space:normal;" v-html="item.refMsg.chatMsg"></span></div>
                                                    <template #reference>
                                                        <div style="max-height:48px; background:#f2f5f9; cursor: pointer;word-wrap:break-word; color:#878b96;line-height:20px;max-width:280px" :class="[item.refMsg.sendUserId==userId?'message':'messages' ,'overmore']"> <span style="float:left">{{item.refMsg.sendUserName}}:</span><span v-html="item.refMsg.chatMsg"></span></div>
                                                    </template>
                                                </el-popover>
                                            </div>
                                            <!-- 附件 -->
                                            <div v-else style="width: 100%;display:flex;background:#f2f5f9;color:#878b96;line-height:20px;padding:5px 10px;" :class="[item.userId==userId?'reverse message':'messages' ]">
                                                <div v-if="item.refMsg.fileSuffix=='png' || item.refMsg.fileSuffix=='jpg'||item.refMsg.fileSuffix=='jpeg'" style="display:flex;align-items: center;cursor: pointer;">
                                                    <div> {{item.refMsg.sendUserName}}:</div>
                                                    <img :src="urlImg + item.refMsg.fileUrl" class="headUrl headUrl1"/>
                                                </div>
                                                <!-- <div v-else style="display:flex;align-items: center;cursor: pointer;flex-wrap: wrap;">  -->
                                                <div v-else class="overhide"> 
                                                    <div class="fl"> {{item.refMsg.sendUserName}}:</div>
                                                    <div style="width:13px;height:13px;margin: 0 0 0 5px;" class="fl yimg">
                                                        <img v-if="item.refMsg.fileSuffix=='png' || item.refMsg.fileSuffix=='jpg'||item.refMsg.fileSuffix=='jpeg'" :src="urlImg+item.refMsg.fileUrl" />
                                                        <img v-else-if="item.refMsg.fileSuffix=='bmp'||item.refMsg.fileSuffix=='webp'" src='@/assets/img/MyWork/32jpg.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='docx'" src='@/assets/img/MyWork/32word.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='zip'||item.refMsg.fileSuffix=='rar'||item.refMsg.fileSuffix=='7z'||item.refMsg.fileSuffix=='ar'" src='@/assets/img/MyWork/32zip.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='xls'" src='@/assets/img/MyWork/32exl.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='xlsx'||item.refMsg.fileSuffix=='xltx'||item.refMsg.fileSuffix=='xlsm'||item.refMsg.fileSuffix=='xlsb'||item.refMsg.fileSuffix=='csv'||item.refMsg.fileSuffix=='xml'" src='@/assets/img/MyWork/32exl.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='ppt'||item.refMsg.fileSuffix=='pptx'||item.refMsg.fileSuffix=='xps'||item.refMsg.fileSuffix=='pps'||item.refMsg.fileSuffix=='ppsx'||item.refMsg.fileSuffix=='ppa'||item.refMsg.fileSuffix=='ppam'||item.refMsg.fileSuffix=='pot'||item.refMsg.fileSuffix=='potx'||item.refMsg.fileSuffix=='thmx'" src='@/assets/img/MyWork/32p.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='pdf'" src='@/assets/img/MyWork/32pdf.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='doc'||item.refMsg.fileSuffix=='dot'||item.refMsg.fileSuffix=='docm'||item.refMsg.fileSuffix=='dotm'||item.refMsg.fileSuffix=='dotx'" src='@/assets/img/MyWork/32word.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='txt'||item.refMsg.fileSuffix=='rtf'" src='@/assets/img/MyWork/32text.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='mov'||item.refMsg.fileSuffix=='mp4'||item.refMsg.fileSuffix=='avi'||item.refMsg.fileSuffix=='wmv'||item.refMsg.fileSuffix=='asf'||item.refMsg.fileSuffix=='rm'||item.refMsg.fileSuffix=='rmvb'" src='@/assets/img/MyWork/32movie.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='mp3'||item.refMsg.fileSuffix=='wav'||item.refMsg.fileSuffix=='wv'||item.refMsg.fileSuffix=='wma'" src='@/assets/img/MyWork/32audio.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='PHTML'||item.refMsg.fileSuffix=='htm'||item.refMsg.fileSuffix=='html'||item.refMsg.fileSuffix=='JSP'||item.refMsg.fileSuffix=='HTML'||item.refMsg.fileSuffix=='php'||item.refMsg.fileSuffix=='ASP'||item.refMsg.fileSuffix=='PHP'||item.refMsg.fileSuffix=='PHP3'" src='@/assets/img/MyWork/32html.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='psd'||item.refMsg.fileSuffix=='eps'" src='@/assets/img/MyWork/32ps.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='svg'" src='@/assets/img/MyWork/32svg.png' />
                                                        <img v-else-if="item.refMsg.fileSuffix=='xmind'" src='@/assets/img/MyWork/32Xmind.png' />
                                                        <img v-else-if="item.fileSuffix=='rp'" src='@/assets/img/MyWork/32rp.png' />
                                                        <img v-else src='@/assets/img/MyWork/32other.png' />
                                                    </div>
                                                    <div class="fl" style="margin-left: 5px;max-width: 189px;">
                                                        {{item.refMsg.fileName?item.refMsg.fileName:''}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 未读消息 -->
                    <div v-if="NewShow" class="NewShow" @click="NewShows">
                        <i class="iconfont icon-zhankai"></i>
                        {{newsNum}}条新消息
                        <div><img src="@/assets/img/MyWork/close1.png" /></div>
                    </div>
                    <!-- @信息 -->
                    <div v-if="reminderMsg.length" class="NewShow1" :style="{bottom:NewShow?'190px':'155px;'}" @click="AiteLocation">
                        <div>{{reminderTip}}</div>
                        <div><img src="@/assets/img/MyWork/close1.png" @click.stop="reminderMsg=[]" /></div>
                    </div>
                    <!-- 发送框 -->
                    <div class="sendOutBox" v-if="details.deleteFlag!='2'">
                        <div class="overhide">
                            <!-- 放大输入框 -->
                            <div class="hisiconbig cura" title="放大" @click="openBigInput">
                                <i class="iconfont icon-fangda"></i>
                            </div>
                            <Information class="fl" style="padding-left: 40px;" :type="2" :Dialogue="false" @loadingNumber="loadingNumber" :height="160" ref="Information1" @FileList="ChildFileList" @type="ChoseType"></Information>
                            <!-- 历史记录  v-if="!remove" -->
                            <div class="hisicon" title="历史记录" @click="openHis">
                                <i class="iconfont icon-jurassic_wait"></i>
                            </div>
                            <!-- 群动态 -->
                            <div v-if="details.chatType == '4'" class="hisicondy cura" title="群动态" @click="openDynamics">
                                <i class="iconfont icon-dongtai"></i>
                            </div>
                        </div>
                        <div style="overflow: auto;position:relative;height: 94px;">
                            <el-mention id="myinput" v-model="message" @blur="draft" @keydown.enter="searchhandle" placement="top" type="textarea" maxlength="5000" autosize :options="aitelist" :placeholder="placeholder">
                                <template #label="{ item }">
                                <!-- whole-->
                                <div style="display: flex; align-items: center">
                                    <div class="aitelist" @click="ChoseAite(item)">
                                        <img v-if="item.headUrl" :src="urlImg+item.headUrl">
                                        <img v-else src="@/assets/img/MyWork/@all.png">
                                        <div class="overone" style="width:120px" :title="item.value+' ('+item.orgName+')'">
                                            <span>{{item.value}}</span>
                                            <span v-if="item.orgName">（{{item.orgName}}）</span>
                                        </div>
                                    </div>
                                </div>
                                </template>
                            </el-mention>
                            <!-- @focus="inputFocus" @blur="inputBlur" @keydown.native="searchhandle" onkeydown="if(event.keyCode==13)return false;"   -->
                            <!-- <el-input ref="myinput" id="myinput" @keydown.enter="searchhandle" @blur="draft" @input="ChangeInput" maxlength="5000" type="textarea" autosize :placeholder="placeholder" v-model="message">
                            </el-input> -->
                            <!-- 用来设置@功能记录光标所在位置 -->
                            <!-- <div id="text_copy" style="visibility: hidden;padding:11px 16px;font-size:14px;position:absolute;line-height:1.5;top:0px"></div> -->
                            <!-- @列表 -->
                            <!-- <div class="Aite" ref="Aite" :style="{left:x,top:y}">
                                <div class="aitelist" @click="ChoseAite('all')">
                                    <img src="@/assets/img/MyWork/@all.png">
                                    <div>所有人</div>
                                </div>
                                <div style="font-size:12px;color:#8590a6;padding:3px 5px">群成员</div>
                                <div v-for="(item,index) in aitelist" :key="index" class="aitelist" @click="ChoseAite(item)">
                                    <img :src="urlImg+item.headUrl">
                                    <div class="overone" style="width:120px" :title="item.mname+' ('+item.orgName+')'">
                                        <span>{{item.mname}}</span>
                                        <span v-if="item.branch">（{{item.orgName}}）</span>
                                    </div>
                                </div>
                            </div> -->
                            <div class="AllFileList">
                                <div v-for="(item,index) in AllFileList" :key="index" style="display:flex;margin-bottom:3px;position: relative;border-radius: 3px;">
                                    <div class="CloseFileList"><img src="@/assets/img/MyWork/close1.png" @click="CloseFileList(item)" /></div>
                                    <div v-if="item.id" class="overmore quote" style="width:280px;padding-right:15px">
                                        <span style="float:left">{{item.sendUserName}}：</span>
                                        <div v-if="item.fileUrl" style="width:34px;height:34px;float:left">
                                            <img style="width:34px;height:34px" v-if="item.fileSuffix=='png' || item.fileSuffix=='jpg'||item.fileSuffix=='jpeg'" :src="urlImg + item.fileUrl" :key="Math.random()"/>
                                            <img v-else-if="item.fileSuffix=='bmp'||item.fileSuffix=='webp'" src='@/assets/img/MyWork/32jpg.png' />
                                            <img v-else-if="item.fileSuffix=='docx'" src='@/assets/img/MyWork/32word.png' />
                                            <img v-else-if="item.fileSuffix=='zip'||item.fileSuffix=='rar'||item.fileSuffix=='7z'||item.fileSuffix=='ar'" src='@/assets/img/MyWork/32zip.png' />
                                            <img v-else-if="item.fileSuffix=='xls'" src='@/assets/img/MyWork/32exl.png' />
                                            <img v-else-if="item.fileSuffix=='xlsx'||item.fileSuffix=='xltx'||item.fileSuffix=='xlsm'||item.fileSuffix=='xlsb'||item.fileSuffix=='csv'||item.fileSuffix=='xml'" src='@/assets/img/MyWork/32exl.png' />
                                            <img v-else-if="item.fileSuffix=='ppt'||item.fileSuffix=='pptx'||item.fileSuffix=='xps'||item.fileSuffix=='pps'||item.fileSuffix=='ppsx'||item.fileSuffix=='ppa'||item.fileSuffix=='ppam'||item.fileSuffix=='pot'||item.fileSuffix=='potx'||item.fileSuffix=='thmx'" src='@/assets/img/MyWork/32p.png' />
                                            <img v-else-if="item.fileSuffix=='pdf'" src='@/assets/img/MyWork/32pdf.png' />
                                            <img v-else-if="item.fileSuffix=='doc'||item.fileSuffix=='dot'||item.fileSuffix=='docm'||item.fileSuffix=='dotm'||item.fileSuffix=='dotx'" src='@/assets/img/MyWork/32word.png' />
                                            <img v-else-if="item.fileSuffix=='txt'||item.fileSuffix=='rtf'" src='@/assets/img/MyWork/32text.png' />
                                            <img v-else-if="item.fileSuffix=='mov'||item.fileSuffix=='mp4'||item.fileSuffix=='avi'||item.fileSuffix=='wmv'||item.fileSuffix=='asf'||item.fileSuffix=='rm'||item.fileSuffix=='rmvb'" src='@/assets/img/MyWork/32movie.png' />
                                            <img v-else-if="item.fileSuffix=='mp3'||item.fileSuffix=='wav'||item.fileSuffix=='wv'||item.fileSuffix=='wma'" src='@/assets/img/MyWork/32audio.png' />
                                            <img v-else-if="item.fileSuffix=='PHTML'||item.fileSuffix=='htm'||item.fileSuffix=='html'||item.fileSuffix=='JSP'||item.fileSuffix=='HTML'||item.fileSuffix=='php'||item.fileSuffix=='ASP'||item.fileSuffix=='PHP'||item.fileSuffix=='PHP3'" src='@/assets/img/MyWork/32html.png' />
                                            <img v-else-if="item.fileSuffix=='psd'||item.fileSuffix=='eps'" src='@/assets/img/MyWork/32ps.png' />
                                            <img v-else-if="item.fileSuffix=='svg'" src='@/assets/img/MyWork/32svg.png' />
                                            <img v-else-if="item.fileSuffix=='xmind'" src='@/assets/img/MyWork/32Xmind.png' />
                                            <img v-else-if="item.fileSuffix=='rp'" src='@/assets/img/MyWork/32rp.png' />
                                            <img v-else src='@/assets/img/MyWork/32other.png' />
                                        </div>
                                        <span v-if="item.fileName" style="margin-left:3px">{{item.fileName}}</span>
                                        <span v-else-if="item.msgType == '4'" style="font-size: 12px;">{{item.quote4Text}}</span>
                                        <span v-else style="word-wrap:break-word;white-space:normal;" v-html="item.chatMsg"></span>
                                    </div>
                                    <div v-else class="file">
                                        <div @contextmenu.prevent="openQuote($event,item)">
                                            <div class="fl cura" @click="preview(item.fileUrl)">
                                                <!-- <img style="width:34px;height:34px" v-if="item.fileUrl" :src="urlImg + item.fileUrl"/> -->
                                                <img style="width:34px;height:34px" v-if="item.fileUrlTemp && (item.fileSuffix=='png' || item.fileSuffix=='jpg'||item.fileSuffix=='jpeg')" :src="urlImg + item.fileUrl" :key="Math.random()"/>
                                                <img v-else-if="item.fileSuffix=='bmp'||item.fileSuffix=='webp'" src='@/assets/img/MyWork/32jpg.png' />
                                                <img v-else-if="item.fileSuffix=='docx'" src='@/assets/img/MyWork/32word.png' />
                                                <img v-else-if="item.fileSuffix=='zip'||item.fileSuffix=='rar'||item.fileSuffix=='7z'||item.fileSuffix=='ar'" src='@/assets/img/MyWork/32zip.png' />
                                                <img v-else-if="item.fileSuffix=='xls'" src='@/assets/img/MyWork/32exl.png' />
                                                <img v-else-if="item.fileSuffix=='xlsx'||item.fileSuffix=='xltx'||item.fileSuffix=='xlsm'||item.fileSuffix=='xlsb'||item.fileSuffix=='csv'||item.fileSuffix=='xml'" src='@/assets/img/MyWork/32exl.png' />
                                                <img v-else-if="item.fileSuffix=='ppt'||item.fileSuffix=='pptx'||item.fileSuffix=='xps'||item.fileSuffix=='pps'||item.fileSuffix=='ppsx'||item.fileSuffix=='ppa'||item.fileSuffix=='ppam'||item.fileSuffix=='pot'||item.fileSuffix=='potx'||item.fileSuffix=='thmx'" src='@/assets/img/MyWork/32p.png' />
                                                <img v-else-if="item.fileSuffix=='pdf'" src='@/assets/img/MyWork/32pdf.png' />
                                                <img v-else-if="item.fileSuffix=='doc'||item.fileSuffix=='dot'||item.fileSuffix=='docm'||item.fileSuffix=='dotm'||item.fileSuffix=='dotx'" src='@/assets/img/MyWork/32word.png' />
                                                <img v-else-if="item.fileSuffix=='txt'||item.fileSuffix=='rtf'" src='@/assets/img/MyWork/32text.png' />
                                                <img v-else-if="item.fileSuffix=='mov'||item.fileSuffix=='mp4'||item.fileSuffix=='avi'||item.fileSuffix=='wmv'||item.fileSuffix=='asf'||item.fileSuffix=='rm'||item.fileSuffix=='rmvb'" src='@/assets/img/MyWork/32movie.png' />
                                                <img v-else-if="item.fileSuffix=='mp3'||item.fileSuffix=='wav'||item.fileSuffix=='wv'||item.fileSuffix=='wma'" src='@/assets/img/MyWork/32audio.png' />
                                                <img v-else-if="item.fileSuffix=='PHTML'||item.fileSuffix=='htm'||item.fileSuffix=='html'||item.fileSuffix=='JSP'||item.fileSuffix=='HTML'||item.fileSuffix=='php'||item.fileSuffix=='ASP'||item.fileSuffix=='PHP'||item.fileSuffix=='PHP3'" src='@/assets/img/MyWork/32html.png' />
                                                <img v-else-if="item.fileSuffix=='psd'||item.fileSuffix=='eps'" src='@/assets/img/MyWork/32ps.png' />
                                                <img v-else-if="item.fileSuffix=='svg'" src='@/assets/img/MyWork/32svg.png' />
                                                <img v-else-if="item.fileSuffix=='xmind'" src='@/assets/img/MyWork/32Xmind.png' />
                                                <img v-else-if="item.fileSuffix=='rp'" src='@/assets/img/MyWork/32rp.png' />
                                                <img v-else src='@/assets/img/MyWork/32other.png' />
                                            </div>
                                            <div style="margin-left:10px" class="fl">
                                                <div class="overone" style="width:200px" v-if="item.title">{{item.title}}</div>
                                                <div v-else class="overone" style="width:200px">{{item.fileName?item.fileName:item.folderName}}</div>
                                                <div>{{item.AllSize}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width:10px;height:10px"></div>
                                </div>
                                <div v-for="(items,indexs) in UploadingList" :key="items.name" style="margin-right:10px">
                                    <!-- <div v-if="indexs==0&&items.progress > 0 && items.progress < 100">上传文件过程中，请不要切换聊天窗口</div> -->
                                    <div class="file" v-if="items.progress > 0 && items.progress < 100" :key="indexs">
                                        <div style="text-align:center">
                                            <img src='@/assets/img/MyWork/32other.png' @click="preview(item.fileUrl)" />
                                        </div>
                                        <div class="overone" style="width:127px;margin-left:8px;font-size: 14px;">{{items.name}}</div>
                                        <el-progress class="progress_self" style="margin-top:3px;width:127px;margin-left:8px;font-size: 14px;" :percentage="items.progress"></el-progress>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div :title="'发送'">
                                <div class="sendOut">
                                    <el-button class="fl" size="small" style="height: 30px;line-height: 30px;min-width: 80px;border-radius: 3px;" type="primary" @click="workTopicSendTopicMessage">发送</el-button>
                                    <div class="fl">
                                        <!-- <div class="send_icon"><i class="iconfont icon-xiajiantou2"></i></div> -->
                                        <el-dropdown @command="handleCommand" class="dropdown">
                                            <div class="send_icon"><i class="iconfont icon-xiajiantou2"></i></div>
                                            <template #dropdown>
                                                <el-dropdown-menu class="headerDropdown" style="text-align: center;">
                                                    <el-dropdown-item :class="sendKey=='0'?'checkSend':''" command="0">Enter</el-dropdown-item>
                                                    <el-dropdown-item :class="sendKey=='1'?'checkSend':''" command="1">Ctrl+Enter</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <MultipleChoice1 @Close="CloseMultipleChoice" :fullscreenCon="fullscreenCon" :Num="ChoseListMsgId.length" :MultipleChoice="MultipleChoice" @ClickForWard="ClickForWard" @OpenDownloadPop="OpenDownloadPop" @workTopicDeleteChatRecord="workTopicDeleteChatRecord"></MultipleChoice1>
                </div>
                <!-- 历史记录 -->
                <div v-if="HistoricalShow">
                    <Historical ref="historical1" @offset="FileOffset" :keywords2="keywords2" :hisSource="hisSource" :groupInfoId="details.id" @newDetail="newDetail" @hisBack="hisBack" @callBackHis="callBackHis"></Historical>
                </div>
                <!-- 放大输入框 -->
                <div v-if="isBigInput">
                    <bigInput1 ref="bigInput" :detailMessage="message" @changeBigDetail="changeBigDetail" @changeBigDetailTemp="changeBigDetailTemp" :title="details.chatName"></bigInput1>
                </div>
                <!-- 群动态 -->
                <div v-if="isDynamics">
                    <dynamics ref="dynamics1" :details="details" @backDynamics="backDynamics"></dynamics>
                </div>
            </div>
            <!-- 为空 -->
            <div v-else :style="fullscreenCon?'width: 1513px':'width: 643px;'" class="noImg_box">
                <div class="noImg">
                    <img src='@/assets/img/MyWork/mywork.png' />
                    <div>欢迎来到我的会话</div>
                </div>
            </div>
        </div>
        <!-- 操作日志 -->
        <Journal v-if="JournalShow" :groupInfoId="details.id" @close="JournalShow=false"></Journal>
        <!-- 设置 -->
        <TopicSetting1 :fullscreenCon="fullscreenCon" ref="TopicSetting" @openStaffSet="openStaffSet" @ClearChatRecord="ClearChatRecord" @Refresh="Refresh" @workGroupFindFriendInfo="workGroupFindFriendInfo" v-if="TopicSettingShow" :detailsId="details.id" @signOut="signOut" @close="TopicSettingShow=false"></TopicSetting1>
        <!-- <Move :comeIn="'2'" ref="moveBox" :title="title" v-if="MoveShow" @choseItemList="choseItemList" :itemIdList=[] @closeCdialog="MoveShow=false" :isFindFile="'1'" :type="type"></Move> -->
        <div id="Mask" ref="Mask" v-show="rightPopup||RightDetailPop||RightQuotePop||RightAitePop" style="width:100vw;height:100vh; position: fixed;top:0px;left:0px;z-index:9999" @click="ClickMark"></div>
        <div id="groupInfoId" v-show="rightPopup" class="rightPopup">
            <div class="rightPopupItem" v-if="RightMessage.msgType==1" @click="Copy">复制</div>
            <div class="rightPopupItem" v-if="downloadShow" @click="download">下载</div>
            <div class="rightPopupItem" @click="ClickForWard">转发</div>
            <div class="rightPopupItem" @click="Multiple">多选</div>
            <div class="rightPopupItem" @click="workTopicDeleteChatRecord">删除</div>
            <!-- 云平台不要撤回 -->
            <!-- <div class="rightPopupItem" v-if="RightMessage.sendUserId==userId" @click="workTopicRecallChatRecord">撤回</div> -->
            <div class="rightPopupItem" @click="quote">引用</div>
        </div>
        <div id="RightQuotePop" v-show="RightQuotePop" style="width:100px" class="rightPopup">
            <div class="rightPopupItem" @click="ClickQuotePop">定位到原文位置</div>
        </div>
        <div id="RightAitePop" v-show="RightAitePop" style="width:100px" class="rightPopup">
            <div class="rightPopupItem" @click="ClickRightAite">{{RightAiteName}}</div>
        </div>
        <div id="RightDetailPop" v-show="RightDetailPop" class="rightPopup" style="width:94px;">
            <div class="rightPopupItem" @click="workMyNotepadIsTop">{{RightDetails.topSign=='0'?'置顶':'取消置顶'}}</div>
            <div class="rightPopupItem" @click="TopicSettingF" v-if="RightDetails.chatType!=3 && RightDetails.chatStatus!= 1 && RightDetails.deleteFlag != '1'">设置</div>
            <div class="rightPopupItem" @click="workGroupDeleteChat(0)">删除会话</div>
            <div class="rightPopupItem" @click="workTopicClearChatRecord">清空历史记录</div>
        </div>
        <!-- 名片 -->
        <div class="detailsPop" ref="detailsPop">
            <businessCard ref="businessCard1" :cardDetail="FriendInfo" @ChoseMailList1="ChoseMailList"></businessCard>
        </div>
        <!-- 多选下载弹窗 -->
        <DownloadPop :downLoadList="DownLoadList" :dialogVisible="DownloadPopDialog" @close="DownloadPopDialog=false" @download="download"></DownloadPop>
        <!-- 选择人员 -->
        <div v-if="selectMore">
            <workSelectMore1 :fullscreenCon="fullscreenCon" :buildId="buildId" :source="source" :value="setImgList" :selectTitle="selectTitle" @getValue="getValue" @getValueCancel="getValueCancel" ref="workSelectMore" />
        </div>
    </div>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, reactive, computed } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import http from '@/api/http'
import workSelectMore1 from '../components/workSelectMore'
import Search from '../components/search.vue'
import TopImg from './components/topimg.vue'
import Historical from './components/Historical.vue'
import MultipleChoice1 from './components/MultipleChoice.vue'
import Information from '@/views/technologyManager/conversation/components/information.vue'
import TopicSetting1 from './components/TopicSetting.vue'
import Journal from './components/journal.vue'
import DownloadPop from './components/downloadPop.vue'
import bigInput1 from '../components/bigInput.vue'
import { conversationStore, headStore } from '@/store'
import businessCard from '@/views/technologyManager/conversation/components/businessCard.vue'//名片
import serviceslist from '@/views/technologyManager/conversation/news/components/serviceslist.vue'//服务通知
import dynamics from '../components/dynamics.vue'
import readComponents from '@/views/technologyManager/conversation/news/components/readComponents.vue'//未读已读
import { ctls, sendMsg, ctmes, amcts, ict, dctr, rbctr, ctms, tpct, bCard, download1, fwdMsg, clmsg, urcount, dpct, pstmsg, sgct, umsk, sumsk, readSign } from '@/api/technologyManager'//接口
import { previewFile,downloadFile } from '@/utils/PreviewDownload'//附件预览下载
import { ExportDownloadPost } from '@/js/exportParams' //导出
import { useRouter } from "vue-router";
import { getToken } from '@/utils/auth';

const router = useRouter()
const emit = defineEmits(['CloseCursor','cursor','showRight'])
const locationId = ref('')//@定位
const x = ref('')//@定位
const y = ref('')//@定位
const aitelist = ref([])//@成员列表
const text_leftLength = ref(0)//记录@输入了什么
const text_rightLength = ref(0)//记录@输入了什么
const text_left = ref('')//光标左侧数据
const text_right = ref('')//光标右侧数据
const reminderMsg = ref('')// 被at的消息id
const RightAiteName = ref([])//右击at
const RightAitePop = ref(false)
const reminderTip = ref('')
const reminderMemberIdList = ref([])//At成员
const messageOld = ref('')
const DownLoadList = ref([])
const DownloadPopDialog = ref(false)
const MultipleChoice = ref(false)
const ChoseListMsgId = ref([])
const disable = ref(false)
const LeftShow = ref(true)
const UploadingList = ref([])
const remove = ref(false)
const newsNum = ref(0)
const Disable = ref(false)
const FriendInfo = ref({})
const recordShow = ref(true)
const RightDetails = ref({})
const RightDetailPop = ref(false)
const RightQuotePop = ref(false)
const LeftList = ref([])
const NewShow = ref(false)
const JournalShow = ref(false)
const TopicSettingShow = ref(false)
const top = ref(0)
const downloadShow = ref(false)
const ForWardShow = ref(false)
const rightPopup = ref(false)
const RightMessage = ref({})
const urlImg = http.FileUrl
const userId = sessionStorage.getItem('userId')
const sortType = ref('1')
const messageType = ref('1')
const memberId = ref('')
const refMessageId = ref('')
const keywords = ref('')
const offset = ref('')
const offsetList = ref([])
const newMessageCount = ref(25)
const msgTime = ref('')
const show = ref(true)
const details = ref({})
const HistoricalShow = ref(false)
const HistoricalList = ref([])
const fileLists = ref([])
const fileList = ref([])
const NotepadList = ref([])
const message = ref('')
const editorData = ref({ detail: '' })
const title = ref('')
const type = ref('')
const MoveShow = ref(false)
const AllFileList = ref([])
const selectMore = ref(false)
const selectTitle = ref('')
const seltxt = ref('')
const source = ref('1')
const setImgList = ref([])
const selUserType = ref('')
const hisSource = ref('')
const openType = ref('')
const sendKey = ref('1')
const placeholder = ref('按Enter键换行，按Ctrl+Enter键发送')
const keywords2 = ref('')
const hoverId = ref('')
const detailsId = ref('')
const userIdTemp = sessionStorage.getItem('userId')
const isBigInput = ref(false)
const Aite = ref(null)
const Mask = ref(null)
const Information1 = ref(null)
const historical1 = ref(null)
const businessCard1 = ref(null)
const workSelectMore = ref(null)
const TopicSetting = ref(null)
const moveBox = ref(null)
const HistoricalList1 = ref(null)
const bigInput = ref(null)
const detailsPop = ref(null)
const container = ref(null)
// const MyWorkNum = ref(null)
const isHeightNews = ref('height: 477px;')
const isWidthNews = ref('width:642px;')
const isSetbtn = ref('left:986px;')
const isDynamics = ref(false)// 群动态
const buildId = ref('')
const fullscreenCon = ref(null)
const serviceslist1 = ref(null)
const choseListData = ref([])
// 点击会议消息的查看详情按钮时，跳转页面
function handleClickMyWork(event, item) {
    const clickedInsideVHtml = event.target.closest('#goDetailButton') != null
    if (clickedInsideVHtml) {
        goMeetingDetail(item)
    }
}
// 跳转至会议详情 ==== 添加会议时，如果勾选发送MyWork消息，在这里需要跳转
function goMeetingDetail(val){
    window.open(window.location.protocol + "//" + window.location.host + val.linkUrl, '_blank')
}
// 标记已读
function doReadSign(val){
    if(val.chatId) {
        readSign({chatId: val.chatId}).then((res) => {})
    }
}
// 鼠标悬浮时出现删除按钮
function hoverDeail(val){
    hoverId.value = val.id
}
// 清空历史
function workTopicClearChatRecord() {
    ElMessageBox.confirm('确定清空历史记录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        RightDetailPop.value = false
        clmsg({
            chatId: RightDetails.value.id
        }).then((res) => {
            if (res.code == 0) {
                ClearChatRecord()
                openSet(1, false)
            }
            ElMessage({
                message:res.code == 0 ? '清空历史记录成功' : res.message,
                type: res.code == 0 ? 'success' : 'error',
                duration: 3000
            })
        })
    })
}
// 查询被@的数量
function workGroupFindIsReminderCount() {
    amcts({
        chatId: detailsId.value
    }).then((res) => {
        if(res.data) {
            reminderMsg.value = res.data.ids
            reminderTip.value = res.data.prompt
        }
    })
}
// 存草稿
function draft() {
    if (reminderMemberIdList.value.length) {
        let reminderMemberIdList1 = []
        // 匹配包含完整的at信息
        reminderMemberIdList.value.map((item) => {
            if (message.value.indexOf('@' + item.mname) != -1) reminderMemberIdList1.push(item.id)
        })
        if (!reminderMemberIdList1.length) reminderMemberIdList.value = []
    }
    if (!message.value.trim()) message.value = ''
    let data = {
        fileLists: fileLists.value,
        id: details.value.id,
        message: message.value,
        fileList: fileList.value,
        NotepadList: NotepadList.value,
        refMessageId: refMessageId.value,
        reminderMemberIdList: reminderMemberIdList.value,
        AllFileList: AllFileList.value
    }
    let list1 = conversationStore.draft
    if (fileLists.value.length || message.value || fileList.value.length || NotepadList.value.length || refMessageId.value || reminderMemberIdList.value.length || AllFileList.value.length) {
        // 可以存
        let indexs = 0
        if(list1) {
            var result = list1.some((item, index) => {
                if (item.id == data.id) {
                    indexs = index
                    return true
                }
            })
            if (result) {
                // 包含
                // if(message.value&&list1[indexs].message){
                list1.splice(indexs, 1)
                list1.unshift(data)
                // }
            } else {
                list1.unshift(data)
            }
        }
        for (let l = 0; l < LeftList.value.length; l++) {
            if (LeftList.value[l].id == details.value.id) {
                LeftList.value[l].draft = true
                LeftList.value[l].draftMessage = message.value
            }
        }
    } else {
        // 删除这条信息
        if(list1 && list1.length) {
            for (let i = 0; i < list1.length; i++) {
                if (list1[i].id == details.value.id) {
                    list1.splice(i, 1)
                }
            }
        }
        for (let l = 0; l < LeftList.value.length; l++) {
            if (LeftList.value[l].id == details.value.id) {
                LeftList.value[l].draft = false
                LeftList.value[l].draftMessage = ''
            }
        }
    }
    conversationStore.SET_Draft(list1)
    takeDraft(list1)
}
// 取草稿
function takeDraft(val) {
    if (!val || val.length < 1) return
    let list1 = [] //置顶列表
    let list2 = [] //非置顶列表
    LeftList.value.map((item) => {
        item.topSign > 0 ? list1.push(item) : list2.push(item)
    })
    for (let i = 0; i < val.length; i++) {
        for (let y = 0; y < list1.length; y++) {
            if (val[i].id == list1[y].id) {
                list1[y].draft = true
                list1[y].draftMessage = val[i].message
            }
        }
    }

    for (let i = 0; i < val.length; i++) {
        for (let y = 0; y < list2.length; y++) {
            if (val[i].id == list2[y].id) {
                list2[y].draft = true
                list2[y].draftMessage = val[i].message
            }
        }
    }
    for (let i = 0; i < list1.length; i++) {
        if (val[0] && list1[i].id == val[0].id) {
            var temp = list1[i]
            list1[i] = list1[0]
            list1[0] = temp
        }
    }
    for (let i = 0; i < list2.length; i++) {
        if (val[0] && list2[i].id == val[0].id) {
            var temp = list2[i]
            list2[i] = list2[0]
            list2[0] = temp
        }
    }
    LeftList.value = [...list1, ...list2]
}
// 获取草稿的详情
function getDetailDraft(){
    let list = conversationStore.draft
    if(list && list.length > 0) {
        if(detailsId.value == list[0].id) {
            if(list[0].AllFileList && list[0].AllFileList.length > 0) {
                AllFileList.value = list[0].AllFileList
                refMessageId.value = list[0].id
                RightMessage.value = list[0].AllFileList[0]
            }
        }
    } else {
        AllFileList.value = []
        refMessageId.value = ''
        RightMessage.value= []
    }
}
// 查询@成员===旧版
function workTopicFindGroupMemberList(keywords, type) {
    // x.value= document.getElementById('myinput').getBoundingClientRect().left + 60 + 'px'
    // y.value = document.getElementById('myinput').getBoundingClientRect().top - 180 + 'px'
    aitelist.value = []
    let detailId = details.value.id
    if(type && type == '1') {
        detailId = detailsId.value
    } else {
        Mask.value.style.display = 'inline'
        Aite.value.style.display = 'inline'
    }
    ctms({ chatId: detailId, keyword:keywords, }).then((res) => {
        if (res.data && res.data.length) {
            aitelist.value = res.data
        }
    })
}
// 查询@成员===新版
function workTopicFindGroupMemberList1(keywords, type) {
    let detailId = details.value.id
    if(type && type == '1') {
        detailId = detailsId.value
    }
    ctms({ chatId: detailId, keyword:keywords, }).then((res) => {
        if (res.data && res.data.length) {
            aitelist.value = res.data
            aitelist.value.unshift({headUrl:'', mname:'所有人', id:'-1'})
            aitelist.value.forEach(el => {
                el.value = el.mname
                el.avatar = el.headUrl
            });
        }
    })
}
// 下载
function OpenDownloadPop() {
    DownLoadList.value = []
    ChoseListMsgId.value.map((item) => {
        HistoricalList.value.map((items) => {
            if (item == items.id && items.fileUrl) {
                DownLoadList.value.push(items)
            }
        })
    })
    DownloadPopDialog.value = true
}
// 关闭多选
function CloseMultipleChoice() {
    MultipleChoice.value = false
    ChoseListMsgId.value = []
    HistoricalList.value.map((item) => (item.checked = false))
}
function Multiple() {
    document.getElementById('RightQuotePop').style.display = 'none'
    RightQuotePop.value = false
    RightDetailPop.value = false
    ChoseListMsgId.value = []
    MultipleChoice.value = true
    rightPopup.value = false
}
function HistoricalItemChecked(e) {
    if (MultipleChoice.value) {
        ChoseListMsgId.value.indexOf(e.id) != -1 ? ChoseListMsgId.value.splice(ChoseListMsgId.value.indexOf(e.id), 1) : ChoseListMsgId.value.push(e.id)
        // e.checked = !e.checked
    }
}
function inputFocus() {
    emit('CloseCursor')
}
function inputBlur() {
    emit('cursor')
}
// 上传进度
function loadingNumber(e) {
    // console.log('e', e)
    let list = []
    UploadingList.value.map((item) => {
        list.push(item.name)
    })
    if (list.indexOf(e.name) !== -1) {
        for (let i = 0; i < UploadingList.value.length; i++) {
            if (UploadingList.value[i].name == e.name) {
                UploadingList.value[i].progress = e.progress
            }
        }
    } else {
        UploadingList.value.push(e)
    }
}
// 退出话题
function signOut() {
    openSet(1, false)
    workGroupFindPersonalMsgList()
    // 查询标题
    getDetailTItle()
}
function FileOffset(e) {
    locationId.value = e.messageId
    setTimeout(function () {
        document.getElementById(`${e.messageId}`).scrollIntoView()
    }, 300)
    setTimeout(function () {
        locationId.value = ''
    }, 3000)
}
//文件预览
function preview(row) {
    previewFile(row)
    // showview({
    //     fileurl: row
    // }).then((res) => {
    //     if (res.code == 0) {
    //         window.open(res.data)
    //     } else {
    //         ElMessage({ message: '该附件格式不支持在线预览！', offset: 100, type: 'warning' })
    //     }
    // })
}
function ClickMark() {
    rightPopup.value = false
    RightDetailPop.value = false
    RightQuotePop.value = false
    RightAitePop.value = false
    document.getElementById('RightQuotePop').style.display = 'none'
    Mask.value.style.display = 'none'
    detailsPop.value.style.display = 'none'
    // Aite.value.style.display = 'none'
}
//  查询用户信息
function workGroupFindFriendInfo(item, e, type) {
    if (MultipleChoice.value) return
    const userIdT = type=='chatTop'?details.value.toUserId:item.sendUserId
    bCard({
        userId: userIdT
    }).then((res) => {
        FriendInfo.value = res.data
        Mask.value.style.display = 'inline'
        let div = detailsPop.value
        div.style.display = 'inline'
        if (type == 'TopicSetting') {
            div.style.left = e.clientX - 314 + 'px'
        } else if (item.id == userId.value) {
            div.style.left = e.clientX - 314 + 'px'
        } else {
            div.style.left = e.clientX + 'px'
        }
        div.style.top = e.clientY - 20 + 'px'
    })
}
// 点击名片聊天
function ChoseMailList(item) {
    detailsPop.value.style.display = 'none'
    Mask.value.style.display = 'none'
    if (disable.value) return
    disable.value = true
    sgct({userId: item.id}).then((res) => {
        ClickMark()
        if (res.code == 0) {
            TopicSettingShow.value = false
            // details.value = item
            recordShow.value = true
            HistoricalList.value = []
            ChoseDetails(res.data)
            synchronization()
            disable.value = false
        } else {
            ElMessage({
                message: res.message,
                type: 'error',
                duration: 3000
            })
            disable.value = false
        }
    })
}
function getCaret(ele) {
    if (ele.selectionStart) {
        return ele.selectionStart
    } else if (!document.selection) {
        return 0
    }
    // var c = `\001`
    ;(sel = document.selection.createRange()), (dul = sel.duplicate()), (len = 0)
    dul.moveToElementText(node)
    // sel.text = c;
    len = dul.text.indexOf(c)
    sel.moveStart('character', -1)
    sel.text = ''
    return len
}
// 选择@人员
function ChoseAite(item) {
    choseListData.value.push(item)
    // if (item.id == 'all') {
    //     message.value = message.value.substring(0, text_leftLength.value + 1) + '所有人' + ' ' + text_right.value
    //     reminderMemberIdList.value.push({ id: '-1', mname: '所有人' })
    // } else {
    //     message.value = message.value.substring(0, text_leftLength.value + 1) + item.mname + ' ' + text_right.value
    //     reminderMemberIdList.value.push(item)
    // }
    messageOld.value = message.value
    document.getElementById('myinput').focus()
    // Aite.value.style.display = 'none'
    // Mask.value.style.display = 'none'
}
function ChangeInput(e) {
        // x.value= document.getElementById('myinput').getBoundingClientRect().left + 60 + 'px'
        // y.value = document.getElementById('myinput').getBoundingClientRect().top - 180 + 'px'
        // 删除at人员
        if (messageOld.value.length - e.length > 0) {
            for (let i = 0; i < reminderMemberIdList.value.length; i++) {
                const d = text_left.value.length - reminderMemberIdList.value[i].mname.length
                if (d >= 0 && text_left.value.lastIndexOf(reminderMemberIdList.value[i].mname) === d) {
                    message.value = messageOld.value.substring(0, d - 1) + text_right.value
                    reminderMemberIdList.value.splice(i, 1)
                    Aite.value.style.display = 'none'
                    return
                }
            }
        }
        let aa = document.getElementById('myinput')
        var index = getCaret(aa)
        var text = aa.value
        var text_left1 = text.substring(0, index)
        var text_right1 = text.substring(index)
        text_right.value = text_right1
        text_left.value = text_left1
        text_leftLength.value = index
        let msg = message.value.substring(index + 1).replace(text_right1, '')
        if (text_left1 && text_left1[index-1] == '@' && msg.split(' ').length < 2) {
            // console.log('在中间加')
            workTopicFindGroupMemberList(msg)
            return
        }

        if ((text_left1[0] == '@' || text_left1[text_leftLength.value] == '@') && message.value.substring(text_leftLength.value + 1).split(' ').length < 2) {
            let texts = message.value.substring(text_leftLength.value + 1)
            workTopicFindGroupMemberList(texts)
            return
        }
        Aite.value.style.display = 'none'
}
// 输入框
function searchhandle(event) {
    if (sendKey.value == '1') {
        // ctrl+enter
        if (event.ctrlKey && event.keyCode == 13) {
            event.preventDefault()
            workTopicSendTopicMessage()
        }
    } else if (sendKey.value == '0') {
        // enter
        if (event.keyCode == 13) {
            if (event.ctrlKey) {
                event.preventDefault()
                textareaRange()
            } else {
                event.preventDefault()
                workTopicSendTopicMessage()
            }
        }
    }
    // event.preventDefault()
    // workTopicSendTopicMessage()
    messageOld.value = message.value
    if (event.keyCode == 32) {
        text_rightLength.value = 0
        text_leftLength.value = 0
        Aite.value.style.display = 'none'
    }
    // @功能
    if (((event.shiftKey && event.keyCode == 50) || (event.shiftKey && event.keyCode == 229)) && details.value.chatType != 3) {
        let aa = event.target
        var index = getCaret(aa)
        var text = aa.value
        text_leftLength.value = 0
        text_rightLength.value = 0
        var text_left = text.substring(0, index)
        var text_right = text.substring(index)
        text_right.value = text_right
        text_left.value = text_left
        text_leftLength.value= text.substring(0, index).length
        text_rightLength.value = text.substring(index).length
        text = text_left + '<span id="anchor"></span>' + text_right
        document.getElementById('text_copy').html(text)
        x.value= document.getElementById('anchor')[0].getBoundingClientRect().left + 30 + 'px'
        y.value = document.getElementById('anchor')[0].getBoundingClientRect().top - 180 + document.getElementById('input_copy_anchor').height() + 'px'
    }
}
//换行并重新定位光标位置
function textareaRange(){
    let e = window.event || arguments[0]
    let dIndex = e.srcElement.selectionStart//光标所在位置
    message.value = message.value.slice(0,dIndex)+'\n'+message.value.slice(dIndex)
    // 更新光标位置
    setTimeout(() => {
        e.srcElement.selectionStart = dIndex+1
        e.srcElement.selectionEnd = dIndex+1
    });
}
// 搜索打开历史记录详情
function dialogueHis(item,keywords3, type) {    
    keywords2.value = keywords3
    if (type && type == '5') {
        hisSource.value = '2' //不显示输入框
        HistoricalShow.value = true
        details.value = item
        setTimeout(() => {
            historical1.value.newInit()
        }, 300);
    } else {
        details.value = item
        recordShow.value = true
        HistoricalShow.value = false
        hisSource.value = '1'
        detailsId.value = item.id
        getDetailTItle()
    }
}
// 输入框打开历史记录
function openHis() {
    hisSource.value = '1'
    keywords2.value = ''
    TopicSettingShow.value = false
    JournalShow.value = false
    HistoricalShow.value = true
    conversationStore.setSetShow(TopicSettingShow.value)
}
// 删除附件或引用
function CloseFileList(item) {
    if (item.id) refMessageId.value = ''
    for (let i = 0; i < AllFileList.value.length; i++) {
        if (AllFileList.value[i].fileUrl == item.fileUrl) AllFileList.value.splice(i, 1)
    }
    for (let i = 0; i < fileLists.value.length; i++) {
        if (fileLists.value[i].fileUrl == item.fileUrl) fileLists.value.splice(i, 1)
    }
    for (let i = 0; i < fileList.value.length; i++) {
        if (fileList.value[i].fileUrl == item.fileUrl) fileList.value.splice(i, 1)
    }
    for (let i = 0; i < NotepadList.value.length; i++) {
        if (NotepadList.value[i].fileUrl == item.fileUrl) NotepadList.value.splice(i, 1)
    }
    draft()
}
// 转发
function ClickForWard() {
    selectMore.value = true
    rightPopup.value = false
    TopicSettingShow.value = false
    conversationStore.setSetShow(TopicSettingShow.value)
    setImgList.value = []
    selectTitle.value = '转发'
    source.value = '2'
    selUserType.value = '2'
    setTimeout(() => {
        if(workSelectMore.value) {
            workSelectMore.value.init()
        }
    });
}
// 历史记录转发回调
function hisBack(val){
    ChoseListMsgId.value = val
    ClickForWard()
}
// 历史记录===发消息
function callBackHis(val){
    details.value = val
    detailsId.value = val.id
    closeHis()
    getDetailTItle()
}
// 选择用户回调
function ChoseUser(e) {
    let groupInfoIdList = []
    e.map((item) => {
        groupInfoIdList.push(item.id)
    })
    let MessageId = []
    if (ChoseListMsgId.value.length) {
        MessageId = ChoseListMsgId.value
    } else {
        MessageId.push(RightMessage.value.id)
    }
    fwdMsg({
        chatIds: groupInfoIdList,
        recordIds: MessageId,
    }).then((res) => {
        MultipleChoice.value = false
        ChoseListMsgId.value = []
        HistoricalList.value.map((item) => (item.checked = false))
        ElMessage({
            message:res.code == 0 ? '转发成功' : res.message,
            type: res.code == 0 ? 'success' : 'error',
            duration: 3000
        })
    })
}
// 获取选中的文字
function getText() {
    seltxt.value = ''
    seltxt.value = window.getSelection ? (window.getSelection()).toString() : document.selection.createRange().text
}
// 复制内容
function Copy() {
    let msg = ''
    if (seltxt.value) {
        msg = seltxt.value
    } else {
        msg = RightMessage.value.chatMsg
    }
    msg = msg.replaceAll('<span>', '')
    msg = msg.replaceAll('</br>', '\n')
    msg = msg.replaceAll('</span>', '')
    const text = msg
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    ElMessage({ type: 'success', message: '复制成功' })
    Mask.value.style.display = 'none'
    rightPopup.value = false
}
// 下载附件
function download(item) {
    let data = ''
    if (item) {
        data = [
            {
                id: RightMessage.value.id,
                fileSize: '',
                fileName: RightMessage.value.fileName,
                fileSize: '',
                fileUrl: RightMessage.value.fileUrl
            }
        ]
        if(RightMessage.value.msgType !='2' && RightMessage.value.msgType !='3') {
            ElMessage({ message: '只能保存文件到电脑！', type: 'warning',duration: 3000 })
            return
        }
        RightMessage.value.readSign = '3'
    } else {
        data = DownLoadList.value
        for(let i=0;i<data.length;i++) {
            if(data[i].msgType !='2' && data[i].msgType !='3') {
                ElMessage({ message: '只能保存文件到电脑！', type: 'warning',duration: 3000 })
                return
            }
        }
        DownLoadList.value.forEach(el => {
            el.readSign = '3'
        });
    }
    download1(data).then((res) => {
        ExportDownloadPost(res)
        MultipleChoice.value = false
        Mask.value.style.display = 'none'
        rightPopup.value = false
    })
    HistoricalList.value.map((item) => {
        item.checked = false
    })
}
// 引用
function quote() {
    rightPopup.value = false
    for (let i = 0; i < AllFileList.value.length; i++) {
        if (AllFileList.value[i].id) {
            AllFileList.value.splice(i, 1)
        }
    }
    AllFileList.value.unshift(RightMessage.value)
    refMessageId.value = RightMessage.value.id
    RightMessage.value.refId = RightMessage.value.id
    document.getElementById('myinput').focus()
}
// 右键设置
function TopicSettingF() {
    RightDetailPop.value = false
    ChoseDetails(RightDetails.value, true)
    // 打开设置栏
    if(RightDetails.value && RightDetails.value.id) {
        detailsId.value = RightDetails.value.id
        setTimeout(() => {
            openSet(1, true)
        }, 300);
    }
}
// 聊天悬浮时==删除会话
function workGroupDeleteChatS(item){
    RightDetails.value = item
    workGroupDeleteChat(0)
}
// 删除聊天
function workGroupDeleteChat(type) {
    RightDetailPop.value = false
    dpct({
        chatId: RightDetails.value.id
    }).then((res) => {
        if (res.code == 0) {
            if (RightDetails.value.id == details.value.id) details.value= {}
            openSet(1, false, '4' )
            workGroupFindPersonalMsgList()
        }
        ElMessage({
            message:res.code == 0 ? '操作成功' : res.message,
            type: res.code == 0 ? 'success' : 'error',
            duration: 3000
        })
    })
}
// 聊天置顶
function workMyNotepadIsTop() {
    RightDetailPop.value = false
    tpct({
        topSign: RightDetails.value.topSign=='0'?'1':'0',
        chatId: RightDetails.value.id
    }).then((res) => {
        if (res.code == 0) {
            workGroupFindPersonalMsgList()
            if(TopicSettingShow.value) {
                TopicSetting.value.workTopicFindTopicSetting()
            }
        }
        ElMessage({
            message:res.code == 0 ? '操作成功' : res.message,
            type: res.code == 0 ? 'success' : 'error',
            duration: 3000
        })
    })
}
// 引用定位
function ClickQuotePop() {
    document.getElementById('RightQuotePop').style.display = 'none'
    RightQuotePop.value = false
    pstmsg({
        chatId: details.value.id,
        msgTime: HistoricalList.value[0].sendTime,
        recordId: RightDetails.value.refMsg.refId
    })
        .then((res) => {
            if(res.data && res.data.length > 0) {
                HistoricalList.value = res.data
            }
            setTimeout(() => {
                FileOffset({ messageId: RightDetails.value.refMsg.refId, offset: 0 })  
            });
        })
        .catch((err) => {})
}
// 右击引用列表
function openQuote(e, item) {
    if (MultipleChoice.value) return
    RightDetails.value = item
    RightQuotePop.value = true
    document.getElementById('RightQuotePop').style.left = e.clientX + 'px'
    document.getElementById('RightQuotePop').style.top = e.clientY + 'px'
}
// 右击左侧列表
function LeftListOpenMenu(e, item) {
    RightDetails.value = item
    RightDetailPop.value = true
    document.getElementById('RightDetailPop').style.left = e.clientX + 'px'
    document.getElementById('RightDetailPop').style.top = e.clientY - 20 + 'px'
}
// 聊天记录列表
function workGroupFindPersonalMsgList(type) {
    let list = conversationStore.draft
    if (type && type != '推送') {
        if (list.length) {
            list.some((item) => {
                if (item.id == details.value.id) {
                    fileLists.value = item.fileLists
                    message.value = item.message
                    fileList.value = item.fileList
                    NotepadList.value = item.NotepadList
                    refMessageId.value = item.refMessageId
                    reminderMemberIdList.value = item.reminderMemberIdList
                    AllFileList.value = item.AllFileList
                }
            })
        }
    }
    ctls().then((res) => {
        LeftList.value = []
        for (let i = 0; i < res.data.length; i++) {
            res.data[i].draft = false //是否有草稿
            res.data[i].imgList = []
            if (details.value.id == res.data[i].id) res.data[i].readCount = 0
            for (let y = 0; y < res.data[i].picList.length; y++) {
                res.data[i].imgList.push(urlImg.value + res.data[i].picList[y].headUrl)
            }
            if (res.data[i].lastChatRecord) {
                res.data[i].lastChatRecord = res.data[i].lastChatRecord.replaceAll('<span>', ' ')
                res.data[i].lastChatRecord = res.data[i].lastChatRecord.replaceAll('</span>', ' ')
                res.data[i].lastChatRecord = res.data[i].lastChatRecord.replaceAll('<p>', ' ')
                res.data[i].lastChatRecord = res.data[i].lastChatRecord.replaceAll('</p>', ' ')
                res.data[i].lastChatRecord = res.data[i].lastChatRecord.replaceAll('</br>', ' ')
            }
        }
        // 去掉超链接
        res.data.map((item) => {
            if (item.lastChatRecord) item.lastChatRecord = item.lastChatRecord.replace(/<a href/g, '<a')
        })
        LeftList.value = res.data
        LeftShow.value = false
        takeDraft(list)
    })
}

// 查询未读消息
function getMyWorkNums() {
    if(getToken()) {
        urcount().then(res => {
            if (res.code == 0) {
                headStore.setConNum(res.data)
            }
        })
    }
}
// 几人提到了我
function AiteLocation() {
    let msgId = reminderMsg.value[0]
    pstmsg({
        chatId: details.value.id,
        recordId: msgId,
        msgTime: ''
    })
        .then((res) => {
            reminderMsg.value.shift()
            FileOffset({ messageId: msgId, offset: 0 })
        })
        .catch((err) => {})
}
function NewShows() {
    NewShow.value = false
    scrollTop()
}
 // 进入聊天详情
 function ChoseDetails(item, show) {
    msgTime.value = ''
    detailsId.value = item.id
    MultipleChoice.value = false
    ChoseListMsgId.value = []
    reminderMsg.value = []
    remove.value = false
    reminderMemberIdList.value = []
    message.value = ''
    if (details.value.isExist === 0) {
        remove.value = true
    }
    offsetList.value = []
    AllFileList.value = []
    UploadingList.value = []
    fileLists.value = []
    NotepadList.value = []
    fileList.value = []
    localStorage.setItem('MyWrokWindow', JSON.stringify(details.value))
    newMessageCount.value = 25
    offset.value = 0
    keywords.value = ''
    memberId.value = ''
    refMessageId.value = ''
    messageType.value = '1'
    sortType.value = '1'
    HistoricalShow.value = false
    JournalShow.value = false
    isDynamics.value = false
    // 查询详情标题
    getDetailTItle()
    workGroupFindIsReminderCount() //查询@数量
    // workTopicFindGroupMemberList1()//查询@成员，标注颜色使用
    // setTimeout(() => {
    //     takeDraft()
    // });
    // $parent.selId = item.id
    openSet(true)
}
// 查询详情标题
function getDetailTItle(valId){
    let dataId = detailsId.value
    if(valId) {
        dataId = valId
        detailsId.value = valId
    }
    details.value = []
    ict({chatId: dataId}).then((res) => {
        if (res.code == 0) {
            details.value = res.data
            if(details.value.deleteFlag =='1') {
                openSet(1, false)
            } else if(details.value.chatType =='5') {
                setTimeout(() => {
                    serviceslist1.value.initFun()
                });
            }
            // 草稿回填
            let list = conversationStore.draft
            if(list && list.length > 0 && list[0].id == details.value.id) {
                message.value = list[0].message
                AllFileList.value = list[0].fileLists.concat(list[0].fileList).concat(list[0].NotepadList)
                fileLists.value = list[0].fileLists
                fileList.value = list[0].fileList
                NotepadList.value = list[0].NotepadList
            }
            workTopicFindChatRecordList()
            if(details.value.chatType == '4') {
                // 服务群可以查询@人员
                workTopicFindGroupMemberList1()
            }
            // 判断弹窗的高度和宽度
            setTimeout(() => {
                getNewsWidth()
                getNewsHeight()
            });
            // 滚动到底部
            scrollToBottom()
        }
    })
}
// 查询快捷键
function getUserKey(){
    umsk().then((res) => {
        if (res.code == 0) {
            sendKey.value = res.data.sendKey
            //  0：Enter；1：Ctrl+Enter
            if(sendKey.value =='0') {
                placeholder.value = '按Ctrl+Enter键换行，按Enter键发送'
            } else {
                placeholder.value = '按Enter键换行，按Ctrl+Enter键发送'
            }
        }
    })
}
// 选择发送方式
function handleCommand(command) {
    sendKey.value = command
    if(command =='0') {
        placeholder.value = '按Ctrl+Enter键换行，按Enter键发送'
    } else {
        placeholder.value = '按Enter键换行，按Ctrl+Enter键发送'
    }
    setTimeout(() => {
        updateUserKey()
    });
}
// 修改快捷键 快捷发送方式（0：Enter；1：Ctrl+Enter） sendKey
function updateUserKey(){
    sumsk({sendKey: sendKey.value}).then((res) => {})
}
// 清除聊天记录 刷新
function ClearChatRecord(e) {
    workGroupFindPersonalMsgList()
    workTopicFindChatRecordList()
}
// 设置
function Refresh(e) {
    // 查询列表
    workGroupFindPersonalMsgList()
    // 查询标题
    getDetailTItle()
}
// 撤回消息
function workTopicRecallChatRecord() {
    rightPopup.value = false
    ElMessageBox.confirm('确定撤回？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        rbctr({
            id: RightMessage.value.id,
        }).then((res) => {
            if (res.code == 0) {
                if (HistoricalList.value.indexOf(RightMessage.value) !== -1) {
                    HistoricalList.value.splice(HistoricalList.value.indexOf(RightMessage.value), 1)
                }
            }
            ElMessage({
                message:res.code == 0 ? '操作成功' : res.message,
                type: res.code == 0 ? 'success' : 'error',
                duration: 3000
            })
        })
    })
}
// 历史记录的删除消息
function removalMessage(e) {
    if (typeof e == 'string') {
        // 单删
        for (let i = 0; i < HistoricalList.value.length; i++) {
            if (HistoricalList.value[i].id == e) {
                HistoricalList.value.splice(i, 1)
                return
            }
        }
    } else {
        // 删除多条
        for (let i = 0; i < e.length; i++) {
            for (let y = 0; y < HistoricalList.value.length; y++) {
                if (e[i] == HistoricalList.value[y].id) {
                    HistoricalList.value.splice(y, 1)
                }
            }
        }
    }
}
// 删除消息
function workTopicDeleteChatRecord() {
    rightPopup.value = false
    ElMessageBox.confirm('是否确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        let list = []
        if (ChoseListMsgId.value.length) {
            list = ChoseListMsgId.value
        } else {
            list.push(RightMessage.value.id)
        }
        dctr({
            ids: list
        }).then((res) => {
            if (res.code == 0) {
                for (let i = 0; i < list.length; i++) {
                    for (let y = 0; y < HistoricalList.value.length; y++) {
                        if (list[i] == HistoricalList.value[y].id) {
                            HistoricalList.value.splice(y, 1)
                        }
                    }
                }
                if (HistoricalList.value.indexOf(RightMessage.value) !== -1) {
                    HistoricalList.value.splice(HistoricalList.value.indexOf(RightMessage.value), 1)
                }
                MultipleChoice.value = false
            }
            ElMessage({
                message:res.code == 0 ? '操作成功' : res.message,
                type: res.code == 0 ? 'success' : 'error',
                duration: 3000
            })
        })
    })
}
//右击人名at
function RightAite(e, item) {
    if (details.value.chatType != 4 && item.sendUserId != userId.value) {
        RightAitePop.value = true
        RightMessage.value = item
        document.getElementById('RightAitePop').style.left = e.clientX + 'px'
        document.getElementById('RightAitePop').style.top = e.clientY - 20 + 'px'
        RightAiteName.value = '@' + item.sendUserName
    }
}
function ClickRightAite() {
    message.value = message.value + '@' + RightMessage.value.sendUserName + ' '
    reminderMemberIdList.value.push({ id: RightMessage.value.sendUserId, mname: RightMessage.value.sendUserName })
    messageOld.value = message.value
    document.getElementById('myinput').focus()
    ClickMark()
}
// 右击
function openMenu(e, item) {
    if (MultipleChoice.value) return
    RightMessage.value = item
    downloadShow.value = item.msgType == 1 ? false : true
    rightPopup.value = true
    document.getElementById('groupInfoId').style.left = e.clientX + 'px'
    document.getElementById('groupInfoId').style.top = e.clientY - 20 + 'px'
    if(item.msgType == '4') {
        item.quote4Text = ''
        const quote4 = document.createElement('span');
        quote4.innerHTML = item.chatMsg;
        item.quote4Text = quote4.innerText
    }
}
function strTrim(strLength, str) {
    if (str.length != 0 && strLength != str.length) {
        strLength = str.length
        strTrim(strLength, str.trim())
    } else {
        if (str.length == 0) {
            message.value = ''
            return false
        } else {
            return true
        }
    }
}
// 发送消息
function workTopicSendTopicMessage() {
    if (Disable.value) {
        return
    }
    Disable.value = true
    let myFileIdList = []
    fileList.value.map((item) => {
        myFileIdList.push(item.folderId)
    })
    let myNotepadList = []
    NotepadList.value.map((item) => {
        myNotepadList.push(item.folderId)
    })
    let regu = '^[ ]+$'
    let re = new RegExp(regu)
    if ((message.value && !re.test(message.value) && !message.value.match(/^\s*$/)) || fileLists.value.length || fileList.value.length || NotepadList.value.length) {
        strTrim(0, message.value)
        if (re.test(message.value)) {
            message.value = ''
        }
        let message1 = ''
        let reg = /(http:\/\/|https:\/\/|www\.)((\w|=|\.|\/|&|-)+)/g
        message1 = message.value.replace(reg, function (arg) {
            let cur_str = arg
            if (arg.indexOf('http') == -1 && arg.indexOf('https') == -1) {
                cur_str = 'http://' + arg
            }
            return ' ' + cur_str
        })
        let bbb = message1.replace(/(\r\n)|(\n)/g, '<nn>').split(' ')
        let list = []
        bbb.forEach((el) => {
            // 说明包含换行
            if (el.includes('<nn>')) {
                let nnList = el.split('<nn>')
                nnList.forEach((e, index) => {
                    if (e.includes('https://')) {
                        list.push("<a href='" + e + "' target='_blank'>" + e + '</a>')
                    } else if (e.includes('http://')) {
                        list.push("<a href='" + e + "' target='_blank'>" + e + '</a>')
                    } else if (e.includes('www.')) {
                        list.push("<a href='http://" + e + "' target='_blank'>" + e + '</a>')
                    } else {
                        list.push(e)
                    }
                    if (nnList.length - 1 > index) list.push('<nn>')
                })
            } else {
                if (el.includes('https://')) {
                    list.push("<a href='" + el + "' target='_blank'>" + el + '</a>')
                } else if (el.includes('http://')) {
                    list.push("<a href='" + el + "' target='_blank'>" + el + '</a>')
                } else if (el.includes('www.')) {
                    list.push("<a href='http://" + el + "' target='_blank'>" + el + '</a>')
                } else {
                    list.push(el)
                }
            }
        })
        let list1 = []
        list.forEach((item) => {
            item == '<nn>' ? list1.push('\n') : list1.push(item)
        })
        message1 = list1.join(' ')
        let reminderMemberIdList = []
        // 匹配包含完整的at信息
        choseListData.value.map((item) => {
            if (message1.indexOf('@' + item.mname) != -1) reminderMemberIdList.push(item.id)
        })
        reminderMemberIdList = Array.from(new Set(reminderMemberIdList))
        if(!fileLists.value || fileLists.value.length < 1) {
            fileLists.value = []
        }
        if(!NotepadList.value || NotepadList.value.length < 1) {
            NotepadList.value = []
        }
        // let AllTemp = fileLists.value.concat(fileList.value).concat(NotepadList.value)
        sendMsg({
            files: fileLists.value,
            chatId: details.value.id,
            chatMsg: message1 ? '<span>' + message1 + '</span>' : '',
            refMsg: refMessageId.value?RightMessage.value:{},
            atIds: reminderMemberIdList,
        }).then((res) => {
            Disable.value = false
            conversationStore.SET_Draft(list)
            // 查询详情
            // workTopicFindChatRecordList()
            //查询@数量
            // workGroupFindIsReminderCount()
        }).catch((err) => {
            Disable.value = false
        })
        AllFileList.value = []
        fileLists.value = []
        NotepadList.value = []
        fileList.value = []
        message.value = ''
        RightMessage.value = {}
        reminderMemberIdList.value = []
        refMessageId.value = ''
        draft()
        // 滚动到底部
        scrollToBottom()
    } else {
        Disable.value = false
    }
}
function ChoseType(e) {
    type.value = e
    if (e == '2') {
        title.value = '我的文件'
        MoveShow.value = true
        setTimeout(() => {
            moveBox.initFun()  
        });
    } else if (e == '1') {
        title.value = '记事本'
        MoveShow.value = true
        setTimeout(() => {
            moveBox.initFun()  
        });
    }
}
// 设置
function openSet(type, val, showType) {
    if (type == 1) {
        TopicSettingShow.value = val
    } else {
        TopicSettingShow.value = !TopicSettingShow.value
    }
    conversationStore.setSetShow(TopicSettingShow.value)
    setTimeout(() => {
        emit('showRight', TopicSettingShow.value)
        if (TopicSettingShow.value == true) {
            TopicSetting.value.workTopicFindTopicSetting()
        }
        getNewsWidth()
    });
}
function bytesToSize(bytes) {
    if (bytes === 0) return '0 B'
    var k = 1024
    var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    var i = Math.floor(Math.log(bytes) / Math.log(k))
    return (bytes / Math.pow(k, i)).toPrecision(3) + sizes[i]
}
function choseItemList(e) {
    if (type.value == 2) {
        if(e && e.length > 0) {
            e.map((item) => {
                if (AllFileList.value.length < 11) {
                    item.fileUrlTemp = urlImg.value + item.fileUrl
                    item.AllSize = bytesToSize(item.fileSize)
                    AllFileList.value.unshift(item)
                    fileList.value.unshift(item)
                } else {
                    ElMessage({
                        message:'最多上传11个文件',
                        type: 'error',
                        duration: 3000
                    })
                   document.getElementById('myinput').focus()
                    return
                }
            })
        }
    } else {
        e.map((item) => {
            if (AllFileList.value.length < 11) {
                item.fileUrlTemp = urlImg.value + item.fileUrl
                item.AllSize = bytesToSize(item.fileSize)
                AllFileList.value.unshift(item)
                NotepadList.value.unshift(item)
            } else {
                ElMessage({
                    message:'最多上传11个文件',
                    type: 'error',
                    duration: 3000
                })
                document.getElementById('myinput').focus()
                return
            }
        })
    }
    document.getElementById('myinput').focus()
    draft()
}
// 上传回调
function ChildFileList(e) {
    if(e && e.length > 0) {
        e.map((item) => {
            if (AllFileList.value.length < 11) {
                item.fileSize = item.size
                item.fileUrlTemp = urlImg.value + item.fileUrl
                item.AllSize = bytesToSize(item.size)
                let list = item.fileUrl.split('.')
                item.fileSuffix = list[list.length - 1]
                AllFileList.value.push(item)
                fileLists.value.push(item)
                document.getElementById('myinput').focus()
            } else {
                ElMessage({
                    message:'最多上传11个文件',
                    type: 'error',
                    duration: 3000
                })
                document.getElementById('myinput').focus()
                return
            }
        })
        draft()
    }
}
function scrollTop() {
    setTimeout(() => {
        let targetbox = HistoricalList1.value
        targetbox.scrollTop = targetbox.scrollHeight
        top.value = targetbox.scrollHeight
    },);
}
function openDrawer() {
    drawer.value = true
}
function scrollEvent(e) {
    var scrollTop = e.target.scrollTop
    if (scrollTop == 0 && HistoricalList.value.length) {
        // 触顶
        workTopicFindChatRecordList('触顶')
    }
    if (e.target.scrollHeight == e.target.scrollTop + e.target.clientHeight && HistoricalList.value.length) {
        // 触底
        workTopicFindChatRecordList('触底')
    }
}
// 消息记录 详情
function workTopicFindChatRecordList(type) {
    isBigInput.value = false
    if(HistoricalShow.value == true) {
        return
    }
    NewShow.value = false
    msgTime.value = ''
    if (type == '触顶') {
        offset.value += 20
        msgTime.value = HistoricalList.value[0].sendTime
        if (offsetList.value.indexOf(offset.value) !== -1) {
            offset.value = offsetList.value.value[offsetList.value.length - 1]
            return
        } else {
        }
    } else if (type == '触底') {
        newsNum.value = 0
        if (offsetList.value.length) offset.value = offsetList.value[offsetList.value.length - 1]
        offset.value -= 20
        if (offsetList.value.indexOf(offset.value) !== -1) {
            return
        }
        if (offset.value < 0) {
            offset.value = 0
            return
        }
    } else {
        HistoricalList.value = []
    }
    ctmes({
        chatId: details.value.id,
        querySize: newMessageCount.value,
        msgTime: msgTime.value,
    }).then((res) => {
        if(res.data && res.data.length > 0){
            for (let i = 0; i < res.data.length; i++) {
                res.data[i].quote = false
                res.data[i].checked = false
                let chatMsg = ''
                if(res.data[i].chatMsg) {
                    chatMsg = JSON.parse(JSON.stringify(res.data[i].chatMsg))
                }
                if(chatMsg) {
                    if(chatMsg.indexOf('@') != -1) {
                        // 使用正则表达式进行匹配并提取需要的部分
                        let regex = /@\S+/g;
                        let matches = chatMsg.match(regex);
                        if(matches&&matches.length >0) {
                            matches.forEach(el => {
                                const aIndex = aitelist.value.findIndex(itemA => '@'+itemA.mname == el)
                                if(aIndex != -1 || el=='@所有人') {
                                    res.data[i].chatMsg = replaceAllM(res.data[i].chatMsg, el,'<span style="color:#1492ff">'+el+'</span>')
                                }
                            });
                        }
                    }
                    if(chatMsg.indexOf('.com') != -1 || chatMsg.indexOf('.cn') != -1) {
                        var urlReg= /[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/g;
                        var array = chatMsg.match(urlReg);
                        res.data[i].chatMsg = replaceAllM(res.data[i].chatMsg, array,'<a href=\"http:\/\/'+array+'\">'+array+'</a>')
                    }
                }
            }
            offsetList.value.push(offset.value ? offset.value : 0)
            if (type == '触顶') {
                HistoricalList.value = [...res.data, ...HistoricalList.value]
                if (res.data.length)
                setTimeout(() => {
                    let top1 = HistoricalList1.value.scrollHeight
                    HistoricalList1.value.scrollTop = top1 - top.value
                    top.value = top1
                });
            } else if (type == '触底') {
                HistoricalList.value = [...HistoricalList.value, ...res.data]
            } else {
                HistoricalList.value = []
                HistoricalList.value = [...res.data, ...HistoricalList.value]
                scrollTop()
            }
            getDetailDraft()
        }
    })
}
// 替换@
function replaceAllM(item, sourceStr,newStr){
    let reg = new RegExp(sourceStr,'g')
    return item.replace(reg, newStr)
}
function synchronization() {
    // 聊天记录列表 左侧列表
    workGroupFindPersonalMsgList()
}
function dialogueSynchronization() {
    workTopicFindChatRecordList()
}
// 添加服务群人员
function openStaffSet(title, oldType, newType, val, chatMemberList, buildId1) {
    setImgList.value = []
    selectTitle.value = title
    selectMore.value = val
    source.value = '1'
    setImgList.value = chatMemberList
    // console.log(chatMemberList,'setImgList.value');
    openType.value = oldType
    selUserType.value = '1'
    buildId.value = ''
    if(buildId1) {
        buildId.value = buildId1
    }
    if (val == true) {
        setTimeout(() => {
            workSelectMore.value.init()
        });
    }
}
// 获取选中的人员
function getValue(type, val) {
    if(val.length <=0 || !val) {
        ElMessage({
            message:'请添加会话！',
            type: 'error',
            duration: 3000
        })
        return
    }
    setImgList.value = val
    selectMore.value = false
    // 设置
    if (selUserType.value == '1') {
        TopicSetting.value.updateMembersUser(val)
    } else if(selUserType.value == '2') {
        ChoseUser(val)
    }
}
function getValueCancel(){
    selectMore.value = false
}
// 历史记录返回 ==== 进入聊天
function newDetail(groupInfoId){
    HistoricalShow.value = false
    recordShow.value = true
    if(groupInfoId) {
        details.value.id = groupInfoId
        detailsId.value = groupInfoId
    }
    // 查询列表
    workGroupFindPersonalMsgList()
    // 查询标题
    getDetailTItle()
    //查询@数量
    workGroupFindIsReminderCount()
    // 查询详情
    workTopicFindChatRecordList()
}
// 历史记录返回
function closeHis(){
    HistoricalShow.value=false
    newDetail()
}
function switchClose(){
    recordShow.value=true
    HistoricalShow.value=false
    newDetail()
}
// 滚动到底部
function scrollToBottom() {
    setTimeout(() => {
        let container = document.getElementById("ChatBox")
        if(container) {
            container.scrollTop = container.scrollHeight
        }
    }, 300)
}
// 打开myWork弹窗
function myWorkOpen(){
    if(details.value && details.value.id) {
        ChoseDetails(details.value)
    }
}
// 放大输入框
function openBigInput(){
    isBigInput.value = true
    // setTimeout(() => {
    //     bigInput.value.detailMessage = message.value
    //     bigInput.value.placeholder = placeholder.value
    // });
}
// 还原输入框
function changeBigDetail(val){
    isBigInput.value = false
    message.value = val
    draft()
}
// 实时保存输入的内容
function changeBigDetailTemp(val){
    message.value = val
    draft()
}
onMounted(()=>{
    isBigInput.value = false
    // 查询列表
    synchronization()
    // 滚动到底部
    // scrollToBottom()
    // // 查询快捷键
    getUserKey()
    if(document.getElementById('LeftList')) {
        document.getElementById('LeftList').oncontextmenu = function (e) {
            return false
        }
    }
    // $bus.on('changeTab', (val) => {
    //     selectMore.value = false
    // })
})
function changeDe(val){
    let valt = JSON.parse(val)
    console.log(val,'推送');
    if(valt.chatMsgType =='1') {
        // 查询列表
        workGroupFindPersonalMsgList('推送')
        // 查询mywork是否打开
        const isOpen = conversationStore.myworkOpen
        if(isOpen && details.value.id == valt.chatId) {
            // 已读
            doReadSign(valt)
        }
        // 查询详情
        if ((details.value && details.value.id && valt.chatId && valt.msgBody) && details.value.id  == valt.chatId) {
            let list = []
            list.push(valt.msgBody)
            HistoricalList.value = [...HistoricalList.value, ...list]
            if (valt.toUserId != userIdTemp.value) {
                let targetbox = HistoricalList1.value
                if (!(targetbox.scrollHeight == targetbox.scrollTop + targetbox.clientHeight) && HistoricalList.value.length) {
                    newsNum.value++
                    NewShow.value = true
                } else {
                    scrollTop()
                }
            } else {
                scrollTop()
            }
            setTimeout(() => {
                // 查询未读数量
                getMyWorkNums()
            });
            // 区分@标识
            let chatMsg = ''
            if(valt.msgBody) {
                chatMsg = JSON.parse(JSON.stringify(valt.msgBody)).chatMsg
                if(chatMsg.includes('@')) {
                    // 使用正则表达式进行匹配并提取需要的部分
                    let regex = /@\S+/g;
                    let matches = chatMsg.match(regex);
                    if(matches&&matches.length >0) {
                        matches.forEach(el => {
                            const aIndex = aitelist.value.findIndex(itemA => '@'+itemA.mname == el)
                            if(aIndex != -1 || el=='@所有人') {
                                valt.msgBody.chatMsg = replaceAllM(valt.msgBody.chatMsg, el,'<span style="color:#1492ff">'+el+'</span>')
                            }
                        });
                    }
                }
                // 组装没有http或者www的链接
                if(chatMsg.indexOf('.com') != -1 || chatMsg.indexOf('.cn') != -1) {
                    var urlReg= /[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/g;
                    var array = chatMsg.match(urlReg);
                    valt.msgBody.chatMsg = replaceAllM(valt.msgBody.chatMsg, array,'<a href=\"http:\/\/'+array+'\">'+array+'</a>')
                }
            }
        }
    }
}
// 判断聊天框的宽度 1代表正常聊天，2代表有设置，3代表通讯录或其他页面，4没有消息页面
function getNewsWidth(){
    if(fullscreenCon.value && fullscreenCon.value == true) {
        // 全屏  
        if(TopicSettingShow.value==true) {
            // 有设置 1173px
            isWidthNews.value = 'width:1173px'//整个聊天框的宽度
            isSetbtn.value = 'left:calc(100% - 410px)'//设置按钮的位置
        } else {
            // 无设置 1513px
            isWidthNews.value= 'width:1500px'
            isSetbtn.value = 'left:calc(100% - 70px)'
        }
    } else {
        // 小屏
        isWidthNews.value = 'width:642px'
        isSetbtn.value = 'left:986px'
    }
}
// 判断高度
function getNewsHeight(){
    if(fullscreenCon.value && fullscreenCon.value == true) {
        if((details.value.deleteFlag == '1' || details.value.deleteFlag == '2') && MultipleChoice.value==false) {
            isHeightNews.value = 'height: 90vh;'//整个聊天框的高度
        } else {
            isHeightNews.value = 'height: calc(100vh - 200px)'//整个聊天框的高度
        }
    } else {
        if((details.value.deleteFlag == '1' || details.value.deleteFlag == '2') && MultipleChoice.value==false) {
            isHeightNews.value = 'height: 600px;'//整个聊天框的高度
        } else {
            isHeightNews.value = 'height: 478px;'//整个聊天框的高度
        }
    }
}
// 打开群动态
function openDynamics(){
    keywords2.value = ''
    TopicSettingShow.value = false
    JournalShow.value = false
    HistoricalShow.value = false
    isDynamics.value = true
}
// 关闭群动态
function backDynamics(){
    isDynamics.value = false
}
// 服务通知跳转
function goProDetail(val){
    const news = router.resolve({ name: val.jumpPath, query: data })
    window.open(news.href, '_blank')
}
// 关闭一系列页面
// function closeDiaNew(){
//     TopicSettingShow.value = false
//     JournalShow.value = false
//     HistoricalShow.value = false
//     isDynamics.value = false
//     conversationStore.setSetShow(TopicSettingShow.value)
// }
defineExpose({
    workGroupFindPersonalMsgList,
    getDetailTItle,
    scrollToBottom,
    myWorkOpen,
    // closeDiaNew
})
// computed 计算属性
const scoketObj = computed(() => {
    return conversationStore.scoketObj
})
// watch监听
watch(scoketObj, (newVal) => {
    // console.log('监听数据变化',newVal)
    if(newVal) {
        changeDe(newVal)
    }
})
watch(() => details, (newVal) => {
    if(newVal) {
        workTopicFindChatRecordList()
    }
},{ immediate: true });
watch(() => conversationStore.isFullscreen, (val) => {
    fullscreenCon.value = val
    getNewsWidth()
    getNewsHeight()
}, { immediate: true })
</script>

<style scoped lang="scss">
.conmessage{
    margin-left: 4px;
}
.TopImg {
    border-radius: 3px;
}
.news {
    width: 983px;
    height: 100%;
    background: #fff;
    display: flex;
    border-radius: 4px;
    .news_left{
        height:100%;
        width:342px;
        overflow-y: auto;
        background:#f5f5f5;
        border-right: solid 1px #d5d7e0;
        position: absolute;
        z-index: 10;
        top: 0;
        padding-top: 5px;
    }
}
.search {
    width: 320px;
    display: flex;
    background: #fff;
    height: 28px;
    // margin: 0 0px 10px 10px;
    margin: 5px 0px 5px 10px;
    padding: 5px 8px;
    font-size: 12px;
    // color: #656c7a;
    color: #a8abb2;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid #d5d7e0;
    border-radius: 3px;
}
.title {
    height: 30px;
    font-size: 16px;
    // line-height: 22px;
    color: #000;
    border-bottom: solid 1px #d5d7e0;
    padding-left: 20px;
    margin-top: -15px;
    // margin: 10px 0 14px;
}

.NewShow {
    width: 120px;
    line-height: 28px;
    padding: 0 10px;
    color: #1492ff;
    font-size: 12px;
    position: absolute;
    bottom: 4px;
    background: #edf1ff;
    display: flex;
    justify-content: space-between;
    right: 8px;
    cursor: pointer;
}
.NewShow1 {
    min-width: 120px;
    line-height: 28px;
    padding: 0 10px;
    color: #1492ff;
    font-size: 12px;
    position: absolute;
    bottom: 4px;
    background: #edf1ff;
    display: flex;
    justify-content: space-between;
    right: 8px;
    cursor: pointer;
}
.readCount {
    font-size: 12px;
    position: absolute;
    // z-index: 999;
    top: 3px;
    background: #fa2d2d;
    color: #fff;
    min-width: 16px;
    text-align: center;
    border-radius: 20px;
}

.HistoricalList {
    height: 100%;
    // padding: 10px 20px;
    overflow-y: auto;
    .choseBg {
        background: #f3f6fe;
    }
    .HistoricalItem {
        // width: 594px;
        margin-bottom: 5px;
        padding-bottom: 5px;
        margin: 0 20px 5px;
        .headUrl {
            height: 36px;
            width: 36px;
            margin: 5px;
            cursor: pointer;
            border-radius: 3px;
        }
        .headUrl1{
            max-width: 61px;
            max-height: 34px;
        }
        .mySendOut {
            display: flex;
            // flex-wrap: wrap;
        }
        .reverse {
            flex-direction: row-reverse;
        }
        .messages {
            background: #EBECF0;
            color: #000;
            font-size: 12px;
            padding: 5px 10px;
            max-width: 775px;
            font-size: 14px;
            border-radius: 3px;
        }
        .message {
            background: #dfe7fc;
            color: #000;
            padding: 5px 10px;
            font-size: 12px;
            max-width: 775px;
            font-size: 14px;
            border-radius: 3px;
        }
        .fileItem {
            background: #fff;
            color: #000;
            border: solid 1px #d5d7e0;
            font-size: 12px;
            padding: 5px 10px;
            width: 300px;
            border-radius: 3px;
        }
        .downtag{
            width: 40px;
            height: 16px;
            background: #e6a23c;
            color: #fff;
            position: absolute;
            right: 0;
            bottom: 0;
            text-align: center;
            border-radius: 3px 3px 0 0;
        }
    }
}

.sendOut {
    position: absolute;
    bottom: -40px;
    right: 20px;
    border-radius: 3px;
}
.sendOut:hover {
    background: #7496ff;
    border-radius: 3px;
}
.AllFileList {
    position: relative;
    font-size: 12px;
    margin-left: 10px;
    // bottom: 15px;
    // width: 1200px;
    // left: 10px;
    display: flex;
    flex-wrap: wrap;
    // overflow: auto;
    // height: 50px;
    .CloseFileList {
        position: absolute;
        right: 15px;
        cursor: pointer;
    }
    .quote {
        background: #f2f5f9;
        color: #666;
        padding: 5px;
        line-height: 18px;
        height: 44px;
        border-radius: 3px;
    }
    .file {
        display: flex;
        align-items: center;
        height: 44px;
        width: 280px;
        padding-left: 5px;
        background: #f2f5f9;
        color: #000;
        font-size: 12px;
    }
}
.Disable {
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 36px;
    height: 36px;
    // top: 10px;
    top: 13px;
    border-radius: 3px;
    // left: 11px;
    left: 10px;
    font-size: 12px;
    z-index: 10;
    span {
        // margin-left: -1px;
        color: #fff;
        line-height: 36px;
        font-size: 12px;
    }
}
.rightPopup {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    width: 46px;
    border: 1px solid #d5d7e0;
    background: #fff;
    font-size: 12px;
    color: #000;
    box-shadow: 2px 0px 7px #d8def0;
    .rightPopupItem {
        line-height: 25px;
        padding-left: 10px;
        cursor: pointer;
        // text-align: center;
    }
    .rightPopupItem:hover {
        background: #f2f5f9;
    }
}
.LeftList:hover {
    background: #eeeeee;
    cursor: pointer;
}
.LeftList {
    height: 62px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-top: 8px;
    .LeftListCenter {
        display: flex;
        justify-content: space-between;
        // width: 100px;
        .ShowTime {
            text-align: right;
            font-size: 12px;
            color: #8590a6;
            float: right;
            width: 50px;
            // max-width:70px;
            // min-width: 30px;
            text-align: right;
            margin-left: 5px;
        }
        .tip {
            margin-left: 5px;
            font-size: 12px;
            color: #1492ff;
            // border: #1492ff solid 1px;
            background: #d3defa;
            cursor: pointer;
            text-align: center;
            // padding: 0 2px;
            width: 35px;
            height: 21px;
            border-radius: 3px;
            line-height: 21px;
        }
    }
}
.AngleMark {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    border-top: 10px solid #1492ff;
    border-right: 10px solid transparent;
}
.popoverIsReference {
    font-size: 12px;
    color: #000;
    width: 280px;
    max-height: 300px;
    overflow: auto;
}
.detailsPop {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    display: none;
    width: 324px;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(189, 203, 224, 0.3);
    border-radius: 3px;
    // border: solid 1px rgb(240, 242, 245);
}

.ChoseMailList {
    line-height: 46px;
    text-align: center;
    color: #1492ff;
    cursor: pointer;
    padding-bottom: 20px;
    .ChoseMailListBtn{
        width: 99px;
        height: 30px;
        line-height: 30px;
        background: #1492ff;
        color: #fff;
        text-align: center;
        margin: auto;
        border-radius: 3px;
    }
}
.remove {
    background: #f0f2f5;
    color: #666;
    font-size: 12px;

    text-align: center;
    cursor: pointer;
    line-height: 28px;
}
.Aite {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    font-size: 12px;
    color: #000;
    width: 162px;
    height: 175px;
    overflow-y: auto;
    border: #d5d7e0 solid 1px;
    display: none;
    background: #fff;
    border-radius: 3px;
}
    .aitelist {
        display: flex;
        padding: 3px 5px;
        cursor: pointer;
        align-items: center;
        // width: ;
        img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
            border-radius: 3px;
        }
    }
    .aitelist:hover {
        background: #f2f5f9;
    }
.dialogset {
    position: absolute;
    top: 24px;
    left: 1028px;
    cursor: pointer;
}
.noImg_box{
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    border-bottom-right-radius: 4px;
}
.noImg {
    // width: 643px;
    height: 100%;
    background: #f0f3f8;
    font-size: 14px;
    color: #000;
    text-align: center;
    margin: auto;
    // margin-top: -20px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    img {
        margin: 167px auto 10px;
    }
}
.ml10 {
    margin-left: 10px;
}
.hisicon {
    position: absolute;
    left: 70px;
    bottom: -30px;
}
.hisiconbig{
    position: absolute;
    left: 10px;
    bottom: -30px;
}
.hisicondy{
    position: absolute;
    left: 100px;
    bottom: -30px;
}
.send_icon {
    width: 20px;
    height: 30px;
    line-height: 30px;
    background: #1492ff;
    color: #fff;
    margin-left: -10px;
    border-radius: 3px;
    text-align: center;
}
.send_icon:hover {
    background: #0468de;
}
.height1{
    height: 477px;
}
.height2{
    height: 600px;
}
.hover_delete{
    margin-top: 26px;
    margin-right: 10px;
    color: #8590a6;
    i{
        font-size: 12px;
    }

}
.sendtimebox{
    font-size: 12px;
    color: #666;
    overflow: auto;
    margin: auto;
    height: 25px;
    line-height: 25px;
    text-align: center;
    .sendtimeS{
        background: #f0f2f5;
        max-width: 200px;
        padding: 2px 4px;
    }
}
.imgf{
    width: 230px;
    height: 127px;
    border: 1px solid #d5d7e0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    overflow: hidden;
}
.fw_box_ul{
    width: 98%;
    background: #fff;
    .fw_time{
        color: #8590A6;
        font-size: 12px;
        text-align: center;
    }
    .fw_box{
        width: 400px;
        height: auto;
        border-radius: 4px;
        background: #FFFFFF;
        border: 1px solid #D5D7E0;
        margin: 10px auto;
    }
    .fw_tip1{
        color: #333333;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        margin: 10px 10px 0;
    }
    .fw_tip2{
        color: #FA8C16;
        font-size: 12px;
        line-height: 12px;
        margin-top: 10px;
    }
    .fw_tip3{
        color: #666666;
        font-size: 12px;
        line-height: 12px;
        border-bottom: 1px solid #D5D7E0;
        padding: 10px;
        box-sizing: border-box;
        line-height: 1.5;
    }
    .fw_tip4{
        color: #0052CC;
        font-size: 15px;
        line-height: 15px;
        cursor: pointer;
        text-align: center;
        margin-top: 10px;
        padding-bottom: 10px;
    }
}
.yd_tip{
    font-size: 12px;
    margin: auto 10px;
}
.head_img_f{
    position: relative;
    width: 36px;
    height: 36px
}
.head_img_s{
    position: absolute;
    bottom: -2px;
    right: 0;
    border-radius: 2px;
    background: #F5F5F5;
    width: 12px;
    height: 14px;
    text-align: center;
    line-height: 12px;
    color: #D9D9D9;
    .i1{
        color: #1492ff;
    }
}
.news_temp{
    width: 342px;
}
.yimg{
    img{
        width: 13px;
        height: 13px;
    }
}
</style>
<style>
.HistoricalList a{
    color: #1492ff !important;
    font-size: 14px;
}
.HistoricalList a:link {
    color: #1492ff !important; /* 设置未访问前超链接文本颜色 */
}
.HistoricalList a:visited{
    color: #551a8b !important; /* 设置访问过的超链接文本颜色 */
}
.news a:hover {
    text-decoration: underline;
}
.sendOutBox .el-textarea__inner {
    border: solid 1px #fff !important;
    box-shadow:none !important;
}
/*定义滚动条轨道 内阴影+圆角*/
.news ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgb(250, 252, 255);
}
/*定义滑块 内阴影+圆角*/
.news ::-webkit-scrollbar-thumb {
    box-shadow: none;
    background-color: #caced8;
}
.news .html div {
    width: 185px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #8590a6;
}
.checkSend {
    color: #0468de !important;
    background: #fff !important;
}
</style>