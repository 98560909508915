<template>
    <div>
         <div class="readComponents">
            <!-- readSign 读取状态（0：未读；1：@未读 2：已读 3:已下载） chatType 会话类型（0集团群 1公司群 2话题 3单聊 4服务群）-->
            <div v-if="props.chatType == '3'" class="yd_tip">
                <div v-if="itemlist.unread > 0" class="blueText">未读</div>
                <div v-else>已读</div>
            </div>
            <div v-else>
                <el-popover placement="bottom-start" width="130" trigger="click">
                    <div>
                        <div class="overhide">
                            <div class="fl cura font12" :class="readFlag == '1'?'blueText':''" @click="changeYTabs('1')">已读({{ itemlist.alread }})</div>
                            <div class="fr cura font12" :class="readFlag == '2'?'blueText':''" @click="changeYTabs('2')">未读({{ itemlist.unread }})</div>
                        </div>
                        <div class="mt10" v-if="yList && yList.length" style="max-height: 200px; overflow-y: auto;">
                            <div v-for="(itemw,indexw) in yList" :key="indexw">
                                <div style="width: 100px;">
                                    <tooltipOver :content="itemw.mname?itemw.mname:''" className="width:100%;"></tooltipOver>
                                </div>
                            </div>
                        </div>
                        <div v-else style="text-align: center;color: #ccc;">暂无数据</div>
                    </div>
                    <template #reference>
                        <div class="yd_tip">
                            <div v-if="itemlist.unread > 0" class="blueText" @click="getydList()">{{itemlist.unread}}人未读</div>
                            <div v-else> 全部已读 </div>
                        </div>
                    </template>
                </el-popover>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { recRd } from '@/api/technologyManager'
import { ElMessage, ElMessageBox } from 'element-plus'

const yList = ref([])
const readFlag = ref('1')
const props = defineProps({
    itemlist: {
        type: Object,
        default: () => {}
    },
    chatType: {
        type: String,
        default: ''
    },
})
// 切换已读未读
function changeYTabs(val){
    readFlag.value = val
    getydList()
}
// 获取已读未读人员列表
function getydList(){
    yList.value = []
    recRd({id: props.itemlist.id, readFlag: readFlag.value }).then((res) => {
        if (res.code == 0) {
            yList.value = res.data
        } else {
            ElMessage({
                message: res.message,
                type: 'error',
                duration: 3000
            })
        }
    })
}
</script>

<style scoped lang="scss">
 .readComponents{
    .yd_tip{
        font-size: 12px;
        margin: auto 10px;
    }
}
</style>