<template>
    <div :class="cursor">
        <div :style="className">
            <!-- <div class="overone1 textBox" :title="isShowTooltip == true ? contentTip : ''"> -->
            <div class="textBox" :class="props.showLang=='3'?'overone3':props.showLang=='2'?'overone2':'overone1'" :title="isShowTooltip == true ? contentTip : ''">
                <span id="idName" @mouseover="onMouseOver">{{ content }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from "vue";
const isShowTooltip = ref(false)
const contentTip = ref('')
// props传参
const props = defineProps({
    // 显示的文字内容
    content:[String, Number],
    // 鼠标移入样式
    cursor: {
        type: String,
        default: ''
    },
    // 外层框的样式，在传入的这个类名中设置文字显示的宽度
    className: {
        type: String,
        default: ''
    },
    // 每一行展示的字数
    showNum: {
        type: String,
        default: ''
    },
    // 展示几行后溢出隐藏 默认1行
    showLang: {
        type: String,
        default: '1'
    },
})
function onMouseOver(e) {
    nextTick(()=>{
        const refName=e.target
        let contentWidth = refName.offsetWidth
        let parentWidth = refName.parentNode.offsetWidth
        // 若子集比中间层更宽 开启tooltip功能
        if (contentWidth+1 > parentWidth) {
            isShowTooltip.value = true
        } else {
            // 否则 关掉tooltip功能
            isShowTooltip.value = false
        }
        // 控制title一行显示几个字
        contentTip.value = ''
        if (props.showNum && props.showNum > 0&& props.content) {
            let workTitleTipData = []
            for (let i = 0; i < props.content.length; i += props.showNum) {
                workTitleTipData.push(props.content.substr(i, props.showNum))
            }
            contentTip.value = workTitleTipData.join('\n')
        } else {
            contentTip.value = props.content
        }
    })
}
</script>

<style lang="scss" scoped>
// 固定宽度时，超出2行溢出隐藏
.overone2{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
// 固定宽度时，超出3行溢出隐藏
.overone3{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis; 
}
</style>