//商城
export const ShoppingMall = [
    {
        path: '/ShoppingMall',
        name: 'ShoppingMall',
        meta: {
            title: '企服商城'
        },
        component: () => import('@/views/ShoppingMall/index.vue')
    },
    {//产学研合作需求
        path: '/iurCooperate',
        name: 'iurCooperate',
        meta: { title: '' },
        component: () => import('@/views/ShoppingMall/iurCooperate.vue')
    },
    {//专家评审需求
        path: '/ExpertReview',
        name: 'ExpertReview',
        meta: { title: '' },
        component: () => import('@/views/ShoppingMall/ExpertReview.vue')
    },
    {//服务详情
        path: '/serviceDetail',
        name: 'serviceDetail',
        meta: { title: '服务详情' },
        component: () => import('@/views/ShoppingMall/mallOrders/serviceDetail.vue')
    },
    {//商城订单确认
        path: '/orderConfirm',
        name: 'orderConfirm',
        meta: { title: '订单确认' },
        component: () => import('@/views/ShoppingMall/mallOrders/orderConfirm.vue')
    },
    {//支付选择
        path: '/PayOptions',
        name: 'PayOptions',
        meta: { title: '' },
        component: () => import('@/views/ShoppingMall/mallOrders/PayOptions.vue')
    },
    {//支付结果
        path: '/PayResult',
        name: 'PayResult',
        meta: { title: '' },
        component: () => import('@/views/ShoppingMall/mallOrders/PayResult.vue')
    },
    // --------科技管家订单
    {//管家订单列表
        path: '/orderList',
        name: 'orderList',
        meta: { title: '订单列表', isTech: true }, //isTech: true,展示科技管家的导航
        component: () => import('@/views/ShoppingMall/housekeeperOrders/orderList.vue')
    },
    {//订单详情
        path: '/ordersDetail',
        name: 'ordersDetail',
        meta: { title: '订单详情' },
        component: () => import('@/views/ShoppingMall/ordersDetail.vue')
    },
    {//开具发票
        path: '/InvoiceList',
        name: 'InvoiceList',
        meta: { title: '开具发票', isTech: true },
        component: () => import('@/views/ShoppingMall/housekeeperOrders/orderInvoice/InvoiceList.vue')
    },
    {//开票历史
        path: '/InvoiceHistory',
        name: 'InvoiceHistory',
        meta: { title: '开票历史', isTech: true },
        component: () => import('@/views/ShoppingMall/housekeeperOrders/orderInvoice/InvoiceHistory.vue')
    },
    {//订单开票
        path: '/InvoiceOpen',
        name: 'InvoiceOpen',
        meta: { title: '订单开票', isTech: true },
        component: () => import('@/views/ShoppingMall/housekeeperOrders/orderInvoice/InvoiceOpen.vue')
    },
]
