// 导出 传参格式转换
function changeQueryString(params) {
  var str = ''
  for (var Key in params) {
    if (!params[Key] || params[Key] == undefined) {
      params[Key] = ''
    }
    str += Key + '=' + params[Key] + '&'
  }
  return '?' + str.substr(0, str.length - 1)
}
// 导出 POST请求处理
function ExportDownloadPost(res, fileName) {
    const content = res.data;
    const blob = new Blob([content]);
    let fileNames = fileName?fileName:''
    if(!fileName || fileName == ''){
      // 获取文件名
      var matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(res.headers["content-disposition"]);
      fileNames = decodeURI(matches[1].replace(/['"]/g, ''));
    }
    if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileNames;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
    } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileNames);
    }
}
// 导出压缩包
function exportPackageUtils(payload) {
  let url = window.URL.createObjectURL(payload.data) //表示一个指定的file对象或Blob对象
  let a = document.createElement('a')
  document.body.appendChild(a)
  //   let fileName = payload.headers['content-disposition']
  //     .split(';')[1]
  //     .split('=')[1] //filename名称截取
  //   console.log('fileName', fileName)
  a.href = url
  //   a.download = decodeURI(fileName) // 命名下载名称,文件名解码
  a.download = '压缩包.jar'
  a.click() //点击触发下载
  window.URL.revokeObjectURL(url)
}
// 导出表格工具函数
function exportUtils(payload) {
  let url = window.URL.createObjectURL(payload.data) //表示一个指定的file对象或Blob对象
  let a = document.createElement('a')
  document.body.appendChild(a)
  let fileName = payload.headers['content-disposition']
    .split(';')[1]
    .split('=')[1] //filename名称截取
  a.href = url
  a.download = decodeURI(fileName) // 命名下载名称,文件名解码
  a.click() //点击触发下载
  window.URL.revokeObjectURL(url)
}
export { changeQueryString,ExportDownloadPost,exportPackageUtils, exportUtils}
