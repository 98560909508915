import { defineStore } from 'pinia'

const useCommonStore = defineStore('common', {
    state: () => ({
        serviceList: [],//服务列表
    }),
    actions: {
        //设置服务列表
        setServiceList(data) {
            this.serviceList = data
        },
    }
})
export default useCommonStore
