<template>
    <div class="HistoricalTop">
        <el-input @clear="workTopicFindTopicSetting()" @blur="workTopicFindTopicSetting()" @keyup.enter="$event.target.blur()" clearable v-model="keywords" style="width:300px;margin: 10px 0 0px 15px" onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;" size="small" placeholder="搜索成员">
            <template #suffix>
                <i class="el-input__icon el-icon-search" @click="workTopicFindTopicSetting()"></i>
            </template>
        </el-input>
        <div style="overflow-y: auto;">
            <!--  :style="{height:details.chatType!=4?'520px':'400px'}" -->
            <div class="line_user" :style="isHeightNewsT">
                <div :style="{height:details.chatType!=4?'504px':'390px'}" style="overflow-y: auto;">
                    <div style="width:100%">
                        <div style="width: 100%;">
                            <div style="margin-top: 10px;">企业方</div>
                            <div v-for="(item, index) in details.memebers" :key="index">
                                <div v-if="item.msource=='2'">
                                    <div class="chatMemberList">
                                        <img @click="workGroupFindFriendInfo(item,$event)" style="width:36px;height:36px;border-radius: 3px;" :src="urlImg +item.headUrl" />
                                        <img class="del" src="@/assets/img/MyWork/del.png" @click="workTopicRemoveSingleMember(item)" />
                                        <div v-for="(itemu, indexu) in userTagList" :key="indexu">
                                            <span v-if="item.mtag != '3'" class="user_tag">
                                                <span v-if="itemu.dictCode == item.mtag">
                                                    <tooltipOver :content="itemu.dictName" className="width:100%;"></tooltipOver>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="label overone">
                                            <tooltipOver :content="item.mname" className="width:100%;"></tooltipOver>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="details.chatType==4" style="cursor: pointer;margin: 10px;display: inline-block;">
                                <img style="height:36px;width:36px" src="@/assets/img/MyWork/add.png" @click="openStaff">
                                <div style="text-align:center">添加</div>
                            </div>
                        </div>
                        <div style="width: 100%;">
                            <div>服务方</div>
                            <div v-for="(item, index) in details.memebers" :key="index">
                                <div v-if="item.msource=='1'">
                                    <div class="chatMemberList">
                                        <img @click="workGroupFindFriendInfo(item,$event)" style="width:36px;height:36px;border-radius: 3px;" :src="urlImg +item.headUrl" />
                                        <div v-for="(itemu, indexu) in userTagList" :key="indexu">
                                            <span v-if="item.mtag != '3'" class="user_tag">
                                                <span v-if="itemu.dictCode == item.mtag">
                                                    <tooltipOver :content="itemu.dictName" className="width:100%;"></tooltipOver>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="label overone">
                                            <tooltipOver :content="item.mname" className="width:100%;"></tooltipOver>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="(details.chatType ==4 && details.chatMemberListTemp && details.chatMemberListTemp.length > 25) || (details.chatType !=4 && details.chatMemberListTemp && details.chatMemberListTemp.length > 35)" class="blueText" style="text-align: center" @click="setMore">{{isMore?'收起':'查看更多'}}</div>
            </div>
            <div class="line_box"></div>
            <div>
            <div class="line ml10" v-if="details.chatType==4">
                <div style="width:70px;font-size:14px">管&nbsp;&nbsp;理&nbsp;员：</div>
                <div style="width:150px;font-size:14px">
                    <tooltipOver :content="details.adminsNames" className="width:100%;"></tooltipOver>
                </div>
            </div>
            <div class="line ml10" style="background: #fff;" v-if="details.chatType==4">
                <div style="width:70px;font-size:14px;margin-top: 5px;">群组名称：</div>
                <div v-if="chatNameSwitch" style="width:220px;font-size:14px;margin-top: 4px;" class="overone">
                    <tooltipOver :content="details.chatName" className="max-width:180px;" class="fl"></tooltipOver>
                    <!-- <span>({{details.memebers?details.memebers.length:0}}人)</span> -->
                </div>
                <el-input ref="chatName" v-else size="small" style="width:200px;font-size:14px;margin-top: 5px;" @blur="workTopicModifyChatName" maxlength="30" v-model="details.chatName"></el-input>
                <i v-if="chatNameSwitch&&details.chatType==4" class="iconfont icon-biaoge-xiugai1" @click="NameSwitch"></i>
            </div>
            <div class="line ml10">
                <div style="width:70px;font-size:14px">创&nbsp;&nbsp;建&nbsp;人：</div>
                <div style="width:220px;font-size:14px">
                    <tooltipOver :content="details.createName" className="width:100%;"></tooltipOver>
                </div>
            </div>
            <div class="line ml10">
                <div style="width:70px;font-size:14px">创建时间：</div>
                <div style="width:220px;font-size:14px">
                    <tooltipOver :content="details.createTime" className="width:100%;"></tooltipOver>
                </div>
            </div>
            <div class="line ml10">
                <div style="width:70px;font-size:14px">服务主题：</div>
                <div style="width:220px;font-size:14px">
                    <tooltipOver :content="details.chatSubj" className="width:100%;"></tooltipOver>
                </div>
            </div>
            <div class="line ml10" style="margin-bottom:10px;">
                <div style="width:70px;font-size:14px">置&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;顶：</div>
                <el-switch v-if="details.topSign" @change="workTopicIsTop" :width="30" v-model="details.topSign" active-value="1" inactive-value="0" />
            </div>
        </div>
        <div class="signOut">
            <div v-if="details.chatType==4" @click="workTopicQuitTopic" style="font-size:14px">退出群聊</div>
            <!-- <div @click="workTopicClearChatRecord" style="font-size:14px">清空历史记录</div> -->
        </div>
        </div>
    </div>
</template>

<script setup>
import {  onMounted, ref, watch } from 'vue'
import http from '@/api/http'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ctms, cgi, exct, modifyChatName, tpct, clmsg, upctm, delctm } from '@/api/technologyManager'//接口
// import { workTopicFindGroupMemberList, workTopicQuitTopic, workTopicClearChatRecord, workTopicFindTopicSetting, modifyChatName, workTopicIsTop, updateMembers, delMember } from '@/request/MyWork'
const details = ref({})
const chatNameSwitch = ref(true)
const urlImg = http.FileUrl
const GroupSelectionShow = ref(false)
const keywords = ref('')
const FriendInfo = ref({})
const oldChatName = ref('')
const isMore = ref(false)
const chatName = ref(null)
const userList = ref([])
// 1：法人 2：负责人 3：普通成员 4：客户经理 5：业务人员 
const userTagList = ref([{dictCode:'1', dictName:'法人'},{dictCode:'2', dictName:'负责人'}, {dictCode:'3', dictName:'普通成员'},{dictCode:'4', dictName:'客户经理'},{dictCode:'5', dictName:'业务人员'}])
const emit = defineEmits(['ClearChatRecord','Refresh','close','signOut','openStaffSet','workGroupFindFriendInfo'])
const isHeightNewsT = ref('')
const props = defineProps({
    detailsId: {
        type: String,
        default: ''
    },
    fullscreenCon: {
        type: Boolean,
        default: false
    },
})
defineExpose({
    workTopicFindTopicSetting,
    updateMembersUser
})
// 查询用户名片信息
function workGroupFindFriendInfo(item, e) {
    item.sendUserId = item.id
    emit('workGroupFindFriendInfo', item, e, 'TopicSetting')
}
// 搜索查询会话成员
// function workTopicFindGroupMemberList() {
//     ctms({ keyword: keywords.value, chatId: props.detailsId}).then((res) => {
//         details.value.chatMemberListTemp = res.data
//         if(details.value.chatType!=2) {
//             details.value.chatMemberList = res.data.slice(0, 35)
//         } else {
//             details.value.chatMemberList = res.data.slice(0, 25)
//         }
//     })
// }

// 清空历史
function workTopicClearChatRecord() {
    ElMessageBox.confirm('是否确定清空历史记录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        clmsg({ chatId: props.detailsId }).then((res) => {
            if (res.code == 0) {
                emit('ClearChatRecord', props.detailsId)
            }
            ElMessage({
                message: res.code == 0 ? '清空历史记录成功' : res.message,
                type: res.code == 0 ? 'success' : 'error',
                duration: 3000
            })
        })
    })
}
// 删除单个成员
function workTopicRemoveSingleMember(item) {
    ElMessageBox.confirm(`是否确定把 ${item.staffName} 移出话题？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        delctm({
            chatId: props.detailsId,
            userId: item.id
        }).then((res) => {
            if (res.code == 0) {
                workTopicFindTopicSetting()
                emit('Refresh', props.detailsId)
                keywords.value = ''
                isMore.value = false
            }
            ElMessage({
                message:  res.code == 0 ? '操作成功' : res.message,
                type:  res.code == 0 ? 'success' : 'error',
                duration: 3000
            })
        })
    })
}
// 退出话题
function workTopicQuitTopic() {
    ElMessageBox.confirm('是否确定退出群聊？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        exct({ chatId: props.detailsId }).then((res) => {
            ElMessage({
                message: res.code == 0 ? '退出群聊成功' : res.message,
                type: res.code == 0 ? 'success' : 'error',
                duration: 3000
            })
            if (res.code == 0) {
                emit('close')
                emit('signOut')
            }
        })
    })
}

// 置顶
function workTopicIsTop() {
    tpct({
        topSign: details.value.topSign,
        chatId: details.value.id
    }).then((res) => {
        if (res.code == 0) {
            emit('Refresh', details.value.id)
        }
        ElMessage({
            message: res.code == 0 ? '操作成功' : res.message,
            type: res.code == 0 ? 'success' : 'error',
            duration: 3000
        })
    })
}
// 修改话题名称
function workTopicModifyChatName() {
    if (!details.value.chatName) {
        details.value.chatName = oldChatName.value
    }
    if (oldChatName.value == details.value.chatName) {
        chatNameSwitch.value = !chatNameSwitch.value
        return
    }
    modifyChatName({
        chatName: details.value.chatName,
        oldName: oldChatName.value,
        chatId: props.detailsId
    }).then((res) => {
        if (res.code == 0) {
            oldChatName.value = details.value.chatName
            chatNameSwitch.value = !chatNameSwitch.value
            emit('Refresh', props.detailsId)
        }
        ElMessage({
            message: res.code == 0 ? '保存成功' : res.message,
            type: res.code == 0 ? 'success' : 'error',
            duration: 3000
        })
    })
}
// 编辑话题标题
function NameSwitch() {
    chatNameSwitch.value = !chatNameSwitch.value
    setTimeout(() => {
        chatName.value.focus()
    });
}
// 查询设置信息
function workTopicFindTopicSetting() {
    cgi({ keyword: keywords.value, chatId: props.detailsId }).then((res) => {
        if (res.code == 0) {
            details.value = res.data
            details.value.adminsNames = []
            oldChatName.value = JSON.parse(JSON.stringify(details.value)).chatName
            details.value.admins.forEach(el => {
                details.value.adminsNames.push(el.mname)
            });
            setTimeout(() => {
                details.value.adminsNames = details.value.adminsNames.join(',')
            })
            // 判断高度
            getNewsHeightTopSet()
        }
    })
}
// 保存人员信息
function updateMembersUser(val) {
    val.forEach(el => {
        userList.value.push(el)
    });
    upctm({ chatId: props.detailsId, members: userList.value }).then((res) => {
        if (res.code == 0) {
            // details.value.chatMemberListTemp = res.data
            // if(details.value.chatType!=2) {
            //     details.value.chatMemberList = res.data.slice(0, 35)
            // } else {
            //     details.value.chatMemberList = res.data.slice(0, 25)
            // }
            // isMore.value = false
            workTopicFindTopicSetting()
        }
    })
}
// 选择人员
function openStaff() {
    GroupSelectionShow.value = !GroupSelectionShow.value.value
    let userList2 = []
    userList.value = []
    details.value.memebers.forEach(el => {
        if(el.msource == '1') {
            userList.value.push(el)
        } else if(el.msource == '2') {
            userList2.push(el)
        }
    });
    
    setTimeout(() => {
        emit('openStaffSet', '选择服务群成员', '2', '5', GroupSelectionShow.value, userList2, details.value.buildId)
    });
}
// 查看更多
function setMore() {
    isMore.value = !isMore.value
    setTimeout(() => {
        if (isMore.value) {
            details.value.chatMemberList = details.value.chatMemberListTemp
        } else {
            if(details.value.chatType!=2) {
                details.value.chatMemberList = details.value.chatMemberListTemp.slice(0, 35)
            } else {
                details.value.chatMemberList = details.value.chatMemberListTemp.slice(0, 25)
            }
        }
    })
}
// 判断高度
function getNewsHeightTopSet(){
    if(props.fullscreenCon && props.fullscreenCon == true) {
        // if(details.value.chatType!=4) {
        //     isHeightNewsT.value = 'height: 80vh;'//整个聊天框的高度
        // } else {
        //     }
        isHeightNewsT.value = 'height: 70vh'//整个聊天框的高度
    } else {
        // if(details.value.chatType!=4) {
        //     isHeightNewsT.value = 'height: 520px;'//整个聊天框的高度
        // } else {
        //     }
        isHeightNewsT.value = 'height: 400px;'//整个聊天框的高度
    }
}
watch(() => props.fullscreenCon, (val) => {
    getNewsHeightTopSet()
}, { immediate: true })
onMounted(()=>{
    // 接收兄弟组件选择的人员并保存
    // $bus.on('saveUsers', (e) => {
    //     updateMembersUser(e)
    // })
})
</script>

<style scoped lang="scss">
// @keyframes examples {
//     0% {
//         left: -455px;
//     }
//     100% {
//         right: 0px;
//     }
// }
.HistoricalTop {
    // animation-duration: 1s;
    position: absolute;
    right: 0px;
    top: 0;
    width: 339px;
    height: 100%;
    background: #fff;
    color: #000;
    border-left: 1px solid #ccc;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-left: 1034px;
}
.line {
    display: flex;
    height: 30px;
    align-items: center;
}
.line_user {
    margin-left: 10px;
    position: relative;
}
.blueText{
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    background: #fff;
}
.line_box {
    width: 100%;
    height: 1px;
    background-color: #d6dbe2;
}
.closeBox {
    position: absolute;
    left: -30px;
    top: 0;
}
.del {
    position: absolute;
    width: 15px;
    height: 15px;
    top: -7px;
    right: 0px;
    cursor: pointer;
}
.signOut {
    position: absolute;
    // bottom: 5px;
    width: 100%;
    border-top: #d6dbe2 solid 1px;
    text-align: center;
    color: #1492ff;
    cursor: pointer;
    line-height: 40px;
    display: flex;
    justify-content: space-around;
}
.chatMemberList {
    position: relative;
    margin: 10px 7px;
    text-align: center;
    cursor: pointer;
    float: left;
    width: 50px;
    .label {
        // margin-left: 4px;
        width: 50px;
        font-size: 12px;
    }
}
.tip {
    margin-left: 5px;
    font-size: 12px;
    color: #1492ff;
    border: #1492ff solid 1px;
    cursor: pointer;
    text-align: center;
    // padding: 0 2px;
    width: 35px;
}
.ml10 {
    margin-left: 10px;
}
.user_tag{
    background: rgba($color: #333333, $alpha: 0.8);
    color: #fff;
    font-size: 12px;
    text-align: center;
    width: 36px;
    line-height: 16px;
    position: absolute;
    border-radius: 4px;
    bottom: 14px;
    left: 0;
    margin-left: 7px;
    // font-size: 8px;
}
</style>
