<template>
    <!-- 整体布局 -->
    <div class="layout-container">
        <el-container>
            <Header v-if="headStore.headShow"></Header>
            <el-main>
                <router-view :key="$route.fullPath"></router-view>
            </el-main>
            <el-footer :height="headStore.footerHeight + 'px'" v-if="headStore.footerShow">
                <Footer id="footerEl"></Footer>
            </el-footer>
        </el-container>
        <!-- 助手 -->
        <div class="helper" v-if="headStore.helperShow">
            <Helper />
        </div>
        <Online v-if="onlineStore.onlineShow" />
        <!-- <NoticeBar /> -->
    </div>
</template>

<script setup>
import Header from './components/header/index.vue'
import Footer from './components/footer/index.vue'
import Helper from './components/helper.vue'
import Online from '@/views/Online/index.vue'
import NoticeBar from './NoticeBar.vue'

import { headStore, onlineStore } from '@/store'
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()

const height = ref(0)
const headH = ref(headStore.height)


watch(() => route.path, (val) => {
    if (headStore.headWhiteList.indexOf(val) != -1) {
        headH.value = 0
        headStore.setHeight(0)
    } else {
        headStore.setHeight()
        headH.value = headStore.height
    }
    height.value = headH.value + headStore.footerHeight + 'px'
    headStore.setcontentHeight()

}, { immediate: true })

onMounted(() => {
    getFooterEl()
})

// -----------------------方法------------------------------
const getFooterEl = () => {
    const el = document.getElementById('footerEl')
    headStore.setFooterHeight(el.offsetHeight)
}

</script>

<style lang="scss" scoped>
.layout-container {
    .el-main {
        background: #f6f9fe;
        padding: 0;
        margin: 0 auto;
        width: 100%;
        overflow: hidden;
        min-height: calc(100vh - 358px);
    }

    .el-footer {
        width: 100%;
        padding: 0;
    }

    .helper {
        position: fixed;
        bottom: 150px;
        right: 20px;
        z-index: 999;
    }
}
</style>
