<template>
    <div>
        <el-dialog title="提示" v-model="visible" class="downloadPop" width="500px" :append-to-body='true' @close="close">
            <img src="@/assets/img/MyWork/img-warning.png" class="diaimg"/>
            <div class="list">
                <div style="display:flex;align-items: center;">
                    <div style="margin-left:10px; color: #000;">只能保存文件到电脑</div>
                </div>
                <div v-if="downLoadList.length" style="margin-left:10px;">
                    <div class="Num">共选择了<span style="color:#1492ff">{{downLoadList.length}}</span>个文件：</div>
                    <div>
                        <div v-for="(item,index) in downLoadList" :key="index" style="width: 100%;display:flex;margin-bottom:5px">
                            <div class="fileItem" style=" display:flex;align-items: center;cursor: pointer;">
                                <!-- <img v-if="item.filePic" :src="item.filePic" style="margin: 5px 10px 5px 5px;cursor: pointer;"/>
                                <img v-else :src="urlImg + item.fileUrl" style="margin: 5px 10px 5px 5px;cursor: pointer;width: 44px;height: 44px;" /> -->
                                <div class="imgboxD">
                                    <img v-if="item.fileSuffix=='png' || item.fileSuffix=='jpg'||item.fileSuffix=='jpeg'" :src="urlImg+item.fileUrl" />
                                    <img v-else-if="item.fileSuffix=='bmp'||item.fileSuffix=='webp'" src='@/assets/img/MyWork/32jpg.png' />
                                    <img v-else-if="item.fileSuffix=='docx'" src='@/assets/img/MyWork/32word.png' />
                                    <img v-else-if="item.fileSuffix=='zip'||item.fileSuffix=='rar'||item.fileSuffix=='7z'||item.fileSuffix=='ar'" src='@/assets/img/MyWork/32zip.png' />
                                    <img v-else-if="item.fileSuffix=='xls'" src='@/assets/img/MyWork/32exl.png' />
                                    <img v-else-if="item.fileSuffix=='xlsx'||item.fileSuffix=='xltx'||item.fileSuffix=='xlsm'||item.fileSuffix=='xlsb'||item.fileSuffix=='csv'||item.fileSuffix=='xml'" src='@/assets/img/MyWork/32exl.png' />
                                    <img v-else-if="item.fileSuffix=='ppt'||item.fileSuffix=='pptx'||item.fileSuffix=='xps'||item.fileSuffix=='pps'||item.fileSuffix=='ppsx'||item.fileSuffix=='ppa'||item.fileSuffix=='ppam'||item.fileSuffix=='pot'||item.fileSuffix=='potx'||item.fileSuffix=='thmx'" src='@/assets/img/MyWork/32p.png' />
                                    <img v-else-if="item.fileSuffix=='pdf'" src='@/assets/img/MyWork/32pdf.png' />
                                    <img v-else-if="item.fileSuffix=='doc'||item.fileSuffix=='dot'||item.fileSuffix=='docm'||item.fileSuffix=='dotm'||item.fileSuffix=='dotx'" src='@/assets/img/MyWork/32word.png' />
                                    <img v-else-if="item.fileSuffix=='txt'||item.fileSuffix=='rtf'" src='@/assets/img/MyWork/32text.png' />
                                    <img v-else-if="item.fileSuffix=='mov'||item.fileSuffix=='mp4'||item.fileSuffix=='avi'||item.fileSuffix=='wmv'||item.fileSuffix=='asf'||item.fileSuffix=='rm'||item.fileSuffix=='rmvb'" src='@/assets/img/MyWork/32movie.png' />
                                    <img v-else-if="item.fileSuffix=='mp3'||item.fileSuffix=='wav'||item.fileSuffix=='wv'||item.fileSuffix=='wma'" src='@/assets/img/MyWork/32audio.png' />
                                    <img v-else-if="item.fileSuffix=='PHTML'||item.fileSuffix=='htm'||item.fileSuffix=='html'||item.fileSuffix=='JSP'||item.fileSuffix=='HTML'||item.fileSuffix=='php'||item.fileSuffix=='ASP'||item.fileSuffix=='PHP'||item.fileSuffix=='PHP3'" src='@/assets/img/MyWork/32html.png' />
                                    <img v-else-if="item.fileSuffix=='psd'||item.fileSuffix=='eps'" src='@/assets/img/MyWork/32ps.png' />
                                    <img v-else-if="item.fileSuffix=='svg'" src='@/assets/img/MyWork/32svg.png' />
                                    <img v-else-if="item.fileSuffix=='xmind'" src='@/assets/img/MyWork/32Xmind.png' />
                                    <img v-else-if="item.fileSuffix=='rp'" src='@/assets/img/MyWork/32rp.png' />
                                    <img v-else src='@/assets/img/MyWork/32other.png' />
                                </div>
                                <div>
                                    <div style="width:210px;" class="overone">{{item.fileName?item.fileName:''}}</div>
                                    <div style="color:#8992a4">{{item.fileSize}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="mini" type="info" @click="close">取 消</el-button>
                    <el-button type="primary" size="mini" @click="determine">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import http from '@/api/http'

const emit = defineEmits(['close', 'download'])
const visible = ref(false)
const urlImg = http.FileUrl

const props = defineProps({
    dialogVisible: {
        type: Boolean,
        default: false
    },
    downLoadList: {
        type: Array,
        default: []
    }
})

watch(() => props.dialogVisible, (val) => {
    visible.value = val
},{ immediate: true });
 
function determine() {
    if (props.downLoadList.length) {
        emit('download','')
    }
    close()
}
function close() {
    visible.value = false
    emit('close')
}
</script>

<style scoped lang="scss">
.Num {
    color: rgb(135, 139, 150);
    margin: 10px 0;
}
.fileItem {
    background: #fff;
    color: #000;
    border: solid 1px #d6dbe2;
    font-size: 12px;
    padding: 5px 10px;
    width: 100%;
    height: 66px;
}
.list {
    max-height: 422px;
    overflow-y: auto;
}
.diaimg{
    width:20px;
    position: absolute;
    top: 16px;
}
.imgboxD{
     margin: 5px 10px 5px 5px;
     cursor: pointer;
     max-width: 44px;
     max-height: 44px;
     img{
        max-width: 44px;
        max-height: 44px;
     }
}
</style>
<style  lang="scss">
.downloadPop .el-dialog__body {
    padding: 0 20px;
}
.downloadPop .el-dialog__header .el-dialog__title {
    font-size: 16px;
    margin-left: 30px;
}
</style>
