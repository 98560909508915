<template>
    <div :style="fullscreenCon?'width: 1500px;height:calc(100vh-100px);':'width: 638px;height: 576px'" class="servicesbox">
        <div id="serviceslist" class="servicesli" ref="container1" @scroll="handleScroll">
            <!-- 服务通知 -->
            <div class="fw_box_ul" v-for="(itemS,indexS) in serviceList" :key="indexS">
                <div class="fw_time">{{itemS.formatTime}}</div>
                <div class="fw_box">
                    <div class="overhide">
                        <div class="fl fw_tip1">{{itemS.title}}</div>
                        <div class="fl fw_tip2">#{{itemS.producter}}</div>
                    </div>
                    <div class="fw_tip3">
                        {{itemS.context}}
                    </div>
                    <div class="fw_tip4" @click="goProDetail(itemS)">点击查看详情</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { conversationStore } from '@/store'
import { svrnf } from '@/api/technologyManager'//接口
import { useRouter } from "vue-router";
const router = useRouter()
const serviceList = ref([])
const fullscreenCon = ref(null)
const sendTime = ref('')
const container1 = ref(null)
const props = defineProps({
    details: {
        type: Object,
        default: ()=>{}
    },
})
// 服务通知跳转
function goProDetail(val){
    if(val.linkUrl && val.linkUrl !== '') {
        const news = router.resolve({ name: val.linkUrl, query: JSON.parse(val.params) })
        window.open(news.href, '_blank')
    }
}
// 查询服务通知
function initFun(){
    sendTime.value = ''
    serviceList.value = []
    getserlist()
}
// 查询列表
function getserlist(type){
    svrnf({chatId: props.details.id, lastTime: sendTime.value}).then((res) => {
        if(res.code==0){
            if(res.data && res.data.length) {
                sendTime.value = res.data[0].sendTime
                if(type=='1') {
                    serviceList.value = [...res.data, ...serviceList.value]
                    // res.data.forEach(el => {
                    //     serviceList.value.unshift(el)
                    // });
                } else {
                    serviceList.value = res.data
                    scrollToBottom1()
                }
            }
        }
    })
}
// 滚动到底部
function scrollToBottom1() {
    setTimeout(() => {
        let container1 = document.getElementById("serviceslist")
        if(container1) {
            container1.scrollTop = container1.scrollHeight
        }
    }, 300)
}
// 处理滚动事件
function handleScroll(){
  if (container1.value.scrollTop == 0) { // 检查是否滚动到顶部
    getserlist('1'); // 加载更多数据
  }
};
watch(() => conversationStore.isFullscreen, (val) => {
    fullscreenCon.value = val
}, { immediate: true })
defineExpose({
    initFun
})
</script>

<style scoped lang="scss">
.servicesbox{
    .servicesli{
        height: 100%;
        overflow-y: auto;
    }
.fw_box_ul{
    width: 98%;
    background: #fff;
    .fw_time{
        color: #8590A6;
        font-size: 12px;
        text-align: center;
    }
    .fw_box{
        width: 400px;
        height: auto;
        border-radius: 4px;
        background: #FFFFFF;
        border: 1px solid #D5D7E0;
        margin: 10px auto;
    }
    .fw_tip1{
        color: #333333;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
        margin: 10px 10px 0;
    }
    .fw_tip2{
        color: #FA8C16;
        font-size: 12px;
        line-height: 12px;
        margin-top: 10px;
    }
    .fw_tip3{
        color: #666666;
        font-size: 12px;
        line-height: 12px;
        border-bottom: 1px solid #D5D7E0;
        padding: 10px;
        box-sizing: border-box;
        line-height: 1.5;
    }
    .fw_tip4{
        color: #0052CC;
        font-size: 15px;
        line-height: 15px;
        cursor: pointer;
        text-align: center;
        margin-top: 10px;
        padding-bottom: 10px;
    }
}
}
</style>