
// 手机号校验
export const checkPhone = (value) => {
    var regex = /^1[3-9]\d{9}$/;
    return regex.test(value)
}
// 密码校验
export const checkPwd = (value) => {
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}\[\]\\\/|:;<>"',.?])[A-Za-z\d!@#$%^&*()_\-+={}\[\]\\\/|:;<>"',.?]{8,16}$/;
    return regex.test(value)
}

// 邮箱校验
export const checkEmail = (value) => {
    var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(value);
}
// 身份证校验
export const checkID = (value) => {
    var regex = /^[1-9]\d{5}(18|19|20|21|22)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|[Xx])$/
    return regex.test(value)
}

// 信用代码
export const checkCreditCode = (value) => {
    let regex = /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/;
    return regex.test(value)
}
