import { onBeforeUnmount } from "vue"

let channelNameId = null
// 创建广播通道
export const createChannel = (name, callback) => {
    channelNameId = new BroadcastChannel(name)
    if (callback && typeof callback === 'function') {
       onChannel(callback)
    }
}

// 发送消息
export const sendChannel = (data) => {
    channelNameId.postMessage(data)
}

// 监听消息
export const onChannel = (callback) => {
    channelNameId.onmessage = callback
}

// 关闭广播通道
export const closeChannel = () => {
    channelNameId.close()
}


export default {
    setup() {
        // 组件卸载时关闭广播通道
        onBeforeUnmount(() => {
            closeChannel()
        })
    }
}