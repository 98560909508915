<template>
    <div class="tag-container" >
        <div class="conter">
            <div class="item">
                <slot>
                    {{ text }}
                </slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { watch } from 'vue';

const prop = defineProps({
    value: {
        type: String,
        default: ''
    }
})

const text=ref('')

watch(() => prop.value, (newVal) => {
    text.value=newVal
}, { immediate: true,deep: true})
</script>

<style lang="scss" scoped>
.tag-container {
    .conter {
        text-align: center;

        .item {
            max-width: 90%;
            display: inline-block;
            padding: 4px 15px;
            font-size: 12px;
            background: #d7d7d7;
            border-radius: 10px;
            text-align: left;
        }
    }
}
</style>