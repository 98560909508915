<template>
    <div class="noData-container">
        <div class="container" :style="styleFnle">
            <img src="@/assets/img/tableEmpty.png" alt="" class="tableEmpty">
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from "vue"
const prop = defineProps({
    top: {
        type: Number,
        default: 30
    }
})

const styleFnle = computed(() => {
    return {
        'margin-top': `${prop.top}px`
    }
})

</script>

<style lang="scss" scoped>
.noData-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>