<template>
    <div class="conmaillist" :style="fullscreenCon?'height:calc(100vh - 31px);':'height:632px;'" @click="ClickMark">
        <div class="txl_title">
            <div style="width:320px;height:28px;">
                <el-input size="small" class="search_center" clearable style="width:320px;height: 28px;" ref="input" v-model="keyword" placeholder="搜索联系人" @keyup.enter="search1" @clear="search1()" @input="search1" onkeypress="javascript:if(event.keyCode == 32)event.returnValue = false;">
                </el-input>
            </div>
        </div>
        <!-- 通讯录 -->
        <div style="margin-top: 10px;">
            <div v-if="userList && userList.length">
                <div v-for="(item, index) in userList" :key="index">
                    <div class="flexh">
                        <i class="iconfont icon-fuwushang"></i>
                        <div class="ml10">{{item.coName}}</div>
                    </div>
                    <div class="overhide">
                        <div v-for="(item1, index1) in item.members" :key="index1" class="fl list1" @click="workGroupFindFriendInfo(item1,$event)">
                            <div>
                                <div class="avatar">
                                    <img :src="urlImg+item1.headUrl" alt="" v-if="item1.headUrl" style="border-radius: 4px;">
                                    <img src="@/assets/img/MyWork/defaultHead.png" alt="" v-else style="border-radius: 4px;">
                                    <div v-for="(itemu, indexu) in userTagList" :key="indexu">
                                        <span v-if="item1.mtag != '3'" class="user_tag">
                                            <span v-if="itemu.dictCode == item1.mtag">
                                                <tooltipOver :content="itemu.dictName" className="width:100%;"></tooltipOver>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <div class="name_tip">
                                    <tooltipOver :content="item1.mname" className="width:100%;"></tooltipOver>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="no_data">
                    <img src="@/assets/img/tableEmpty.png" class="tableEmpty">
                </div>
            </div>
        </div>
        <div class="detailsPop" ref="detailsPop">
            <businessCard :cardDetail="FriendInfo" @ChoseMailList1="ChoseMailList"></businessCard>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, computed, watch  } from "vue";
import { ElMessage } from 'element-plus'
import http from '@/api/http'
import { conversationStore } from '@/store'
import { adbk, bCard, sgct } from '@/api/technologyManager'//接口
import businessCard from '@/views/technologyManager/conversation/components/businessCard.vue'
const detailsPop = ref(null)
const FriendInfo = ref({})
const selItem = ref({})
const keyword = ref('')
const emit = defineEmits(['callBack'])
const userList = ref([])
const urlImg = http.FileUrl
// 1：法人 2：负责人 3：普通成员 4：客户经理 5：业务人员 
const userTagList = ref([{dictCode:'1', dictName:'法人'},{dictCode:'2', dictName:'负责人'}, {dictCode:'3', dictName:'普通成员'},{dictCode:'4', dictName:'客户经理'},{dictCode:'5', dictName:'业务人员'}])
const fullscreenCon = computed(() => {
    return conversationStore.isFullscreen
})
watch(() => fullscreenCon, (val) => {
}, { immediate: true })

function ClickMark() {
    detailsPop.value.style.display = 'none'
}
// 查询用户信息
function workGroupFindFriendInfo(item, e) {
    selItem.value = item
    bCard({ userId: item.id }).then((res) => {
        FriendInfo.value = res.data
        let div = detailsPop.value
        div.style.display = 'inline'
        div.style.left = e.clientX + 'px'
        div.style.top = e.clientY - 20 + 'px'
    })
}
// 点击名片聊天
function ChoseMailList() {
    detailsPop.value.style.display = 'none'
    sgct({userId: FriendInfo.value.id}).then((res) => {
        if (res.code == 0) {
            emit('callBack', res.data)
        } else {
            ElMessage({
                message: res.message,
                type: 'error',
                duration: 3000
            })
        }
    })
}
// 查询
function getData() {
    adbk({ keyword: keyword.value }).then((res) => {
        if(res.code==0) {
            userList.value = res.data
        }
    })
}
function search1(){
    getData()
}
function clear1(){
    keyword.value = ''
    getData()
}
onMounted(()=>{
    getData()
})
</script>

<style scoped lang="scss">
.conmaillist{
    width:100%;
    // height: 640px;
    overflow-y: auto;
    padding: 10px 20px;
    box-sizing: border-box;
    .conmaillist {
        background: #fff;
    }
    .list1{
        width: 50px;
        text-align: center;
        margin: 10px 10px;
        position: relative;
        cursor: pointer;
        .name_tip{
            width: 50px;
            text-align: center;
        }
    }
    .avatar{
        width: 50px;
        height: 50px;
        border-radius: 4px;
        margin: auto;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .no_data{
        width: 200px;
        margin: 200px auto 0;
        .tableEmpty{
            width: 200px;
            height: 200px;
            margin: auto;
        }
    }
    
.detailsPop {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    display: none;
    width: 324px;
    background: #fff;
    box-shadow: 0px 0px 10px 2px rgba(189, 203, 224, 0.3);
    border-radius: 3px;
    // border: solid 1px rgb(240, 242, 245);
}

.ChoseMailList {
    line-height: 46px;
    text-align: center;
    color: #1492ff;
    cursor: pointer;
    padding-bottom: 20px;
    .ChoseMailListBtn{
        width: 99px;
        height: 30px;
        line-height: 30px;
        background: #1492ff;
        color: #fff;
        text-align: center;
        margin: auto;
        border-radius: 3px;
    }
}

.user_tag{
    background: rgba($color: #333333, $alpha: 0.8);
    color: #fff;
    font-size: 12px;
    text-align: center;
    width: 50px;
    line-height: 16px;
    position: absolute;
    border-radius: 4px;
    bottom: 19px;
    left: 0;
    // font-size: 8px;
}
.txl_title{
    margin-top: -30px;
    margin-left: -3px;
    width:331px;
    position: absolute;
}
}
</style>
<style lang="scss">
.conmaillist .el-input__wrapper{
    height: 28px;
}
</style>